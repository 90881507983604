export default {
  i2_b_1: 1,
  i8_a_1: 2,
  i10_a_1: 3,
  i17_10_1: 4,
  i17_11_1: 5,
  i17_12_1: 6,
  i17_11_i: 7,
  i17_11_ii: 9,
  i8_2_i: 10,
  i10_a_i: 11,
};
