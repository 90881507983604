export default [
  {
    category: "All products",
    items: [
      {
        id: 42,
        code: "all",
        type: "All products",
      },
    ],
  },
  {
    category: "Broad product groups",
    items: [
      {
        id: 1,
        code: "ind",
        type: "Industry",
      },
      {
        id: 2,
        code: "agr",
        type: "Agriculture",
      },
      {
        id: 3,
        code: "tex",
        type: "Textile",
      },
      {
        id: 4,
        code: "arm",
        type: "Arms",
      },
      {
        id: 5,
        code: "oil",
        type: "Oil",
      },
      {
        id: 6,
        code: "clo",
        type: "Clothing",
      },
    ],
  },
  {
    category: "Detailed product sectors",
    items: [
      {
        id: 9,
        code: "WTP01",
        type: "Animal Products",
      },
      {
        id: 10,
        code: "WTP02",
        type: "Dairy Products",
      },
      {
        id: 11,
        code: "WTP03",
        type: "Fruit, vegetables, plants",
      },
      {
        id: 12,
        code: "WTP04",
        type: "Coffee, tea",
      },
      {
        id: 13,
        code: "WTP05",
        type: "Cereals & preparations",
      },
      {
        id: 14,
        code: "WTP06",
        type: "Oilseeds, fats & oils",
      },
      {
        id: 15,
        code: "WTP07",
        type: "Sugars and confectionery",
      },
      {
        id: 16,
        code: "WTP08",
        type: "Beverages & tobacco",
      },
      {
        id: 17,
        code: "WTP09",
        type: "Cotton",
      },
      {
        id: 18,
        code: "WTP10",
        type: "Other agricultural products",
      },
      {
        id: 19,
        code: "WTP11",
        type: "Fish & fish products",
      },
      {
        id: 20,
        code: "WTP12",
        type: "Minerals & metals",
      },
      {
        id: 21,
        code: "WTP13",
        type: "Petroleum",
      },
      {
        id: 22,
        code: "WTP14",
        type: "Chemicals",
      },
      {
        id: 23,
        code: "WTP15",
        type: "Wood, paper, etc",
      },
      {
        id: 24,
        code: "WTP16",
        type: "Textiles",
      },
      {
        id: 25,
        code: "WTP17",
        type: "Clothing",
      },
      {
        id: 26,
        code: "WTP18",
        type: "Leather, footwear, etc.",
      },
      {
        id: 27,
        code: "WTP19",
        type: "Non - electrical machinery",
      },
      {
        id: 28,
        code: "WTP20",
        type: "Electrical machinery",
      },
      {
        id: 29,
        code: "WTP21",
        type: "Transport equipment",
      },
      {
        id: 30,
        code: "WTP22",
        type: "Manufactures, n.e.s.",
      },
    ],
  },
  {
    category: "Processing product groups",
    items: [
      {
        id: 32,
        code: "R01",
        type: "Raw",
      },
      {
        id: 33,
        code: "S02",
        type: "Semi-processed",
      },
      {
        id: 34,
        code: "P03",
        type: "Processed",
      },
    ],
  },
];
