import React from "react";
import styled from "styled-components";
import { Alert } from "@material-ui/lab";

const AlertStyled = styled.div`
  flex: 1 0 auto;
`;

export default () => (
  <AlertStyled>
    <Alert severity="info">This visualization is under development!</Alert>
  </AlertStyled>
);
