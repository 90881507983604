import { createSelector } from "reselect";

const getCurrentIndicatorCode = state => state.indicators.currentUIState.indicatorCode;
const sortDataByCountryNameASC = data => data.sort((a, b) => a.name.localeCompare(b.name));

const flattenData = countriesByGeoRegions => {
  let results = [];
  if (countriesByGeoRegions.length > 0) {
    getSingleCountries(countriesByGeoRegions[0], results);
    results = sortDataByCountryNameASC(results);
  }
  return results;
};

const getSingleCountries = (data, results) => {
  if (!data.items) {
    results.push(data);
    return;
  }

  data.items.forEach(item => getSingleCountries({ ...item, region: data.name }, results));
};

const getCountriesByGeoRegions = state => state.metaData.geographicRegions;

export const selectSingleCountries = createSelector(
  getCountriesByGeoRegions,
  countriesByGeoRegions => flattenData(countriesByGeoRegions)
);

export const selectSingleCountriesRegions = createSelector(
  getCountriesByGeoRegions,
  getCurrentIndicatorCode,
  (countriesByGeoRegions, currentIndicatorCode) => {
    if (currentIndicatorCode === "2_b_1") {
      return [
        {
          items: [
            ...flattenData(countriesByGeoRegions),
            {
              id: 296,
              m49Code: "97",
              alpha3Code: "EUN",
              name: "European Union",
              isDeveloped: true,
              isLDC: false,
              isLLDC: false,
              isSIDS: false,
              isOther: false,
            },
          ],
        },
      ];
    }

    return [
      {
        items: [...flattenData(countriesByGeoRegions)],
      },
    ];
  }
);
