import { VALUES } from "modules/meta-data/constants";
import vzConf from "modules/visualizations.config";

export default (indicatorCode, uiConf, dimension, vz) => {
  let measure = uiConf.defaultViewData.measures[0];
  let measureSecondary =
    uiConf.defaultViewData.measure_secondary[0] || uiConf.viewData.measures_dependency[measure];
  const measureCrossCutting = uiConf.defaultViewData.measure_cross_cutting[0];

  if (vzConf[vz].measureSelectionType === VALUES.MEASURE_SELECTION_SEPARATED) {
    if (!measureSecondary || measureSecondary === "") {
      measureSecondary = Object.values(uiConf.viewData.measures_dependency)[0];
    }
  }

  return {
    indicatorCode: indicatorCode,
    dimension: dimension,
    visualization: vz,
    measure: measure,
    measure_secondary: measureSecondary,
    measure_cross_cutting: measureCrossCutting,
  };
};
