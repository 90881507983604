import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setVisualizationDimension } from "../../../reducer";
import ViewSwitcher from "./VzSwitcher";
import { selectIndicatorMetaData } from "modules/meta-data/selectors/meta-data";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { setVisualizationCharts } from "modules/indicators/reducer";

const mapStateToProps = (state, { match }) => ({
  metaData: selectIndicatorMetaData(state, match.params.code),
  currentUIState: selectCurrentUIState(state),
});

const mapDispatchToProps = dispatch => ({
  setVisualizationDimension: viewCode => dispatch(setVisualizationDimension(viewCode)),
  setVisualizationCharts: data => dispatch(setVisualizationCharts(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ViewSwitcher)
);
