import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import IndicatorsComponent from "./IndicatorsComponent";
import HomeFeaturedGoalsLoading from "./HomeFeaturedGoalsLoading";
import { GoalsStyled } from "./Styled";
import imgChart1 from "./images/chart1.png";
import imgChart2 from "./images/chart2.png";
import imgChart3 from "./images/chart3.png";
import imgChart4 from "./images/chart4.png";
import SDG_icon_2 from "modules/indicators/images/icons/sdg_icon_2.png";
import SDG_icon_8 from "modules/indicators/images/icons/sdg_icon_8.png";
import SDG_icon_10 from "modules/indicators/images/icons/sdg_icon_10.png";
import SDG_icon_17 from "modules/indicators/images/icons/sdg_icon_17.png";
import { FormattedMessage } from "react-intl";

const Goals = ({ goals, targets, indicators }) => {
  // default content should be show on homepage
  const selectedGoals = [2, 8, 10, 17];
  const [selectedTargets, setSelectedTarget] = useState({
    2: 1 /* 2.b */,
    8: 2 /* 8.a */,
    10: 3 /* 10.a */,
    17: 4 /* 17.10 */,
  });

  const imgSrc = {
    2: imgChart1,
    8: imgChart2,
    10: imgChart3,
    17: imgChart4,
  };

  const goalsFiltered = goals.filter(d => selectedGoals.indexOf(+d.id) !== -1);
  const targetsFiltered = targets.filter(d => selectedGoals.indexOf(+d.goalId) !== -1);
  const targetIds = targetsFiltered.map(d => d.id);

  /* map indicators to corresponding target */
  const indicatorsByTargetId = targetIds.reduce(
    (acc, id) => ({
      ...acc,
      [id]: indicators.filter(d => d?.targetId === id),
    }),
    {}
  );

  if (goals.length === 0 || targets.length === 0 || indicators.length === 0) {
    return <HomeFeaturedGoalsLoading goals={selectedGoals} />;
  }

  return (
    <GoalsStyled>
      <ul>
        {goalsFiltered.map(goal => (
          <li key={`goal-${goal.id}`} className="item">
            <div className="inner-wrapper">
              <div className="goal-targets">
                <div className="goal-heading">
                  <div className="img">
                    {+goal.id === 2 && (
                      <img src={SDG_icon_2} width="100" height="100" alt={goal.shortDescription} />
                    )}
                    {+goal.id === 8 && (
                      <img src={SDG_icon_8} width="100" height="100" alt={goal.shortDescription} />
                    )}
                    {+goal.id === 10 && (
                      <img src={SDG_icon_10} width="100" height="100" alt={goal.shortDescription} />
                    )}
                    {+goal.id === 17 && (
                      <img src={SDG_icon_17} width="100" height="100" alt={goal.shortDescription} />
                    )}
                  </div>

                  <div className="txt">
                    <span className="goal-title">
                      <FormattedMessage id="misc.analyse" defaultMessage="Analyse" />
                    </span>

                    <span className="goal-code">
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: goal.id }}
                      />
                      : {goal.shortDescription}
                      <Tooltip title={goal.longDescription}>
                        <sup className="goal-info">
                          <InfoOutlinedIcon style={{ fontSize: 15 }} />
                        </sup>
                      </Tooltip>
                    </span>
                  </div>
                </div>

                <ul className="targets">
                  {targetsFiltered
                    .filter(d => d.goalId === goal.id)
                    .map(target => (
                      <li className="target" key={`target-it${target.targetId}`}>
                        <label htmlFor={`target-${target.id}`}>
                          <input
                            type="radio"
                            id={`target-${target.id}`}
                            name={goal.id}
                            checked={target.id === selectedTargets[goal.id]}
                            onChange={() => setSelectedTarget({ ...selectedTargets, [goal.id]: target.id })}
                          />
                          {target.shortDescription}
                        </label>

                        {target.id === selectedTargets[goal.id] && (
                          <p className="target-desc">{target.longDescription}</p>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="target-indicators">
                <IndicatorsComponent
                  indicators={indicatorsByTargetId[selectedTargets[(goal?.id)]]}
                  imgSrc={imgSrc[goal.id]}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </GoalsStyled>
  );
};

Goals.propTypes = {
  goals: PropTypes.array.isRequired,
  targets: PropTypes.array.isRequired,
  indicators: PropTypes.array.isRequired,
};

export default Goals;
