import { concat, of } from "rxjs";
import { debounceTime, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  setCurrentUIState,
  setDefaultFilterParams,
  setFilterParams,
  setFilterParamsProduct,
  setFilterParamsYear,
  setSecondarySeriesData,
} from "../reducer";
import isResponseValid from "../../../utils/isResponseValid";
import { addError } from "modules/ui/reducer";
import { INDICATORS, VALUES } from "modules/meta-data/constants";
import { getFilterParams, validateFilterParams } from "modules/indicators/helpers";
import * as constants from "../../constants";

export default (action$, state$, { ajax, apiConfig, timestamp, indicatorsConfig, visualizationsConfig }) =>
  action$.pipe(
    ofType(
      setDefaultFilterParams,
      setFilterParams,
      setCurrentUIState,
      setFilterParamsProduct,
      setFilterParamsYear
    ),
    debounceTime(10),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { indicatorCode, visualization, measure_secondary } = state?.indicators?.currentUIState;

      const indicatorConf = indicatorsConfig[INDICATORS[`i${indicatorCode}`]];

      const vzConf = visualizationsConfig[visualization];

      const paramsNeededKeys = {
        ...indicatorConf?.viewData?.params,
        ...vzConf?.params,
      };

      const selectionType = vzConf?.measureSelectionType;

      if (
        validateFilterParams(paramsNeededKeys, state?.indicators?.filterParams) === false ||
        selectionType === VALUES.MEASURE_SELECTION_MERGED ||
        !measure_secondary ||
        measure_secondary === ""
      ) {
        return false;
      }

      return {
        action: action,
        measure: measure_secondary,
        params: getFilterParams(paramsNeededKeys, state?.indicators?.filterParams),
      };
    }),
    filter(data => data !== false),
    mergeMap(data => {
      const { params, measure, action } = data;

      const urlParams = Object.keys(params)
        .reduce((acc, key) => [...acc, `${key}=${params[key]}`], [])
        .join("&");

      const request = {
        responseType: "json",
        method: "GET",
        mode: "cors",
        crossDomain: true,
        url: `${apiConfig.apiRoot}/measures/${measure}?${urlParams}`,
        headers: {
          "Ocp-Apim-Subscription-Key": apiConfig.OcpApimSubscriptionKey,
        },
      };

      return concat(
        of(setSecondarySeriesData({ status: constants.STATUS_LOADING, data: [] })),
        ajax(request).pipe(
          map(data => {
            if (!isResponseValid(data.response)) {
              return addError({
                timestamp: timestamp(),
                message: "error.invalid-response",
                retryAction: action,
              });
            }

            return setSecondarySeriesData({
              status: constants.STATUS_OK,
              data: data.response,
            });
          })
        )
      );
    })
  );
