import { map } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  addChart,
  updateTitle,
  removeChart,
  updateReportsSuccessfully,
  deleteReports,
  updateChartDescription, updateChartLayout,
} from "../reducer";

export default (action$, state$) =>
  action$.pipe(
    ofType(addChart, updateTitle, removeChart, deleteReports, updateChartDescription, updateChartLayout),
    map(() => {
      const reportData = JSON.stringify(state$.value.reports);
      localStorage.setItem("SDGReports", reportData);
      return updateReportsSuccessfully();
    })
  );
