import { createSelector } from "reselect";
import indicators from "modules/meta-data/constants/indicators";

const getIndicatorsMetaData = state => state.metaData.indicators;
const getCurrentIndicatorCode = state => state.indicators.currentUIState.indicatorCode;

export const selectRationale = createSelector(
  getIndicatorsMetaData,
  getCurrentIndicatorCode,
  (metaData, currentIndicator) => {
    const data = metaData.find(d => d.id === indicators[`i${currentIndicator}`]);

    return data?.rationale;
  }
);
