export default {
  LINE_CHART: 1,
  BUBBLE_CHART: 2,
  VERTICAL_BAR_CHART: 3,
  HORIZONTAL_BAR_CHART: 4,
  FLOW_CHART: 5,
  MAP: 6,
  RADAR_CHART: 7,
  GAP_CHART: 8,
  TREEMAP_CHART: 9,
  SCATTER_PLOT_CHART: 10,
  AREA_CHART: 11,
  TREE_RADIAL_CHART: 12,
};
