export default [
  [
    {
      id: 5689,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 1995,
      value: 0,
      name: "Australia",
    },
    {
      id: 5690,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 1996,
      value: 0,
      name: "Australia",
    },
    {
      id: 5691,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 1997,
      value: 0,
      name: "Australia",
    },
    {
      id: 5692,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 1998,
      value: 1.2604899497487436,
      name: "Australia",
    },
    {
      id: 5693,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 1999,
      value: 2.410774193548387,
      name: "Australia",
    },
    {
      id: 5694,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2000,
      value: 0,
      name: "Australia",
    },
    {
      id: 5695,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2001,
      value: 0,
      name: "Australia",
    },
    {
      id: 5696,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2002,
      value: 0,
      name: "Australia",
    },
    {
      id: 5697,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2003,
      value: 0,
      name: "Australia",
    },
    {
      id: 5698,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2004,
      value: 0,
      name: "Australia",
    },
    {
      id: 5699,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2005,
      value: 0,
      name: "Australia",
    },
    {
      id: 5700,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2006,
      value: 0,
      name: "Australia",
    },
    {
      id: 5701,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2007,
      value: 0,
      name: "Australia",
    },
    {
      id: 5702,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2008,
      value: 0,
      name: "Australia",
    },
    {
      id: 5703,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2009,
      value: 0,
      name: "Australia",
    },
    {
      id: 5704,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2010,
      value: 0,
      name: "Australia",
    },
    {
      id: 5705,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2011,
      value: 0,
      name: "Australia",
    },
    {
      id: 5706,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2012,
      value: 0,
      name: "Australia",
    },
    {
      id: 5707,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2013,
      value: 0,
      name: "Australia",
    },
    {
      id: 5708,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2014,
      value: 0,
      name: "Australia",
    },
    {
      id: 5709,
      indicatorTypeId: 1,
      geoAreaId: 26,
      year: 2015,
      value: 0,
      name: "Australia",
      region: "Australia and New Zealand",
      cx: 840.375,
      cy: 318.96043501828456,
      x: 445.77831374593046,
      y: 409.75527190630004,
      radius: 2,
      index: 0,
      vy: 3.095312974348379e-7,
      vx: 1.0081307280785235e-7,
    },
  ],
  [
    {
      id: 5710,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 1995,
      value: 0,
      name: "Barbados",
    },
    {
      id: 5711,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 1996,
      value: 0,
      name: "Barbados",
    },
    {
      id: 5712,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 1997,
      value: 0,
      name: "Barbados",
    },
    {
      id: 5713,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 1998,
      value: 0,
      name: "Barbados",
    },
    {
      id: 5714,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 1999,
      value: 0,
      name: "Barbados",
    },
    {
      id: 5715,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 2000,
      value: 0,
      name: "Barbados",
    },
    {
      id: 5716,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 2001,
      value: 0,
      name: "Barbados",
    },
    {
      id: 5717,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 2002,
      value: 0.0025475,
      name: "Barbados",
    },
    {
      id: 5718,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 2003,
      value: 0.005363,
      name: "Barbados",
    },
    {
      id: 5719,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 2004,
      value: 0.0050056,
      name: "Barbados",
    },
    {
      id: 5720,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 2005,
      value: 0.0021365,
      name: "Barbados",
    },
    {
      id: 5721,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 2006,
      value: 0.0012582000000000001,
      name: "Barbados",
    },
    {
      id: 5722,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 2015,
      value: 0,
      name: "Barbados",
      region: "Caribbean",
      cx: 321.0790749638889,
      cy: 214.5388148078656,
      x: 163.99215418099837,
      y: 282.9187427857774,
      radius: 2,
      index: 1,
      vy: 4.793539285935955e-7,
      vx: 1.330647808202496e-7,
    },
    {
      id: 5723,
      indicatorTypeId: 1,
      geoAreaId: 32,
      year: 2016,
      value: 0,
      name: "Barbados",
      value2015: 0,
      value2016: 0,
    },
  ],
  [
    {
      id: 5724,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 1995,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5725,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 1996,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5726,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 1997,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5727,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 1998,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5728,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 1999,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5729,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2000,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5730,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2001,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5731,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2002,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5732,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2003,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5733,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2004,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5734,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2005,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5735,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2006,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5736,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2007,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5737,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2008,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5738,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2009,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5739,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2010,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5740,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2011,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5741,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2012,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5742,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2013,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5743,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2014,
      value: 0,
      name: "Brazil",
    },
    {
      id: 5744,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2015,
      value: 0,
      name: "Brazil",
      region: "South America",
      cx: 333.18055555555554,
      cy: 276.8310123056154,
      x: 165.967975580515,
      y: 365.56719891141364,
      radius: 2,
      index: 2,
      vy: -1.9908445412524964e-7,
      vx: 4.4462800408974446e-7,
    },
    {
      id: 5745,
      indicatorTypeId: 1,
      geoAreaId: 38,
      year: 2016,
      value: 0,
      name: "Brazil",
      value2015: 0,
      value2016: 0,
    },
  ],
  [
    {
      id: 5746,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 1995,
      value: 0,
      name: "Bulgaria",
    },
    {
      id: 5747,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 1996,
      value: 0,
      name: "Bulgaria",
    },
    {
      id: 5748,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 1997,
      value: 0,
      name: "Bulgaria",
    },
    {
      id: 5749,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 1998,
      value: 0,
      name: "Bulgaria",
    },
    {
      id: 5750,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 1999,
      value: 0,
      name: "Bulgaria",
    },
    {
      id: 5751,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 2000,
      value: 0,
      name: "Bulgaria",
    },
    {
      id: 5752,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 2001,
      value: 0,
      name: "Bulgaria",
    },
    {
      id: 5753,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 2002,
      value: 0,
      name: "Bulgaria",
    },
    {
      id: 5754,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 2003,
      value: 0,
      name: "Bulgaria",
    },
    {
      id: 5755,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 2004,
      value: 1.4937811286646268,
      name: "Bulgaria",
    },
    {
      id: 5756,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 2005,
      value: 3.4579071941284334,
      name: "Bulgaria",
    },
    {
      id: 5757,
      indicatorTypeId: 1,
      geoAreaId: 42,
      year: 2006,
      value: 3.625095129058952,
      name: "Bulgaria",
    },
  ],
  [
    {
      id: 5758,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 1995,
      value: 37.479939936694294,
      name: "Canada",
    },
    {
      id: 5759,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 1996,
      value: 4.264125435011937,
      name: "Canada",
    },
    {
      id: 5760,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 1997,
      value: 0,
      name: "Canada",
    },
    {
      id: 5761,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 1998,
      value: 0,
      name: "Canada",
    },
    {
      id: 5762,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 1999,
      value: 0,
      name: "Canada",
    },
    {
      id: 5763,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2000,
      value: 0,
      name: "Canada",
    },
    {
      id: 5764,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2001,
      value: 0,
      name: "Canada",
    },
    {
      id: 5765,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2002,
      value: 0,
      name: "Canada",
    },
    {
      id: 5766,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2003,
      value: 61.61300261351294,
      name: "Canada",
    },
    {
      id: 5767,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2004,
      value: 72.15497081454733,
      name: "Canada",
    },
    {
      id: 5768,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2005,
      value: 83.41727895161046,
      name: "Canada",
    },
    {
      id: 5769,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2006,
      value: 88.25391041670952,
      name: "Canada",
    },
    {
      id: 5770,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2007,
      value: 83.65521805481957,
      name: "Canada",
    },
    {
      id: 5771,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2008,
      value: 84.36703403808664,
      name: "Canada",
    },
    {
      id: 5772,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2009,
      value: 78.94231131197877,
      name: "Canada",
    },
    {
      id: 5773,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2010,
      value: 85.62141970135522,
      name: "Canada",
    },
    {
      id: 5774,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2011,
      value: 89.21603010850652,
      name: "Canada",
    },
    {
      id: 5775,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2012,
      value: 100.50958265102207,
      name: "Canada",
    },
    {
      id: 5776,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2013,
      value: 81.71711040795186,
      name: "Canada",
    },
    {
      id: 5777,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2014,
      value: 73.40867992766727,
      name: "Canada",
    },
    {
      id: 5778,
      indicatorTypeId: 1,
      geoAreaId: 48,
      year: 2015,
      value: 63.42767787333855,
      name: "Canada",
      region: "Northern America",
      cx: 196.06136502777775,
      cy: 67.89656524168868,
      x: 89.25851465249585,
      y: 115.83367594198478,
      radius: 42.18332753378028,
      index: 3,
      vy: -2.945184432492139e-7,
      vx: -6.306784885968478e-9,
    },
  ],
  [
    {
      id: 5779,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 1995,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5780,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 1996,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5781,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 1997,
      value: 0.1501832243812803,
      name: "Sri Lanka",
    },
    {
      id: 5782,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 1998,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5783,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 1999,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5784,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2000,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5785,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2001,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5786,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2002,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5787,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2003,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5788,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2004,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5789,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2005,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5790,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2006,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5791,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2007,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5792,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2008,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5793,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2009,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5794,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2010,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5795,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2011,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5796,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2012,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5797,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2013,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5798,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2014,
      value: 0,
      name: "Sri Lanka",
    },
    {
      id: 5799,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2015,
      value: 0,
      name: "Sri Lanka",
      region: "Southern Asia",
      cx: 696.225,
      cy: 231.26722906381062,
      x: 366.19930787894396,
      y: 301.8154271483589,
      radius: 2,
      index: 4,
      vy: 1.9576053367163217e-7,
      vx: 1.6835828231134104e-7,
    },
    {
      id: 5800,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2016,
      value: 0,
      name: "Sri Lanka",
      value2015: 0,
      value2016: 0,
    },
    {
      id: 5801,
      indicatorTypeId: 1,
      geoAreaId: 52,
      year: 2017,
      value: 0,
      name: "Sri Lanka",
    },
  ],
  [
    {
      id: 5802,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 1995,
      value: 14.7862,
      name: "Colombia",
    },
    {
      id: 5803,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 1996,
      value: 18.956690000000002,
      name: "Colombia",
    },
    {
      id: 5804,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 1997,
      value: 22.80018,
      name: "Colombia",
    },
    {
      id: 5805,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 1998,
      value: 20.115,
      name: "Colombia",
    },
    {
      id: 5806,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 1999,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5807,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2000,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5808,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2001,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5809,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2002,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5810,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2003,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5811,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2004,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5812,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2005,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5813,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2006,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5814,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2007,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5815,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2008,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5816,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2009,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5817,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2010,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5818,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2011,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5819,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2012,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5820,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2013,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5821,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2014,
      value: 0,
      name: "Colombia",
    },
    {
      id: 5822,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2015,
      value: 0,
      name: "Colombia",
      region: "South America",
      cx: 287.79999999999995,
      cy: 239.31353793962217,
      x: 133.06840915142755,
      y: 320.45150622443384,
      radius: 2,
      index: 5,
      vy: -5.525465513933267e-8,
      vx: -7.044624367182462e-7,
    },
    {
      id: 5823,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2016,
      value: 0,
      name: "Colombia",
      value2015: 0,
      value2016: 0,
    },
    {
      id: 5824,
      indicatorTypeId: 1,
      geoAreaId: 56,
      year: 2017,
      value: 0,
      name: "Colombia",
    },
  ],
  [
    {
      id: 5825,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 1995,
      value: 3.4215981383087475,
      name: "Cyprus",
    },
    {
      id: 5826,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 1996,
      value: 3.040887390128374,
      name: "Cyprus",
    },
    {
      id: 5827,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 1997,
      value: 1.8778069809015037,
      name: "Cyprus",
    },
    {
      id: 5828,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 1998,
      value: 3.4586935401511476,
      name: "Cyprus",
    },
    {
      id: 5829,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 1999,
      value: 3.20435990387657,
      name: "Cyprus",
    },
    {
      id: 5830,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 2000,
      value: 2.590418354786845,
      name: "Cyprus",
    },
    {
      id: 5831,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 2001,
      value: 3.689899042427451,
      name: "Cyprus",
    },
    {
      id: 5832,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 2002,
      value: 4.181705111209106,
      name: "Cyprus",
    },
    {
      id: 5833,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 2003,
      value: 2.6909230502202854,
      name: "Cyprus",
    },
    {
      id: 5834,
      indicatorTypeId: 1,
      geoAreaId: 63,
      year: 2004,
      value: 0.9626535816785309,
      name: "Cyprus",
    },
  ],
  [
    {
      id: 5835,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 1995,
      value: 40.08942252141368,
      name: "Czechia",
    },
    {
      id: 5836,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 1996,
      value: 39.24491694270567,
      name: "Czechia",
    },
    {
      id: 5837,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 1997,
      value: 40.191281898938165,
      name: "Czechia",
    },
    {
      id: 5838,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 1998,
      value: 41.77358315219917,
      name: "Czechia",
    },
    {
      id: 5839,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 1999,
      value: 34.55643382485879,
      name: "Czechia",
    },
    {
      id: 5840,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 2000,
      value: 24.34011757822536,
      name: "Czechia",
    },
    {
      id: 5841,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 2001,
      value: 32.09463552678678,
      name: "Czechia",
    },
    {
      id: 5842,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 2002,
      value: 74.416929171759,
      name: "Czechia",
    },
    {
      id: 5843,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 2003,
      value: 99.12651990499485,
      name: "Czechia",
    },
    {
      id: 5844,
      indicatorTypeId: 1,
      geoAreaId: 64,
      year: 2004,
      value: 51.230070331423455,
      name: "Czechia",
    },
  ],
  [
    {
      id: 5845,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 1995,
      value: 58.53792634830485,
      name: "Hungary",
    },
    {
      id: 5846,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 1996,
      value: 30.08044722015984,
      name: "Hungary",
    },
    {
      id: 5847,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 1997,
      value: 22.947369360285883,
      name: "Hungary",
    },
    {
      id: 5848,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 1998,
      value: 23.950373520106343,
      name: "Hungary",
    },
    {
      id: 5849,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 1999,
      value: 22.066590529737333,
      name: "Hungary",
    },
    {
      id: 5850,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 2000,
      value: 13.845813091564168,
      name: "Hungary",
    },
    {
      id: 5851,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 2001,
      value: 11.661838109532619,
      name: "Hungary",
    },
    {
      id: 5852,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 2002,
      value: 12.711010004394694,
      name: "Hungary",
    },
    {
      id: 5853,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 2003,
      value: 19.183558223860196,
      name: "Hungary",
    },
    {
      id: 5854,
      indicatorTypeId: 1,
      geoAreaId: 93,
      year: 2004,
      value: 14.4367948375429,
      name: "Hungary",
    },
  ],
  [
    {
      id: 5855,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 1995,
      value: 0.05564858946283654,
      name: "Iceland",
    },
    {
      id: 5856,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 1996,
      value: 0.007518796992481203,
      name: "Iceland",
    },
    {
      id: 5857,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 1997,
      value: 0.0014103518817367294,
      name: "Iceland",
    },
    {
      id: 5858,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 1998,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5859,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 1999,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5860,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2000,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5861,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2001,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5862,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2002,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5863,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2003,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5864,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2004,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5865,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2005,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5866,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2006,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5867,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2007,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5868,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2008,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5869,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2009,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5870,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2010,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5871,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2011,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5872,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2012,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5873,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2013,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5874,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2014,
      value: 0,
      name: "Iceland",
    },
    {
      id: 5875,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2015,
      value: 0,
      name: "Iceland",
      region: "Northern Europe",
      cx: 431.95,
      cy: 19.590987485000994,
      x: 217.60089989463387,
      y: 58.510758609852175,
      radius: 2,
      index: 6,
      vy: -0.0000010048836430954686,
      vx: 6.250994526694195e-7,
    },
    {
      id: 5876,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2016,
      value: 0,
      name: "Iceland",
      value2015: 0,
      value2016: 0,
    },
    {
      id: 5877,
      indicatorTypeId: 1,
      geoAreaId: 94,
      year: 2017,
      value: 0,
      name: "Iceland",
    },
  ],
  [
    {
      id: 5878,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 1995,
      value: 2.0705,
      name: "India",
    },
    {
      id: 5879,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 1996,
      value: 2.1,
      name: "India",
    },
    {
      id: 5880,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 1997,
      value: 3.9000000000000004,
      name: "India",
    },
    {
      id: 5881,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 1998,
      value: 2.5,
      name: "India",
    },
    {
      id: 5882,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 1999,
      value: 2.4000000000000004,
      name: "India",
    },
    {
      id: 5883,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2000,
      value: 1.1,
      name: "India",
    },
    {
      id: 5884,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2001,
      value: 1.44,
      name: "India",
    },
    {
      id: 5885,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2002,
      value: 0.9610000000000001,
      name: "India",
    },
    {
      id: 5886,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2003,
      value: 9.32,
      name: "India",
    },
    {
      id: 5887,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2004,
      value: 12.988999999999999,
      name: "India",
    },
    {
      id: 5888,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2005,
      value: 12.41,
      name: "India",
    },
    {
      id: 5889,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2006,
      value: 10.770000000000003,
      name: "India",
    },
    {
      id: 5890,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2007,
      value: 28.599999999999998,
      name: "India",
    },
    {
      id: 5891,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2008,
      value: 64.01,
      name: "India",
    },
    {
      id: 5892,
      indicatorTypeId: 1,
      geoAreaId: 95,
      year: 2009,
      value: 101.99,
      name: "India",
    },
  ],
  [
    {
      id: 5893,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 1995,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5894,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 1996,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5895,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 1997,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5896,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 1998,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5897,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 1999,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5898,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2000,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5899,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2001,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5900,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2002,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5901,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2003,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5902,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2004,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5903,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2005,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5904,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2006,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5905,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2007,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5906,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2008,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5907,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2009,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5908,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2010,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5909,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2011,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5910,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2012,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5911,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2013,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5912,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2014,
      value: 0,
      name: "Indonesia",
    },
    {
      id: 5913,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2015,
      value: 0,
      name: "Indonesia",
      region: "South-Eastern Asia",
      cx: 800.3333333333333,
      cy: 263.3641953649791,
      x: 426.6808514592979,
      y: 339.38723268376515,
      radius: 2,
      index: 7,
      vy: -5.718275266603878e-8,
      vx: 4.7510581019866503e-7,
    },
    {
      id: 5914,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2016,
      value: 0,
      name: "Indonesia",
      value2015: 0,
      value2016: 0,
    },
    {
      id: 5915,
      indicatorTypeId: 1,
      geoAreaId: 96,
      year: 2017,
      value: 0,
      name: "Indonesia",
    },
  ],
  [
    {
      id: 5916,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 1995,
      value: 0.018822,
      name: "Israel",
    },
    {
      id: 5917,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 1996,
      value: 12.676,
      name: "Israel",
    },
    {
      id: 5918,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 1997,
      value: 5.9036,
      name: "Israel",
    },
    {
      id: 5919,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 1998,
      value: 0.98,
      name: "Israel",
    },
    {
      id: 5920,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 1999,
      value: 1.409,
      name: "Israel",
    },
    {
      id: 5921,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2000,
      value: 0,
      name: "Israel",
    },
    {
      id: 5922,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2001,
      value: 4.883,
      name: "Israel",
    },
    {
      id: 5923,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2002,
      value: 5.9350000000000005,
      name: "Israel",
    },
    {
      id: 5924,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2003,
      value: 3.793,
      name: "Israel",
    },
    {
      id: 5925,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2004,
      value: 0.598,
      name: "Israel",
    },
    {
      id: 5926,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2005,
      value: 0.7012,
      name: "Israel",
    },
    {
      id: 5927,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2006,
      value: 0.1696,
      name: "Israel",
    },
    {
      id: 5928,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2007,
      value: 0,
      name: "Israel",
    },
    {
      id: 5929,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2008,
      value: 0.1891,
      name: "Israel",
    },
    {
      id: 5930,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2009,
      value: 1.36476,
      name: "Israel",
    },
    {
      id: 5931,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2010,
      value: 1.6166900000000002,
      name: "Israel",
    },
    {
      id: 5932,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2011,
      value: 1.9724199999999998,
      name: "Israel",
    },
    {
      id: 5933,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2012,
      value: 0.79755,
      name: "Israel",
    },
    {
      id: 5934,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2013,
      value: 1.0589999999999997,
      name: "Israel",
    },
    {
      id: 5935,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2014,
      value: 1.81525,
      name: "Israel",
    },
    {
      id: 5936,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2015,
      value: 1.1331499999999999,
      name: "Israel",
      region: "Western Asia",
      cx: 572.7631944444445,
      cy: 161.32532768892503,
      x: 301.8769332628751,
      y: 224.53059688903792,
      radius: 2.7178843546161566,
      index: 8,
      vy: -5.531798204835695e-8,
      vx: -1.4713629418768732e-7,
    },
    {
      id: 5937,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2016,
      value: 0.5807399999999999,
      name: "Israel",
      value2015: 1.1331499999999999,
      value2016: 0.5807399999999999,
      growthCommit: 1.7138899999999997,
      growthDisb: -0.55241,
    },
    {
      id: 5938,
      indicatorTypeId: 1,
      geoAreaId: 100,
      year: 2017,
      value: 1.40376,
      name: "Israel",
    },
  ],
  [
    {
      id: 5939,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 1995,
      value: 1.426212929268483,
      name: "Republic of Korea",
    },
    {
      id: 5940,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 1996,
      value: 2.9833924487022254,
      name: "Republic of Korea",
    },
    {
      id: 5941,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 1997,
      value: 3.258735733176118,
      name: "Republic of Korea",
    },
    {
      id: 5942,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 1998,
      value: 2.5687925010287,
      name: "Republic of Korea",
    },
    {
      id: 5943,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 1999,
      value: 12.617588918952983,
      name: "Republic of Korea",
    },
    {
      id: 5944,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2000,
      value: 16.888344610650712,
      name: "Republic of Korea",
    },
    {
      id: 5945,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2001,
      value: 20.139511300557405,
      name: "Republic of Korea",
    },
    {
      id: 5946,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2002,
      value: 21.261488330826683,
      name: "Republic of Korea",
    },
    {
      id: 5947,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2003,
      value: 20.64426614599099,
      name: "Republic of Korea",
    },
    {
      id: 5948,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2004,
      value: 22.351848065641025,
      name: "Republic of Korea",
    },
    {
      id: 5949,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2005,
      value: 28.219441144400772,
      name: "Republic of Korea",
    },
    {
      id: 5950,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2006,
      value: 30.477889670491514,
      name: "Republic of Korea",
    },
    {
      id: 5951,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2007,
      value: 31.207719537200195,
      name: "Republic of Korea",
    },
    {
      id: 5952,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2008,
      value: 29.672064703853952,
      name: "Republic of Korea",
    },
    {
      id: 5953,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2009,
      value: 31.12151801586618,
      name: "Republic of Korea",
    },
    {
      id: 5954,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2010,
      value: 34.07259687248432,
      name: "Republic of Korea",
    },
    {
      id: 5955,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2011,
      value: 32.00419744929614,
      name: "Republic of Korea",
    },
    {
      id: 5956,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2012,
      value: 32.810436936487434,
      name: "Republic of Korea",
    },
    {
      id: 5957,
      indicatorTypeId: 1,
      geoAreaId: 109,
      year: 2013,
      value: 28.359973771210427,
      name: "Republic of Korea",
    },
  ],
  [
    {
      id: 5958,
      indicatorTypeId: 1,
      geoAreaId: 129,
      year: 2007,
      value: 0.09962646888288557,
      name: "Mauritius",
    },
    {
      id: 5959,
      indicatorTypeId: 1,
      geoAreaId: 129,
      year: 2013,
      value: 0.3257328990228013,
      name: "Mauritius",
    },
    {
      id: 5960,
      indicatorTypeId: 1,
      geoAreaId: 129,
      year: 2014,
      value: 0.4898500351331325,
      name: "Mauritius",
    },
    {
      id: 5961,
      indicatorTypeId: 1,
      geoAreaId: 129,
      year: 2015,
      value: 1.1410087087489695,
      name: "Mauritius",
      region: "Eastern Africa",
      cx: 633.7155083694445,
      cy: 305.3603900481137,
      x: 331.5081807699779,
      y: 392.98715804430213,
      radius: 2.722863081226376,
      index: 9,
      vy: 1.795493883585469e-7,
      vx: 6.457643610351506e-8,
    },
    {
      id: 5962,
      indicatorTypeId: 1,
      geoAreaId: 129,
      year: 2016,
      value: 0.7033931686455461,
      name: "Mauritius",
      value2015: 1.1410087087489695,
      value2016: 0.7033931686455461,
      growthCommit: 1.8444018773945157,
      growthDisb: -0.4376155401034234,
    },
    {
      id: 5963,
      indicatorTypeId: 1,
      geoAreaId: 129,
      year: 2017,
      value: 0.14501160092807425,
      name: "Mauritius",
    },
  ],
  [
    {
      id: 5964,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 1995,
      value: 0,
      name: "Mexico",
    },
    {
      id: 5965,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 1996,
      value: 0,
      name: "Mexico",
    },
    {
      id: 5966,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 1997,
      value: 46.6,
      name: "Mexico",
    },
    {
      id: 5967,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 1998,
      value: 7,
      name: "Mexico",
    },
    {
      id: 5968,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 1999,
      value: 4.4,
      name: "Mexico",
    },
    {
      id: 5969,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2000,
      value: 17.9,
      name: "Mexico",
    },
    {
      id: 5970,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2001,
      value: 5.9,
      name: "Mexico",
    },
    {
      id: 5971,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2002,
      value: 9.5,
      name: "Mexico",
    },
    {
      id: 5972,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2003,
      value: 0.8,
      name: "Mexico",
    },
    {
      id: 5973,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2004,
      value: 3.1,
      name: "Mexico",
    },
    {
      id: 5974,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2005,
      value: 14.3,
      name: "Mexico",
    },
    {
      id: 5975,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2006,
      value: 21.9,
      name: "Mexico",
    },
    {
      id: 5976,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2007,
      value: 21.299999999999997,
      name: "Mexico",
    },
    {
      id: 5977,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2008,
      value: 12.32,
      name: "Mexico",
    },
    {
      id: 5978,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2009,
      value: 13.46,
      name: "Mexico",
    },
    {
      id: 5979,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2010,
      value: 15.67,
      name: "Mexico",
    },
    {
      id: 5980,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2011,
      value: 11.03,
      name: "Mexico",
    },
    {
      id: 5981,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2012,
      value: 0.72,
      name: "Mexico",
    },
    {
      id: 5982,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2013,
      value: 0,
      name: "Mexico",
    },
    {
      id: 5983,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2014,
      value: 0,
      name: "Mexico",
    },
    {
      id: 5984,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2015,
      value: 0,
      name: "Mexico",
      region: "Central America",
      cx: 207.71666666666664,
      cy: 186.88412083354714,
      x: 87.88181684271215,
      y: 257.3800879129976,
      radius: 2,
      index: 10,
      vy: -1.1677020985866232e-7,
      vx: -7.493891120953522e-7,
    },
    {
      id: 5985,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2016,
      value: 0,
      name: "Mexico",
      value2015: 0,
      value2016: 0,
    },
    {
      id: 5986,
      indicatorTypeId: 1,
      geoAreaId: 130,
      year: 2017,
      value: 0,
      name: "Mexico",
    },
  ],
  [
    {
      id: 5987,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 1995,
      value: 0.5854639260059468,
      name: "Morocco",
    },
    {
      id: 5988,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 1996,
      value: 0,
      name: "Morocco",
    },
    {
      id: 5989,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 1997,
      value: 0.7505951439611603,
      name: "Morocco",
    },
    {
      id: 5990,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 1998,
      value: 0.5275698270387607,
      name: "Morocco",
    },
    {
      id: 5991,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 1999,
      value: 0.40430747937388434,
      name: "Morocco",
    },
    {
      id: 5992,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2000,
      value: 0.2028113861794339,
      name: "Morocco",
    },
    {
      id: 5993,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2001,
      value: 0.12324188985643159,
      name: "Morocco",
    },
    {
      id: 5994,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2002,
      value: 0.003992529131095613,
      name: "Morocco",
    },
    {
      id: 5995,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2003,
      value: 3.9650595425641004,
      name: "Morocco",
    },
    {
      id: 5996,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2004,
      value: 3.5867095423441153,
      name: "Morocco",
    },
    {
      id: 5997,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2005,
      value: 4.017931925237899,
      name: "Morocco",
    },
    {
      id: 5998,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2006,
      value: 5.866808151818623,
      name: "Morocco",
    },
    {
      id: 5999,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2007,
      value: 7.363836106929246,
      name: "Morocco",
    },
    {
      id: 6000,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2008,
      value: 9.107455493801874,
      name: "Morocco",
    },
    {
      id: 6001,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2009,
      value: 6.323128669124126,
      name: "Morocco",
    },
    {
      id: 6002,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2010,
      value: 4.356485710240691,
      name: "Morocco",
    },
    {
      id: 6003,
      indicatorTypeId: 1,
      geoAreaId: 134,
      year: 2011,
      value: 14.210078647692335,
      name: "Morocco",
    },
  ],
  [
    {
      id: 6004,
      indicatorTypeId: 1,
      geoAreaId: 143,
      year: 1995,
      value: 0.010499537364134892,
      name: "New Zealand",
    },
    {
      id: 6005,
      indicatorTypeId: 1,
      geoAreaId: 143,
      year: 1996,
      value: 0,
      name: "New Zealand",
    },
    {
      id: 6006,
      indicatorTypeId: 1,
      geoAreaId: 143,
      year: 1997,
      value: 0,
      name: "New Zealand",
    },
    {
      id: 6007,
      indicatorTypeId: 1,
      geoAreaId: 143,
      year: 1998,
      value: 0,
      name: "New Zealand",
    },
    {
      id: 6008,
      indicatorTypeId: 1,
      geoAreaId: 143,
      year: 1999,
      value: 0,
      name: "New Zealand",
    },
    {
      id: 6009,
      indicatorTypeId: 1,
      geoAreaId: 143,
      year: 2000,
      value: 0,
      name: "New Zealand",
    },
    {
      id: 6010,
      indicatorTypeId: 1,
      geoAreaId: 143,
      year: 2001,
      value: 0,
      name: "New Zealand",
    },
  ],
  [
    {
      id: 6011,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 1995,
      value: 83.24971705514254,
      name: "Norway",
    },
    {
      id: 6012,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 1996,
      value: 78.34341330556336,
      name: "Norway",
    },
    {
      id: 6013,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 1997,
      value: 102.3128784939729,
      name: "Norway",
    },
    {
      id: 6014,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 1998,
      value: 77.21570198397038,
      name: "Norway",
    },
    {
      id: 6015,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 1999,
      value: 128.46236021218493,
      name: "Norway",
    },
    {
      id: 6016,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2000,
      value: 44.67246911394491,
      name: "Norway",
    },
    {
      id: 6017,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2001,
      value: 32.20764440358351,
      name: "Norway",
    },
    {
      id: 6018,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2002,
      value: 32.74138793883172,
      name: "Norway",
    },
    {
      id: 6019,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2003,
      value: 45.12573767751928,
      name: "Norway",
    },
    {
      id: 6020,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2004,
      value: 54.85968599332428,
      name: "Norway",
    },
    {
      id: 6021,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2005,
      value: 51.37757081878153,
      name: "Norway",
    },
    {
      id: 6022,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2006,
      value: 53.326403326403316,
      name: "Norway",
    },
    {
      id: 6023,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2007,
      value: 40.858686380437874,
      name: "Norway",
    },
    {
      id: 6024,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2008,
      value: 36.47163120567376,
      name: "Norway",
    },
    {
      id: 6025,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2009,
      value: 39.56533262655714,
      name: "Norway",
    },
    {
      id: 6026,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2010,
      value: 45.39914518130426,
      name: "Norway",
    },
    {
      id: 6027,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2011,
      value: 40.37749437447924,
      name: "Norway",
    },
    {
      id: 6028,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2012,
      value: 39.77653631284916,
      name: "Norway",
    },
    {
      id: 6029,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2013,
      value: 44.91914893617022,
      name: "Norway",
    },
    {
      id: 6030,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2014,
      value: 30.72040622024754,
      name: "Norway",
    },
    {
      id: 6031,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2015,
      value: 16.356646825396826,
      name: "Norway",
      region: "Northern Europe",
      cx: 506.69444444444446,
      cy: 37.55751249861913,
      x: 268.37363646502547,
      y: 81.02151555049508,
      radius: 12.36242408324971,
      index: 11,
      vy: 6.737055512729762e-7,
      vx: -1.942360555297481e-7,
    },
    {
      id: 6032,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2016,
      value: 19.821428571428573,
      name: "Norway",
      value2015: 16.356646825396826,
      value2016: 19.821428571428573,
      growthCommit: 36.1780753968254,
      growthDisb: 3.464781746031747,
    },
    {
      id: 6033,
      indicatorTypeId: 1,
      geoAreaId: 147,
      year: 2017,
      value: 15.631044487427467,
      name: "Norway",
    },
  ],
  [
    {
      id: 6034,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 1995,
      value: 0,
      name: "Pakistan",
    },
    {
      id: 6035,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 1996,
      value: 1.7,
      name: "Pakistan",
    },
    {
      id: 6036,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 1997,
      value: 2.3,
      name: "Pakistan",
    },
    {
      id: 6037,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 1998,
      value: 2.9000000000000004,
      name: "Pakistan",
    },
    {
      id: 6038,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 1999,
      value: 2.7,
      name: "Pakistan",
    },
    {
      id: 6039,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2000,
      value: 0.4,
      name: "Pakistan",
    },
    {
      id: 6040,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2001,
      value: 0,
      name: "Pakistan",
    },
    {
      id: 6041,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2002,
      value: 0,
      name: "Pakistan",
    },
    {
      id: 6042,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2003,
      value: 0.2,
      name: "Pakistan",
    },
    {
      id: 6043,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2004,
      value: 5.800000000000001,
      name: "Pakistan",
    },
    {
      id: 6044,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2005,
      value: 18.2,
      name: "Pakistan",
    },
    {
      id: 6045,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2006,
      value: 0,
      name: "Pakistan",
    },
    {
      id: 6046,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2007,
      value: 3.7,
      name: "Pakistan",
    },
    {
      id: 6047,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2008,
      value: 0,
      name: "Pakistan",
    },
    {
      id: 6048,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2009,
      value: 0,
      name: "Pakistan",
    },
    {
      id: 6049,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2010,
      value: 0,
      name: "Pakistan",
    },
    {
      id: 6050,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2011,
      value: 0,
      name: "Pakistan",
    },
    {
      id: 6051,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2012,
      value: 0,
      name: "Pakistan",
    },
    {
      id: 6052,
      indicatorTypeId: 1,
      geoAreaId: 148,
      year: 2013,
      value: 0,
      name: "Pakistan",
    },
  ],
  [
    {
      id: 6053,
      indicatorTypeId: 1,
      geoAreaId: 149,
      year: 1995,
      value: 0,
      name: "Panama",
    },
    {
      id: 6054,
      indicatorTypeId: 1,
      geoAreaId: 149,
      year: 1996,
      value: 0,
      name: "Panama",
    },
    {
      id: 6055,
      indicatorTypeId: 1,
      geoAreaId: 149,
      year: 1997,
      value: 7.62021501,
      name: "Panama",
    },
    {
      id: 6056,
      indicatorTypeId: 1,
      geoAreaId: 149,
      year: 1998,
      value: 10.9816272,
      name: "Panama",
    },
    {
      id: 6057,
      indicatorTypeId: 1,
      geoAreaId: 149,
      year: 1999,
      value: 13.06947665,
      name: "Panama",
    },
    {
      id: 6058,
      indicatorTypeId: 1,
      geoAreaId: 149,
      year: 2000,
      value: 15.75177285,
      name: "Panama",
    },
    {
      id: 6059,
      indicatorTypeId: 1,
      geoAreaId: 149,
      year: 2001,
      value: 13.52397316,
      name: "Panama",
    },
    {
      id: 6060,
      indicatorTypeId: 1,
      geoAreaId: 149,
      year: 2002,
      value: 10.1891925,
      name: "Panama",
    },
    {
      id: 6061,
      indicatorTypeId: 1,
      geoAreaId: 149,
      year: 2003,
      value: 9.56625862,
      name: "Panama",
    },
  ],
  [
    {
      id: 6062,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 1995,
      value: 0.087,
      name: "Poland",
    },
    {
      id: 6063,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 1996,
      value: 15.76631503,
      name: "Poland",
    },
    {
      id: 6064,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 1997,
      value: 8.78407603,
      name: "Poland",
    },
    {
      id: 6065,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 1998,
      value: 13.82777619,
      name: "Poland",
    },
    {
      id: 6066,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 1999,
      value: 55.52897804,
      name: "Poland",
    },
    {
      id: 6067,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 2000,
      value: 36.417995669999996,
      name: "Poland",
    },
    {
      id: 6068,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 2001,
      value: 21.58659371,
      name: "Poland",
    },
    {
      id: 6069,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 2002,
      value: 18.12837172,
      name: "Poland",
    },
    {
      id: 6070,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 2003,
      value: 37.31457172,
      name: "Poland",
    },
    {
      id: 6071,
      indicatorTypeId: 1,
      geoAreaId: 154,
      year: 2004,
      value: 28.91377172,
      name: "Poland",
    },
  ],
  [
    {
      id: 6072,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 1995,
      value: 0,
      name: "Romania",
    },
    {
      id: 6073,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 1996,
      value: 0,
      name: "Romania",
    },
    {
      id: 6074,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 1997,
      value: 0,
      name: "Romania",
    },
    {
      id: 6075,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 1998,
      value: 2.5237787277000487,
      name: "Romania",
    },
    {
      id: 6076,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 1999,
      value: 7.063289157883753,
      name: "Romania",
    },
    {
      id: 6077,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 2000,
      value: 4.563738836126741,
      name: "Romania",
    },
    {
      id: 6078,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 2001,
      value: 0,
      name: "Romania",
    },
    {
      id: 6079,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 2002,
      value: 0,
      name: "Romania",
    },
    {
      id: 6080,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 2003,
      value: 0,
      name: "Romania",
    },
    {
      id: 6081,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 2004,
      value: 0.018384284868170887,
      name: "Romania",
    },
    {
      id: 6082,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 2005,
      value: 0.02059269199682186,
      name: "Romania",
    },
    {
      id: 6083,
      indicatorTypeId: 1,
      geoAreaId: 160,
      year: 2006,
      value: 0.053400109577024846,
      name: "Romania",
    },
  ],
  [
    {
      id: 6084,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 1995,
      value: 8.326205053272792,
      name: "Slovakia",
    },
    {
      id: 6085,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 1996,
      value: 8.152346776495534,
      name: "Slovakia",
    },
    {
      id: 6086,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 1997,
      value: 12.535635135872123,
      name: "Slovakia",
    },
    {
      id: 6087,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 1998,
      value: 12.27527844068486,
      name: "Slovakia",
    },
    {
      id: 6088,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 1999,
      value: 11.868142495074082,
      name: "Slovakia",
    },
    {
      id: 6089,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 2000,
      value: 11.612861182212148,
      name: "Slovakia",
    },
    {
      id: 6090,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 2001,
      value: 6.603269580083342,
      name: "Slovakia",
    },
    {
      id: 6091,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 2002,
      value: 12.081166198767837,
      name: "Slovakia",
    },
    {
      id: 6092,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 2003,
      value: 15.568523029856664,
      name: "Slovakia",
    },
    {
      id: 6093,
      indicatorTypeId: 1,
      geoAreaId: 171,
      year: 2004,
      value: 3.853437118137453,
      name: "Slovakia",
    },
  ],
  [
    {
      id: 6094,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 1995,
      value: 40.07722068823862,
      name: "South Africa",
    },
    {
      id: 6095,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 1996,
      value: 41.64747780623385,
      name: "South Africa",
    },
    {
      id: 6096,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 1997,
      value: 18.152453308169157,
      name: "South Africa",
    },
    {
      id: 6097,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 1998,
      value: 3.385812746902633,
      name: "South Africa",
    },
    {
      id: 6098,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 1999,
      value: 4.890402067495879,
      name: "South Africa",
    },
    {
      id: 6099,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2000,
      value: 3.1697272530996803,
      name: "South Africa",
    },
    {
      id: 6100,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2001,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6101,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2002,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6102,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2003,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6103,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2004,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6104,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2005,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6105,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2006,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6106,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2007,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6107,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2008,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6108,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2009,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6109,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2010,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6110,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2011,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6111,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2012,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6112,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2013,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6113,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2014,
      value: 0,
      name: "South Africa",
    },
    {
      id: 6114,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2015,
      value: 0,
      name: "South Africa",
      region: "Southern Africa",
      cx: 549.4055555555556,
      cy: 334.01522140399584,
      x: 281.1805033681019,
      y: 430.4067741078156,
      radius: 2,
      index: 12,
      vy: 2.3990972864911343e-7,
      vx: -2.5180275316596424e-8,
    },
    {
      id: 6115,
      indicatorTypeId: 1,
      geoAreaId: 175,
      year: 2016,
      value: 0,
      name: "South Africa",
      value2015: 0,
      value2016: 0,
    },
  ],
  [
    {
      id: 6116,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 1995,
      value: 446.69240847012935,
      name: "Switzerland",
    },
    {
      id: 6117,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 1996,
      value: 368.60543199488677,
      name: "Switzerland",
    },
    {
      id: 6118,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 1997,
      value: 295.2499892338831,
      name: "Switzerland",
    },
    {
      id: 6119,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 1998,
      value: 292.0376006802761,
      name: "Switzerland",
    },
    {
      id: 6120,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 1999,
      value: 290.18310360781675,
      name: "Switzerland",
    },
    {
      id: 6121,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2000,
      value: 188.47228205116818,
      name: "Switzerland",
    },
    {
      id: 6122,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2001,
      value: 131.64199180500293,
      name: "Switzerland",
    },
    {
      id: 6123,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2002,
      value: 135.83984421992065,
      name: "Switzerland",
    },
    {
      id: 6124,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2003,
      value: 153.04635388658656,
      name: "Switzerland",
    },
    {
      id: 6125,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2004,
      value: 147.51155177439944,
      name: "Switzerland",
    },
    {
      id: 6126,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2005,
      value: 113.59030713178586,
      name: "Switzerland",
    },
    {
      id: 6127,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2006,
      value: 109.15239277634367,
      name: "Switzerland",
    },
    {
      id: 6128,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2007,
      value: 85.53225787416199,
      name: "Switzerland",
    },
    {
      id: 6129,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2008,
      value: 96.19699193972801,
      name: "Switzerland",
    },
    {
      id: 6130,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2009,
      value: 110.85872401533751,
      name: "Switzerland",
    },
    {
      id: 6131,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2010,
      value: 73.60205624930856,
      name: "Switzerland",
    },
    {
      id: 6132,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2011,
      value: 86.02520778531463,
      name: "Switzerland",
    },
    {
      id: 6133,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2012,
      value: 68.56037539553472,
      name: "Switzerland",
    },
    {
      id: 6134,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2013,
      value: 75.59870550161813,
      name: "Switzerland",
    },
    {
      id: 6135,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2014,
      value: 76.50436681222706,
      name: "Switzerland",
    },
    {
      id: 6136,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2015,
      value: 99.44282744282744,
      name: "Switzerland",
      region: "Western Europe",
      cx: 501.35555555555555,
      cy: 107.50890065976608,
      x: 260.3507086071484,
      y: 162.2055219328248,
      radius: 65,
      index: 13,
      vy: -2.8507529479702368e-8,
      vx: -1.1230366618543207e-7,
    },
    {
      id: 6137,
      indicatorTypeId: 1,
      geoAreaId: 184,
      year: 2016,
      value: 96.07523703496685,
      name: "Switzerland",
      value2015: 99.44282744282744,
      value2016: 96.07523703496685,
      growthCommit: 195.51806447779427,
      growthDisb: -3.3675904078605896,
    },
  ],
  [
    {
      id: 6138,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 1995,
      value: 15.2,
      name: "Thailand",
    },
    {
      id: 6139,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 1996,
      value: 6.2,
      name: "Thailand",
    },
    {
      id: 6140,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 1997,
      value: 4.5,
      name: "Thailand",
    },
    {
      id: 6141,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 1998,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6142,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 1999,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6143,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2000,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6144,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2001,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6145,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2002,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6146,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2003,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6147,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2004,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6148,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2005,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6149,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2006,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6150,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2007,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6151,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2008,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6152,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2009,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6153,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2010,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6154,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2011,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6155,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2012,
      value: 0,
      name: "Thailand",
    },
    {
      id: 6156,
      indicatorTypeId: 1,
      geoAreaId: 187,
      year: 2013,
      value: 0,
      name: "Thailand",
    },
  ],
  [
    {
      id: 6157,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 1995,
      value: 0.0007401533174729051,
      name: "Tunisia",
    },
    {
      id: 6158,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 1996,
      value: 1.8491725808798987,
      name: "Tunisia",
    },
    {
      id: 6159,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 1997,
      value: 5.390681867846207,
      name: "Tunisia",
    },
    {
      id: 6160,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 1998,
      value: 6.049397352301917,
      name: "Tunisia",
    },
    {
      id: 6161,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 1999,
      value: 5.63215241627853,
      name: "Tunisia",
    },
    {
      id: 6162,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2000,
      value: 6.110309942729311,
      name: "Tunisia",
    },
    {
      id: 6163,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2001,
      value: 3.6901048680678032,
      name: "Tunisia",
    },
    {
      id: 6164,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2002,
      value: 3.2743130451092637,
      name: "Tunisia",
    },
    {
      id: 6165,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2003,
      value: 5.67001552795031,
      name: "Tunisia",
    },
    {
      id: 6166,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2004,
      value: 3.691177510040161,
      name: "Tunisia",
    },
    {
      id: 6167,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2005,
      value: 6.108373168851195,
      name: "Tunisia",
    },
    {
      id: 6168,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2006,
      value: 2.6317731029301275,
      name: "Tunisia",
    },
    {
      id: 6169,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2007,
      value: 8.284508977361437,
      name: "Tunisia",
    },
    {
      id: 6170,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2008,
      value: 4.178076298701299,
      name: "Tunisia",
    },
    {
      id: 6171,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2009,
      value: 3.6318896296296295,
      name: "Tunisia",
    },
    {
      id: 6172,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2010,
      value: 3.296744933612858,
      name: "Tunisia",
    },
    {
      id: 6173,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2011,
      value: 2.2536640625,
      name: "Tunisia",
    },
    {
      id: 6174,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2012,
      value: 2.846895646606914,
      name: "Tunisia",
    },
    {
      id: 6175,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2013,
      value: 3.705128,
      name: "Tunisia",
    },
    {
      id: 6176,
      indicatorTypeId: 1,
      geoAreaId: 192,
      year: 2014,
      value: 3.1196130742049473,
      name: "Tunisia",
    },
  ],
  [
    {
      id: 6177,
      indicatorTypeId: 1,
      geoAreaId: 193,
      year: 1995,
      value: 44.79228500000001,
      name: "Turkey",
    },
    {
      id: 6178,
      indicatorTypeId: 1,
      geoAreaId: 193,
      year: 1996,
      value: 16.396146,
      name: "Turkey",
    },
    {
      id: 6179,
      indicatorTypeId: 1,
      geoAreaId: 193,
      year: 1997,
      value: 29.394354,
      name: "Turkey",
    },
    {
      id: 6180,
      indicatorTypeId: 1,
      geoAreaId: 193,
      year: 1998,
      value: 25.974027,
      name: "Turkey",
    },
    {
      id: 6181,
      indicatorTypeId: 1,
      geoAreaId: 193,
      year: 1999,
      value: 27.556705,
      name: "Turkey",
    },
    {
      id: 6182,
      indicatorTypeId: 1,
      geoAreaId: 193,
      year: 2000,
      value: 23.784996999999997,
      name: "Turkey",
    },
  ],
  [
    {
      id: 6183,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 1995,
      value: 25.578,
      name: "United States of America",
    },
    {
      id: 6184,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 1996,
      value: 121.463,
      name: "United States of America",
    },
    {
      id: 6185,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 1997,
      value: 112.227192,
      name: "United States of America",
    },
    {
      id: 6186,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 1998,
      value: 146.707934,
      name: "United States of America",
    },
    {
      id: 6187,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 1999,
      value: 80.16355100000001,
      name: "United States of America",
    },
    {
      id: 6188,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2000,
      value: 15.311505,
      name: "United States of America",
    },
    {
      id: 6189,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2001,
      value: 54.61527,
      name: "United States of America",
    },
    {
      id: 6190,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2002,
      value: 31.52599,
      name: "United States of America",
    },
    {
      id: 6191,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2003,
      value: 2.68454,
      name: "United States of America",
    },
    {
      id: 6192,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2004,
      value: 0,
      name: "United States of America",
    },
    {
      id: 6193,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2005,
      value: 0,
      name: "United States of America",
    },
    {
      id: 6194,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2006,
      value: 0,
      name: "United States of America",
    },
    {
      id: 6195,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2007,
      value: 0,
      name: "United States of America",
    },
    {
      id: 6196,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2008,
      value: 18.893822999999998,
      name: "United States of America",
    },
    {
      id: 6197,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2009,
      value: 2.371433,
      name: "United States of America",
    },
    {
      id: 6198,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2010,
      value: 0,
      name: "United States of America",
    },
    {
      id: 6199,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2011,
      value: 0,
      name: "United States of America",
    },
    {
      id: 6200,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2012,
      value: 0,
      name: "United States of America",
    },
    {
      id: 6201,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2013,
      value: 0,
      name: "United States of America",
    },
    {
      id: 6202,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2014,
      value: 0,
      name: "United States of America",
    },
    {
      id: 6203,
      indicatorTypeId: 1,
      geoAreaId: 202,
      year: 2015,
      value: 0,
      name: "United States of America",
      region: "Northern America",
      cx: 224.3505809638889,
      cy: 143.14306515641675,
      x: 103.43710873536322,
      y: 200.95724757528598,
      radius: 2,
      index: 14,
      vy: 1.320723037233568e-8,
      vx: 1.5841490399381637e-7,
    },
  ],
  [
    {
      id: 6204,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 1995,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6205,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 1996,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6206,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 1997,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6207,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 1998,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6208,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 1999,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6209,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2000,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6210,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2001,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6211,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2002,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6212,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2003,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6213,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2004,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6214,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2005,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6215,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2006,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6216,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2007,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6217,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2008,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6218,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2009,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6219,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2010,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6220,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2011,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6221,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2012,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6222,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2013,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6223,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2014,
      value: 0,
      name: "Uruguay",
    },
    {
      id: 6224,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2015,
      value: 0,
      name: "Uruguay",
      region: "South America",
      cx: 330.51111111111106,
      cy: 343.40949595084356,
      x: 161.34413609212302,
      y: 442.0312532803829,
      radius: 2,
      index: 15,
      vy: 7.016847560393588e-8,
      vx: -7.322607437328663e-7,
    },
    {
      id: 6225,
      indicatorTypeId: 1,
      geoAreaId: 205,
      year: 2016,
      value: 0,
      name: "Uruguay",
      value2015: 0,
      value2016: 0,
    },
  ],
  [
    {
      id: 6226,
      indicatorTypeId: 1,
      geoAreaId: 207,
      year: 1995,
      value: 3.2358179999999996,
      name: "Venezuela (Bolivarian Republic of)",
    },
    {
      id: 6227,
      indicatorTypeId: 1,
      geoAreaId: 207,
      year: 1996,
      value: 20.064529999999998,
      name: "Venezuela (Bolivarian Republic of)",
    },
    {
      id: 6228,
      indicatorTypeId: 1,
      geoAreaId: 207,
      year: 1997,
      value: 2.3651044999999997,
      name: "Venezuela (Bolivarian Republic of)",
    },
    {
      id: 6229,
      indicatorTypeId: 1,
      geoAreaId: 207,
      year: 1998,
      value: 5.501270000000001,
      name: "Venezuela (Bolivarian Republic of)",
    },
  ],
  [
    {
      id: 6535,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 1995,
      value: 3775.857179553086,
      name: "European Union",
    },
    {
      id: 6536,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 1996,
      value: 4445.2627917478685,
      name: "European Union",
    },
    {
      id: 6537,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 1997,
      value: 3861.497959502017,
      name: "European Union",
    },
    {
      id: 6538,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 1998,
      value: 4757.502472229994,
      name: "European Union",
    },
    {
      id: 6539,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 1999,
      value: 5980.75524176094,
      name: "European Union",
    },
    {
      id: 6540,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2000,
      value: 2545.787616095744,
      name: "European Union",
    },
    {
      id: 6541,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2001,
      value: 2302.5297312775724,
      name: "European Union",
    },
    {
      id: 6542,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2002,
      value: 2948.7507274156083,
      name: "European Union",
    },
    {
      id: 6543,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2003,
      value: 3342.534759287875,
      name: "European Union",
    },
    {
      id: 6544,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2004,
      value: 3269.2009213213887,
      name: "European Union",
    },
    {
      id: 6545,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2005,
      value: 2388.3251256031435,
      name: "European Union",
    },
    {
      id: 6546,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2006,
      value: 1834.054835563352,
      name: "European Union",
    },
    {
      id: 6547,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2007,
      value: 1163.2307402781814,
      name: "European Union",
    },
    {
      id: 6548,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2008,
      value: 660.3437809806335,
      name: "European Union",
    },
    {
      id: 6549,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2009,
      value: 522.8915358920586,
      name: "European Union",
    },
    {
      id: 6550,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2010,
      value: 230.1849705000608,
      name: "European Union",
    },
    {
      id: 6551,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2011,
      value: 186.9729863307256,
      name: "European Union",
    },
    {
      id: 6552,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2012,
      value: 75.87111071298203,
      name: "European Union",
    },
    {
      id: 6553,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2013,
      value: 0.04780876494023904,
      name: "European Union",
    },
    {
      id: 6554,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2014,
      value: 0,
      name: "European Union",
    },
    {
      id: 6555,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2015,
      value: 0,
      name: "European Union",
      cx: 0,
      cy: 0,
      x: -13.641968599580297,
      y: 44.20781219088048,
      radius: 2,
      index: 16,
      vy: -2.1987227897068517e-7,
      vx: -3.5338711947784897e-7,
    },
    {
      id: 6556,
      indicatorTypeId: 1,
      geoAreaId: 296,
      year: 2016,
      value: 0,
      name: "European Union",
      value2015: 0,
      value2016: 0,
    },
  ],
];
