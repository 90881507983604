import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { updateTitle } from "../../reducer";

const Heading = styled.input`
  font-size: 1.8em;
  font-weight: bold;
  padding: 0 15px;
  text-align: center;
  border: 1px dashed #fff;
  border-radius: 5px;
  flex: 1 0 auto;
  &:hover,
  &:focus {
    border-color: #eee;
  }
`;

const ReportTitleStyled = styled.div`
  position: relative;
  display: flex;
  width: 782px;
  margin: 15px auto;
`;

const ReportTitle = ({ title }) => {
  const dispatch = useDispatch();

  return (
    <ReportTitleStyled>
      <Heading onChange={e => dispatch(updateTitle(e.target.value))} placeholder="Untitled" value={title} />
    </ReportTitleStyled>
  );
};

ReportTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ReportTitle;
