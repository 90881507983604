import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
// import { selectFilterDataYear } from "modules/indicators/selectors/filter-data";
import { createSelector } from "reselect";

const dataAvailability = state => state.metaData.dataAvailability;

export const selectAvailableReportersByYears = createSelector(
  dataAvailability,
  selectCurrentUIState,
  (dataAvailability, ui) => {
    const { measure } = ui;

    if (!dataAvailability || !dataAvailability[measure]) {
      return [];
    }

    // return dataAvailability[measure]?.data?.reduce((acc, item) => ({
    //   ...acc,
    //   [item.year]: [
    //     ...(acc?.[item.year] ?? []),
    //     item.geoAreaId,
    //   ],
    // }), {});

    return dataAvailability[measure]?.data;
  }
);
