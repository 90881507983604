import { connect } from "react-redux";
// import store from "modules/store";
// import { fetchMeasure } from "../../meta-data/reducer";
// import { setSelectedMeasure } from "../reducer";
// import { getMeasurements } from "../../meta-data/selectors/indicators";
// import { getDataAvailability, getCountriesDataByM49Code } from "../selectors/get-data-table-content";
// import { getMeasureMetaData } from "../selectors/get-measure-meta-data";
import Content from "../components";

// store.dispatch(fetchMeasure("AG_PRD_XSUBDY"));
// store.dispatch(setSelectedMeasure("AG_PRD_XSUBDY"));

// const mapStateToProps = state => ({
//   measurements: getMeasurements(state),
//   measureMetaData: getMeasureMetaData(state),
//   dataTableContent: getDataAvailability(state),
//   countriesDataByM49Code: getCountriesDataByM49Code(state),
// });

// const mapDispatchToProps = dispatch => ({
//   onChangeMeasure: value => {
//     dispatch(setSelectedMeasure(value));
//     dispatch(fetchMeasure(value));
//   },
// })
export default connect(
  null,
  null
)(Content);
