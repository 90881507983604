import React from "react";
import WebFont from "webfontloader";
import "react-hot-loader";
import { hot } from "react-hot-loader/root";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route } from "react-router-dom";
import { addLocaleData } from "react-intl";
import { LanguageProvider, ThemeProvider } from "modules/ui";
import store, { history } from "modules/store";
import { setMessages, setAvailableLanguages } from "modules/ui/reducer";
// import { fetchReports } from "modules/reports/reducer";
import ReactGA from "react-ga4";
import "./web.config";
import GlobalStyle from "./GlobalStyle";
import MetaData from "modules/indicators/components/MetaData";
import Home from "./pages/Home";
import About from "./pages/About";
import {
  Indicator2_b_1,
  Indicator8_2_i,
  Indicator8_a_1,
  Indicator10_a_i,
  Indicator10_a_1,
  Indicator17_10_1,
  Indicator17_11_1,
  Indicator17_11_i,
  Indicator17_11_ii,
  Indicator17_12_1,
} from "./pages/Indicators";
// import Reports from "./pages/ReportsPlacehoder";
import Reports from "./pages/Reports";
import ReportsFooter from "./pages/ReportsFooter";
import DataAvailability from "./pages/DataAvailability";
import Methodologies from "./pages/Methodologies";
import FAQ from "./pages/FAQ";
import Publications from "./pages/Publications";
import Contact from "./pages/Contact";
import { availableLanguages, languagesData, messages } from "./i18n";

ReactGA.initialize("G-ZCWL437SYJ");

addLocaleData(languagesData);
store.dispatch(setAvailableLanguages(availableLanguages));
store.dispatch(setMessages(messages));
// store.dispatch(fetchReports());

const acceptedLanguages = availableLanguages.map(l => l.key).join("|");

WebFont.load({
  google: {
    families: ["Roboto:300,400,500,700:latin-ext"],
  },
});

const App = () => (
  <Provider store={store}>
    <MetaData />
    <ThemeProvider
      theme={{
        maxContentWidth: 1170,
        colorBackground: "#041527",
        colorAccent: "#3E75B0",
        colorHighlight: "#4CAF4F",
        colorDisabled: "#666666",
        fontSize: "14px",
        fontWeightLight: 300,
        fontWeightNormal: 400,
        fontWeightBold: 500,
        fontWeightBlack: 700,
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <>
        <GlobalStyle />
        <LanguageProvider>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path={`/:language(${acceptedLanguages})`} component={Home} exact />
              <Route path={`/:language(${acceptedLanguages})/about`} component={About} />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(2-b-1)/:dimension?/:visualization?/:embed?`}
                component={Indicator2_b_1}
              />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(8-a-1)/:dimension?/:visualization?/:embed?`}
                component={Indicator8_a_1}
              />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(8-2-i)/:dimension?/:visualization?/:embed?`}
                component={Indicator8_2_i}
              />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(10-a-1)/:dimension?/:visualization?/:embed?`}
                component={Indicator10_a_1}
              />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(10-a-i)/:dimension?/:visualization?/:embed?`}
                component={Indicator10_a_i}
              />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(17-10-1)/:dimension?/:visualization?/:embed?`}
                component={Indicator17_10_1}
              />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(17-11-1)/:dimension?/:visualization?/:embed?`}
                component={Indicator17_11_1}
              />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(17-11-i)/:dimension?/:visualization?/:embed?`}
                component={Indicator17_11_i}
              />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(17-11-ii)/:dimension?/:visualization?/:embed?`}
                component={Indicator17_11_ii}
              />
              <Route
                path={`/:language(${acceptedLanguages})/indicators/:code(17-12-1)/:dimension?/:visualization?/:embed?`}
                component={Indicator17_12_1}
              />
              <Route path={`/:language(${acceptedLanguages})/reports`} component={Reports} />
              <Route path={`/:language(${acceptedLanguages})/reports-demo`} component={ReportsFooter} />
              <Route
                path={`/:language(${acceptedLanguages})/data-availability`}
                component={DataAvailability}
              />
              <Route path={`/:language(${acceptedLanguages})/methodologies`} component={Methodologies} />
              <Route path={`/:language(${acceptedLanguages})/faq`} component={FAQ} />
              <Route path={`/:language(${acceptedLanguages})/publications`} component={Publications} />
              <Route path={`/:language(${acceptedLanguages})/contact`} component={Contact} />
              {/*
                <Route path={`/:language(${acceptedLanguages})/v2/indicators`} component={IndicatorsV2} />
                */}
            </Switch>
          </ConnectedRouter>
        </LanguageProvider>
      </>
    </ThemeProvider>
  </Provider>
);

const HotApp = hot(App);
render(<HotApp />, document.getElementById("root"));
// render(App, document.getElementById("root"));
