import { createSelector } from "reselect";
import { selectSingleCountries, selectProducts } from "modules/indicators/selectors/indicators";
import * as constants from "modules/constants";

const getSeriesData = state => state.indicators.seriesData;
const getSecondarySeriesData = state => state.indicators.seriesDataSecondary;
const getDimension = state => state.indicators.currentUIState.dimension;

const selectSeriesDataHorizontalBarChartByCountries = (data, secondaryData, countriesData) => {
  const countriesPrimaryDataSeries = data ? data.map(d => d.geoAreaId) : [];
  const countriesSecondaryDataSeries = secondaryData?.map(d => d.geoAreaId);
  const availableCountries = Array.from(
    new Set([...countriesPrimaryDataSeries, ...countriesSecondaryDataSeries])
  );

  let primaryDataByKey = {};
  let secondaryDataByKey = {};
  if (secondaryData) {
    secondaryDataByKey = secondaryData.reduce(
      (acc, currentVal) => ({
        ...acc,
        [currentVal.geoAreaId]: currentVal.growth,
      }),
      {}
    );
  }

  if (data) {
    primaryDataByKey = data.reduce(
      (acc, currentVal) => ({
        ...acc,
        [currentVal.geoAreaId]: currentVal.growth,
      }),
      {}
    );
  }

  return (
    availableCountries
      // .filter(d => d.growth !== undefined )
      .map(d => ({
        id: d,
        growth: primaryDataByKey[d] ? primaryDataByKey[d] : 0.0,
        growthSecond: secondaryDataByKey[d] ? secondaryDataByKey[d] : 0.0,
        name: countriesData[d]?.name || `${d}`,
        m49Code: countriesData[d]?.m49Code,
      }))
  );
};
const selectSeriesDataHorizontalBarChartByProducts = (data, secondaryData, products) => {
  const countriesPrimaryDataSeries = data ? data.map(d => d.productTypeId) : [];
  const countriesSecondaryDataSeries = secondaryData.map(d => d.productTypeId);
  const availableCountries = Array.from(
    new Set([...countriesPrimaryDataSeries, ...countriesSecondaryDataSeries])
  );

  let primaryDataByKey = {};
  let secondaryDataByKey = {};
  if (secondaryData) {
    secondaryDataByKey = secondaryData.reduce(
      (acc, currentVal) => ({
        ...acc,
        [currentVal.productTypeId]: currentVal.growth,
      }),
      {}
    );
  }

  if (data) {
    primaryDataByKey = data.reduce(
      (acc, currentVal) => ({
        ...acc,
        [currentVal.productTypeId]: currentVal.growth,
      }),
      {}
    );
  }

  return (
    availableCountries
      // .filter(d => d.growth !== undefined )
      .map(d => ({
        id: d,
        growth: primaryDataByKey[d] ? primaryDataByKey[d] : 0.0,
        growthSecond: secondaryDataByKey[d] ? secondaryDataByKey[d] : 0.0,
        name: products[d].type,
      }))
  );
};

export const selectSeriesDataHorizontalBarChart = createSelector(
  getSeriesData,
  getSecondarySeriesData,
  selectSingleCountries,
  selectProducts,
  getDimension,
  (data, secondaryData, countriesData, products, currentDimension) => {
    if (
      data === null ||
      Object.keys(countriesData).length === 0 ||
      data.status === constants.STATUS_LOADING ||
      secondaryData.status === constants.STATUS_LOADING
    ) {
      return null;
    }
    if (currentDimension === "BC") {
      return selectSeriesDataHorizontalBarChartByCountries(data.data, secondaryData.data, countriesData);
    }
    return selectSeriesDataHorizontalBarChartByProducts(data.data, secondaryData.data, products);
  }
);
