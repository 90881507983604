import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import Prismic from "prismic-javascript";
import { addError } from "modules/ui/reducer";
import { selectCurrentLocale } from "modules/ui/selectors";
import { fetchPageContentByKey, addContent } from "../reducer";

export default (action$, state$, { apiConfig, timestamp }) =>
  action$.pipe(
    ofType(fetchPageContentByKey),
    mergeMap(async action => {
      const locale = selectCurrentLocale(state$.value);
      const api = await Prismic.api(apiConfig.cmsApiRoot);
      const response = await api.getByUID(
        action.payload === "home" ? "homepage" : "page_2cols_right",
        action.payload,
        { lang: `${locale}` }
      );

      return addContent({ key: action.payload, value: response || null });
    }),
    catchError(e => [
      addError({
        timestamp: timestamp(),
        message: "error.content.api-error",
        details: e,
      }),
    ])
  );
