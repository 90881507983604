import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectBearerToken, selectContent } from "../selectors";
import { fetchBearerToken, fetchContent, fetchContentByGraphQL } from "../reducer";

export default params => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.ui.language, shallowEqual);
  const content = useSelector(
    state => selectContent(state, params.contentType, params.contentKey),
    shallowEqual
  );
  const bearerToken = useSelector(selectBearerToken);

  useEffect(() => {
    if (!bearerToken) {
      dispatch(fetchBearerToken());
    }

    dispatch(params?.query ? fetchContentByGraphQL(params) : fetchContent(params));
  }, [language]);

  return content;
};
