import { combineEpics } from "redux-observable";
import setSeriesData from "./set-series-data";
import setSecondarySeriesData from "./set-secondary-series-data";
import setCrossCuttingSeriesData from "./set-cross-cutting-series-data";
import resetSeriesData from "./reset-reseries-data";
import setFilterParamsProduct from "./set-filter-params-product";

export default combineEpics(
  resetSeriesData,
  setSeriesData,
  setSecondarySeriesData,
  setCrossCuttingSeriesData,
  setFilterParamsProduct
);
