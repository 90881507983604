import { connect } from "react-redux";
import { selectChartMetaData } from "modules/indicators/selectors/indicators";
import { selectSeriesDataVerticalChart } from "modules/indicators/selectors/charts/vertical-bar-chart";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import VerticalBarChartComponent from "./VerticalBarChartComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataVerticalChart(state),
  filterData: selectFilterParams(state),
  chartMetaData: selectChartMetaData(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(VerticalBarChartComponent);
