import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import TextField from "@material-ui/core/TextField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { FormattedMessage } from "react-intl";

const SearchFormStyled = styled.div`
  position: relative;
  border-bottom: 1px solid #eee;
  padding: 0 20px 0 15px;

  .keyword-input {
    width: 100%;
  }

  .btn-delete {
    position: absolute;
    top: 60%;
    right: 10px;
    transform: translate(0, -50%);
  }
`;

const SearchForm = ({ keywordSelector, keywordCallbackFn }) => {
  const [keyword, setKeyword] = useState(keywordSelector);

  const dispatch = useDispatch();

  const handleOnChangeKeyword = keyword => {
    let timer;
    setKeyword(keyword);

    if (keyword.length === 0) {
      dispatch(keywordCallbackFn(""));
    }
    if (keyword.length > 1) {
      return (() => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(keywordCallbackFn(keyword));
        }, 300);
      })();
    }
  };

  return (
    <SearchFormStyled>
      <FormattedMessage id="indicators.search-form.enter-keyword" defaultMessage="Enter keyword">
        {text => (
          <TextField
            className="keyword-input"
            label={<FormattedMessage id="indicators.search-form.keyword" defaultMessage="Keyword" />}
            placeholder={text}
            default={keyword}
            onChange={event => handleOnChangeKeyword(event.target.value)}
          />
        )}
      </FormattedMessage>
      <span className="btn-delete" onClick={() => handleOnChangeKeyword("")}>
        <HighlightOffIcon />
      </span>
    </SearchFormStyled>
  );
};

SearchForm.propTypes = {
  keywordSelector: PropTypes.any.isRequired,
  keywordCallbackFn: PropTypes.any.isRequired,
};

export default SearchForm;
