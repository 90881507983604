export default [
  {
    id: 17181,
    indicatorTypeId: 2,
    geoAreaId: 107,
    partnerId: 1,
    year: 2017,
    value: 71.440750213128723,
    productTypeId: 42,
  },
  {
    id: 17346,
    indicatorTypeId: 2,
    geoAreaId: 107,
    partnerId: 1,
    year: 2017,
    value: 67.097012172630031,
    productTypeId: 2,
  },
  {
    id: 19197,
    indicatorTypeId: 2,
    geoAreaId: 175,
    partnerId: 1,
    year: 2017,
    value: 60.592120905134543,
    productTypeId: 42,
  },
  {
    id: 19536,
    indicatorTypeId: 2,
    geoAreaId: 107,
    partnerId: 1,
    year: 2017,
    value: 100.0,
    productTypeId: 5,
  },
  {
    id: 19628,
    indicatorTypeId: 2,
    geoAreaId: 175,
    partnerId: 1,
    year: 2017,
    value: 60.300257013476212,
    productTypeId: 1,
  },
  {
    id: 19684,
    indicatorTypeId: 2,
    geoAreaId: 107,
    partnerId: 1,
    year: 2017,
    value: 46.736292428198432,
    productTypeId: 6,
  },
  {
    id: 20059,
    indicatorTypeId: 2,
    geoAreaId: 107,
    partnerId: 1,
    year: 2017,
    value: 75.918496621621628,
    productTypeId: 1,
  },
  {
    id: 20348,
    indicatorTypeId: 2,
    geoAreaId: 175,
    partnerId: 1,
    year: 2017,
    value: 92.857142857142861,
    productTypeId: 5,
  },
  {
    id: 20483,
    indicatorTypeId: 2,
    geoAreaId: 107,
    partnerId: 1,
    year: 2017,
    value: 68.249660786974218,
    productTypeId: 3,
  },
  {
    id: 22153,
    indicatorTypeId: 2,
    geoAreaId: 175,
    partnerId: 1,
    year: 2017,
    value: 68.907563025210081,
    productTypeId: 4,
  },
  {
    id: 22259,
    indicatorTypeId: 2,
    geoAreaId: 175,
    partnerId: 1,
    year: 2017,
    value: 59.31297709923664,
    productTypeId: 6,
  },
  {
    id: 23424,
    indicatorTypeId: 2,
    geoAreaId: 175,
    partnerId: 1,
    year: 2017,
    value: 59.995627140878945,
    productTypeId: 2,
  },
  {
    id: 23661,
    indicatorTypeId: 2,
    geoAreaId: 175,
    partnerId: 1,
    year: 2017,
    value: 66.679252407022844,
    productTypeId: 3,
  },
  {
    id: 24320,
    indicatorTypeId: 2,
    geoAreaId: 107,
    partnerId: 1,
    year: 2017,
    value: 75.0,
    productTypeId: 4,
  },
];
