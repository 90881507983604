import React, { Fragment } from "react";
import { TreemapChart } from "datadesign-charts-sdgtrade";
import AG_PRD_XSUBDY_2016 from "./data/AG_PRD_XSUBDY_2016";
import regions from "./data/region_by_id";
import { FormattedMessage } from "react-intl";

const TreemapChartModule = () => {
  // replace id by country name
  const dataFiltered = AG_PRD_XSUBDY_2016.map(item => {
    const country = regions.find(k => +k.id === +item.geoAreaId);
    item.name = country ? country.name : "";
    item.region = country ? country.region : "";
    return item;
  });

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.map-force-chart"
        defaultMessage="Agriculture Export subsidies (millions of current United States dollars)"
      >
        {text => (
          <TreemapChart
            data={dataFiltered}
            valueKey="value"
            nameKey="name"
            uniqueIDKey="id"
            colorKey="region" // should be the subregion name for countries, region name for regions and  product type name for products
            title={text}
            aspectRatio={0.5} //optional
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};

export default TreemapChartModule;
