import { createSelector } from "reselect";
import { ctryWithAlpha3, selectSingleCountries } from "modules/indicators/selectors/indicators";

const getSeriesData = state => state.indicators.seriesData.data;
const getSecondarySeriesData = state => state.indicators.seriesDataSecondary.data;

export const selectSeriesDataBubbleChart = createSelector(
  getSeriesData,
  getSecondarySeriesData,
  selectSingleCountries,
  (data, secondaryData, countriesData) => {
    if (data === null || Object.keys(countriesData).length === 0) return null;
    // remove EU and World region out of the data
    const countriesDataFiltered = Object.keys(countriesData)
      .filter(d => +d !== 296 && +d !== 1)
      .reduce((acc, key) => ({ ...acc, [key]: countriesData[key] }), {});
    const countriesPrimaryDataSeries = data.map(d => d.geoAreaId);
    const countriesSecondaryDataSeries = secondaryData.map(d => d.geoAreaId);
    const availableCountries = Array.from(
      new Set([...countriesPrimaryDataSeries, ...countriesSecondaryDataSeries])
    );
    let primaryDataByKey = {};
    let secondaryDataByKey = {};
    if (data) {
      primaryDataByKey = data.reduce(
        (acc, currentVal) => ({
          ...acc,
          [currentVal.geoAreaId]: currentVal.value,
        }),
        {}
      );
    }
    if (secondaryData) {
      secondaryDataByKey = secondaryData.reduce(
        (acc, currentVal) => ({
          ...acc,
          [currentVal.geoAreaId]: currentVal.value,
        }),
        {}
      );
    }

    const dataFiltered = availableCountries
      .map(key => {
        const regionData = countriesDataFiltered[key];

        if (!regionData) return null;
        return {
          alpha3: ctryWithAlpha3[regionData.name],
          id: key,
          name: regionData.name,
          region: regionData.region,
          m49Code: regionData.m49Code,
          value: primaryDataByKey[key] ? primaryDataByKey[key] : 0.0,
          valueSecond: secondaryDataByKey[key] ? secondaryDataByKey[key] : 0.0,
        };
      })
      .filter(item => item !== null);

    return dataFiltered;
  }
);
