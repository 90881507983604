import { connect } from "react-redux";
import {
  selectChartMetaData,
  selectSingleCountries,
  selectProducts,
} from "modules/indicators/selectors/indicators";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import ExportDataComponent from "./ExportDataComponent";

const mapStateToProps = state => ({
  chartMetaData: selectChartMetaData(state),
  currentUIState: selectCurrentUIState(state),
  regionsData: selectSingleCountries(state),
  productsData: selectProducts(state),
  filterParams: selectFilterParams(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(ExportDataComponent);
