import React, { Fragment } from "react";
import { Scatterplot } from "datadesign-charts-sdgtrade";
import scatterplotData from "./data/191119_wto_agr_prd_xsubdy/scatterplot";
import { FormattedMessage } from "react-intl";

const ScatterplotModule = () => {
  const dataFiltered = scatterplotData.filter(d => d.year > 2000); //exclude all
  const uniqueData = dataFiltered.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

  return (
    <Fragment>
      <FormattedMessage id="demo.chart.title.map-force-chart" defaultMessage="Pref and MFN">
        {text => (
          <Scatterplot
            data={uniqueData}
            uniqueIDKey="id"
            nameKey="name"
            xValueKey="PREF"
            yValueKey="MFN"
            rValueKey="id" // optional, if defined circle radius will be based on this key otherwise a default value will be taken
            xyUnit="USD" // 'PERCENTAGE' or 'USD', default USD
            rUnit="USD" // 'PERCENTAGE' or 'USD', default USD
            xValueTitle="Pref"
            yValueTitle="MFN"
            rValueTitle="GDP"
            title={text}
            aspectRatio={0.5}
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};
export default ScatterplotModule;
