import React from "react";
import { TreeRadial } from "datadesign-charts-sdgtrade";
import { useParams } from "react-router-dom";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import PropTypes from "prop-types";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import Skeleton from "@material-ui/lab/Skeleton";

const Chart = ({ data, title }) => {
  const { embed } = useParams();

  if (data === null) {
    return (
      <div className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  if (data === null) {
    return (
      <div className="loading">
        {/* <CircularProgress /> */}
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  return (
    <div className="chart">
      <TreeRadial
        data={data}
        radiusValueKey="value"
        colorValueKey="region"
        uniqueIDKey="name"
        groupKey="region"
        nameKey="name"
        title={title}
        aspectRatio={0.5}
      />
      {(!embed || embed !== "embed") && <Toolbar vzData={data} />}
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
