import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import TreeItem from "@material-ui/lab/TreeItem";
import Checkbox from "@material-ui/core/Checkbox";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { economicRegionIds, geographicRegionsIds, singleCountriesIds } from "./constants";
import { FormattedMessage } from "react-intl";

const TreeStyled = styled.div`
  position: relative;

  .toolbar {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9999;
    cursor: default;
    border-bottom: 1px solid #eee;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }

  .group-items {
    display: flex;
    padding-left: 15px;

    label {
      flex: 1 0 auto;
    }
  }

  .item {
    display: flex;
    white-space: nowrap;
    padding-left: 15px;
  }

  .item-p-l {
    padding-left: 15px;
    flex: 1 0 auto;
  }

  .deselect {
    display: inline-block;
    padding: 5px 15px;
    margin: 5px 5px;
    background: #eee;
    cursor: pointer;
  }

  .keyword-input {
    border-bottom: 1px solid #eee;
    margin-top: 5px;
    padding: 5px 15px;
    width: 100%;

    label {
      padding-left: 15px;
    }
  }
`;

let dataSelectionState = {};
const NestedTreBuilder = React.memo(
  ({ data, onChangeCallback, preselectedValues, depth, keywordSelector, paramLabel, dataKey }) => {
    const { indicatorCode } = useSelector(selectCurrentUIState);

    const expandedListOnSearch = ["1", "62", "753", "513", "419", "747", "9", "202"];

    const handleValueChange = e => {
      const value = parseInt(e.target.value);
      onChangeCallback({ [value]: e.target.checked });
    };

    const handleMassUpdateState = (flag, dataKey) => {
      let updatedDataSelectionState;
      switch (dataKey) {
        case "tcn1":
          updatedDataSelectionState = economicRegionIds.reduce(
            (result, d) => ({
              ...result,
              [d]: flag,
            }),
            {},
          );
          break;
        case "tcn2":
          updatedDataSelectionState = geographicRegionsIds.reduce(
            (result, d) => ({
              ...result,
              [d]: flag,
              238: flag,
              239: flag,
              240: flag,
              236: flag,
            }),
            {},
          );
          break;
        default:
          updatedDataSelectionState = singleCountriesIds.reduce(
            (result, d) => ({
              ...result,
              [d]: flag,
            }),
            {},
          );

          break;
      }

      if (["2_b_1", "8_a_1"].includes(indicatorCode)) {
        updatedDataSelectionState = {
          ...updatedDataSelectionState,
          296: flag,
          0: flag,
        };
      }

      onChangeCallback(updatedDataSelectionState);
    };

    const keyword = useSelector(keywordSelector);
    return (
      <TreeStyled>
        <div className="toolbar">
          {keyword.trim().length === 0 &&
            ["2_b_1", "8_a_1"].includes(indicatorCode) &&
            paramLabel === "Countries / Regions" && (
              <div className="deselect" onClick={() => handleMassUpdateState(true, dataKey)}>
                <FormattedMessage id="indicators.filter.select-all" defaultMessage="Select All"/>
              </div>
            )}
          {keyword.trim().length === 0 && (
            <div className="deselect" onClick={() => handleMassUpdateState(false, dataKey)}>
              <FormattedMessage id="indicators.filter.deselect-all" defaultMessage="Deselect All"/>
            </div>
          )}
        </div>

        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon/>}
          defaultExpandIcon={<ChevronRightIcon/>}
          defaultExpanded={keyword.length === 0 ? [""] : expandedListOnSearch}
        >
          {data.map((d, i) => (
            <div key={`ts-${i}`}>
              <div>{_buildTree(d, preselectedValues, handleValueChange, depth, keyword, indicatorCode)}</div>
            </div>
          ))}
        </TreeView>
      </TreeStyled>
    );
  },
);

const _buildTree = (data, preselectedValues, handleValueChange, depth, keyword, indicatorCode) => {
  let isChecked = id => preselectedValues.indexOf(id) !== -1;

  if (data.id !== undefined && depth >= 0) {
    dataSelectionState[data.id] = preselectedValues.indexOf(data.id) !== -1;
  }

  if (data.items) {
    if (depth === 0) {
      return createSingleTreeItem(data, isChecked(data.id), handleValueChange, keyword, indicatorCode);
    }

    if (data.name === undefined) {
      return (
        <>
          {data.items.map(item =>
            _buildTree(item, preselectedValues, handleValueChange, depth - 1, keyword, indicatorCode),
          )}
        </>
      );
    }
    return (
      <TreeItem
        key={`it-${data.id}`}
        nodeId={`${data.m49Code}`}
        label={
          <FormControlLabel
            className="item"
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <Checkbox
                checked={isChecked(data.id)}
                onChange={e => handleValueChange(e)}
                disableRipple
                value={data.id}
              />
            }
            label={data.name}
          />
        }
      >
        {data.items.map(item => _buildTree(item, preselectedValues, handleValueChange, depth - 1, keyword))}
      </TreeItem>
    );
  }

  return createSingleTreeItem(data, isChecked(data.id), handleValueChange, keyword, indicatorCode);
};

const createSingleTreeItem = (data, isChecked, onChangeCallback, keyword) => {
  if (
    keyword.trim().length > 1 &&
    data.name
      .toLowerCase()
      .trim()
      .indexOf(keyword.toLowerCase().trim()) === -1
  ) {
    return <div key={`it-${data.id}`}/>;
  }

  return (
    <TreeItem
      key={`it-${data.id}`}
      nodeId={data.m49Code}
      label={
        <FormControlLabel
          className="item item-p-l"
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
          control={
          <Checkbox checked={isChecked} onChange={onChangeCallback} disableRipple value={data.id}/>
        }
          label={data.name}
        />
      }
    />
  );
};

NestedTreBuilder.propTypes = {
  data: PropTypes.array.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  preselectedValues: PropTypes.array.isRequired,
  depth: PropTypes.number.isRequired,
  keywordSelector: PropTypes.any.isRequired,
  dataKey: PropTypes.string.isRequired,
  paramLabel: PropTypes.any.isRequired,
};

export default NestedTreBuilder;
