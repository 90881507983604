import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import iconFileDownload from "./images/icons/file-download.svg";
import iconArrowRight from "./images/icons/arrow-right.svg";
import { useParams } from "react-router";

const dateFormat = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const PublicationListStyled = styled.div`
  border-radius: 5px;
  h2 {
    margin: 0;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .content {
    padding-bottom: 15px;
  }

  .title {
    background-color: #425a85;
    border-bottom: 1px solid #fff;
    transition: all 0.2s ease-out;
    color: #fff;
    font-size: 16px;
    & > * {
      color: #fff;
    }
  }

  .actions {
    display: table;
    width: 100%;
    border-collapse: collapse;
    .item {
      display: table-row;

      > * {
        display: table-cell;
        padding: 10px 0;
        border-bottom: 1px solid #04519c;
      }
      > span {
        width: 200px;
        font-weight: bold;
      }
    }
  }
  .action {
    color: #38568c;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .tt {
    flex: 1 0 auto;
  }

  .MuiExpansionPanel-root.Mui-expanded {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
`;

const CMSContentPublications = ({ data }) => {
  const { language } = useParams();

  return (
    <PublicationListStyled>
      {data.map((article, key) => (
        <Accordion key={key}>
          <AccordionSummary className="title" expandIcon={<ExpandMoreIcon />}>
            <CalendarTodayIcon />
            &nbsp;&nbsp;&nbsp;
            <b>{article?.publication_date}</b>
            <span className="tt">
              <span
                dangerouslySetInnerHTML={{
                  __html: new Date(article?.date).toLocaleDateString(language, dateFormat),
                }}
              />
              &nbsp;-&nbsp;
              <span
                dangerouslySetInnerHTML={{
                  __html: article?.["item-heading"],
                }}
              />
            </span>
            <AttachFileIcon />
          </AccordionSummary>

          <AccordionDetails>
            <div className="content">
              <span dangerouslySetInnerHTML={{ __html: article?.["publication-summary"] }} />
              <div className="actions">
                <div className="item">
                  <span>Document</span>
                  {article?.document && (
                    <a
                      className="action action-download"
                      href={article?.pdf_link?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={iconFileDownload} alt="" />
                      &nbsp;Download PDF
                    </a>
                  )}
                  {article?.["document-external"] && (
                    <a
                      className="action action-download"
                      href={article?.["document-external"]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={iconFileDownload} alt="" />
                      &nbsp;Download PDF
                    </a>
                  )}
                </div>
                <div className="item">
                  <span>Online resource</span>
                  <a
                    className="action action-visit"
                    href={article?.["online-resource"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={iconArrowRight} alt="" />
                    &nbsp;Go to Website
                  </a>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </PublicationListStyled>
  );
};

CMSContentPublications.propTypes = {
  data: PropTypes.array,
};

CMSContentPublications.defaultProps = {
  data: [],
};

export default CMSContentPublications;
