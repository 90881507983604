export default (data, regionsData, productsData, unit, filterParams) =>
  data.flat().map(d => ({
    product: +d.productTypeId !== 42 ? d.name : "",
    productSector: productsData[(d?.id)]?.category || "",
    reporter: regionsData?.[d.geoAreaId]?.name || regionsData?.[filterParams.region[0]]?.name || "",
    reporterCode: d.geoAreaId || regionsData?.[filterParams.region[0]]?.m49Code || "",
    partner: regionsData[d.partnerId]?.name || regionsData[(filterParams?.partner[0])]?.name || "",
    partnerCode: d.partnerId || filterParams?.partner[0] || "",
    year: d.year || filterParams?.year[0] || "",
    value: d?.growthSecond || d?.valueSecond || "",
    unit: unit || "",
  }));
