let environment = process.env.NODE_ENV || "development";
if (String(location.search).indexOf("&env=dev") > -1) {
  environment = "development";
}
const PROXY = "https://cors-anywhere.herokuapp.com/";
let config;

export const apisProd = {
  proxy: PROXY,
  apiRoot: "https://apims.unctad.org/v1",
  OcpApimSubscriptionKey: "24c8976b9bb042c89ed4ada19fbeae9b",
  cmsApiRoot: "https://sdgtrademonitor.cdn.prismic.io/api/v2",
  cmsNewApiRoot: "https://gthd.prismic.io/api/v2",
  cmsRoot: "https://cms.tmi-internal.org",
  cmsContentRoot: "https://cms.tmi-internal.org/api/content/sdg",
  cmsClientId: "5e787c986d5ae700019dc85a",
  cmsClientSecret: "e2d8rjmqrmmquugvahumwfqf1qvxvy9wyl2vxqdgwowx",
  cmsContactId: "sdg:contact",
  cmsContactSecret: "iaq8hmhei8yocgpgjdkohmxx6ehjmylsjekdh7syrpix",
};

export const apisDev = {
  proxy: PROXY,
  apiRoot: "https://apims.unctad.org/uat",
  OcpApimSubscriptionKey: "10fa2d15d11d408e89d1b5d1f31d0b6a",
  cmsApiRoot: "https://sdgtrademonitor.cdn.prismic.io/api/v2",
  cmsNewApiRoot: "https://gthd.prismic.io/api/v2",
  cmsRoot: "https://cms.tmi-internal.org",
  cmsContentRoot: "https://cms.tmi-internal.org/api/content/sdg",
  cmsClientId: "5e787c986d5ae700019dc85a",
  cmsClientSecret: "e2d8rjmqrmmquugvahumwfqf1qvxvy9wyl2vxqdgwowx",
  cmsContactId: "sdg:contact",
  cmsContactSecret: "iaq8hmhei8yocgpgjdkohmxx6ehjmylsjekdh7syrpix",
};

switch (environment) {
  case "production":
    config = apisProd;
    break;
  default:
    config = apisDev;
    break;
}

export default config;
