import React from "react";
import Header from "modules/ui/components/Header";
import Footer from "modules/ui/components/Footer";
import CMSPage from "modules/cms/components/CMSPage";
import ScrollToTopOnMount from "modules/shared-components/ScrollToTopOnMount";
import useContent from "modules/cms/hooks/useContent";

const Methodologies = () => {
  const query = React.useMemo(
    () => `{
      queryMethodologiesContents {
        id,
        data {
          key { iv},
          heading { en, fr, es },
          imageBanner {
            en { id}, fr { id }, es { id }
          },
          list {
            iv {
              item {
                data {
                  heading { en, fr, es },
                  headingTooltip { en, fr, es },
                  list {
                    en { term, definition },
                    fr { term, definition },
                    es { term, definition },
                  }
                }
              }
            }
          }
        }
      }
    }`,
    []
  );

  const content = useContent({
    contentType: "methodologies",
    contentKey: "page-methodologies",
    query: query,
  });

  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      <CMSPage content={content} />
      <Footer />
    </>
  );
};

export default React.memo(Methodologies);
