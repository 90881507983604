import React from "react";
import { useSelector } from "react-redux";
import { selectRationale } from "modules/indicators/selectors/rationale";
import Skeleton from "@material-ui/lab/Skeleton";
import { FormattedMessage } from "react-intl";

const Rationale = () => {
  const rationale = useSelector(selectRationale);

  return (
    <div>
      {!rationale && <Skeleton variant="rect" height={24} />}
      {rationale && (
        <FormattedMessage
          id="indicators.rationale"
          defaultMessage="{label}: {value}"
          values={{
            label: <b>Rationale</b>,
            value: rationale,
          }}
        />
      )}
    </div>
  );
};

export default React.memo(Rationale);
