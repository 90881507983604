import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import NestedTreeBuilder from "./NestedTreeBuilder";
import { SelectionStyled } from "../Styled";
import SearchForm from "../SearchForm";
import { useSelector } from "react-redux";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { REGIONS, VISUALIZATIONS } from "modules/meta-data/constants";
import { FormattedMessage } from "react-intl";

const Selection = ({
  label,
  tabsData,
  onSelect,
  preselectedValues,
  regions,
  keywordSelector,
  keywordCallbackFn,
}) => {
  const { visualization, indicatorCode } = useSelector(selectCurrentUIState);

  let tabsDataFiltered = tabsData;

  if (
    label === "Countries / Regions" &&
    [VISUALIZATIONS.TREEMAP_CHART, VISUALIZATIONS.MAP].includes(visualization)
  ) {
    tabsDataFiltered = tabsData.filter(d => [REGIONS.INDIVIDUAL_COUNTRIES].includes(d.key));
  }

  if (label === "Partners" && ["8_2_i"].includes(indicatorCode)) {
    tabsDataFiltered = tabsData.map(d => {
      if (d.key === REGIONS.GEOGRAPHIC_REGIONS) {
        return {
          ...d,
          depth: 1,
        };
      }

      return d;
    });
  }

  if (
    label === "Partners" &&
    ["8_a_1", "17_11_ii"].includes(indicatorCode) &&
    visualization === VISUALIZATIONS.FLOW_CHART
  ) {
    tabsDataFiltered = tabsData.filter(d => d.key !== REGIONS.ECONOMIC_REGIONS);
  }

  const regionsByKey = regions.reduce(
    (accumulator, d) => ({
      ...accumulator,
      [d.id]: d,
    }),
    {}
  );

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleChangeTab = (event, newValue) => {
    event.stopPropagation();
    setCurrentTabIndex(newValue);
  };

  const selectedItemsLbl =
    preselectedValues.length > 0 ? (
      preselectedValues
        .filter(d => regionsByKey[d] !== undefined)
        .map(d => regionsByKey[d].name)
        .join(", ")
    ) : (
      <FormattedMessage id="indicators.filter.empty-selection" defaultMessage="Empty selection" />
    );

  return (
    <SelectionStyled>
      <label className="filter-criteria-lbl">
        {label === "Partners" && (
          <FormattedMessage id="indicators.search-form.partners" defaultMessage="Partners" />
        )}
        {label === "Countries / Regions" && (
          <FormattedMessage
            id="indicators.search-form.countries-regions"
            defaultMessage="Countries / Regions"
          />
        )}
      </label>
      <div className="selected-items-lbl" title={selectedItemsLbl}>
        {selectedItemsLbl}
      </div>
      <div className="popup">
        <SearchForm keywordSelector={keywordSelector} keywordCallbackFn={keywordCallbackFn} />
        <Tabs value={currentTabIndex} onChange={handleChangeTab}>
          {tabsDataFiltered.map((d, i) => (
            <Tab key={`tt${i}`} label={d.label} />
          ))}
        </Tabs>
        {tabsDataFiltered.map((d, i) => (
          <div key={`tc${i}`} className={`tab-content ${i === currentTabIndex ? "active" : ""}`}>
            <NestedTreeBuilder
              data={d.data}
              preselectedValues={preselectedValues}
              onChangeCallback={onSelect}
              depth={d.depth}
              isSearchable={d.isSearchable}
              keywordSelector={keywordSelector}
              dataKey={`tcn${i}`}
              paramLabel={label}
            />
          </div>
        ))}
      </div>
    </SelectionStyled>
  );
};

Selection.propTypes = {
  label: PropTypes.any.isRequired,
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      data: PropTypes.array.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  regions: PropTypes.array,
  preselectedValues: PropTypes.array.isRequired,
  keywordSelector: PropTypes.any.isRequired,
  keywordCallbackFn: PropTypes.any.isRequired,
};

Selection.defaultProps = {
  regions: null,
};

export default Selection;
