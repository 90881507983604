import { createSelector } from "reselect";
import { selectSingleCountries } from "modules/indicators/selectors/indicators";
import * as constants from "modules/constants";

const getSeriesData = state => state.indicators.seriesData;
const getDimension = state => state.indicators.currentUIState.dimension;

export const selectSeriesDataMapChoropleth = createSelector(
  getSeriesData,
  selectSingleCountries,
  getDimension,
  ({ data, status }, singleCountries, currentDimension) => {
    if (!data || Object.keys(singleCountries).length === 0 || status === constants.STATUS_LOADING) {
      return null;
    }
    if (currentDimension === "BP") return [];
    return data
      .filter(d => singleCountries[d.geoAreaId])
      .map(d => ({
        ...d,
        name: singleCountries[d.geoAreaId].name,
        region: singleCountries[d.geoAreaId].region,
        m49Code: singleCountries[d.geoAreaId].m49Code,
        alpha3Code: singleCountries[d.geoAreaId].alpha3Code,
      }));
  }
);
