import { createAction, handleActions } from "redux-actions";

export const setLanguage = createAction("[UI] Set language");
export const setMessages = createAction("[UI] Set translation messages");
export const setAvailableLanguages = createAction("[UI] Init available languages");
export const addError = createAction("[UI] Add error ⛔️");
export const removeError = createAction("[UI] Remove error");

export default handleActions(
  {
    [setLanguage]: (state, action) => ({
      ...state,
      language: action.payload,
    }),
    [setMessages]: (state, action) => ({
      ...state,
      messages: action.payload,
    }),
    [setAvailableLanguages]: (state, action) => ({
      ...state,
      availableLanguages: [...action.payload],
    }),
    [addError]: (state, action) => ({
      ...state,
      errors: [
        // no point of display same error multiple times
        ...state.errors.filter(e => e.message !== action.payload.message),
        action.payload,
      ],
    }),
    [removeError]: (state, action) => ({
      ...state,
      errors: state.errors.filter(e => e.timestamp !== action.payload),
    }),
  },
  {
    language: undefined,
    availableLanguages: undefined,
    errors: [],
  }
);
