import { of, concat } from "rxjs";
import { debounceTime, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  setCurrentUIState,
  setDefaultFilterParams,
  setFilterParams,
  setFilterParamsProduct,
  setFilterParamsYear,
  setSeriesData,
} from "../reducer";
import isResponseValid from "../../../utils/isResponseValid";
import { addError } from "modules/ui/reducer";
import { INDICATORS } from "modules/meta-data/constants";
import { getFilterParams, validateFilterParams } from "modules/indicators/helpers";
import * as constants from "modules/constants";

export default (action$, state$, { ajax, apiConfig, timestamp, indicatorsConfig, visualizationsConfig }) =>
  action$.pipe(
    ofType(
      setDefaultFilterParams,
      setFilterParams,
      setCurrentUIState,
      setFilterParamsProduct,
      setFilterParamsYear
    ),
    debounceTime(10),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { indicatorCode, visualization, measure } = state?.indicators?.currentUIState;

      const indicatorConf = indicatorsConfig[INDICATORS[`i${indicatorCode}`]];

      const vzConf = visualizationsConfig[visualization];

      const paramsNeededKeys = {
        ...indicatorConf?.viewData?.params,
        ...vzConf?.params,
      };

      if (
        validateFilterParams(paramsNeededKeys, state?.indicators?.filterParams) === false ||
        measure === ""
      ) {
        return false;
      }

      return {
        action: action,
        measure: measure,
        params: getFilterParams(paramsNeededKeys, state?.indicators?.filterParams),
      };
    }),
    mergeMap(data => {
      if (data === false) {
        return of(setSeriesData({ status: constants.STATUS_OK, data: null }));
      } else {
        const { params, measure, action } = data;

        const urlParams = Object.keys(params)
          .reduce((acc, key) => [...acc, `${key}=${params[key]}`], [])
          .join("&");

        const request = {
          responseType: "json",
          method: "GET",
          mode: "cors",
          crossDomain: true,
          url: `${apiConfig.apiRoot}/measures/${measure}?${urlParams}`,
          headers: {
            "Ocp-Apim-Subscription-Key": apiConfig.OcpApimSubscriptionKey,
          },
        };

        return concat(
          of(setSeriesData({ status: constants.STATUS_LOADING, data: null })),
          ajax(request).pipe(
            map(data => {
              if (!isResponseValid(data.response)) {
                return addError({
                  timestamp: timestamp(),
                  message: "error.invalid-response",
                  retryAction: action,
                });
              }

              return setSeriesData({ status: constants.STATUS_OK, data: data.response });
            })
          )
        );
      }
    })
  );
