import React, { Fragment } from "react";
import { RadarChart } from "datadesign-charts-sdgtrade";
import TM_TRF_ZERO_2017 from "./data/TM_TRF_ZERO_2017";
import regions from "./data/region_by_id";
import products from "./data/products_by_id";
import { FormattedMessage } from "react-intl";

const RadarChartModule = () => {
  const dataFiltered = TM_TRF_ZERO_2017.filter(d => d.productTypeId !== 42); //exclude all

  // group by 'geoAreaId'
  const dataGrouped = dataFiltered.reduce((rv, x) => {
    (rv[x.geoAreaId] = rv[x.geoAreaId] || []).push(x);
    return rv;
  }, {});

  const dataGroupedClean = Object.keys(dataGrouped).map(key => dataGrouped[key]);

  const productsFlatData = products.reduce((acc, d) => acc.concat(d.items), []);

  // replace id by country name
  dataGroupedClean.map(item =>
    item.map(d => {
      const region = regions.find(k => +k.id === +d.geoAreaId);
      d.name = region.name;
      const product = productsFlatData.find(k => +k.id === +d.productTypeId);
      d.productName = product.type;
      return d;
    })
  );

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.map-force-chart"
        defaultMessage="Proportion of tariff lines applied to imports with zero-tariff in 2017"
      >
        {text => (
          <RadarChart
            data={dataGroupedClean}
            xValueKey="productName" // axislabel on outer circle
            yValueKey="value" // value of datapoint generating inner form
            uniqueIDKey="id"
            nameKey="name"
            yUnit="PERCENTAGE" // 'PERCENTAGE' or 'USD', default USD
            title={text}
            aspectRatio={0.5}
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};

export default RadarChartModule;
