import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import PropTypes from "prop-types";
import { MapSymbolForce } from "datadesign-charts-sdgtrade";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import { IndicatorContext } from "components/IndicatorContextProviderComponent";
import { useRouteMatch } from "react-router";
import { INDICATORS, VISUALIZATIONS } from "modules/meta-data/constants";
import Skeleton from "@material-ui/lab/Skeleton";

const Chart = ({ data, chartMetaData, isSecondarySeriesDataEmpty, title }) => {
  const { embed } = useParams();
  const match = useRouteMatch();
  const indicatorContext = useContext(IndicatorContext);
  const indicatorCode = match.params.code.replace(/-/g, "_");
  const uiConf = indicatorContext[INDICATORS[`i${indicatorCode}`]];
  let dataTitle = Object.keys(chartMetaData)
    .filter(d => chartMetaData[d] !== undefined)
    .map(d => chartMetaData[d].seriesDescription);

  const shouldSwitchMeasures =
    uiConf.viewData.switchMeasureVsSecondaryMeasure.indexOf(VISUALIZATIONS.BUBBLE_CHART) !== 1;
  const valueKey = "value";
  const secondValueKey = isSecondarySeriesDataEmpty ? "" : "valueSecond";

  let props = {
    data: data,
    colorValueKey: "region",
    uniqueIDKey: "alpha3",
    nameKey: "name",
    aspectRatio: 0.5,
    title: "chart",
    radiusVisibleMin: 0.1,
  };

  if (isSecondarySeriesDataEmpty) {
    props = {
      ...props,
      radiusValueKey: valueKey,
      radiusSecondValueKey: "",
      dataTitle: dataTitle,
    };
  } else {
    props = {
      ...props,
      radiusValueKey: shouldSwitchMeasures === false ? valueKey : secondValueKey,
      radiusSecondValueKey: shouldSwitchMeasures === false ? secondValueKey : valueKey,
      dataTitle: shouldSwitchMeasures === false ? dataTitle : dataTitle.reverse(),
    };
  }

  if (data === null) {
    return (
      <div className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  return (
    <div className="chart">
      <MapSymbolForce {...props} title={title} />
      {(!embed || embed !== "embed") && <Toolbar vzData={data} />}
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  chartMetaData: PropTypes.object.isRequired,
  isSecondarySeriesDataEmpty: PropTypes.bool.isRequired,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
