import { VISUALIZATIONS, VALUES, PARAMS, DIMENSIONS } from "modules/meta-data/constants";

export default {
  [VISUALIZATIONS.LINE_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_MERGED,
    params: {
      year: PARAMS.YEAR_RANGE,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_SINGULAR,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_RANGE,
      region: VALUES.REGIONS_FIRST_20_SINGLE_COUNTRIES,
    },
  },
  [VISUALIZATIONS.FLOW_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_MERGED,
    params: {
      year: PARAMS.YEAR_1_POINT,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_MULTIPLE,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_MULTIPLE,
        product: VALUES.SELECTION_SINGULAR,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_ALL_SINGLE_COUNTRIES,
    },
  },
  [VISUALIZATIONS.MAP]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_MERGED,
    params: {
      year: PARAMS.YEAR_1_POINT,
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_ALL_SINGLE_COUNTRIES,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
    },
  },
  [VISUALIZATIONS.VERTICAL_BAR_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_MERGED,
    params: {
      year: PARAMS.YEAR_2_POINTS,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_SINGULAR,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_2_POINTS,
      region: VALUES.REGIONS_FIRST_20_SINGLE_COUNTRIES,
    },
  },
  [VISUALIZATIONS.RADAR_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_MERGED,
    params: {
      year: PARAMS.YEAR_1_POINT,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_ALL_SINGLE_COUNTRIES,
    },
  },
  [VISUALIZATIONS.TREEMAP_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_MERGED,
    params: {
      year: PARAMS.YEAR_1_POINT,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_SINGULAR,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_ALL_SINGLE_COUNTRIES,
    },
  },

  [VISUALIZATIONS.HORIZONTAL_BAR_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_SEPARATED,
    params: {
      year: PARAMS.YEAR_1_POINT,
      growth: PARAMS.GROWTH,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_SINGULAR,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_FIRST_20_SINGLE_COUNTRIES,
    },
  },
  [VISUALIZATIONS.GAP_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_SEPARATED,
    params: {
      year: PARAMS.YEAR_1_POINT,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_SINGULAR,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_ALL_SINGLE_COUNTRIES,
    },
  },

  [VISUALIZATIONS.SCATTER_PLOT_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_SEPARATED,
    params: {
      year: PARAMS.YEAR_1_POINT,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_SINGULAR,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_FIRST_20_SINGLE_COUNTRIES,
    },
  },
  [VISUALIZATIONS.AREA_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_SEPARATED,
    params: {
      year: PARAMS.YEAR_RANGE,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_SINGULAR,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_FIRST_20_SINGLE_COUNTRIES,
    },
  },
  [VISUALIZATIONS.TREE_RADIAL_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_SEPARATED,
    params: {
      year: PARAMS.YEAR_1_POINT,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_SINGULAR,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_FIRST_20_SINGLE_COUNTRIES,
    },
  },
  [VISUALIZATIONS.BUBBLE_CHART]: {
    measureSelectionType: VALUES.MEASURE_SELECTION_SEPARATED,
    params: {
      year: PARAMS.YEAR_1_POINT,
    },
    paramsQty: {
      [DIMENSIONS.BY_COUNTRIES]: {
        region: VALUES.SELECTION_MULTIPLE,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_SINGULAR,
      },
      [DIMENSIONS.BY_PRODUCTS]: {
        region: VALUES.SELECTION_SINGULAR,
        partner: VALUES.SELECTION_SINGULAR,
        product: VALUES.SELECTION_MULTIPLE,
      },
    },
    filter: {
      year: VALUES.FILTER_PARAM_YEAR_1_POINT,
      region: VALUES.REGIONS_ALL_SINGLE_COUNTRIES,
    },
  },
};
