import React from "react";
import styled from "styled-components";
import OrganizationLinks from "../OganizationLinks/OrganizationLinks";
import { NavLink, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const FooterStyled = styled.footer`
  background-color: #232c3e;
  .footer-upper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    padding: 65px 0;
    max-width: 1090px;
  }

  .footer-links {
    margin-bottom: 15px;

    ul {
      list-style-type: none;
      padding: 0;
    }
    .title {
      font-family: Roboto, "sans-serif";
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.14;
      letter-spacing: 2px;
      color: #ffffff;
      text-transform: uppercase;
    }
    li {
      font-family: Roboto, "sans-serif";
      font-size: 16px;
      line-height: 200%;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
    a {
      color: #fff;
    }
  }

  .footer-lower {
    display: flex;
    justify-content: flex-start;
    background: #1a212e;
    .copyright {
      width: 100%;
      max-width: 1090px;
      margin: 15px auto;
      p {
        margin: 0;
        opacity: 0.52;
        font-family: Roboto, "sans-serif";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
    }
  }
`;

const Footer = () => {
  const { embed, language } = useParams();

  if (embed === "embed") return <></>;

  return (
    <FooterStyled>
      <div className="footer-upper">
        <div className="footer-organization">
          <OrganizationLinks/>
        </div>
        <div className="footer-links">
          <ul>
            <li>
              <NavLink to={`/${language}/faq`}>
                <FormattedMessage id="nav.menu-item.faq" defaultMessage="Frequently Asked Questions"/>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${language}/reports-demo`}>
                <FormattedMessage id="nav.menu-item.lib" defaultMessage="Data Visualizations Library"/>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${language}/methodologies`}>
                <FormattedMessage id="nav.menu-item.methodology" defaultMessage="Methodology"/>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${language}/publications`}>
                <FormattedMessage id="nav.menu-item.publications" defaultMessage="Publications"/>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${language}/contact`}>
                <FormattedMessage id="nav.menu-item.contact-us" defaultMessage="Contact us"/>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-lower">
        <div className="copyright">
          <p>
            <FormattedMessage
              id="nav.copyright"
              defaultMessage="© Copyright {date} - ITC, UNCTAD, WTO. All rights reserved."
              values={{ date: new Date().getFullYear() }}
            />
          </p>
        </div>
      </div>
    </FooterStyled>
  );
};

export default Footer;
