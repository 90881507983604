import { connect } from "react-redux";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { setCurrentUIState } from "modules/indicators/reducer";
import Content from "./Content";

const mapStateToProps = state => ({
  currentUIState: selectCurrentUIState(state),
});

const mapDispatchToProps = dispatch => ({
  onCurrentUIStateChange: view => {
    dispatch(setCurrentUIState(view));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content);
