import { connect } from "react-redux";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { selectSingleCountries, selectProducts } from "modules/indicators/selectors/indicators";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import { selectMeasuresMetaData } from "modules/meta-data/selectors/measures";
import { setCurrentVzTitle } from "modules/indicators/reducer";

import Title from "./ChartTitleComponent";

const mapStateToProps = state => ({
  currentUIState: selectCurrentUIState(state),
  regionsData: selectSingleCountries(state),
  productsData: selectProducts(state),
  filterParams: selectFilterParams(state),
  measuresMetaData: selectMeasuresMetaData(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrentVzTitle: data => dispatch(setCurrentVzTitle(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Title);
