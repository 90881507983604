import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
// import { useLanguage } from "modules/ui/hooks";
import officalIcon from "./images/official-icon.svg";
import slideshowArrow from "./images/slideshow-arrow.png";
import { FormattedMessage } from "react-intl";

const IndicatorsComponentStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #425a85;
  padding: 20px 25px;

  .idi {
    display: none;
    flex-direction: column;
    height: 380px;
    &.current {
      display: flex;
    }
    &.official {
      &:before {
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 5px;
        background: url(${officalIcon}) 0 0 / contain no-repeat;
      }
    }
  }

  .indicator {
    display: block;
    opacity: 0.69;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.56px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .title {
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.32;
    letter-spacing: 0.28px;
    color: #ffffff;
  }

  .figure {
    width: 450px;
    height: 190px;
    margin: 20px auto 10px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .slider {
    &-nav {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
      display: flex;
      li {
        margin: 20px 3px;
        width: 23px;
        height: 5px;
        font-size: 0;
        transition: background 0.1s ease-out;
        background: #cdd1d8;
        cursor: pointer;
        &.current {
          background: #425a85;
        }
      }
    }
    &-arrow {
      position: absolute;
      top: 220px;
      background: transparent url(${slideshowArrow}) 50%/50% no-repeat;
      width: 30px;
      height: 30px;
      font-size: 0;
      cursor: pointer;
      &-left {
        left: 10px;
        transform: translateY(-50%) rotate(180deg);
      }
      &-right {
        right: 10px;
      }
    }
  }

  .action {
    justify-self: flex-end;
    flex: 1 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .btn {
      border-radius: 2px;
      background-color: #ffffff;
      margin: 5px;
      padding: 5px 24px;
      font-family: Roboto;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 3.08;
      letter-spacing: 1.5px;
      color: #374e64;
      text-transform: uppercase;
    }
  }
`;

const getDefaultDimensionURL = indicator => {
  switch (indicator) {
    case "2-b-1":
      return "BC/1/";
    case "8-2-i":
      return "BC/6/";
    case "8-a-1":
      return "BC/1/";
    case "10-a-1":
      return "BP/7/";
    case "10-a-i":
      return "BC/3/";
    case "17-10-1":
      return "BC/8/";
    case "17-11-1":
      return "BC/1/";
    case "17-11-i":
      return "BC/6/";
    case "17-11-ii":
      return "BC/5/";
    case "17-12-1":
      return "BC/3/";
    default:
      return indicator;
  }
};

const formatIndicator = code => {
  const indicator = code
    .replace("INDICATOR", "")
    .replace(/ *\(|\) */g, "")
    .replace(/ *\. */g, "-")
    .trim()
    .toLowerCase();

  return `${indicator}/${getDefaultDimensionURL(indicator)}`;
};

const IndicatorsComponent = ({ indicators, imgSrc }) => {
  const language = "en";
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => setCurrentIndex(0), [indicators.length]);

  return (
    <IndicatorsComponentStyled>
      {indicators.map((d, i) => (
        <div
          key={d.id}
          className={`idi ${d.official === true ? "official" : ""} ${i === currentIndex ? "current" : ""}`}
        >
          <span className="indicator">SDG {d.shortDescription}</span>
          <span className="title">{d.longDescription}</span>
          <div className="figure">
            <img src={imgSrc} alt=""/>
          </div>
          <div className="action">
            <NavLink
              className="btn btn-more"
              target={"_blank"}
              to={`/${language}/indicators/${formatIndicator(d.shortDescription)}`}
            >
              <FormattedMessage id="misc.analyse-data" defaultMessage=" Analyse Data"/>
            </NavLink>
          </div>
        </div>
      ))}

      {indicators.length > 1 && (
        <>
          <ul className="slider-nav">
            {indicators.map((d, i) => (
              <li
                key={`sl-nav-${i}`}
                className={`${i === currentIndex ? "current" : ""}`}
                onClick={() => setCurrentIndex(i)}
              >
                <span>{i}</span>
              </li>
            ))}
          </ul>

          <div className="slider-control">
            <div
              className="slider-arrow slider-arrow-left"
              onClick={() => setCurrentIndex(currentIndex === 0 ? indicators.length - 1 : currentIndex - 1)}
            >
              Previous
            </div>
            <div
              className="slider-arrow slider-arrow-right"
              onClick={() => setCurrentIndex(currentIndex === indicators.length - 1 ? 0 : currentIndex + 1)}
            >
              Next
            </div>
          </div>
        </>
      )}
    </IndicatorsComponentStyled>
  );
};

IndicatorsComponent.propTypes = {
  indicators: PropTypes.array,
  imgSrc: PropTypes.string.isRequired,
};

IndicatorsComponent.defaultProps = {
  indicators: [],
};

export default IndicatorsComponent;
