import { createSelector } from "reselect";

const getSearchKeywordRegion = state => state?.indicators.searchKeyword.region;
const getSearchKeywordPartner = state => state?.indicators.searchKeyword.partner;

export const selectSearchKeywordRegion = createSelector(
  getSearchKeywordRegion,
  data => data
);

export const selectSearchKeywordPartner = createSelector(
  getSearchKeywordPartner,
  data => data
);
