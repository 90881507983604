import React from "react";
import PropTypes from "prop-types";
import ByCountries from "./components/ByContries";
import ByProducts from "./components/ByProducts";
import { useParams } from "react-router-dom";

const ExportData = props => {
  if (!props.vzData || JSON.stringify(props.vzData) === "{}" || props.vzData?.flat().length === 0)
    return null;

  const { code } = useParams();
  const fileName = props.title !== "" ? `SDG ${code} - ${props.title}` : "Exported data";

  if (props.currentUIState?.dimension === "BC") {
    return <ByCountries {...props} fileName={fileName} />;
  }
  return <ByProducts {...props} fileName={fileName} />;
};

ExportData.propTypes = {
  vzData: PropTypes.any,
  chartMetaData: PropTypes.any.isRequired,
  currentUIState: PropTypes.any.isRequired,
  regionsData: PropTypes.any.isRequired,
  productsData: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
  filterParams: PropTypes.any.isRequired,
};

ExportData.defaultProps = {
  vzData: null,
};

export default ExportData;
