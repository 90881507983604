import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";
import Header from "modules/ui/components/Header";
import Footer from "modules/ui/components/Footer";
import ScrollToTopOnMount from "modules/shared-components/ScrollToTopOnMount";
import { selectReports } from "modules/reports/selectors/reports";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Charts from "./Charts";
import Title from "./Title";
import Actions from "./Actions";
import { Alert } from "@material-ui/lab";
import { FormattedMessage } from "react-intl";
import ErrorBoundary from "modules/ui/components/ErrorBoundary";
import useContent from "../../../cms/hooks/useContent";
import CMSPage from "modules/cms/components/CMSPage";

const ReportDetailStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: ${props => props.theme.maxContentWidth}px;
  width: 100%;
  margin: 0 auto 50px;
  padding: 0 25px;
  .report-content {
    flex: 1 0 auto;
  }
`;

const ReportDetail = () => {
  const reports = useSelector(selectReports, shallowEqual);
  const content = useContent({ contentType: "report", contentKey: "page-report" });

  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      <CMSPage content={content} />
      <ErrorBoundary>
        <ReportDetailStyled>
          {!reports ||
            (reports?.content.length === 0 && (
              <Alert severity="warning">
                <FormattedMessage
                  id="indicators.exception.no-report-data"
                  defaultMessage="There is no data to for the report. Please go to the indicator pages and add generated visualizations!"
                />
              </Alert>
            ))}
          {reports?.content.length > 0 && (
            <>
              <div className="report-content">
                <Title title={reports.title} />
                <DndProvider backend={HTML5Backend}>
                  <Charts charts={reports?.content} />
                </DndProvider>
              </div>
              <Actions />
            </>
          )}
        </ReportDetailStyled>
      </ErrorBoundary>
      <Footer />
    </>
  );
};

export default ReportDetail;
