import React, { Fragment } from "react";
import { GapChart } from "datadesign-charts-sdgtrade";
import TM_TAX_WWTAV_2017_allproducts from "./data/TM_TAX_WWTAV_2017_allproducts";
import regions from "./data/region_by_id";
import { FormattedMessage } from "react-intl";

const GapChartModule = () => {
  // match two values in one obj
  const dataMerged = [];
  TM_TAX_WWTAV_2017_allproducts.forEach(d => {
    const find = dataMerged.find(k => +k.id === +d.geoAreaId);
    if (!find) {
      const find = TM_TAX_WWTAV_2017_allproducts.find(k => +k.geoAreaId === +d.geoAreaId && +k.id !== +d.id);

      const e = {};
      e.id = d.geoAreaId;
      let random = Math.floor(Math.random() * 10);
      e.tariffPRF = random > 5 ? null : d.tariffRegimeStatusId === 1 ? +d.value : +find.value;

      random = Math.floor(Math.random() * 10);
      e.tariffMFN = random > 5 ? null : d.tariffRegimeStatusId === 2 ? +d.value : +find.value;

      dataMerged.push(e);
    }
  });

  // replace id by country name
  dataMerged.map(d => {
    const region = regions.find(k => +k.id === +d.id);
    d.name = region ? region.name : "";
    return d;
  });

  const dataFiltered = dataMerged.filter((d, i) => d.name !== "" && i < 30);

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.gap-chart"
        defaultMessage="World weighted tariff average for all products"
      >
        {text => (
          <GapChart
            data={dataFiltered}
            dataTitle={["Preferential Status", "Most-Favoured-Nation (MFN) Status"]} //Title for Tooltip and Legend
            xValueKey={["tariffPRF", "tariffMFN"]}
            yValueKey="name"
            uniqueIDKey="id"
            title={text}
            aspectRatio={0.5} // optional
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};

export default GapChartModule;
