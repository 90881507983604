import React from "react";
import PropTypes from "prop-types";
import { AreaChart } from "datadesign-charts-sdgtrade";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import ErrorBoundary from "modules/ui/components/ErrorBoundary";
import Skeleton from "@material-ui/lab/Skeleton";

const Chart = ({ data, title }) => {
  if (data === null) {
    return (
      <div className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  const handleScaleChange = /*option*/ () => {
    // console.log("set new visualizationType to", option);
  };

  return (
    <ErrorBoundary>
      <AreaChart
        data={data}
        xValueKey="year"
        yValueKey="value"
        uniqueIDKey="id"
        nameKey="productName"
        visualizationType="STACKED" // STACKED or ONEHUNDREDPERC, by default STACKED
        handleScaleChange={handleScaleChange}
        yUnit="PERCENTAGE"
        title={title}
        aspectRatio={0.5}
      />
    </ErrorBoundary>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
