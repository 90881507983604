import { connect } from "react-redux";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import Download from "./DownloadComponent";

const mapStateToProps = state => ({
  currentUIState: selectCurrentUIState(state),
});

export default connect(
  mapStateToProps,
  null
)(Download);
