export const singleCountriesIds = [
  20,
  275,
  21,
  22,
  280,
  291,
  23,
  270,
  211,
  25,
  31,
  256,
  26,
  27,
  24,
  28,
  29,
  30,
  32,
  45,
  33,
  39,
  65,
  283,
  34,
  35,
  292,
  36,
  37,
  304,
  38,
  258,
  252,
  41,
  42,
  204,
  44,
  49,
  46,
  47,
  48,
  273,
  50,
  53,
  200,
  54,
  55,
  92,
  121,
  277,
  288,
  56,
  57,
  58,
  212,
  60,
  102,
  61,
  62,
  279,
  63,
  64,
  108,
  59,
  66,
  78,
  213,
  67,
  68,
  198,
  69,
  70,
  72,
  73,
  182,
  71,
  251,
  268,
  74,
  75,
  76,
  297,
  274,
  77,
  290,
  79,
  81,
  80,
  83,
  84,
  259,
  85,
  260,
  215,
  289,
  86,
  87,
  262,
  88,
  156,
  89,
  90,
  267,
  263,
  91,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  261,
  100,
  101,
  103,
  104,
  276,
  106,
  105,
  107,
  214,
  110,
  111,
  112,
  115,
  113,
  114,
  116,
  118,
  254,
  119,
  120,
  122,
  123,
  124,
  125,
  126,
  127,
  221,
  266,
  128,
  129,
  253,
  130,
  220,
  293,
  131,
  133,
  217,
  134,
  135,
  43,
  137,
  218,
  138,
  139,
  298,
  140,
  143,
  144,
  145,
  146,
  219,
  269,
  197,
  281,
  147,
  136,
  0,
  148,
  222,
  149,
  150,
  151,
  152,
  153,
  282,
  154,
  155,
  158,
  159,
  109,
  132,
  287,
  160,
  161,
  162,
  285,
  223,
  255,
  163,
  278,
  271,
  164,
  208,
  265,
  165,
  305,
  166,
  167,
  168,
  224,
  169,
  170,
  272,
  171,
  173,
  40,
  174,
  175,
  264,
  178,
  177,
  52,
  82,
  179,
  303,
  181,
  286,
  183,
  184,
  185,
  295,
  186,
  187,
  157,
  188,
  225,
  189,
  190,
  192,
  193,
  194,
  257,
  226,
  195,
  196,
  191,
  199,
  201,
  294,
  202,
  203,
  205,
  206,
  142,
  207,
  172,
  227,
  180,
  209,
  302,
  210,
  176,
];

export const economicRegionIds = [
  1,
  117, // LLDCs
  4, // Least Developed Countries (LDCs)
  16, // Small island developing States (SIDS)
  230, // Developed regions (MDG)
  19, // Developing regions (MDG)
];

export const geographicRegionsIds = [
  1, // World
  10, // Central and Southern Asia
  51, // Eastern and South-Eastern Asia
  7, // Southern Asia
  18, // Eastern and South-Eastern Asia
  6, // Eastern Asia
  8, // South-Eastern Asia
  15, // Europe and Northern America
  12, // Europe
  5, // Northern America
  14, // Latin America and the Caribbean
  242, // Caribbean
  237, // Central America
  235, //
  17, // Northern Africa and Western Asia
  3, // Northern Africa
  11, // Western Asia
  2, // Oceania
  9, // Australia and New Zealand
  141, // Oceania (exc. Australia and New Zealand)
  0, // Other
  13, // Sub-Saharan Africa
];
