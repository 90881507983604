import styled from "styled-components";

export const GoalsStyled =  styled.div`
  margin: 0 auto 50px;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    display: flex;
  }

  .goal-targets {
    flex: 1 0 540px;
    max-width: 540px;
  }

  .target-indicators {
    flex: 1 0 590px;
    max-width: 590px;
  }

  .item {
    background: #fff;
    &:nth-child(2n + 1) {
      background: #f8fafc;
      .goal-targets {
        padding-right: 50px;
      }
    }
    &:nth-child(2n) {
      .inner-wrapper {
        flex-direction: row-reverse;
      }
      .goal-targets {
        padding-left: 50px;
      }
    }

    .inner-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 1090px;
      margin: auto;
      padding: 50px 25px 60px;
    }

    .goal {
      &-heading {
        display: grid;
        grid-template-columns: 100px auto;
        grid-column-gap: 15px;
      }
      &-title {
        padding-top: 15px;
        display: block;
        opacity: 0.48;
        font-family: Roboto;
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.62;
        letter-spacing: 1.44px;
        color: #000000;
        text-transform: uppercase;
      }
      &-code {
        display: block;
        margin-bottom: 20px;
        font-family: Roboto;
        font-size: 28px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #000000;
      }
      &-info {
        display: inline-block;
        transform: translate(5px, 0px);
      }
    }

    .target {
      flex-direction: column;
      label {
        width: 100%;
        margin-top: 10px;
        padding: 15px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #000000;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(151, 151, 151, 0.35);
        cursor: pointer;
        transition: background 0.1s ease-out;
        input {
          margin-right: 20px;
        }
        &:hover {
          background: rgba(66, 90, 133, 0.2);
        }
      }
      &-desc {
        padding-left: 35px;
      }
    }
  }
`;