import React from "react";
import { Flex } from "@rebass/grid";
import AreaChartModule from "../../../../../pages/ReportsData/charts/AreaChart";
import TreeRadialModule from "../../../../../pages/ReportsData/charts/TreeRadial";
import ScatterplotModule from "../../../../../pages/ReportsData/charts/Scatterplot";
import LineChartModule from "../../../../../pages/ReportsData/charts/LineChart";
import RadarChartModule from "../../../../../pages/ReportsData/charts/RadarChart";
import GapChartModule from "../../../../../pages/ReportsData/charts/GapChart";
import MapSymbolForceModule from "../../../../../pages/ReportsData/charts/MapSymbolForce";
import BarchartVerticalModule from "../../../../../pages/ReportsData/charts/BarChartVertical";
import TreemapChartModule from "../../../../../pages/ReportsData/charts/TreemapChart";
import BarchartHorizontalModule from "../../../../../pages/ReportsData/charts/BarChartHorizontal";
import MapChoroplethModule from "../../../../../pages/ReportsData/charts/MapChoropleth";
import FlowChartModule from "../../../../../pages/ReportsData/charts/FlowChart";
import styled from "styled-components";

const ReportStyled = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  main {
    padding: 30px;
    margin: 30px 0;
    background: #f8fafc;
  }
  .report-detail {
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      margin: 15px 0;
      padding-bottom: 15px;
      border-bottom: 1px solid #eee;
    }
    hr {
      border-bottom: 0;
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
`;

const CMSContentLib = () => (
  <Flex>
    <ReportStyled>
      <AreaChartModule />
      <br />
      <TreeRadialModule />
      <br />
      <ScatterplotModule />
      <br />
      <LineChartModule />
      <br />
      <RadarChartModule />
      <br />
      <GapChartModule />
      <br />
      <MapSymbolForceModule />
      <br />
      <BarchartVerticalModule />
      <br />
      <TreemapChartModule />
      <br />
      <BarchartHorizontalModule />
      <br />
      <MapChoroplethModule />
      <br />
      <FlowChartModule />
      <br />
    </ReportStyled>
  </Flex>
);

export default CMSContentLib;
