import React from "react";
import Header from "modules/ui/components/Header";
import Footer from "modules/ui/components/Footer";
import HeroBanner from "modules/indicators/components/HeroBanner";
import SetData from "modules/indicators/components/SetData";
import SetTitle from "modules/indicators/components/SetTitle";
import {
  Charts,
  DimensionSwitcher,
  Filter,
  Rationale,
  VzSwitcher,
} from "modules/indicators/components/Visualization";
import { VisualizationContextProviderComponent } from "components/VisualizationContextProviderComponent";
import { IndicatorContextProviderComponent } from "components/IndicatorContextProviderComponent";
import ErrorBoundary from "modules/ui/components/ErrorBoundary";
import ScrollToTopOnMount from "modules/shared-components/ScrollToTopOnMount";
import Styled from "./Styled";

export default () => (
  <VisualizationContextProviderComponent>
    <ScrollToTopOnMount />
    <IndicatorContextProviderComponent>
      <Styled>
        <Header />
        <ErrorBoundary>
          <SetData />
        </ErrorBoundary>
        <HeroBanner>
          <DimensionSwitcher />
        </HeroBanner>
        <div className="vz-container">
          <div>
            <Filter />
          </div>

          <div className="right-content">
            <VzSwitcher />
            <SetTitle />
            <Charts />
            <Rationale />
          </div>
        </div>
        <Footer />
      </Styled>
    </IndicatorContextProviderComponent>
  </VisualizationContextProviderComponent>
);
