import { connect } from "react-redux";
import { selectAllIndicators, selectAllTargets, selectAllGoals } from "modules/meta-data/selectors/meta-data";
import Goals from "./GoalsComponent";

const mapStateToProps = state => ({
    indicators: selectAllIndicators(state),
    targets: selectAllTargets(state),
    goals: selectAllGoals(state),
});

export default connect(
    mapStateToProps,
    null,
)(Goals);