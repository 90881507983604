import React, { Fragment } from "react";
import { FlowChart } from "datadesign-charts-sdgtrade";
import DC_TOF_TRDCMDL_2016 from "./data/DC_TOF_TRDCMDL_2016";
import regions from "./data/region_by_id";
import { FormattedMessage } from "react-intl";

const FlowChartModule = () => {
  const dataFiltered = DC_TOF_TRDCMDL_2016.slice(0);
  const regionsFilterd = regions.filter((d, i) => i < regions.length / 4);

  // replace id by country name
  dataFiltered.map(d => {
    var randomSource = regionsFilterd[Math.floor(Math.random() * regionsFilterd.length)];
    var randomTarget = regionsFilterd[Math.floor(Math.random() * regionsFilterd.length)];

    d.source_id = randomSource.id;
    d.target_id = randomTarget.id;
    d.source_name = randomSource.name ? randomSource.name : "";
    d.target_name = randomTarget.name ? randomTarget.name : "";
    d.values = d.value + Math.floor(Math.random() * 10000 - 5000);
    return d;
  });

  const cleanData = dataFiltered.filter(d => d.source_name !== "" && d.target_name !== "" && d.value > 100);

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.flow-chart"
        defaultMessage="Aid for Trade commitments donated (millions of constant 2016) in 2016"
      >
        {text => (
          <FlowChart
            data={cleanData}
            sourceIDKey="source_id" // this is fixed and must be provided
            targetIDKey="target_id" // this is fixed and must be provided
            sourceNameKey="source_name" // this is fixed and must be provided
            targetNameKey="target_name" // this is fixed and must be provided
            valueKey="values"
            uniqueIDKey="id" // this is fixed and must be provided
            title={text}
            aspectRatio={0.5} // optional
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};

export default FlowChartModule;
