export const selectBearerToken = state => state?.cms?.bearerToken || undefined;
export const selectCmsRoot = state => state?.cms?.cmsRoot || undefined;

export const selectContent = (state, contentType, contentKey) => {
  if (!state?.cms?.contents) {
    return undefined;
  }

  const content = state.cms.contents[`${contentType}/${contentKey}`];

  if (!content) {
    return undefined;
  }

  return {
    status: content?.status,
    data:
      content?.response?.items && content?.response?.items.length > 0 ? content.response.items[0].data : null,
  };
};

export const selectComponent = (state, id) => {
  if (!state?.cms?.components) {
    return undefined;
  }

  const component = state.cms.components[id];

  if (!component) {
    return undefined;
  }

  return {
    status: component.status,
    data:
      component?.response?.items && component?.response?.items.length > 0
        ? component.response.items[0].data
        : null,
    schemaName:
      component?.response?.items && component?.response?.items.length > 0
        ? component.response.items[0].schemaName
        : null,
  };
};
