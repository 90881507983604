import { map } from "rxjs/operators";
import { ofType } from "redux-observable";
import { fetchFilterParamsProduct, setFilterParamsProduct } from "../reducer";

export default action$ =>
  action$.pipe(
    ofType(fetchFilterParamsProduct),
    map(({ payload }) => {
      switch (payload) {
        case 1:
          return setFilterParamsProduct([
            42,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
          ]);

        case 2:
          return setFilterParamsProduct([42, 32, 33, 34]);

        default:
          return setFilterParamsProduct([42, 1, 2, 3, 4, 5, 6]);
      }
    })
  );
