import React from "react";
import PropTypes from "prop-types";
import { VisualizationContextProvider } from "./VisualizationContext";
import VISUALIZATION_UI_CONFIG from "modules/visualizations.config";

const VisualizationContextProviderComponent = ({ children }) => (
    <VisualizationContextProvider value={VISUALIZATION_UI_CONFIG}>{children}</VisualizationContextProvider>
);

VisualizationContextProviderComponent.propTypes = {
    children: PropTypes.node,
};

VisualizationContextProviderComponent.defaultProps = {
    children: null,
};

export default VisualizationContextProviderComponent;
