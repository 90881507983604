import React, { Fragment } from "react";
import { MapChoropleth } from "datadesign-charts-sdgtrade";
import dataAG_PRD_XSUBDY_2016 from "./data/AG_PRD_XSUBDY_2016";
import regions from "./data/region_by_id";
import { FormattedMessage } from "react-intl";

const MapChoroplethModule = () => {
  // replace id by country name
  const dataModified = dataAG_PRD_XSUBDY_2016.slice(0).map(item => {
    const country = regions.find(k => +k.id === +item.geoAreaId);
    item.name = country ? country.name : "";
    item.value = Math.floor(Math.random() * 100 + 1);
    return item;
  });

  // show random data for all countries for testing
  // const dataModified = regions.map(d => {
  //   let item = {}
  //   item.name = d.name;
  //   item.value = Math.floor((Math.random() * 100) + 1);
  //   item.region = d.region;
  //   return item;
  // });

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.map-chart"
        defaultMessage="Agriculture Export subsidies (millions of current United States dollars)"
      >
        {text => (
          <MapChoropleth
            data={dataModified}
            colorValueKey="value"
            uniqueIDKey="name" // code must be country abrev. (such as DEU for Germany) or name such as Germany, see uniqueIDKeyMap
            uniqueIDKeyMap="countryName" //optional, Type of country id of the uniqueIDKey, countryName or countryID, see uniqueIDKey, by default countryName
            nameKey="name"
            yUnit="USD" // 'PERCENTAGE' or 'USD', default USD
            title={text}
            aspectRatio={0.5} //opotional
            colorConfig={{
              domain: [],
              range: "schemePurples",
            }} // optional, these are the dafault settings, range cold be any these Diverging colors https://github.com/d3/d3-scale-chromatic
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};

export default MapChoroplethModule;
