import React from "react";

import Header from "../modules/ui/components/Header";
import Footer from "../modules/ui/components/Footer";
import Detail from "../modules/data-availability/containers";
import ScrollToTopOnMount from "modules/shared-components/ScrollToTopOnMount";

export default () => (
  <>
    <ScrollToTopOnMount />
    <Header />
    <Detail />
    <Footer />
  </>
);
