import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { shallowEqual, useSelector } from "react-redux";
import { selectReports } from "../../selectors/reports";
import { jsPDF } from "jspdf";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { deleteReports, LAYOUT } from "../../reducer";

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  height: 36px;
  padding: 0 20px;
  margin-left: 5px;
  border: 1px solid #1b3159;
  border-radius: 22px;
  vertical-align: middle;
  color: #1b3159;
  background-color: #f4f7fc;
  cursor: pointer;
  .loading {
    opacity: 0;
    animation: ${animation} infinite 5s linear;
  }
  &:disabled {
    cursor: progress;
    .loading {
      opacity: 1;
    }
  }
`;

const Actions = () => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const reports = useSelector(selectReports, shallowEqual);
  const downloadReport = el => {
    const doc = new jsPDF("p", "px");
    let y = 40;
    const pageHeight = doc.internal.pageSize.height;
    doc.text(reports.title, doc.internal.pageSize.width / 2, 30, "center");

    reports.content.forEach(({ img, desc, layout }) => {
      if (y + 250 >= pageHeight) {
        doc.addPage();
        y = 40;
      }
      if (layout === LAYOUT.TEXT_DOWN) {
        doc.addImage(
          `data:image/png;base64,${img}`,
          "PNG",
          doc.internal.pageSize.width / 2 - 380 / 2,
          y,
          380,
          250
        );
        doc.setFontSize(8);
        doc.text(desc, doc.internal.pageSize.width / 2 - 380 / 2, y + 270, { maxWidth: 380});
        y = y + 300;
      }
      if (layout === LAYOUT.TEXT_RIGHT) {
        const imgWidth = 380 * 70 / 100;
        const imgHeight = 250 * 70 / 100;
        doc.addImage(
          `data:image/png;base64,${img}`,
          "PNG",
          doc.internal.pageSize.width / 2 - 380 / 2,
          y,
          imgWidth,
          imgHeight
        );
        doc.setFontSize(8);
        doc.text(desc, doc.internal.pageSize.width - 140, y + 10, { maxWidth: 110 });
        y = y + imgHeight + 20;
      }
    });

    doc.save("report.pdf", { returnPromise: true }).then(() => {
      setTimeout(() => {
        setIsPending(false);
        el.disabled = false;
      }, 1000);
    });
  };

  return (
    <div>
      <Action>
        <Button
          onClick={e => {
            e.target.disabled = true;
            setIsPending(true);
            downloadReport(e.target);
          }}
        >
          {isPending && <FontAwesomeIcon className="loading" icon={faSpinner} />} Download
        </Button>
        <Button onClick={() => dispatch(deleteReports())}>Delete Report</Button>
      </Action>
    </div>
  );
};

export default Actions;
