import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { LineChart } from "datadesign-charts-sdgtrade";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import { MEASURES_UNIT } from "modules/meta-data/constants";
import ErrorBoundary from "modules/ui/components/ErrorBoundary";
import Skeleton from "@material-ui/lab/Skeleton";

const Chart = ({ data, chartMetaData, title }) => {
  const { embed } = useParams();
  const [currentScaleType, setCurrentScaleType] = useState("LINEAR");
  const handleScaleChange = data => setCurrentScaleType(data.id);

  if (data === null) {
    return (
      <div className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  return (
    <div className="chart">
      <ErrorBoundary>
        <LineChart
          data={data}
          xValueKey="year"
          yValueKey="value"
          uniqueIDKey="id"
          nameKey="name"
          scaleType={currentScaleType}
          handleScaleChange={handleScaleChange}
          yUnit={MEASURES_UNIT[chartMetaData?.primaryMeasure?.unit?.trim()]}
          title={title}
          aspectRatio={0.5}
        />
        {(!embed || embed !== "embed") && <Toolbar vzData={data} metaData={chartMetaData} />}
      </ErrorBoundary>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  chartMetaData: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
