import { fetchFilterDataYear, setFilterDataYear } from "modules/indicators/reducer";
import { concat, forkJoin, of } from "rxjs";
import { ofType } from "redux-observable";
import { map, mergeMap, withLatestFrom } from "rxjs/operators";
import { VALUES } from "modules/meta-data/constants";
import * as constants from "modules/constants";

export default (action$, state$, { ajax, apiConfig, visualizationsConfig }) => {
  const requests = params =>
    params.map(d => ({
      responseType: "json",
      method: "GET",
      mode: "cors",
      url: `${apiConfig.apiRoot}/Metadata/available-years?region=${d.region}&partner=${d.partner}&product=${d.product}&indicator=${d.indicator}`,
      headers: {
        "Ocp-Apim-Subscription-Key": apiConfig.OcpApimSubscriptionKey,
      },
    }));

  const normalizeData = data => data.map(d => d.year);

  return action$.pipe(
    ofType(fetchFilterDataYear),
    withLatestFrom(state$),
    map(([, state]) => {
      const currentVz = state.indicators.currentUIState.visualization;
      const multipleMeasuresNeeded =
        visualizationsConfig[currentVz].measureSelectionType === VALUES.MEASURE_SELECTION_SEPARATED;
      const { region, partner, product } = state.indicators.filterParams;

      if (multipleMeasuresNeeded && state?.indicators?.measure_secondary) {
        return [
          {
            region: region,
            partner: partner,
            product: product,
            indicator: state.indicators.currentUIState.measure,
          },
          {
            region: region,
            partner: partner,
            product: product,
            indicator: state.indicators.currentUIState.measure_secondary,
          },
        ];
      }

      return [
        {
          region: region,
          partner: partner,
          product: product,
          indicator: state.indicators.currentUIState.measure,
        },
      ];
    }),
    mergeMap(params =>
      concat(
        of(setFilterDataYear({ status: constants.STATUS_LOADING, data: null })),
        forkJoin(requests(params).map(request => ajax(request))).pipe(
          mergeMap(ajaxResponses => {
            const data = ajaxResponses.reduce(
              (result, ajaxResponse) =>
                Array.from(new Set([...result, ...normalizeData(ajaxResponse.response)])),
              []
            );

            return of(
              setFilterDataYear({
                status: constants.STATUS_OK,
                data: data.sort(),
              })
            );
          })
        )
      )
    )
  );
};
