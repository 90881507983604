import {
  INDICATORS,
  MEASURES,
  PRODUCTS,
  REGIONS,
  VALUES,
  DIMENSIONS,
  VISUALIZATIONS,
  PARAMS,
} from "modules/meta-data/constants";

export default {
  [INDICATORS.i2_b_1]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.ECONOMIC_REGIONS, REGIONS.GEOGRAPHIC_REGIONS],
      partner: [REGIONS.GEOGRAPHIC_REGIONS],
      product: [PRODUCTS.BROAD_PRODUCT_GROUPS],
      measures: [MEASURES.AG_PRD_XSUBDY],
      measure_secondary: [],
      measure_cross_cutting: [],
      measures_dependency: {},
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          // VISUALIZATIONS.BUBBLE_CHART,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.TREEMAP_CHART,
          VISUALIZATIONS.HORIZONTAL_BAR_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.TREEMAP_CHART]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.SCATTER_PLOT_CHART]: false,
          [VISUALIZATIONS.HORIZONTAL_BAR_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [],
      positiveChangeGood: true,
    },
    defaultViewData: {
      growth: VALUES.BASIC_GROWTH_2015,
      dimensions: DIMENSIONS.BY_COUNTRIES,
      partner: {
        [REGIONS.INDIVIDUAL_COUNTRIES]: VALUES.REGIONS_WORLD,
      },
      region: {
        [REGIONS.INDIVIDUAL_COUNTRIES]: VALUES.REGIONS_FIRST_20,
      },
      product: {
        [PRODUCTS.BROAD_PRODUCT_GROUPS]: VALUES.PRODUCTS_BROAD_AGRICULTURE,
      },
      measures: [MEASURES.AG_PRD_XSUBDY],
      measure_secondary: [],
      measure_cross_cutting: [],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.LINE_CHART,
      },
    },
  },

  [INDICATORS.i8_a_1]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
        partner: PARAMS.PARTNER,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.ECONOMIC_REGIONS, REGIONS.GEOGRAPHIC_REGIONS],
      partner: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      product: [
        PRODUCTS.BROAD_PRODUCT_GROUPS,
        PRODUCTS.PROCESSING_PRODUCT_GROUPS,
        PRODUCTS.DETAILED_PRODUCT_SECTORS,
      ],
      measures: [MEASURES.DC_TOF_TRDCMDL, MEASURES.DC_TOF_TRDCML],
      measure_secondary: [MEASURES.DC_TOF_TRDDBMDL, MEASURES.DC_TOF_TRDDBML],
      measure_cross_cutting: [],
      measures_dependency: {
        [MEASURES.DC_TOF_TRDCMDL]: MEASURES.DC_TOF_TRDDBMDL,
        [MEASURES.DC_TOF_TRDCML]: MEASURES.DC_TOF_TRDDBML,
      },
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.TREEMAP_CHART,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.GAP_CHART,
          VISUALIZATIONS.FLOW_CHART,
          VISUALIZATIONS.HORIZONTAL_BAR_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.BUBBLE_CHART]: true,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.GAP_CHART]: false,
          [VISUALIZATIONS.FLOW_CHART]: false,
          [VISUALIZATIONS.HORIZONTAL_BAR_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [VISUALIZATIONS.BUBBLE_CHART, VISUALIZATIONS.GAP_CHART],
      positiveChangeGood: false,
    },
    defaultViewData: {
      growth: VALUES.BASIC_GROWTH_2015,
      dimensions: DIMENSIONS.BY_COUNTRIES,
      partner: {
        [REGIONS.GEOGRAPHIC_REGIONS]: [VALUES.REGIONS_WORLD],
      },
      region: {
        [REGIONS.ECONOMIC_REGIONS]: VALUES.REGIONS_FIRST_20,
      },
      product: [VALUES.PRODUCTS_ALL],
      measures: [MEASURES.DC_TOF_TRDCMDL],
      measure_secondary: [MEASURES.DC_TOF_TRDDBMDL],
      measure_cross_cutting: [],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.LINE_CHART,
      },
    },
  },

  [INDICATORS.i8_2_i]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
        product: PARAMS.PRODUCT,
        partner: PARAMS.PARTNER,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES, DIMENSIONS.BY_PRODUCTS],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      product: [
        PRODUCTS.DETAILED_PRODUCT_SECTORS,
        PRODUCTS.BROAD_PRODUCT_GROUPS,
        PRODUCTS.PROCESSING_PRODUCT_GROUPS,
      ],
      partner: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      measures: [MEASURES.PR_EXP_DIVM],
      measure_secondary: [MEASURES.PR_EXP_DIVP],
      measure_cross_cutting: [],
      measures_dependency: {
        [MEASURES.PR_EXP_DIVM]: MEASURES.PR_EXP_DIVP,
      },
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.MAP,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.HORIZONTAL_BAR_CHART,
        ],
        [DIMENSIONS.BY_PRODUCTS]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.RADAR_CHART,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.HORIZONTAL_BAR_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.MAP]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
        },
        [DIMENSIONS.BY_PRODUCTS]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          // [VISUALIZATIONS.RADAR_CHART]: true,
          [VISUALIZATIONS.RADAR_CHART]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.HORIZONTAL_BAR_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [],
      positiveChangeGood: false,
    },
    defaultViewData: {
      growth: VALUES.BASIC_GROWTH_2015,
      dimensions: DIMENSIONS.BY_COUNTRIES,
      product: [VALUES.PRODUCTS_ALL],
      measures: [MEASURES.PR_EXP_DIVM],
      measure_secondary: [MEASURES.PR_EXP_DIVP],
      measure_cross_cutting: [],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.MAP,
        [DIMENSIONS.BY_PRODUCTS]: VISUALIZATIONS.LINE_CHART,
      },
    },
  },

  [INDICATORS.i10_a_1]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
        partner: PARAMS.PARTNER,
        product: PARAMS.PRODUCT,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES, DIMENSIONS.BY_PRODUCTS],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      partner: [REGIONS.ECONOMIC_REGIONS, REGIONS.GEOGRAPHIC_REGIONS],
      product: [
        PRODUCTS.DETAILED_PRODUCT_SECTORS,
        PRODUCTS.BROAD_PRODUCT_GROUPS,
        PRODUCTS.PROCESSING_PRODUCT_GROUPS,
      ],
      measures: [MEASURES.TM_TRF_ZERO],
      measure_secondary: [MEASURES.M17],
      measure_cross_cutting: [],
      measures_dependency: {
        [MEASURES.M17]: MEASURES.TM_TRF_ZERO,
      },
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.MAP,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.GAP_CHART,
        ],
        [DIMENSIONS.BY_PRODUCTS]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.RADAR_CHART,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.GAP_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.MAP]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.GAP_CHART]: false,
        },
        [DIMENSIONS.BY_PRODUCTS]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.RADAR_CHART]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.GAP_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [],
      positiveChangeGood: false,
    },
    defaultViewData: {
      growth: VALUES.BASIC_GROWTH_2015,
      dimensions: DIMENSIONS.BY_PRODUCTS,
      partner: {
        [REGIONS.ECONOMIC_REGIONS]: [VALUES.REGIONS_ECONOMIC_LDCS, VALUES.REGIONS_ECONOMIC_DEVELOPING],
      },
      region: {
        [REGIONS.INDIVIDUAL_COUNTRIES]: [VALUES.WORLD],
      },
      product: {
        [PRODUCTS.BROAD_PRODUCT_GROUPS]: VALUES.ALL,
      },
      measures: [MEASURES.M17],
      measure_secondary: [MEASURES.TM_TRF_ZERO],
      measure_cross_cutting: [],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.VERTICAL_BAR_CHART,
        [DIMENSIONS.BY_PRODUCTS]: VISUALIZATIONS.RADAR_CHART,
      },
    },
  },

  [INDICATORS.i10_a_i]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
        partner: PARAMS.PARTNER,
        product: PARAMS.PRODUCT,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES, DIMENSIONS.BY_PRODUCTS],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      partner: [REGIONS.ECONOMIC_REGIONS, REGIONS.GEOGRAPHIC_REGIONS],
      product: [
        PRODUCTS.DETAILED_PRODUCT_SECTORS,
        PRODUCTS.BROAD_PRODUCT_GROUPS,
        PRODUCTS.PROCESSING_PRODUCT_GROUPS,
      ],
      measures: [MEASURES.TM_EXP_DTFR],
      measure_secondary: [],
      measure_cross_cutting: [],
      measures_dependency: {},
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.MAP,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
        ],
        [DIMENSIONS.BY_PRODUCTS]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.RADAR_CHART,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.MAP]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
        },
        [DIMENSIONS.BY_PRODUCTS]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          // [VISUALIZATIONS.RADAR_CHART]: true,
          [VISUALIZATIONS.RADAR_CHART]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [],
      positiveChangeGood: false,
    },
    defaultViewData: {
      growth: VALUES.BASIC_GROWTH_2015,
      dimensions: DIMENSIONS.BY_COUNTRIES,
      partner: {
        [REGIONS.ECONOMIC_REGIONS]: [VALUES.REGIONS_ECONOMIC_LDCS, VALUES.REGIONS_ECONOMIC_DEVELOPING],
      },
      region: {
        [REGIONS.INDIVIDUAL_COUNTRIES]: [VALUES.WORLD],
      },
      product: {
        [PRODUCTS.BROAD_PRODUCT_GROUPS]: VALUES.ALL,
      },
      measures: [MEASURES.TM_EXP_DTFR],
      measure_secondary: [],
      measure_cross_cutting: [],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.VERTICAL_BAR_CHART,
        [DIMENSIONS.BY_PRODUCTS]: VISUALIZATIONS.VERTICAL_BAR_CHART,
      },
    },
  },

  [INDICATORS.i17_10_1]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
        partner: PARAMS.PARTNER,
        product: PARAMS.PRODUCT,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES, DIMENSIONS.BY_PRODUCTS],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      partner: [REGIONS.ECONOMIC_REGIONS, REGIONS.GEOGRAPHIC_REGIONS],
      product: [
        PRODUCTS.DETAILED_PRODUCT_SECTORS,
        PRODUCTS.BROAD_PRODUCT_GROUPS,
        PRODUCTS.PROCESSING_PRODUCT_GROUPS,
      ],
      measures: [MEASURES.TM_TAX_WWTAV_Pref],
      measure_secondary: [MEASURES.TM_TAX_WWTAV_MFN],
      measure_cross_cutting: [],
      measures_dependency: {
        [MEASURES.TM_TAX_WWTAV_Pref]: MEASURES.TM_TAX_WWTAV_MFN,
      },
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.MAP,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.GAP_CHART,
        ],
        [DIMENSIONS.BY_PRODUCTS]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.GAP_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.MAP]: true,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.GAP_CHART]: false,
        },
        [DIMENSIONS.BY_PRODUCTS]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.GAP_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [],
      positiveChangeGood: true,
    },
    defaultViewData: {
      growth: VALUES.BASIC_GROWTH_2015,
      dimensions: DIMENSIONS.BY_COUNTRIES,
      partner: {
        [REGIONS.ECONOMIC_REGIONS]: [117, 199, 19],
      },
      region: {
        [REGIONS.GEOGRAPHIC_REGIONS]: [VALUES.WORLD],
      },
      product: {
        [PRODUCTS.DETAILED_PRODUCT_SECTORS]: [VALUES.PRODUCTS_ALL],
        [PRODUCTS.BROAD_PRODUCT_GROUP]: [VALUES.PRODUCTS_ALL],
        [PRODUCTS.PROCESSING_PRODUCT_GROUPS]: [VALUES.PRODUCTS_ALL],
      },
      measures: [MEASURES.TM_TAX_WWTAV_Pref],
      measure_secondary: [MEASURES.TM_TAX_WWTAV_MFN],
      measure_cross_cutting: [],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.GAP_CHART,
        [DIMENSIONS.BY_PRODUCTS]: VISUALIZATIONS.GAP_CHART,
      },
    },
  },

  [INDICATORS.i17_11_1]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
        // partner: PARAMS.PARTNER,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      partner: [REGIONS.INDIVIDUAL_COUNTRIES],
      product: [],
      measures: [MEASURES.TX_EXP_GBMRCH],
      measure_secondary: [MEASURES.TX_EXP_GBSVR],
      measure_cross_cutting: [MEASURES.SDG_GDP],
      measures_dependency: {
        [MEASURES.TX_EXP_GBMRCH]: [MEASURES.TX_EXP_GBSVR],
      },
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.MAP,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.SCATTER_PLOT_CHART,
          VISUALIZATIONS.HORIZONTAL_BAR_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.MAP]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.SCATTER_PLOT_CHART]: false,
          [VISUALIZATIONS.HORIZONTAL_BAR_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [],
      positiveChangeGood: false,
    },
    defaultViewData: {
      growth: VALUES.BASIC_GROWTH_2015,
      dimensions: DIMENSIONS.BY_COUNTRIES,
      partner: {
        [REGIONS.ECONOMIC_REGIONS]: [117, 4, 19],
      },
      region: {
        [REGIONS.GEOGRAPHIC_REGIONS]: [VALUES.WORLD],
      },
      product: {
        [PRODUCTS.DETAILED_PRODUCT_SECTORS]: [VALUES.PRODUCTS_ALL],
        [PRODUCTS.BROAD_PRODUCT_GROUP]: [VALUES.PRODUCTS_ALL],
        [PRODUCTS.PROCESSING_PRODUCT_GROUPS]: [VALUES.PRODUCTS_ALL],
      },
      measures: [MEASURES.TX_EXP_GBMRCH],
      measure_secondary: [MEASURES.TX_EXP_GBSVR],
      measure_cross_cutting: [MEASURES.SDG_GDP],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.LINE_CHART,
      },
    },
  },

  [INDICATORS.i17_12_1]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
        partner: PARAMS.PARTNER,
        product: PARAMS.PRODUCT,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES, DIMENSIONS.BY_PRODUCTS],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      partner: [REGIONS.ECONOMIC_REGIONS],
      product: [
        PRODUCTS.DETAILED_PRODUCT_SECTORS,
        PRODUCTS.BROAD_PRODUCT_GROUPS,
        PRODUCTS.PROCESSING_PRODUCT_GROUPS,
      ],
      measures: [MEASURES.TM_TAX_ATRFD_Pref],
      measure_secondary: [MEASURES.TM_TAX_ATRFD_MFN],
      measure_cross_cutting: [MEASURES.SDG_GDP],
      measures_dependency: {
        [MEASURES.TM_TAX_ATRFD_Pref]: [MEASURES.TM_TAX_ATRFD_MFN],
      },
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.MAP,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.GAP_CHART,
          VISUALIZATIONS.SCATTER_PLOT_CHART,
        ],
        [DIMENSIONS.BY_PRODUCTS]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.GAP_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.MAP]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.GAP_CHART]: false,
          [VISUALIZATIONS.SCATTER_PLOT_CHART]: true,
        },
        [DIMENSIONS.BY_PRODUCTS]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          // [VISUALIZATIONS.GAP_CHART]: true,
          [VISUALIZATIONS.GAP_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [],
    },
    defaultViewData: {
      growth: VALUES.BASIC_GROWTH_2015,
      dimensions: DIMENSIONS.BY_COUNTRIES,
      partner: {
        [REGIONS.ECONOMIC_REGIONS]: [117, 4, 19],
      },
      region: {
        [REGIONS.GEOGRAPHIC_REGIONS]: [VALUES.WORLD],
      },
      product: {
        [PRODUCTS.DETAILED_PRODUCT_SECTORS]: [VALUES.PRODUCTS_ALL],
        [PRODUCTS.BROAD_PRODUCT_GROUPS]: [VALUES.PRODUCTS_ALL],
        [PRODUCTS.PROCESSING_PRODUCT_GROUPS]: [VALUES.PRODUCTS_ALL],
      },
      measures: [MEASURES.TM_TAX_ATRFD_Pref],
      measure_secondary: [MEASURES.TM_TAX_ATRFD_MFN],
      measure_cross_cutting: [MEASURES.SDG_GDP],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.VERTICAL_BAR_CHART,
        [DIMENSIONS.BY_PRODUCTS]: VISUALIZATIONS.VERTICAL_BAR_CHART,
      },
    },
  },

  [INDICATORS.i17_11_i]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      partner: [REGIONS.INDIVIDUAL_COUNTRIES],
      product: [],
      measures: [MEASURES.SDG_TMT_EXP_Ratio],
      measure_secondary: [MEASURES.SDG_TIS_EXP_Ratio],
      measure_cross_cutting: [MEASURES.SDG_GDP],
      measures_dependency: {
        [MEASURES.SDG_TMT_EXP_Ratio]: [MEASURES.SDG_TIS_EXP_Ratio],
      },
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.MAP,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.SCATTER_PLOT_CHART,
          VISUALIZATIONS.HORIZONTAL_BAR_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.MAP]: false,
          [VISUALIZATIONS.SCATTER_PLOT_CHART]: false,
          [VISUALIZATIONS.HORIZONTAL_BAR_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [],
      positiveChangeGood: false,
    },
    defaultViewData: {
      growth: VALUES.BASIC_GROWTH_2015,
      dimensions: DIMENSIONS.BY_COUNTRIES,
      region: [{ [REGIONS.ECONOMIC_REGIONS]: VALUES.ALL }],
      partner: [{ [REGIONS.ECONOMIC_REGIONS]: VALUES.ALL }],
      product: [],
      measures: [MEASURES.SDG_TMT_EXP_Ratio],
      measure_secondary: [MEASURES.SDG_TIS_EXP_Ratio],
      measure_cross_cutting: [MEASURES.SDG_GDP],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.MAP,
      },
    },
  },

  [INDICATORS.i17_11_ii]: {
    viewData: {
      params: {
        region: PARAMS.REGION,
        partner: PARAMS.PARTNER,
        product: PARAMS.PRODUCT,
      },
      dimensions: [DIMENSIONS.BY_COUNTRIES, DIMENSIONS.BY_PRODUCTS],
      region: [REGIONS.INDIVIDUAL_COUNTRIES, REGIONS.GEOGRAPHIC_REGIONS, REGIONS.ECONOMIC_REGIONS],
      partner: [REGIONS.ECONOMIC_REGIONS, REGIONS.GEOGRAPHIC_REGIONS],
      product: [
        PRODUCTS.DETAILED_PRODUCT_SECTORS,
        PRODUCTS.BROAD_PRODUCT_GROUPS,
        PRODUCTS.PROCESSING_PRODUCT_GROUPS,
      ],
      measures: [MEASURES.SDG_TMT_Exp_Pr],
      measure_secondary: [],
      measure_cross_cutting: [],
      measures_dependency: {},
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: [
          VISUALIZATIONS.LINE_CHART,
          VISUALIZATIONS.MAP,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.TREE_RADIAL_CHART,
          VISUALIZATIONS.FLOW_CHART,
          VISUALIZATIONS.HORIZONTAL_BAR_CHART,
        ],
        [DIMENSIONS.BY_PRODUCTS]: [
          VISUALIZATIONS.AREA_CHART,
          VISUALIZATIONS.RADAR_CHART,
          VISUALIZATIONS.VERTICAL_BAR_CHART,
          VISUALIZATIONS.HORIZONTAL_BAR_CHART,
        ],
      },
      underDevelopment: {
        [DIMENSIONS.BY_COUNTRIES]: {
          [VISUALIZATIONS.LINE_CHART]: false,
          [VISUALIZATIONS.MAP]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.TREE_RADIAL_CHART]: true,
          [VISUALIZATIONS.FLOW_CHART]: false,
          [VISUALIZATIONS.HORIZONTAL_BAR_CHART]: false,
        },
        [DIMENSIONS.BY_PRODUCTS]: {
          // [VISUALIZATIONS.AREA_CHART]: true,
          [VISUALIZATIONS.AREA_CHART]: true,
          [VISUALIZATIONS.RADAR_CHART]: false,
          [VISUALIZATIONS.VERTICAL_BAR_CHART]: false,
          [VISUALIZATIONS.HORIZONTAL_BAR_CHART]: false,
        },
      },
      switchMeasureVsSecondaryMeasure: [],
      positiveChangeGood: false,
    },
    defaultViewData: {
      dimensions: DIMENSIONS.BY_COUNTRIES,
      growth: VALUES.BASIC_GROWTH_2015,
      product: [],
      measures: [MEASURES.SDG_TMT_Exp_Pr],
      measure_secondary: [],
      measure_cross_cutting: [],
      visualizations: {
        [DIMENSIONS.BY_COUNTRIES]: VISUALIZATIONS.VERTICAL_BAR_CHART,
        [DIMENSIONS.BY_PRODUCTS]: VISUALIZATIONS.RADAR_CHART,
      },
    },
  },
};
