import { createSelector } from "reselect";

const getProducts = state => state.metaData.products;

export const selectProducts = createSelector(
  getProducts,
  data => {
    const handleSorting = (a, b) => {
      if (a.type < b.type) {
        return -1;
      }
      if (a.type > b.type) {
        return 1;
      }
      return 0;
    };

    if (data.length === 0) return [];

    return data.map(d => ({
      ...d,
      items: [
        {
          ...d.items.find(d => ["all", "alld", "allp"].includes(d.code)),
          id: 42,
        },
        ...d.items.sort(handleSorting).filter(d => !["all", "alld", "allp"].includes(d.code)),
      ],
    }));
  }
);
