import React from "react";
import styled, { keyframes } from "styled-components";

const blink = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1;
  }
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  animation: ${blink} 2s ease infinite;
`;

const LoadingPlaceholder = () => <Placeholder />;

export default LoadingPlaceholder;