import React from "react";
import { FlowChart } from "datadesign-charts-sdgtrade";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MEASURES_UNIT } from "modules/meta-data/constants";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { useSelector } from "react-redux";

const Styled = styled.div`
  &.i17_11_ii {
    rect[data-for$="target"],
    text[data-for$="target"] {
      pointer-events: none;
    }
  }
`;

const Chart = ({ data, chartMetaData, title }) => {
  const { embed } = useParams();
  const { indicatorCode } = useSelector(selectCurrentUIState);

  if (data === null) {
    return (
      <div className="loading">
        <CircularProgress />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  return (
    <div className="chart">
      <Styled className={`i${indicatorCode}`}>
        <FlowChart
          data={data}
          sourceIDKey="source_id"
          targetIDKey="target_id"
          sourceNameKey="source_name"
          targetNameKey="target_name"
          valueKey="value"
          uniqueIDKey="id"
          unit={MEASURES_UNIT[chartMetaData?.primaryMeasure?.unit?.trim()]}
          title={title}
          aspectRatio={0.5} // optional
        />
        {(!embed || embed !== "embed") && <Toolbar vzData={data} />}
      </Styled>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  chartMetaData: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default React.memo(Chart);
