import { connect } from "react-redux";
import { selectChartMetaData } from "modules/indicators/selectors/indicators";
import { selectSeriesDataHorizontalBarChart } from "modules/indicators/selectors/charts/horizontal-bar-chart";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import Chart from "./HorizontalBarChartComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataHorizontalBarChart(state),
  chartMetaData: selectChartMetaData(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(Chart);
