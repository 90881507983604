import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { VISUALIZATIONS } from "modules/meta-data/constants";
import { useRouteMatch } from "react-router";
import { FormattedMessage } from "react-intl";

const getYearText = (vzCode, data) => {
  switch (vzCode) {
    case VISUALIZATIONS.MAP:
    case VISUALIZATIONS.RADAR_CHART:
    case VISUALIZATIONS.GAP_CHART:
    case VISUALIZATIONS.BUBBLE_CHART:
    case VISUALIZATIONS.TREEMAP_CHART:
    case VISUALIZATIONS.HORIZONTAL_BAR_CHART:
    case VISUALIZATIONS.FLOW_CHART:
      if (data && data.length > 0) {
        return `(${data[0]})`;
      } else {
        return "";
      }
    default:
      return "";
  }
};

const getTitle = (
  visualizationCode,
  dimension,
  region,
  partner,
  product,
  year,
  metaData,
  metaDataSecondary,
  indicatorCode
) => {
  if (metaData !== undefined) {
    let regionFormatted =
      region.length > 0 ? (
        <FormattedMessage
          id="indicators.vz.title.regions"
          defaultMessage="from {region}"
          values={{ region: region }}
        />
      ) : (
        ""
      );
    let partnerFormatted =
      partner.length > 0 ? (
        <FormattedMessage
          id="indicators.vz.title.partners"
          defaultMessage="to {partner}"
          values={{ partner: partner }}
        />
      ) : (
        ""
      );
    let productFormatted =
      product.length > 0 ? (
        <FormattedMessage
          id="indicators.vz.title.products"
          defaultMessage="on {product}"
          values={{ product: product }}
        />
      ) : (
        ""
      );
    let yearFormatted = getYearText(visualizationCode, year);

    if (visualizationCode === VISUALIZATIONS.HORIZONTAL_BAR_CHART) {
      const measureDesc = [metaData?.seriesDescription, metaDataSecondary?.seriesDescription]
        .filter(d => d !== undefined)
        .join(" and ");
      return (
        <FormattedMessage
          id="indicators.vz.title.h-bar"
          defaultMessage="Evolution in {measureDesc} up to {year}"
          values={{
            measureDesc: measureDesc,
            year: year,
          }}
        />
      );
    }

    if (visualizationCode === VISUALIZATIONS.SCATTER_PLOT_CHART) {
      const measureDesc = [metaData?.seriesDescription, metaDataSecondary?.seriesDescription]
        .filter(d => d !== undefined)
        .join(" and ");
      return (
        <FormattedMessage
          id="indicators.vz.title.scatter-plot"
          defaultMessage="Evolution in {measureDesc} in {year}"
          values={{
            measureDesc: measureDesc,
            year: year,
          }}
        />
      );
    }

    if (visualizationCode === VISUALIZATIONS.FLOW_CHART) {
      partnerFormatted = "";
    }

    if (visualizationCode === VISUALIZATIONS.RADAR_CHART) {
      regionFormatted = "";
    }

    if (["17_12_1"].includes(indicatorCode)) {
      partnerFormatted = partner.length > 0 ? `in ${partner}` : "";
    }

    if (["2_b_1"].indexOf(indicatorCode) !== -1) {
      partnerFormatted = "";
      productFormatted = "";
    }

    if (dimension === "BP") {
      return (
        <FormattedMessage
          id="indicators.vz.title.bp"
          defaultMessage="{meta} {region} {product} {year}"
          values={{
            meta: metaData?.seriesDescription,
            region: regionFormatted,
            product: productFormatted,
            year: yearFormatted,
          }}
        />
      );
    }

    return (
      <FormattedMessage
        id="indicators.vz.title.bc"
        defaultMessage="{meta} {partner} {product} {year}"
        values={{
          meta: metaData?.seriesDescription,
          partner: partnerFormatted,
          product: productFormatted,
          year: yearFormatted,
        }}
      />
    );
  }
  return "";
};

const ChartTitleComponent = ({
  currentUIState,
  regionsData,
  productsData,
  filterParams,
  measuresMetaData,
  setCurrentVzTitle,
}) => {
  const match = useRouteMatch();
  const region = filterParams.region.map(d => regionsData[d]?.name).join(", ");
  let partner = filterParams.partner.map(d => (regionsData[d] ? regionsData[d]?.name : "")).join(", ");
  const product = filterParams.product.map(d => (productsData[d] ? productsData[d]?.type : "")).join(", ");
  const measure = measuresMetaData.find(d => d?.seriesCode === currentUIState?.measure);
  const mesureSecondary = measuresMetaData.find(d => d?.seriesCode === currentUIState.measure_secondary);
  const year = filterParams.year;
  const indicatorCode = match.params.code.replace(/-/g, "_");

  if (currentUIState.indicatorCode === "17_11_ii" && currentUIState.visualization === 5) {
    partner = "";
  }

  useEffect(() => {
    setCurrentVzTitle(
      getTitle(
        currentUIState.visualization,
        currentUIState.dimension,
        region,
        partner,
        product,
        year,
        measure,
        mesureSecondary,
        indicatorCode
      )
    );
  }, [Object.values(currentUIState)]);

  return <></>;
};

ChartTitleComponent.propTypes = {
  currentUIState: PropTypes.object.isRequired,
  regionsData: PropTypes.object.isRequired,
  productsData: PropTypes.any.isRequired,
  filterParams: PropTypes.object.isRequired,
  measuresMetaData: PropTypes.array.isRequired,
  setCurrentVzTitle: PropTypes.func.isRequired,
};

ChartTitleComponent.defaultProps = {
  productData: [],
};

export default ChartTitleComponent;
