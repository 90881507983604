export default [
  {
    id: 5723,
    indicatorTypeId: 1,
    geoAreaId: 32,
    year: 2016,
    value: 0,
    name: "Barbados",
    value2015: 0,
    value2016: 0,
  },
  {
    id: 5745,
    indicatorTypeId: 1,
    geoAreaId: 38,
    year: 2016,
    value: 0,
    name: "Brazil",
    value2015: 0,
    value2016: 0,
  },
  {
    id: 5800,
    indicatorTypeId: 1,
    geoAreaId: 52,
    year: 2016,
    value: 0,
    name: "Sri Lanka",
    value2015: 0,
    value2016: 0,
  },
  {
    id: 5823,
    indicatorTypeId: 1,
    geoAreaId: 56,
    year: 2016,
    value: 0,
    name: "Colombia",
    value2015: 0,
    value2016: 0,
  },
  {
    id: 5876,
    indicatorTypeId: 1,
    geoAreaId: 94,
    year: 2016,
    value: 0,
    name: "Iceland",
    value2015: 0,
    value2016: 0,
  },
  {
    id: 5914,
    indicatorTypeId: 1,
    geoAreaId: 96,
    year: 2016,
    value: 0,
    name: "Indonesia",
    value2015: 0,
    value2016: 0,
  },
  {
    id: 5937,
    indicatorTypeId: 1,
    geoAreaId: 100,
    year: 2016,
    value: 0.5807399999999999,
    name: "Israel",
    value2015: 1.1331499999999999,
    value2016: 0.5807399999999999,
    growthCommit: 1.7138899999999997,
    growthDisb: -0.55241,
  },
  {
    id: 5962,
    indicatorTypeId: 1,
    geoAreaId: 129,
    year: 2016,
    value: 0.7033931686455461,
    name: "Mauritius",
    value2015: 1.1410087087489695,
    value2016: 0.7033931686455461,
    growthCommit: 1.8444018773945157,
    growthDisb: -0.4376155401034234,
  },
  {
    id: 5985,
    indicatorTypeId: 1,
    geoAreaId: 130,
    year: 2016,
    value: 0,
    name: "Mexico",
    value2015: 0,
    value2016: 0,
  },
  {
    id: 6032,
    indicatorTypeId: 1,
    geoAreaId: 147,
    year: 2016,
    value: 19.821428571428573,
    name: "Norway",
    value2015: 16.356646825396826,
    value2016: 19.821428571428573,
    growthCommit: 36.1780753968254,
    growthDisb: 3.464781746031747,
  },
  {
    id: 6115,
    indicatorTypeId: 1,
    geoAreaId: 175,
    year: 2016,
    value: 0,
    name: "South Africa",
    value2015: 0,
    value2016: 0,
  },
  {
    id: 6137,
    indicatorTypeId: 1,
    geoAreaId: 184,
    year: 2016,
    value: 96.07523703496685,
    name: "Switzerland",
    value2015: 99.44282744282744,
    value2016: 96.07523703496685,
    growthCommit: 195.51806447779427,
    growthDisb: -3.3675904078605896,
  },
  {
    id: 6225,
    indicatorTypeId: 1,
    geoAreaId: 205,
    year: 2016,
    value: 0,
    name: "Uruguay",
    value2015: 0,
    value2016: 0,
  },
  {
    id: 6556,
    indicatorTypeId: 1,
    geoAreaId: 296,
    year: 2016,
    value: 0,
    name: "European Union",
    value2015: 0,
    value2016: 0,
  },
];
