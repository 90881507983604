import { createAction, handleActions } from "redux-actions";

export const setEconomicRegionsData = createAction("[Meta Data] Set economy regions data");
export const setIndicatorsMetaData = createAction("[Meta Data] Set indicators meta data");
export const setGeographicRegionsData = createAction("[Meta Data] Set geographic regions data");
export const setRegionsData = createAction("[Meta Data] Set regions data");
export const setMeasuresMetaData = createAction("[Meta Data] Set measures meta data");
export const setProductsData = createAction("[Meta Data] Set products Data");
export const setGoalsMetaData = createAction("[Meta Data] Set goals meta data");
export const setTargetsMetaData = createAction("[Meta Data] Set targets meta data");
export const setDataAvailability = createAction("[Meta Data] Set data availability");
export const resetMetaData = createAction("[Meta Data] Reset meta data ⚡");

// Psuedo actions
export const fetchMetaData = createAction("[Meta Data] Fetch meta data ⚡");
export const fetchEconomicRegionsData = createAction("[Meta Data] Fetch economy regions ⚡");
export const fetchGeographicRegionsData = createAction("[Meta Data] Fetch geographic regions ⚡");
export const fetchRegionsData = createAction("[Meta Data] Fetch regions ⚡");
export const fetchIndicatorsMetaData = createAction("[Meta Data] Fetch indicators meta data ⚡");
export const fetchMeasuresMetaData = createAction("[Meta Data] Fetch measures meta data ⚡");
export const fetchProductsData = createAction("[Meta Data] Fetch products data ⚡");
export const fetchGoalsMetaData = createAction("[Meta Data] Fetch goals meta data ⚡");
export const fetchTargetsMetaData = createAction("[Meta Data] Fetch targets meta data ⚡");
export const fetchPrimarySeriesData = createAction("[Meta Data] Fetch primary series data ⚡");
export const fetchSecondarySeriesData = createAction("[Meta Data] Fetch secondary series data ⚡");
export const fetchDataAvailability = createAction("[Meta Data] Fetch data availability ⚡");

export default handleActions(
  {
    [resetMetaData]: () => ({
      indicators: [],
      measures: [],
      regions: [],
      geographicRegions: [],
      economicRegions: [],
      products: [],
      goals: [],
      targets: [],
      dataAvailability: {},
    }),
    [setIndicatorsMetaData]: (state, { payload }) => ({
      ...state,
      indicators: [...payload],
    }),
    [setEconomicRegionsData]: (state, { payload }) => ({
      ...state,
      economicRegions: [...payload],
    }),
    [setGeographicRegionsData]: (state, { payload }) => ({
      ...state,
      geographicRegions: [{ ...payload }],
    }),
    [setRegionsData]: (state, { payload }) => ({
      ...state,
      regions: [...payload],
    }),
    [setProductsData]: (state, { payload }) => ({
      ...state,
      products: [...payload],
    }),
    [setMeasuresMetaData]: (state, { payload }) => ({
      ...state,
      measures: [...payload],
    }),
    [setGoalsMetaData]: (state, { payload }) => ({
      ...state,
      goals: [...payload],
    }),
    [setTargetsMetaData]: (state, { payload }) => ({
      ...state,
      targets: [...payload],
    }),
    [setDataAvailability]: (state, { payload }) => ({
      ...state,
      dataAvailability: {
        ...state.dataAvailability,
        ...payload,
      },
    }),
  },
  {
    indicators: [],
    measures: [],
    regions: [],
    geographicRegions: [],
    economicRegions: [],
    products: [],
    goals: [],
    targets: [],
    dataAvailability: {},
  }
);
