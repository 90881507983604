import React from "react";
import PropTypes from "prop-types";

const CMSContentAbout = ({ data }) => (
  <>
    <div className="ct">
      <div dangerouslySetInnerHTML={{ __html: data?.["main-content"] }} />
    </div>
    {data?.["sidebar-content"] && (
      <div className="side-bar">
        <div dangerouslySetInnerHTML={{ __html: data?.["sidebar-content"]?.[0]?.["item-content"] }} />
      </div>
    )}
  </>
);

CMSContentAbout.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CMSContentAbout;
