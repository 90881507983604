import React from "react";
import addToReportIcon from "../images/add-report.svg";
import { FormattedMessage } from "react-intl";
import html2canvas from "html2canvas";
import { useDispatch } from "react-redux";
import { addChart } from "modules/reports/reducer";

const AddToReport = () => {
  const dispatch = useDispatch();
  const addToReport = () => {
    const chart = document.querySelector("#svg-chart .chart > div:first-child");

    return html2canvas(chart, {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      width: 880,
      height: 600,
      scrollY: -window.scrollY,
      scrollX: -window.scrollX,
      backgroundColor: "#f4f7fc",
    }).then(canvas => {
      const dataURL = canvas.toDataURL("image/png");

      dispatch(addChart({ img: dataURL.replace(/^data:image\/(png|jpg);base64,/, "") }));
      alert("The chart is added and is ready to be edited on the report page.");
    });
  };

  return (
    <div>
      <button onClick={addToReport}>
        <img src={addToReportIcon} width="20" height="20" style={{ pointerEvents: "none" }} />
        <FormattedMessage
          id="report.add-to"
          defaultMessage="
        Add to report"
        />
      </button>
    </div>
  );
};

export default AddToReport;
