import { connect } from "react-redux";
import { selectChartMetaData } from "modules/indicators/selectors/indicators";
import { selectSeriesDataRadarChart } from "modules/indicators/selectors/charts/radar-chart";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import RadarChart from "./RadarChartComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataRadarChart(state),
  chartMetaData: selectChartMetaData(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(RadarChart);
