import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import NestedTreeBuilder from "./NestedTreeBuilder";
import { SelectionStyled } from "../Styled";

const Selection = ({ label, tabsData, onSelect, onChangeTab, preselectedValues }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setCurrentTabIndex(newValue);
    onChangeTab(newValue);
  };

  const productsByKeys = tabsData
    .reduce((accumulator, d) => [...accumulator, ...d.data], [])
    .reduce((accumulator, d) => ({ ...accumulator, [d.id]: d.type }), {});

  const selectedItemsLbl =
    preselectedValues.length > 0
      ? preselectedValues
          .filter(d => productsByKeys[d] !== undefined)
          .map(d => productsByKeys[d])
          .join(", ")
      : "";

  return (
    <SelectionStyled>
      <label className="filter-criteria-lbl">{label}</label>
      <div className="selected-items-lbl" title={selectedItemsLbl}>
        {selectedItemsLbl}
      </div>
      <div className="popup">
        <Tabs value={currentTabIndex} onChange={handleChangeTab}>
          {tabsData.map((d, i) => (
            <Tab
              className="tab"
              key={`tt${d.label
                .substr(0, 2)
                .trim()
                .toLowerCase()}-${i}`}
              label={d.label}
            />
          ))}
        </Tabs>
        {tabsData.map((d, i) => (
          <div
            key={`tc${d.label
              .substr(0, 2)
              .trim()
              .toLowerCase()}-${i}`}
            className={`tab-content ${i === currentTabIndex ? "active" : ""}`}
          >
            <NestedTreeBuilder
              data={d.data}
              preselectedValues={preselectedValues}
              onChangeCallback={onSelect}
              isSearchable={d.isSearchable}
            />
          </div>
        ))}
      </div>
    </SelectionStyled>
  );
};

Selection.propTypes = {
  label: PropTypes.any.isRequired,
  isMultipleSelection: PropTypes.bool,
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      data: PropTypes.array.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  preselectedValues: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func.isRequired,
};

Selection.defaultProps = {
  isMultipleSelection: false,
};

export default React.memo(Selection);
