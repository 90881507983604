import React from "react";
// import PropTypes from "prop-types";
// import { IntlProvider } from "react-intl";
import styled from "styled-components";
import ITCLogo from "./images/ITC_Logo_EN_White_big.png";
import UNCTAD from "./images/unctad-white-logo.png";
import WTOLogo from "./images/wto-light.png";
import { FormattedMessage } from "react-intl";

const OrganizationLinksStyled = styled.div`
  flex-grow: 1;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin-right: 25px;
      vertical-align: middle;
    }
    .sdg-logo {
      position: relative;
      padding-left: 15px;
      &:before {
        content: "";
        position: absolute;
        top: 7px;
        bottom: 7px;
        left: 0;
        background: #979797;
        opacity: 0.33;
        width: 1px;
      }
      img {
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-family: Roboto, serif;
        font-size: 21px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.95;
        letter-spacing: normal;
        color: #000000;
      }
    }
  }
  .introduction {
    margin-top: 30px;
    max-width: 380px;
    opacity: 0.8;
    font-family: Roboto, serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #ffffff;
  }
`;

const OrganizationLinks = () => (
  <OrganizationLinksStyled>
    <ul style={{ margin: 0 }} className="zz">
      <li>
        <a
          href={"http://www.intracen.org"}
          rel="noopener noreferrer"
          target="_blank"
          title="International Trade Centre"
        >
          <img src={ITCLogo} alt="International Trade Centre" width="108" height="45" />
        </a>
      </li>
      <li style={{ transform: "translateY(11px)" }}>
        <a href={"https://unctad.org/"} rel="noopener noreferrer" target="_blank" title="UNCTAD">
          <img src={UNCTAD} alt="United Nations" width="60" />
        </a>
      </li>
      <li>
        <a
          href={"https://wto.org"}
          rel="noopener noreferrer"
          target="_blank"
          title="World Trade Organization"
        >
          <img src={WTOLogo} alt="World Trade Organization" width="47" height="45" />
        </a>
      </li>
    </ul>
    <p className="introduction">
      <FormattedMessage
        id="footer.intro"
        defaultMessage="This portal is the result of the cooperation among the International Trade Centre (ITC), the United Nations Conference on Trade and Development (UNCTAD) and the World Trade Organization (WTO)."
      />
    </p>
  </OrganizationLinksStyled>
);

export default OrganizationLinks;
