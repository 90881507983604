import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import Item from "./Item";
import { useDispatch } from "react-redux";
import { reorderReports } from "../../reducer";

const Charts = ({ charts }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState(charts);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = items[dragIndex];
      setItems(
        update(items, {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
        })
      );
    },
    [items]
  );

  useEffect(() => {
    dispatch(
      reorderReports(
        items.map((item, i) => ({
          id: i + 1,
          data: item.data,
          img: item.img,
          desc: item.desc,
          layout: item.layout,
        }))
      )
    );
  }, [items]);

  return (
    <div>
      {charts.map((chart, i) => (
        <Item
          key={`it-${i}`}
          index={i}
          id={chart.id}
          layout={chart.layout}
          desc={chart.desc}
          img={chart.img}
          moveCard={moveCard}
        />
      ))}
    </div>
  );
};

Charts.propTypes = {
  charts: PropTypes.array.isRequired,
};

export default Charts;
