import React from "react";
import PropTypes from "prop-types";
import ReactExport from "react-export-excel";
import formatSheetDataPrimary from "../helpers/formatSheetDataPrimaryByProducts";
import formatSheetDataSecondary from "../helpers/formatSheetDataSecondaryByProducts";
import downloadIcon from "../../images/download.svg";
import vzConf from "modules/visualizations.config";
import { VALUES } from "modules/meta-data/constants";
import { FormattedMessage } from "react-intl";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ButtonExport = () => (
  <button>
    <img src={downloadIcon} width="20" height="20" loading="lazy" alt="Export data" />
    <FormattedMessage id="indicators.toolbar.export-data" defaultMessage="Export data" />
  </button>
);

const generateExcelFileSingularMeasure = (
  vzData,
  chartMetaData,
  currentUIState,
  regionsData,
  productsData,
  fileName,
  filterParams
) => {
  const sheetPrimary = formatSheetDataPrimary(
    vzData,
    regionsData,
    productsData,
    chartMetaData?.primaryMeasure?.unit?.trim(),
    filterParams
  );

  return (
    <>
      <ExcelFile element={<ButtonExport />} filename={fileName}>
        <ExcelSheet data={sheetPrimary} name={chartMetaData?.primaryMeasure?.seriesDescription}>
          <ExcelColumn label="Reporter" value="reporter" />
          <ExcelColumn label="Reporter Code" value="reporterCode" />
          <ExcelColumn label="Partner" value="partner" />
          <ExcelColumn label="Partner Code" value="partnerCode" />
          <ExcelColumn label="Year" value="year" />
          <ExcelColumn label="Value" value="value" />
          <ExcelColumn label="Unit" value="unit" />
          <ExcelColumn label="Product Name" value="product" />
          <ExcelColumn label="Product Sector" value="productSector" />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};

const generateExcelFileMultipleMeasures = (
  vzData,
  chartMetaData,
  currentUIState,
  regionsData,
  productsData,
  fileName,
  filterParams
) => {
  const sheetPrimary = formatSheetDataPrimary(
    vzData,
    regionsData,
    productsData,
    chartMetaData?.primaryMeasure?.unit?.trim(),
    filterParams
  );
  const sheetSecondary = formatSheetDataSecondary(
    vzData,
    regionsData,
    productsData,
    chartMetaData?.primaryMeasure?.unit?.trim(),
    filterParams
  );

  return (
    <>
      <ExcelFile element={<ButtonExport />} filename={fileName}>
        <ExcelSheet data={sheetPrimary} name={chartMetaData?.primaryMeasure?.seriesDescription}>
          <ExcelColumn label="Reporter" value="reporter" />
          <ExcelColumn label="Reporter code" value="reporterCode" />
          <ExcelColumn label="Partner" value="partner" />
          <ExcelColumn label="Partner Code" value="partnerCode" />
          <ExcelColumn label="Year" value="year" />
          <ExcelColumn label="Value" value="value" />
          <ExcelColumn label="Unit" value="unit" />
          <ExcelColumn label="Product Name" value="product" />
          <ExcelColumn label="Product Sector" value="productSector" />
        </ExcelSheet>

        <ExcelSheet data={sheetSecondary} name={chartMetaData?.secondaryMeasure?.seriesDescription}>
          <ExcelColumn label="Reporter" value="reporter" />
          <ExcelColumn label="Reporter code" value="reporterCode" />
          <ExcelColumn label="Partner" value="partner" />
          <ExcelColumn label="Partner Code" value="partnerCode" />
          <ExcelColumn label="Year" value="year" />
          <ExcelColumn label="Value" value="value" />
          <ExcelColumn label="Unit" value="unit" />
          <ExcelColumn label="Product Name" value="product" />
          <ExcelColumn label="Product Sector" value="productSector" />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};

const ByProducts = ({
  vzData,
  chartMetaData,
  currentUIState,
  regionsData,
  productsData,
  fileName,
  filterParams,
}) => {
  if (vzConf[(currentUIState?.visualization)].measureSelectionType === VALUES.MEASURE_SELECTION_SEPARATED) {
    return (
      <div>
        {generateExcelFileMultipleMeasures(
          vzData,
          chartMetaData,
          currentUIState,
          regionsData,
          productsData,
          fileName,
          filterParams
        )}
      </div>
    );
  }

  return (
    <div>
      {generateExcelFileSingularMeasure(
        vzData,
        chartMetaData,
        currentUIState,
        regionsData,
        productsData,
        fileName,
        filterParams
      )}
    </div>
  );
};

ByProducts.propTypes = {
  vzData: PropTypes.any.isRequired,
  chartMetaData: PropTypes.any.isRequired,
  currentUIState: PropTypes.any.isRequired,
  regionsData: PropTypes.any.isRequired,
  productsData: PropTypes.any.isRequired,
  fileName: PropTypes.string.isRequired,
  filterParams: PropTypes.any.isRequired,
};

export default ByProducts;
