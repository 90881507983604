import { createAction, handleActions } from "redux-actions";

export const setTableData = createAction("[Data availability] Set table data");

export const setSelectedMeasure = createAction("[Data availability] Set selected measure");

// Psuedo actions
export const fetchData = createAction("[Data availability] Fetch data");

export default handleActions(
  {
    [setTableData]: (state, { payload }) => ({
      ...state,
      tableData: {
        ...state.tableData,
        heading: payload.heading,
        body: payload.body,
      },
    }),
    [setSelectedMeasure]: (state, { payload }) => ({
      ...state,
      selectedMeasure: payload,
    }),
  },
  {
    selectedMeasure: "",
    tableData: [],
  }
);
