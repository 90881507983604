import { DIMENSIONS, VALUES, VISUALIZATIONS } from "modules/meta-data/constants";
import * as DEFAULT_FILTER_PARAMS from "modules/meta-data/constants/default-filter-params";
import { economicRegionIds } from "modules/indicators/components/Visualization/Filter/RegionsSelection/constants";

const getRegions = (indicator, key, vz, dimension) => {
  if ([VISUALIZATIONS.TREEMAP_CHART].includes(+vz)) {
    if (indicator === "2_b_1") {
      return [...DEFAULT_FILTER_PARAMS.ALL_SINGLE_COUNTRIES, 296];
    } else if (indicator === "8_a_1") {
      return [...DEFAULT_FILTER_PARAMS.ALL_SINGLE_COUNTRIES, 0];
    } else {
      return DEFAULT_FILTER_PARAMS.ALL_SINGLE_COUNTRIES;
    }
  }

  if ([VISUALIZATIONS.MAP, VISUALIZATIONS.BUBBLE_CHART].includes(+vz)) {
    return DEFAULT_FILTER_PARAMS.ALL_SINGLE_COUNTRIES;
  }

  if ([VISUALIZATIONS.RADAR_CHART].includes(+vz) && indicator === "8_2_i") {
    return [4];
  }

  if (indicator === "2_b_1") return [...economicRegionIds, 296];
  if (indicator === "8_2_i") {
    if (dimension === DIMENSIONS.BY_COUNTRIES) {
      return economicRegionIds;
    } else {
      return [4];
    }
  }

  if (indicator === "8_a_1") return [1, 117, 4, 16, 230, 19, 296, 0];
  if (indicator === "10_a_1") {
    if (dimension === DIMENSIONS.BY_COUNTRIES) {
      return [4, 19];
    } else {
      [VISUALIZATIONS.RADAR_CHART].includes(+vz);
      return [4, 19];
    }
  }

  if (indicator === "10_a_i") return [4, 16, 19, 117];
  if (indicator === "17_10_1") {
    if (dimension === DIMENSIONS.BY_PRODUCTS) {
      return [1];
    }
    return [51, 7, 6, 8, 12, 5, 242, 237, 235, 3, 11, 9, 141, 238, 239, 240, 236];
  }
  if (indicator === "17_11_1") return [117, 4, 16, 19];
  if (indicator === "17_11_i") return economicRegionIds;
  if (indicator === "17_11_ii") {
    return [4, 117, 16, 19, 230];
  }
  if (indicator === "17_12_1") return [4, 117, 16, 19];

  switch (key) {
    case VALUES.REGIONS_FIRST_20_SINGLE_COUNTRIES:
      return DEFAULT_FILTER_PARAMS.FIRST_20_SINGLE_COUNTRIES;
    case VALUES.REGIONS_ALL_SINGLE_COUNTRIES:
      return DEFAULT_FILTER_PARAMS.ALL_SINGLE_COUNTRIES;
    default:
      return [];
  }
};

const getPartners = (indicator, vz) => {
  switch (indicator) {
    case "8_a_1":
      if (+vz === VISUALIZATIONS.FLOW_CHART) {
        return [51, 7, 6, 8, 12, 5, 252, 237, 235, 173, 11, 141, 238, 239, 240, 242, 236, 3, 9, 141];
      }
      return [1];
    case "17_11_ii":
      if (+vz === VISUALIZATIONS.FLOW_CHART) {
        return [51, 7, 6, 8, 12, 5, 252, 237, 235, 173, 11, 141, 238, 239, 240, 242, 236, 3, 9, 141];
      }
      return [12];
    case "10_a_i":
    case "17_12_1":
      return [230];
    default:
      return [1];
  }
};

const getProducts = (indicatorCode, dimension) => {
  switch (indicatorCode) {
    case "17_11_ii":
      if (dimension === DIMENSIONS.BY_PRODUCTS) {
        return [42, 1, 2, 3, 4, 5, 6];
      }
      return [42];
    default:
      return [42, 1, 2, 3, 4, 5, 6];
  }
};

export default (indicatorCode, vzCode, uiConf, vzConf, dimension) => {
  const paramsNeededKeys = {
    ...uiConf?.viewData?.params,
    ...vzConf?.params,
  };

  return Object.keys(paramsNeededKeys).reduce((acc, key) => {
    if (key === "region") {
      const regions = getRegions(indicatorCode, vzConf.filter.region, vzCode, dimension);

      return {
        ...acc,
        region: dimension === "BC" || +vzCode === 7 ? regions : [regions[0]],
      };
    }

    if (key === "partner") {
      return {
        ...acc,
        partner: getPartners(indicatorCode, vzCode),
      };
    }

    if (key === "growth") {
      return {
        ...acc,
        growth: "BasicGrowth2015",
      };
    }

    if (key === "product") {
      const products = getProducts(indicatorCode, dimension);

      return {
        ...acc,
        product: dimension === "BP" ? products : [products[0]],
      };
    }

    return acc;
  }, {});
};
