import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const H1Styled = styled.h1`
  width: 100%;
  max-width: 100%;
  font-size: 36px;
  font-weight: ${props => props.theme.fontWeightMedium};
  margin: 0 0 10px 0;
  color: inherit;
`;

const H1 = props => {
  const { className, children } = props;

  return <H1Styled className={className}>{children}</H1Styled>;
};

H1.propTypes = {
  children: PropTypes.node,
  sub: PropTypes.node,
  className: PropTypes.string,
};

H1.defaultProps = {
  children: null,
  sub: null,
  className: "",
};

export default H1;
