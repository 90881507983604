import styled from "styled-components";

export default styled.div`
  .vz-container {
    display: flex;
    position: relative;
    max-width: 1190px;
    padding: 0 25px;
    margin: 30px auto;
    min-height: 700px;
  }
  .right-content {
    flex: 1 0 auto;
    max-width: 880px;
    width: 100%;
  }
  *[data-title="All products"],
  *[data-title="Tous les produits"],
  *[data-title="Todos los productos"]
  {
    font-weight: bold;
  }
`;
