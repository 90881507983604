import { connect } from "react-redux";
import { selectChartMetaData } from "modules/indicators/selectors/indicators";
import { selectSeriesDataMapChoropleth } from "modules/indicators/selectors/charts/map";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import MapComponent from "./MapComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataMapChoropleth(state),
  chartMetaData: selectChartMetaData(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(MapComponent);
