import { createSelector } from "reselect";
import { selectSingleCountries, selectProducts } from "modules/indicators/selectors/indicators";
import * as constants from "modules/constants";

const getDimension = state => state.indicators.currentUIState.dimension;
const getSeriesData = state => state.indicators.seriesData;

// = Vertical bar chart: by country dimension
const getSeriesDataVerticalBarChartByCountry = (data, singleCountries) => {
  if (data === null) return null;
  const filterData = Array.from(new Set(data.map(d => d.year)));
  const fixedYear = Math.min(...filterData);
  const basedYear = Math.max(...filterData);

  if (fixedYear === basedYear) return [];

  let dataBasedYear = data
    .filter(d => +d.year === basedYear)
    .filter(d => singleCountries[d.geoAreaId] !== undefined);

  const dataFixedYear = data
    .filter(d => +d.year === fixedYear)
    .filter(d => singleCountries[d.geoAreaId] !== undefined)
    .reduce((accumulator, currentValue) => {
      accumulator[currentValue.geoAreaId] = currentValue;
      return accumulator;
    }, {});

  // match data with other dataset
  dataBasedYear = dataBasedYear
    .map(d => {
      const find = dataFixedYear[d.geoAreaId];
      if (find) {
        d.valueFixedYear = find.value < 0 ? find.value * -1 : find.value;
        d.valueBasedYear = d.value;
        return d;
      }
      return null;
    })
    .filter(item => item !== null);

  // replace id by country name
  dataBasedYear = dataBasedYear.map(d => {
    const region = singleCountries[d.geoAreaId];
    d.name = region.name;
    d.m49Code = region.m49Code;
    return d;
  });

  return dataBasedYear;
};

// = Vertical bar chart: by product dimension
const getSeriesDataVerticalBarChartByProduct = (data, products) => {
  if (data === null) return null;
  const filterData = Array.from(new Set(data.map(d => d.year)));
  const fixedYear = Math.min(...filterData);
  const basedYear = Math.max(...filterData);

  if (fixedYear === basedYear) return [];

  let dataBasedYear = data
    .filter(d => d.year === basedYear)
    .filter(d => products[d.productTypeId] !== undefined);

  const dataFixedYear = data
    .filter(d => d.year === fixedYear)
    .filter(d => products[d.productTypeId] !== undefined)
    .reduce((accumulator, currentValue) => {
      accumulator[currentValue.productTypeId] = currentValue;
      return accumulator;
    }, {});

  // match data with other dataset
  dataBasedYear = dataBasedYear
    .map(d => {
      const find = dataFixedYear[d.productTypeId];
      if (find) {
        d.valueFixedYear = find.value < 0 ? find.value * -1 : find.value;
        d.valueBasedYear = d.value;
        return d;
      }
      return null;
    })
    .filter(item => item !== null);

  // replace id by country name
  dataBasedYear = dataBasedYear.map(d => {
    d.name = products[d.productTypeId].type;
    return d;
  });

  return dataBasedYear;
};

export const selectSeriesDataVerticalChart = createSelector(
  getSeriesData,
  selectProducts,
  selectSingleCountries,
  getDimension,
  ({ data, status }, products, singleCountries, currentDimension) => {
    if (
      data === null ||
      products.length === 0 ||
      singleCountries.length === 0 ||
      status === constants.STATUS_LOADING
    )
      return null;

    if (currentDimension === "BC") return getSeriesDataVerticalBarChartByCountry(data, singleCountries);
    return getSeriesDataVerticalBarChartByProduct(data, products);
  }
);
