import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchMetaData } from "modules/meta-data/reducer";

const MetaData = () => {
  const language = useSelector(state => state.ui.language, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (language) {
      dispatch(fetchMetaData(language));
    }
  }, [language]);

  return <></>;
};

export default MetaData;
