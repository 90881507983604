import React from "react";
import PropTypes from "prop-types";
import { IndicatorContextProvider } from "./IndicatorContext";
import INDICATION_UI_CONF from "modules/indicators.config";


const IndicatorContextProviderComponent = ({ children }) => (
    <IndicatorContextProvider value={INDICATION_UI_CONF}>{children}</IndicatorContextProvider>
);

IndicatorContextProviderComponent.propTypes = {
    children: PropTypes.node,
};

IndicatorContextProviderComponent.defaultProps = {
    children: null,
};

export default IndicatorContextProviderComponent;
