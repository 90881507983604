export default {
  PRODUCTS_ALL: 42,
  PRODUCTS_BROAD_AGRICULTURE: 2,
  REGIONS_WORLD: 1,
  REGIONS_FIRST_20: "F20",
  REGIONS_ALL: "R_ALL",
  REGIONS_FIRST_20_SINGLE_COUNTRIES: "RS_F20",
  REGIONS_ALL_SINGLE_COUNTRIES: "RS_ALL",
  REGIONS_ECONOMIC_LDCS: 4,
  REGIONS_ECOMOMIC_DEVELOPING: 19,
  MEASURE_SELECTION_MERGED: 0,
  MEASURE_SELECTION_SEPARATED: 1,
  FILTER_PARAM_YEAR_RANGE: "F_Y_R",
  FILTER_PARAM_YEAR_1_POINT: "F_Y_1P",
  FILTER_PARAM_YEAR_2_POINTS: "F_Y_2P",
  BASIC_GROWTH_2015: "BasicGrowth2015",
  SELECTION_SINGULAR: "SELECTION_SINGULAR",
  SELECTION_MULTIPLE: "SELECTION_MULTIPLE",
};
