import { createSelector } from "reselect";
import { selectSingleCountries } from "modules/indicators/selectors/indicators";
import * as constants from "modules/constants";

const getSeriesData = state => state.indicators.seriesData;
const getSecondarySeriesData = state => state.indicators.seriesDataSecondary;
const getCrossCuttingSeriesData = state => state.indicators.seriesDataCrossCutting;
export const selectSeriesDataScatterPlotChart = createSelector(
  getSeriesData,
  getSecondarySeriesData,
  getCrossCuttingSeriesData,
  selectSingleCountries,
  (data, secondaryData, crossCuttingData, regions) => {
    if (
      data === null ||
      data.status === constants.STATUS_LOADING ||
      crossCuttingData.status === constants.STATUS_LOADING
    )
      return null;

    let primaryDataByKey = {};
    let secondaryDataByKey = {};
    let crossCuttingDataByKey = {};
    const countriesPrimaryDataSeries = data.data.map(d => d.geoAreaId);
    const countriesSecondaryDataSeries = secondaryData.data.map(d => d.geoAreaId);

    const availableCountries = Array.from(
      new Set([...countriesPrimaryDataSeries, ...countriesSecondaryDataSeries])
    );

    if (data.data) {
      primaryDataByKey = data.data.reduce(
        (acc, currentVal) => ({
          ...acc,
          [currentVal.geoAreaId]: currentVal.value,
        }),
        {}
      );
    }

    if (secondaryData.data) {
      secondaryDataByKey = secondaryData.data.reduce(
        (acc, currentVal) => ({
          ...acc,
          [currentVal.geoAreaId]: currentVal.value,
        }),
        {}
      );
    }

    if (crossCuttingData.data) {
      crossCuttingDataByKey = crossCuttingData.data.reduce(
        (acc, currentVal) => ({
          ...acc,
          [currentVal.geoAreaId]: currentVal.value,
        }),
        {}
      );
    }

    return availableCountries.map(geoAreaId => ({
      id: geoAreaId,
      name: regions[geoAreaId] ? regions[geoAreaId].name : "",
      m49Code: regions[geoAreaId] ? regions[geoAreaId].m49Code : "",
      value: primaryDataByKey[geoAreaId] ? primaryDataByKey[geoAreaId] : 0,
      valueSecond: secondaryDataByKey[geoAreaId] ? secondaryDataByKey[geoAreaId] : 0,
      valueGDP: crossCuttingDataByKey[geoAreaId] ? crossCuttingDataByKey[geoAreaId] : 0,
    }));
  }
);
