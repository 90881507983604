import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  deleteSeriesData,
  // deleteSecondarySeriesData,
  // deleteCrossCuttingSeriesData,
  setVisualizationCharts,
} from "../reducer";

export default action$ =>
  action$.pipe(
    ofType(setVisualizationCharts),
    mergeMap(
      () => of(deleteSeriesData())
      // concat(of(deleteSeriesData()), of(deleteSecondarySeriesData()), of(deleteCrossCuttingSeriesData()))
    )
  );
