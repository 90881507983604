import { connect } from "react-redux";
import SetData from "./SetData";
import {
  setCurrentUIState,
  deleteSeriesData,
  setDefaultFilterParams,
  fetchSeriesData,
  fetchSecondarySeriesData,
  fetchCrossCuttingSeriesData,
  deleteSecondarySeriesData,
  deleteCrossCuttingSeriesData,
} from "modules/indicators/reducer";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";

const mapStateToProps = state => ({
  currentUIState: selectCurrentUIState(state),
  filterParams: selectFilterParams(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrentUIState: currentUIState => dispatch(setCurrentUIState(currentUIState)),
  deleteSeriesData: () => dispatch(deleteSeriesData()),
  setDefaultFilterParams: data => dispatch(setDefaultFilterParams(data)),
  fetchSeriesData: data => dispatch(fetchSeriesData(data)),
  fetchSecondarySeriesData: data => dispatch(fetchSecondarySeriesData(data)),
  deleteSecondarySeriesData: () => dispatch(deleteSecondarySeriesData()),
  fetchCrossCuttingSeriesData: data => dispatch(fetchCrossCuttingSeriesData(data)),
  deleteCrossCuttingSeriesData: () => dispatch(deleteCrossCuttingSeriesData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetData);
