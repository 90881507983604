import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import PropTypes from "prop-types";
import { BarchartVertical } from "datadesign-charts-sdgtrade";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import { MEASURES_UNIT } from "modules/meta-data/constants";
import ErrorBoundary from "modules/ui/components/ErrorBoundary";
import Skeleton from "@material-ui/lab/Skeleton";

const ChartStyled = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    overflow: visible;
  }
  &.loading {
    justify-content: center;
    align-items: center;
  }
  &.empty {
    color: red;
  }
  .chart {
    min-width: 100%;
    flex: 1 0 auto !important;
  }
`;

const Chart = ({ data, filterData, chartMetaData, indicatorConf, title }) => {
  const { embed } = useParams();
  if (data === null) {
    return (
      <ChartStyled className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </ChartStyled>
    );
  }

  if (data.length === 0) {
    return <EmptyMessage />;
  }

  return (
    <ChartStyled>
      <div className="chart">
        <ErrorBoundary>
          <BarchartVertical
            data={data.map(d => ({
              ...d,
              value: parseFloat(d.value.toFixed(3)),
              valueBasedYear: parseFloat(d.valueBasedYear.toFixed(3)),
              valueFixedYear: parseFloat(d.valueFixedYear.toFixed(3)),
            }))}
            dataTitle={[filterData.year[0], filterData.year[1]]}
            xValueKey="name"
            yValueKey={["valueFixedYear", "value"]}
            positiveChangeGood={indicatorConf.positiveChangeGood}
            positiveChangeUpward={true}
            uniqueIDKey="id"
            yUnit={MEASURES_UNIT[chartMetaData?.primaryMeasure?.unit.trim()]}
            title={title}
            aspectRatio={0.5}
          />
        </ErrorBoundary>

        {(!embed || embed !== "embed") && <Toolbar vzData={data} />}
      </div>
    </ChartStyled>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  filterData: PropTypes.object.isRequired,
  chartMetaData: PropTypes.object.isRequired,
  indicatorConf: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
