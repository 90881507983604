import React from "react";
import styled from "styled-components";
import { setLanguage } from "modules/ui/reducer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const Styled = styled.div`
  position: relative;
  &:before {
    content: "";
    border-left: 1px solid #eee;
    margin-right: 10px;
    padding-left: 15px;
  }
  ul {
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s ease-out;
    padding: 15px 0 0 0 !important;
    background: #fff;
  }

  li {
    border-top: 1px solid #eee;
    padding: 15px 30px;
    cursor: pointer;
    &:hover {
      color: blue;
    }
    &:first-child {
      margin-top: 15px;
    }
  }
  span {
    position: relative;
    z-index: 1;
  }
  &:hover {
    ul {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const LANG = {
  en: "English",
  fr: "Français",
  es: "Español",
};
const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.ui.language, shallowEqual);

  return (
    <Styled>
      <span>{LANG[language]}</span>
      <ul>
        {language !== "en" && <li onClick={() => dispatch(setLanguage("en"))}>{LANG.en}</li>}
        {language !== "fr" && <li onClick={() => dispatch(setLanguage("fr"))}>{LANG.fr}</li>}
        {language !== "es" && <li onClick={() => dispatch(setLanguage("es"))}>{LANG.es}</li>}
      </ul>
    </Styled>
  );
};

export default LanguageSwitcher;
