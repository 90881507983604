import { createSelector } from "reselect";

const getRegions = state => state.metaData.regions;

export const selectRegions = createSelector(
  getRegions,
  data => data
);

export const selectRegionWorld = createSelector(
  selectRegions,
  data => data.find(d => d.id === 1)
);