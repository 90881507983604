import React, { Fragment } from "react";
import { BarchartHorizontal } from "datadesign-charts-sdgtrade";
import chartHorizontal from "./data/191119_wto_agr_prd_xsubdy/chart-horizontal";
import regions from "./data/region_by_id";
import { FormattedMessage } from "react-intl";

const BarchartHorizontalModule = () => {
  const chartHorizontalDataFiltered = chartHorizontal.filter(d => +d.growthCommit >= 0);

  // replace id by country name
  chartHorizontalDataFiltered.map(d => {
    const region = regions.find(k => +k.id === +d.geoAreaId);
    d.name = region ? region.name : "";
    return d;
  });

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.h-bar-chart"
        defaultMessage="Agriculture Export subsidies (millions of current United States dollars)"
      >
        {text => (
          <BarchartHorizontal
            data={chartHorizontalDataFiltered}
            dataTitle={["Commitment", "Disbursement"]} //Title for Tooltip and Legend
            xValueKey={["growthCommit", "growthDisb"]}
            yValueKey="name" // must be a unique name otherwise it will be rendered over each other
            uniqueIDKey="id"
            title={text}
            aspectRatio={0.5} // optional
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};

export default BarchartHorizontalModule;
