import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RegionsSelection from "./RegionsSelection";
import ProductSelection from "./ProductsSelection";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { INDICATORS, PARAMS, REGIONS, VALUES, VISUALIZATIONS } from "modules/meta-data/constants";
import iconSelectionArrow from "modules/indicators/components/Visualization/Filter/images/arrow.svg";
import { VisualizationContext } from "components/VisualizationContextProviderComponent";
import { IndicatorContext } from "components/IndicatorContextProviderComponent";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resetSearchKeywords,
  setSearchKeywordPartner,
  setSearchKeywordRegion,
} from "modules/indicators/reducer";
import {
  selectSearchKeywordPartner,
  selectSearchKeywordRegion,
} from "modules/indicators/selectors/search-keyword";
import { YearDuoPoints, YearRange, YearsSinglePoint } from "modules/v2/indicators/components/SearchForm";
import { FormattedMessage } from "react-intl";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import { useSelector } from "react-redux";
import { selectAvailableReportersByYears } from "modules/meta-data/selectors/available-reporters";

const FilterStyled = styled.div`
  display: flex;
  padding-right: 30px;
  flex-direction: column;
  width: 260px;
  max-width: 260px;
  flex: 1 0 260px;
  position: sticky;
  top: 0;
  align-self: flex-start;
  z-index: 1;
  .filter-criteria {
    position: relative;
    border-bottom: 1px solid #cfcfcf;
    background: url(${iconSelectionArrow}) 99% 85% no-repeat;
    &-lbl {
      opacity: 0.6;
      color: #000000;
      font-family: Roboto, "sans-serif";
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.46px;
      line-height: 18px;
      text-transform: uppercase;
    }
  }
  .partner,
  .region,
  .product {
    cursor: pointer;
  }
  .selected-values {
    position: relative;
    padding-right: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #1b3159;
    font-family: Roboto, "sans-serif";
    font-size: 16px;
    font-weight: 500;
    &::after {
      position: absolute;
      top: 30%;
      right: 15px;
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: "";
      height: 0.45em;
      transform: rotate(135deg) translateY(-50%);
      vertical-align: top;
      width: 0.45em;
    }
  }

  .partner,
  .region,
  .product {
    position: relative;
    margin-bottom: 45px;
    .popup {
      display: none;
      flex-direction: column;
      visibility: hidden;
      z-index: -1;
      position: absolute;
      top: 20px;
      left: 0;
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      padding: 15px;
      background: #fff;
      > span {
        width: 100%;
      }
    }
    &.opened {
      display: flex;
      > span {
        width: 100%;
        display: block;
      }
      .popup {
        display: flex;
        visibility: visible;
        z-index: 1;
      }
    }
  }
  .dimension {
    display: flex;
    flex-direction: column;
    .filter-years {
      order: 10;
    }
    &-product {
      .filter-product {
        order: -1;
      }
      .filter-region {
        order: 2;
      }
    }
  }
  .MuiSelect-selectMenu {
    font-size: 16px;
    font-weight: 500;
    color: rgb(27, 49, 89);
  }
  .MuiInput-underline:before,
  .MuiInput-underline:hover:before,
  .Mui-focused:before,
  .MuiInput-underline:after {
    display: none;
  }
  .MuiSelect-select:focus {
    background: transparent;
  }
  .MuiSelect-icon {
    display: none;
  }
  .MuiTab-wrapper {
    font-size: 13px;
  }
`;

const Filter = ({
  params,
  singleCountries,
  economicRegions,
  geographicRegions,
  products,
  regions,
  setFilterParams,
  fetchFilterParamsProduct,
}) => {
  const dispatch = useDispatch();
  const { code, visualization, dimension } = useParams();
  const vzContext = useContext(VisualizationContext);
  const indicatorCode = code.replace(/-/g, "_");
  const indicatorContext = useContext(IndicatorContext);
  const uiConf = indicatorContext[INDICATORS[`i${indicatorCode}`]];
  const currentDimension = dimension ? dimension : uiConf?.defaultViewData?.dimensions;
  const currentVz =
    visualization && uiConf?.viewData?.visualizations[currentDimension].indexOf(+visualization) !== -1
      ? +visualization
      : uiConf?.defaultViewData.visualizations[currentDimension];
  const vzConf = vzContext[currentVz];

  const paramsNeeded = {
    ...uiConf?.viewData?.params,
    ...vzConf?.params,
  };
  const filterParams = useSelector(selectFilterParams);
  const dataAvailability = useSelector(selectAvailableReportersByYears);
  const paramsQty = vzConf?.paramsQty[currentDimension];

  const [open, setOpen] = useState({
    partner: false,
    region: false,
    product: false,
  });

  const handleChangeProductTab = tabIndex => {
    if (+visualization === VISUALIZATIONS.RADAR_CHART) {
      fetchFilterParamsProduct(tabIndex);
    }
  };

  const singleAvailableContries = singleCountries.map(({ items }) => ({
    items: items.filter(d => {
      const { year } = filterParams;
      return (
        dataAvailability?.find(item => item.geoAreaId === d.id && year.includes(item.year)) !==
        undefined
      );
    }),
  }))


  let tasData = {
    region: [
      {
        label: (
          <FormattedMessage
            id="indicator.search-form.label.single-countries"
            defaultMessage="Single countries"
          />
        ),
        key: REGIONS.INDIVIDUAL_COUNTRIES,
        hideOnMapVz: false,
        selectChildrenWithParent: true,
        data: singleAvailableContries[0].items.length === 0 ? singleCountries : singleAvailableContries,
        depth: 10,
        isSearchable: true,
      },
      {
        label: (
          <FormattedMessage
            id="indicator.search-form.label.economic-regions"
            defaultMessage="Economic regions"
          />
        ),
        key: REGIONS.ECONOMIC_REGIONS,
        hideOnMapVz: true,
        selectChildrenWithParent: true,
        data: economicRegions,
        depth: 1,
        isSearchable: true,
      },
      {
        label: (
          <FormattedMessage
            id="indicator.search-form.label.geographic-regions"
            defaultMessage="Geographic regions"
          />
        ),
        key: REGIONS.GEOGRAPHIC_REGIONS,
        hideOnMapVz: true,
        selectChildrenWithParent: true,
        data: geographicRegions,
        depth: 2,
        isSearchable: true,
      },
    ],
    partner: [
      {
        label: (
          <FormattedMessage
            id="indicator.search-form.label.economic-regions"
            defaultMessage="Economic regions"
          />
        ),
        key: REGIONS.ECONOMIC_REGIONS,
        hideOnMapVz: false,
        selectChildrenWithParent: true,
        data: economicRegions,
        depth: 1,
        isSearchable: true,
      },
      {
        label: (
          <FormattedMessage
            id="indicator.search-form.label.geographic-regions"
            defaultMessage="Geographic regions"
          />
        ),
        key: REGIONS.GEOGRAPHIC_REGIONS,
        hideOnMapVz: true,
        selectChildrenWithParent: true,
        data: geographicRegions,
        depth: 2,
        isSearchable: true,
      },
    ],
    products: products.map(d => ({ label: d.category, data: d.items, isSearchable: true })),
  };

  const handleClick = key => {
    const newParams = ["product", "partner", "region"].reduce((accumulator, currentValue) => {
      accumulator[currentValue] = currentValue === key;
      return accumulator;
    }, {});

    dispatch(resetSearchKeywords());

    setOpen(newParams);
  };

  const handleClickAway = () =>
    setOpen({
      partner: false,
      region: false,
      product: false,
    });

  const handleSelectValues = (data, key) => {
    setFilterParams({
      key: key,
      isMultipleSelection: paramsQty[key] === VALUES.SELECTION_MULTIPLE,
      data: data,
    });
  };

  const handleProductFilterChange = data => {
    if (visualization === VISUALIZATIONS.RADAR_CHART) {
      fetchFilterParamsProduct([42, 43, 44]);
    } else {
      handleSelectValues(data, "product");
    }
  };

  if (!paramsNeeded) return null;

  return (
    <FilterStyled>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`dimension ${currentDimension === "BC" ? "dimension-country" : "dimension-product"}`}>
          {/* ===== start param region =====*/}
          {paramsNeeded?.region && dataAvailability && (
            <div
              className={`filter-criteria filter-region region ${open.region === true ? "opened" : ""}`}
              onClick={() => handleClick("region")}
            >
              <RegionsSelection
                label="Countries / Regions"
                tabsData={tasData.region.filter(d => uiConf.viewData.region.indexOf(d.key) !== -1)}
                onSelect={data => {
                  handleSelectValues(data, "region");
                }}
                preselectedValues={params.region}
                regions={regions} //@todo consider remove this property ???
                keywordSelector={selectSearchKeywordRegion}
                keywordCallbackFn={setSearchKeywordRegion}
              />
            </div>
          )}
          {/* ===== end param report =====*/}

          {/* ===== start param partner =====*/}
          {paramsNeeded?.partner && (
            <div
              className={`filter-criteria filter-partner partner ${open.partner === true ? "opened" : ""}`}
              onClick={() => handleClick("partner")}
              style={{ display: code === "17-12-1" ? "none" : "block" }}
            >
              <RegionsSelection
                label="Partners"
                tabsData={tasData.partner.filter(d => uiConf.viewData.partner.indexOf(d.key) !== -1)}
                onSelect={data => handleSelectValues(data, "partner")}
                preselectedValues={params.partner}
                regions={regions} //@todo consider remove this property ???
                keywordSelector={selectSearchKeywordPartner}
                keywordCallbackFn={setSearchKeywordPartner}
              />
            </div>
          )}
          {/* ===== end param partner =====*/}

          {/* ===== start param product =====*/}
          {paramsNeeded?.product && (
            <div
              className={`filter-criteria filter-product product ${open.product === true ? "opened" : ""}`}
              onClick={() => handleClick("product")}
            >
              <ProductSelection
                label={<FormattedMessage id="indicators.search-form.products" defaultMessage="Products" />}
                tabsData={tasData.products.filter(d => uiConf?.viewData?.product.indexOf(d.label) !== -1)}
                onSelect={data => handleProductFilterChange(data)}
                onChangeTab={data => handleChangeProductTab(data)}
                preselectedValues={params.product}
              />
            </div>
          )}
          {/* ===== end param product =====*/}

          {/* ===== start param year =====*/}

          <div className="filter-years years">
            <label className="filter-criteria-lbl">
              <FormattedMessage id="indicators.search-form.time" defaultMessage="Time" />
            </label>
            {paramsNeeded.year === PARAMS.YEAR_2_POINTS && <YearDuoPoints />}
            {paramsNeeded.year === PARAMS.YEAR_RANGE && <YearRange />}
            {paramsNeeded.year === PARAMS.YEAR_1_POINT && <YearsSinglePoint />}
          </div>
          {/* ===== end param year =====*/}
        </div>
      </ClickAwayListener>
    </FilterStyled>
  );
};

Filter.propTypes = {
  params: PropTypes.any,
  singleCountries: PropTypes.array.isRequired,
  economicRegions: PropTypes.array.isRequired,
  geographicRegions: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  setFilterParams: PropTypes.func.isRequired,
  fetchFilterParamsProduct: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  params: {
    region: null,
    partner: null,
    product: null,
    year: null,
    growth: null,
  },
};

export default Filter;
