import { createAction, handleActions } from "redux-actions";
import * as constants from "modules/constants";

const MODULE_NAME = "[Indicators]";

export const setIndicatorsData = createAction(`${MODULE_NAME} Set indicators data`);
export const setIndicatorCode = createAction(`${MODULE_NAME} Set indicator code`);
export const setVisualizationDimension = createAction(`${MODULE_NAME} Set visualization dimension`);
export const setCurrentUIState = createAction(`${MODULE_NAME} Set current UI state`);
export const setFilterParams = createAction(`${MODULE_NAME} Set filter params`);
export const setFilterParamsProduct = createAction(`${MODULE_NAME} Set filter params.product`);
export const setFilterParamsYear = createAction(`${MODULE_NAME} Set filter params.year`);
export const setDefaultFilterParams = createAction(`${MODULE_NAME} Set default filter params`);
export const setSeriesData = createAction(`${MODULE_NAME} Set series data`);
export const setSecondarySeriesData = createAction(`${MODULE_NAME} Set secondary series data`);
export const setCrossCuttingSeriesData = createAction(`${MODULE_NAME} Set cross-cutting series data`);
export const deleteSeriesData = createAction(`${MODULE_NAME} Delete series data`);
export const deleteSecondarySeriesData = createAction(`${MODULE_NAME} Delete secondary series data`);
export const deleteCrossCuttingSeriesData = createAction(`${MODULE_NAME} Delete cross-cutting series data`);
export const setVisualizationCharts = createAction(`${MODULE_NAME} Set visualization charts`);
export const setFilterYearsType = createAction(`${MODULE_NAME} Set filter years type`);
export const setCurrentVzTitle = createAction(`${MODULE_NAME} Set current vz title`);
export const setSearchKeywordRegion = createAction(`${MODULE_NAME} Set search keyword region`);
export const setSearchKeywordPartner = createAction(`${MODULE_NAME} Set search keyword partner`);
export const setSearchKeywordProduct = createAction(`${MODULE_NAME} Set search keyword product`);
export const setAvailableYears = createAction(`${MODULE_NAME} Set available years`);
export const resetSearchKeywords = createAction(`${MODULE_NAME} Reset search keywords`);
export const resetProductsSelection = createAction(`${MODULE_NAME} Reset products selection`);
export const setFilterDataYear = createAction(`${MODULE_NAME} Set filter data year`);
export const resetFilterDataYear = createAction(`${MODULE_NAME} Reset filter data year`);

// Psuedo action
export const fetchIndicatorsData = createAction(`${MODULE_NAME} Fetch indicators data ⚡`);
export const fetchDefaultFilterParams = createAction(`${MODULE_NAME} Fetch default filter params ⚡`);
export const fetchMeasureData = createAction(`${MODULE_NAME} Fetch measure data ⚡`);
export const fetchSeriesData = createAction(`${MODULE_NAME} Fetch series data ⚡`);
export const fetchSecondarySeriesData = createAction(`${MODULE_NAME} Fetch secondary series data  ⚡`);
export const fetchCrossCuttingSeriesData = createAction(`${MODULE_NAME} Fetch cross-cutting series data ⚡`);
export const fetchFilterParamsProduct = createAction(`${MODULE_NAME} Fetch filter params product ⚡`);
export const fetchFilterDataYear = createAction(`${MODULE_NAME} Fetch filter data years ⚡`);

export default handleActions(
  {
    [setIndicatorsData]: (state, { payload }) => ({
      ...state,
      data: [...state.data, ...payload],
    }),
    [setIndicatorCode]: (state, { payload }) => ({
      ...state,
      metaData: {
        ...state.metaData,
        code: payload,
      },
    }),
    [setVisualizationDimension]: (state, { payload }) => ({
      ...state,
      metaData: {
        ...state.metaData,
        dimension: payload,
      },
    }),
    [setCurrentUIState]: (state, { payload }) => ({
      ...state,
      currentUIState: {
        ...state.currentUIState,
        ...payload,
      },
    }),

    [setFilterParamsProduct]: (state, { payload }) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        product: [...payload],
      },
    }),

    [setFilterParams]: (state, { payload }) => {
      let newDataCleaned = null;

      if (payload.key === "year") {
        return {
          ...state,
          filterParams: {
            ...state.filterParams,
            year: payload.data,
          },
        };
      }

      if (payload.isMultipleSelection === true) {
        const unselectedData = Object.keys(payload.data)
          .filter(d => payload.data[d] === false)
          .map(d => +d);
        const selectedData = Object.keys(payload.data)
          .filter(d => payload.data[d] === true)
          .map(d => +d);

        const newDataAfterRemoveUncheckedItems = state.filterParams[payload.key].filter(
          d => unselectedData.indexOf(d) === -1
        );
        const newDataAFterAddCheckedItems = [...newDataAfterRemoveUncheckedItems, ...selectedData];

        newDataCleaned = Array.from(new Set(newDataAFterAddCheckedItems));
      } else {
        const unselectedData = Object.keys(payload.data)
          .filter(d => payload.data[d] === false)
          .map(d => +d);
        const selectedData = Object.keys(payload.data)
          .filter(d => payload.data[d] === true)
          .map(d => +d);

        if (selectedData && selectedData.length > 0) {
          newDataCleaned = selectedData;
        } else {
          newDataCleaned = state.filterParams[payload.key].filter(d => unselectedData.indexOf(d) === -1);
        }
      }

      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          [payload.key]: newDataCleaned,
        },
      };
    },
    [setSeriesData]: (state, { payload }) => ({
      ...state,
      seriesData: { ...payload },
    }),
    [setSecondarySeriesData]: (state, { payload }) => ({
      ...state,
      seriesDataSecondary: { ...payload },
    }),
    [setCrossCuttingSeriesData]: (state, { payload }) => ({
      ...state,
      seriesDataCrossCutting: { ...payload },
    }),
    [setDefaultFilterParams]: (state, { payload }) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        ...payload,
      },
    }),
    [setVisualizationCharts]: (state, { payload }) => ({
      ...state,
      currentUIState: {
        ...state.currentUIState,
        visualization: payload,
      },
    }),
    [setFilterYearsType]: (state, { payload }) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        yearsType: payload,
      },
    }),
    [deleteSeriesData]: state => ({
      ...state,
      seriesData: {
        status: constants.STATUS_LOADING,
        data: [],
      },
    }),
    [deleteSecondarySeriesData]: state => ({
      ...state,
      seriesDataSecondary: {
        status: constants.STATUS_LOADING,
        data: [],
      },
    }),
    [deleteCrossCuttingSeriesData]: state => ({
      ...state,
      seriesDataCrossCutting: {
        status: constants.STATUS_LOADING,
        data: [],
      },
    }),
    [setCurrentVzTitle]: (state, { payload }) => ({
      ...state,
      title: payload,
    }),
    [setSearchKeywordRegion]: (state, { payload }) => ({
      ...state,
      searchKeyword: {
        ...state.searchKeyword,
        region: payload,
      },
    }),
    [setSearchKeywordPartner]: (state, { payload }) => ({
      ...state,
      searchKeyword: {
        ...state.searchKeyword,
        partner: payload,
      },
    }),
    [resetSearchKeywords]: state => ({
      ...state,
      searchKeyword: {
        region: "",
        partner: "",
        product: "",
      },
    }),
    [resetProductsSelection]: (state, { payload }) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        product: payload ? payload : [],
      },
    }),
    [setFilterDataYear]: (state, { payload }) => ({
      ...state,
      filterData: {
        ...state.filterData,
        year: { ...payload },
      },
    }),
    [setFilterParamsYear]: (state, { payload }) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        year: payload,
      },
    }),
    [resetFilterDataYear]: state => ({
      ...state,
      filterData: {
        year: {
          status: constants.STATUS_NO_DATA,
          data: [],
        },
      },
    }),
  },
  {
    title: "",
    seriesData: {
      status: constants.STATUS_NO_DATA,
      data: [],
    },
    seriesDataSecondary: {
      status: constants.STATUS_NO_DATA,
      data: [],
    },
    seriesDataCrossCutting: {
      status: constants.STATUS_NO_DATA,
      data: [],
    },
    filterData: {
      year: {
        status: constants.STATUS_NO_DATA,
        data: [],
      },
    },
    filterParams: {
      region: [],
      partner: [],
      product: [],
      year: [],
    },
    searchKeyword: {
      region: "",
      partner: "",
      product: "",
    },
    currentUIState: {
      indicatorCode: "",
      dimension: "",
      visualization: "",
      measure: "",
      measure_secondary: "",
      measure_cross_cutting: "",
    },
  }
);
