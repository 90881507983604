import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setFilterParamsYear } from "modules/indicators/reducer";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import { selectFilterDataYear } from "modules/indicators/selectors/filter-data";
import Slider from "@material-ui/core/Slider";
import { FormattedMessage } from "react-intl";

const Styled = styled.div`
  margin-right: 5px;
  label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
  }
`;

const FilterYearsSinglePoint = () => {
  const dispatch = useDispatch();
  const { year } = useSelector(selectFilterParams) || [];
  const { data } = useSelector(selectFilterDataYear) || [];

  const getYearsRange = (startYear, endYear) => {
    let years = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    return years;
  };

  const setYears = (event, newValue) => {
    dispatch(setFilterParamsYear(getYearsRange(newValue[0], newValue[1])));
  };

  useEffect(() => {
    if (data !== null) {
      if (data.length > 0) {
        const latestYear = Math.max(...data);
        dispatch(
          setFilterParamsYear(
            getYearsRange(Math.min(...data), latestYear)
          )
        );
      }
    }
  }, [data]);

  if (year === null || data === null || year?.length < 2 || data?.length === 0) return null;

  return (
    <Styled>
      <label className="filter-criteria-lbl">
        <FormattedMessage id="indicators.search-form.years" defaultMessage="Years" />
      </label>
      <Slider
        min={Math.min(...data)}
        max={Math.max(...data)}
        onChange={setYears}
        value={[Math.min(...year), Math.max(...year)]}
        step={1}
        marks={true}
        valueLabelDisplay="auto"
      />
    </Styled>
  );
};

export default FilterYearsSinglePoint;
