export default [
  {
    id: 16911,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2005,
    value: 78.571428571428569,
    productTypeId: 5,
  },
  {
    id: 16912,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2015,
    value: 72.854471150516034,
    productTypeId: 42,
  },
  {
    id: 17177,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2017,
    value: 74.435504801453419,
    productTypeId: 2,
  },
  {
    id: 18002,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2016,
    value: 0.0,
    productTypeId: 4,
  },
  {
    id: 18144,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2005,
    value: 74.013422818791952,
    productTypeId: 1,
  },
  {
    id: 18290,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2010,
    value: 77.822908204711624,
    productTypeId: 2,
  },
  {
    id: 18375,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2015,
    value: 0.0,
    productTypeId: 4,
  },
  {
    id: 18378,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2005,
    value: 69.938921837656011,
    productTypeId: 42,
  },
  {
    id: 18690,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2015,
    value: 93.333333333333329,
    productTypeId: 5,
  },
  {
    id: 18950,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2005,
    value: 50.0,
    productTypeId: 4,
  },
  {
    id: 19115,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2010,
    value: 73.71877970789042,
    productTypeId: 42,
  },
  {
    id: 19296,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2016,
    value: 66.3157894736842,
    productTypeId: 3,
  },
  {
    id: 19756,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2010,
    value: 84.615384615384613,
    productTypeId: 5,
  },
  {
    id: 20220,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2010,
    value: 52.502910360884748,
    productTypeId: 3,
  },
  {
    id: 20532,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2017,
    value: 89.473684210526315,
    productTypeId: 5,
  },
  {
    id: 20771,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2016,
    value: 76.110049675200614,
    productTypeId: 42,
  },
  {
    id: 20861,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2010,
    value: 33.333333333333336,
    productTypeId: 4,
  },
  {
    id: 20899,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2017,
    value: 75.274990610076728,
    productTypeId: 42,
  },
  {
    id: 20954,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2016,
    value: 86.666666666666671,
    productTypeId: 5,
  },
  {
    id: 21196,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2017,
    value: 50.0,
    productTypeId: 4,
  },
  {
    id: 22085,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2017,
    value: 77.30520585233073,
    productTypeId: 1,
  },
  {
    id: 22523,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2015,
    value: 74.876468650488633,
    productTypeId: 1,
  },
  {
    id: 22807,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2015,
    value: 57.639524245196704,
    productTypeId: 3,
  },
  {
    id: 23241,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2016,
    value: 74.422018348623851,
    productTypeId: 2,
  },
  {
    id: 23697,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2016,
    value: 78.6686281721221,
    productTypeId: 1,
  },
  {
    id: 24115,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2015,
    value: 76.84696569920844,
    productTypeId: 2,
  },
  {
    id: 24153,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2017,
    value: 65.985915492957744,
    productTypeId: 3,
  },
  {
    id: 24247,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2010,
    value: 75.637737886354429,
    productTypeId: 1,
  },
  {
    id: 24303,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2005,
    value: 45.791245791245792,
    productTypeId: 3,
  },
  {
    id: 24450,
    indicatorTypeId: 2,
    geoAreaId: 51,
    partnerId: 1,
    year: 2005,
    value: 77.426739926739927,
    productTypeId: 2,
  },
];
