import { createAction, handleActions } from "redux-actions";
import * as constants from "modules/constants";

const MODULE_NAME = "[Indicators - v2]";

export const setIndicator = createAction(`${MODULE_NAME} - Set indicator`);
export const setDimension = createAction(`${MODULE_NAME} - Set dimension`);
export const setVisualization = createAction(`${MODULE_NAME} - Set visualization`);
export const setTarget = createAction(`${MODULE_NAME} - Set target`);
export const setMeasurePrimary = createAction(`${MODULE_NAME} - Set measure.primary`);

export default handleActions(
  {
    [setTarget]: (state, { payload }) => ({
      ...state,
      target: payload,
    }),
    [setIndicator]: (state, { payload }) => ({
      ...state,
      indicator: payload,
    }),
    [setDimension]: (state, { payload }) => ({
      ...state,
      dimension: payload,
    }),
    [setVisualization]: (state, { payload }) => ({
      ...state,
      visualization: payload,
    }),
    [setMeasurePrimary]: (state, { payload }) => ({
      ...state,
      measure: {
        ...state.measures,
        primary: {
          ...payload,
        },
      },
    }),
  },
  {
    target: null,
    visualization: null,
    dimension: null,
    indicator: null,
    searchForm: {
      formData: {
        region: [],
        partner: [],
        product: [],
        year: [],
      },
      formParams: {
        region: [],
        partner: [],
        product: [],
        year: [],
      },
    },
    measure: {
      primary: null,
      secondary: null,
      crossCutting: null,
    },
    seriesData: {
      primary: {
        status: constants.STATUS_NO_DATA,
        data: null,
      },
      secondary: {
        status: constants.STATUS_NO_DATA,
        data: null,
      },
      crossCutting: {
        status: constants.STATUS_NO_DATA,
        data: null,
      },
    },
  }
);
