import { createSelector } from "reselect";
import { selectProducts, selectSingleCountries } from "modules/indicators/selectors/indicators";
import * as constants from "modules/constants";

const getSeriesData = state => state.indicators.seriesData;
const getSecondarySeriesData = state => state.indicators.seriesDataSecondary;
const getDimension = state => state.indicators.currentUIState.dimension;

const _getSeriesDataGapChartByCountry = (data, secondaryData, regions) => {
  let primaryDataByKey = {};
  let secondaryDataByKey = {};
  const countriesPrimaryDataSeries = data?.map(d => d.geoAreaId) || [];
  const countriesSecondaryDataSeries = secondaryData?.map(d => d.geoAreaId) || [];
  const availableCountries = Array.from(
    new Set([...countriesPrimaryDataSeries, ...countriesSecondaryDataSeries])
  );

  if (data) {
    primaryDataByKey = data.reduce(
      (acc, currentVal) => ({
        ...acc,
        [currentVal.geoAreaId]: currentVal.value,
      }),
      {}
    );
  }

  if (secondaryData) {
    secondaryDataByKey = secondaryData.reduce(
      (acc, currentVal) => ({
        ...acc,
        [currentVal.geoAreaId]: currentVal.value,
      }),
      {}
    );
  }

  return availableCountries.map(geoAreaId => ({
    id: geoAreaId,
    name: regions[geoAreaId] ? regions[geoAreaId].name : "",
    m49Code: regions[geoAreaId] ? regions[geoAreaId].m49Code : "",
    value: primaryDataByKey[geoAreaId] ? primaryDataByKey[geoAreaId] : 0,
    valueSecond: secondaryDataByKey[geoAreaId] ? secondaryDataByKey[geoAreaId] : 0,
  }));
};

const _getSeriesDataGapChartByProduct = (data, secondaryData, products) => {
  let primaryDataByKey = {};
  let secondaryDataByKey = {};
  const productsPrimaryDataSeries = data?.map(d => d.productTypeId) || [];
  const productsSecondaryDataSeries = secondaryData?.map(d => d.productTypeId) || [];
  const availableProducts = Array.from(
    new Set([...productsPrimaryDataSeries, ...productsSecondaryDataSeries])
  );

  if (data) {
    primaryDataByKey = data.reduce(
      (acc, currentVal) => ({
        ...acc,
        [currentVal.productTypeId]: currentVal.value,
      }),
      {}
    );
  }

  if (secondaryData) {
    secondaryDataByKey = secondaryData.reduce(
      (acc, currentVal) => ({
        ...acc,
        [currentVal.productTypeId]: currentVal.value,
      }),
      {}
    );
  }

  return availableProducts.map(key => ({
    id: key,
    name: products[key] ? products[key].type : "",
    value: primaryDataByKey[key] ? primaryDataByKey[key] : 0,
    valueSecond: secondaryDataByKey[key] ? secondaryDataByKey[key] : 0,
  }));
};

export const selectSeriesDataGapChart = createSelector(
  getSeriesData,
  getSecondarySeriesData,
  selectProducts,
  selectSingleCountries,
  getDimension,
  (data, secondaryData, products, regions, currentDimension) => {
    if (
      data === null ||
      data.status === constants.STATUS_LOADING ||
      secondaryData.status === constants.STATUS_LOADING
    )
      return null;

    if (currentDimension === "BC") {
      return _getSeriesDataGapChartByCountry(data.data, secondaryData.data, regions);
    }
    return _getSeriesDataGapChartByProduct(data.data, secondaryData.data, products);
  }
);
