import { connect } from "react-redux";
import { setLanguage } from "modules/ui/reducer";
import { selectCurrentLanguage, selectAvailableLanguages } from "modules/ui/selectors";

const mapStateToProps = state => ({
  currentLocale: selectCurrentLanguage(state),
  availableLanguages: selectAvailableLanguages(state),
});
const mapDispatchToProps = dispatch => ({
  handleChangeLocale: lang => dispatch(setLanguage(lang)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
