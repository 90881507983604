import React, { useContext } from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PropTypes from "prop-types";
import { IndicatorContext } from "components/IndicatorContextProviderComponent";
import { INDICATORS, DIMENSIONS } from "modules/meta-data/constants";
import { useParams, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const ButtonStyled = styled(Button)`
  &.MuiButton-outlined {
    flex: 1 0 auto;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0);
    color: #fff;
    cursor: pointer;
    border-radius: 0;
    border: 0;
    &.active {
      color: #1b3159;
      background: #fff;
    }
  }
`;

const Content = ({ onCurrentUIStateChange }) => {
  const { language, code, dimension, embed } = useParams();
  const history = useHistory();
  const match = useRouteMatch();

  const indicatorContext = useContext(IndicatorContext);
  const indicatorCode = match.params.code.replace(/-/g, "_");
  const defaultViewDataConf = indicatorContext[INDICATORS[`i${indicatorCode}`]]?.defaultViewData;
  const neededDimensions = indicatorContext[INDICATORS[`i${indicatorCode}`]]?.viewData?.dimensions;

  const handleViewChange = dimensionCode => {
    const data = {
      dimension: dimensionCode.toUpperCase(),
      visualization: defaultViewDataConf.visualizations[dimensionCode],
    };

    onCurrentUIStateChange(data);

    history.push(
      `/${language}/indicators/${code}/${data.dimension}/${data.visualization}/${
        embed ? embed : ""
      }${location.search.trim()}`
    );
  };
  if (!neededDimensions) return null;
  return (
    <ButtonGroup size="small">
      {neededDimensions.indexOf(DIMENSIONS.BY_COUNTRIES) !== -1 && (
        <ButtonStyled
          className={dimension === DIMENSIONS.BY_COUNTRIES ? "active" : ""}
          onClick={() => handleViewChange("BC")}
          disableRipple={true}
          disableFocusRipple={true}
        >
          <FormattedMessage id="indicators.dimension.by-country" defaultMessage="By country" />
        </ButtonStyled>
      )}
      {neededDimensions.indexOf(DIMENSIONS.BY_PRODUCTS) !== -1 && (
        <ButtonStyled
          className={dimension === DIMENSIONS.BY_PRODUCTS ? "active" : ""}
          onClick={() => handleViewChange("BP")}
          disableRipple={true}
          disableFocusRipple={true}
        >
          <FormattedMessage id="indicators.dimension.by-product" defaultMessage="By product" />
        </ButtonStyled>
      )}
    </ButtonGroup>
  );
};

Content.propTypes = {
  onCurrentUIStateChange: PropTypes.func.isRequired,
};

Content.defaultProps = {
  singleCountries: null,
};

export default Content;
