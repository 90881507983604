import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import { RadarChart } from "datadesign-charts-sdgtrade";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import { MEASURES_UNIT } from "modules/meta-data/constants";
import Skeleton from "@material-ui/lab/Skeleton";

const Chart = ({ data, chartMetaData, title }) => {
  const { embed } = useParams();
  if (data === null) {
    return (
      <div className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  // sort data from having more data to less data
  const dataSorted = data.sort((a, b) => b.length - a.length > 0);

  if (dataSorted.flat().length === 0) {
    return <EmptyMessage />;
  }

  return (
    <div className="chart">
      <RadarChart
        data={dataSorted}
        xValueKey="name" // axislabel on outer circle
        yValueKey="value" // value of datapoint generating inner form
        uniqueIDKey="id"
        nameKey="regionName"
        yUnit={MEASURES_UNIT[chartMetaData?.primaryMeasure?.unit?.trim()]}
        title={title}
        aspectRatio={0.5}
      />
      {(!embed || embed !== "embed") && <Toolbar vzData={data} />}
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  chartMetaData: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
