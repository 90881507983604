import { of, concat } from "rxjs";
import { map, mergeMap, catchError, filter, take } from "rxjs/operators";
import { ofType } from "redux-observable";
import { addError } from "modules/ui/reducer";
import { selectCurrentLanguage } from "modules/ui/selectors";
import { selectBearerToken } from "../selectors";
import { fetchComponent, setComponent } from "../reducer";
import * as constants from "modules/constants";

export default (action$, state$, { apiConfig, ajax, timestamp }) => {
  const request = params => ({
    responseType: "json",
    method: "GET",
    url: `${apiConfig.cmsContentRoot}/?ids=${params.id}`,
    headers: {
      Authorization: `Bearer ${params.bearerToken}`,
      "X-Languages": params.language,
      "X-Flatten": true,
    },
  });

  return action$.pipe(
    ofType(fetchComponent),
    mergeMap(action =>
      concat(
        of(
          setComponent({
            id: action.payload,
            status: constants.STATUS_LOADING,
          })
        ),
        state$.pipe(
          map(selectBearerToken),
          filter(Boolean),
          take(1),
          map(() => ({
            id: action.payload,
            language: selectCurrentLanguage(state$.value),
            bearerToken: selectBearerToken(state$.value),
          })),
          mergeMap(params =>
            ajax(request(params)).pipe(
              mergeMap(data =>
                of(setComponent({ id: params.id, status: constants.STATUS_OK, response: data.response }))
              ),
              catchError(e =>
                concat(
                  of(setComponent({ id: params.id, status: constants.STATUS_ERROR })),
                  of(
                    addError({
                      timestamp: timestamp(),
                      message: "cms.api-error",
                      details: e,
                    })
                  )
                )
              )
            )
          )
        )
      )
    )
  );
};
