import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { default as _Container } from "shared-components/Container";
import LoadingPlaceholder from "shared-components/LoadingPlaceHolder";
import CMSContentAbout from "./Content/About";
import CMSContentPublications from "./Content/Publications";
import CMSContentFAQ from "./Content/FAQ";
import CMSContentMethodologies from "./Content/Methodologies";
import CMSContentLib from "./Content/Lib";
import CMSContact from "./Content/Contact";
import { useRouteMatch } from "react-router";
import Hero from "../../../ui/components/Hero";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

const OtherPageStyled = styled.div`
  margin-bottom: 50px;
  h2 {
    text-align: center;
  }

  a {
    text-decoration: underline;
  }

  .title-icon {
    margin-top: 80px;
    text-align: center;
  }

  .ct {
    line-height: 150%;
  }

  .side-bar {
    padding-left: 30px;
    ul {
      padding: 0;
    }
    li {
      list-style-type: none;
      text-align: center;
      img {
        margin: 15px auto;
        width: 233px;
        height: auto;
      }
    }
  }
`;

const Container = styled(_Container)`
  display: flex;
  &:empty {
  display: none;
  }
`;

const HeroBanner = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 300px;
  margin-bottom: 50px;
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: right;
    width: 100%;
    height: 100%;
  }
  .heading {
    position: relative;
    z-index: 1;
  }
  h1 {
    margin-top: 0;
    font-size: 36px;
  }
`;

const CMSPage = ({ content }) => {
  const language = useSelector(state => state.ui.language);
  const { url } = useRouteMatch();
  let data;
  if (url?.indexOf("methodologies") !== -1) {
    data = content?.data?.queryMethodologiesContents?.[0]?.data;
  } else {
    data = content?.data;
  }
  return (
    <OtherPageStyled>
      {!content?.data && (
        <div style={{ height: 300, width: "100%", marginBottom: 50 }}>
          <LoadingPlaceholder />
        </div>
      )}
      {content?.data && data?.key === "page-home" && (
        <Hero height={315} background={`https://cms.tmi-internal.org/api/assets/sdg/${data?.["img-banner"]}`}>
          <ul>
            <li>
              <article>
                <span className="heading">
                  <FormattedMessage id="home.hero-banner.sub-heading" defaultMessage="SDG Trade Monitor" />
                </span>
                <span className="sub-heading" dangerouslySetInnerHTML={{ __html: data?.heading }} />
                <p className="content" dangerouslySetInnerHTML={{ __html: data?.description }} />
              </article>
            </li>
          </ul>
        </Hero>
      )}
      {content?.data && data?.key !== "page-home" && (
        <>
          <HeroBanner>
            {!data?.key?.iv && (
              <>
                <Container>
                  <div className="heading">
                    <h1 dangerouslySetInnerHTML={{ __html: data?.heading }} />
                    {data?.desc && <div dangerouslySetInnerHTML={{ __html: data?.desc }} />}
                  </div>
                </Container>
                <img
                  className="bg"
                  src={`https://cms.tmi-internal.org/api/assets/sdg/${data?.["image-banner"]}`}
                  alt=""
                />
              </>
            )}

            {data?.key?.iv && (
              <>
                <Container>
                  <div className="heading">
                    <h1 dangerouslySetInnerHTML={{ __html: data?.heading?.[language] }} />

                  </div>
                </Container>
                <img
                  className="bg"
                  src={`https://cms.tmi-internal.org/api/assets/sdg/${data?.imageBanner?.[language]?.[0]?.id}`}
                  alt=""
                />
              </>
            )}
          </HeroBanner>

          <Container>
            {data?.key === "page-about" && <CMSContentAbout data={data} />}
            {data?.key === "page-publications" && <CMSContentPublications data={data?.list} />}
            {data?.key === "page-faq" && <CMSContentFAQ data={data?.item} />}
            {data?.key === "page-data-vz-lib" && <CMSContentLib data={data?.item} />}
            {data?.key === "page-contact" && <CMSContact data={data?.item} />}
            {data?.key?.iv && data?.key?.iv === "page-methodologies" && (
              <CMSContentMethodologies data={data?.list?.iv?.[0]?.item} />
            )}
          </Container>
        </>
      )}
    </OtherPageStyled>
  );
};

CMSPage.propTypes = {
  content: PropTypes.object,
};

CMSPage.defaultProps = {
  content: null,
};

export default CMSPage;
