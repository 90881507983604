import React, { Fragment } from "react";
import { BarchartVertical } from "datadesign-charts-sdgtrade";
import chartVertical from "./data/191119_wto_agr_prd_xsubdy/chart-vertical";
import regions from "./data/region_by_id";
import { FormattedMessage } from "react-intl";

const BarchartVerticalModule = () => {
  // replace id by country name
  const dataFiltered = chartVertical.map(d => {
    const region = regions.find(k => +k.id === +d.geoAreaId);
    d.name = region ? region.name : "";
    return d;
  });

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.v-bar-chart"
        defaultMessage="Agriculture Export subsidies (millions of current United States dollars)"
      >
        {text => (
          <BarchartVertical
            data={dataFiltered}
            dataTitle={["2015", "2016"]} //Title for Tooltip and Legend
            xValueKey="name"
            yValueKey={["value2015", "value2016"]}
            positiveChangeGood={true} // optional, true or false, default true
            uniqueIDKey="id"
            yUnit="USD" // 'PERCENTAGE', 'USD' or 'BLANK', default USD
            title={text}
            aspectRatio={0.5} //optional
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};

export default BarchartVerticalModule;
