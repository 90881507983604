import { createSelector } from "reselect";
import { VISUALIZATIONS } from "../constants";

const getCurrentVisualization = state => state.indicators.currentUIState.visualization;

const getGeographicRegions = state => state.metaData.geographicRegions;

const getRegions = state => state.metaData.regions;

export const selectGeographicRegions = createSelector(
  getGeographicRegions,
  getRegions,
  getCurrentVisualization,
  (data, dataFlatten, currentVz) => {

    if (data.length === 0) return [];

    if ([VISUALIZATIONS.FLOW_CHART].includes(+currentVz)) {
      return [
        {
          items: dataFlatten.filter(d =>
            [
              7, // Southern Asia
              6, // Eastern Asia
              8, // South-Eastern Asia
              12, // Europe
              5, // Northern America
              242, // Caribbean
              237, // Central America
              235, //
              3, // Northern Africa
              11, // Western Asia
              9, // Australia and New Zealand
              141, // Oceania (exc. Australia and New Zealand)
              0, // Other
              13, // Sub-Saharan Africa
            ].includes(d.id)
          ),
        },
      ];
    }

    return [
      {
        items: [
          {
            id: data[0].id,
            m49Code: data[0].m49Code,
            name: data[0].name,
          },
          ...data[0].items,
        ],
      },
    ];
  }
);
