import React from "react";
import Header from "../modules/ui/components/Header";
import HomeFeaturedGoals from "components/HomeFeaturedGoals";
import Footer from "../modules/ui/components/Footer";
import ScrollToTopOnMount from "modules/shared-components/ScrollToTopOnMount";
import CMSPage from "modules/cms/components/CMSPage";
import useContent from "../modules/cms/hooks/useContent";

export default () => {
  const content = useContent({ contentType: "home", contentKey: "page-home" });

  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      <CMSPage content={content} />
      <HomeFeaturedGoals goals={[2, 8, 10, 17]} />
      <Footer />
    </>
  );
};
