import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { VisualizationContext } from "components/VisualizationContextProviderComponent";
import { IndicatorContext } from "components/IndicatorContextProviderComponent";
import { INDICATORS } from "modules/meta-data/constants";
import ErrorBoundary from "modules/ui/components/ErrorBoundary";
import { getDefaultFilterParams, getDefaultUIState } from "modules/indicators/helpers";
import {
  fetchFilterDataYear,
  setFilterParamsYear,
  setSecondarySeriesData,
  setSeriesData,
} from "modules/indicators/reducer";
import { useDispatch, useSelector } from "react-redux";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { STATUS_OK } from "../../../constants";
import { fetchDataAvailability } from "../../../meta-data/reducer";

const SetData = ({
                   filterParams,
                   currentUIState,
                   setCurrentUIState,
                   setDefaultFilterParams,
                   deleteSeriesData,
                 }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { language, code, visualization, dimension, embed } = useParams();
  const { region, partner, product } = useSelector(selectFilterParams);
  const { measure } = useSelector(selectCurrentUIState);
  const indicatorCode = code.replace(/-/g, "_");
  const vzContext = useContext(VisualizationContext);
  const indicatorContext = useContext(IndicatorContext);
  const uiConf = indicatorContext[INDICATORS[`i${indicatorCode}`]];
  const currentDimension = dimension ? dimension : uiConf?.defaultViewData?.dimensions;

  useEffect(() => {
    deleteSeriesData();
    if (visualization !== "" && currentDimension !== "" && currentUIState.indicatorCode === indicatorCode) {
      const currentVz =
        visualization && uiConf?.viewData?.visualizations[currentDimension].indexOf(+visualization) !== -1
          ? +visualization
          : uiConf?.defaultViewData.visualizations[currentDimension];

      setDefaultFilterParams(
        getDefaultFilterParams(
          indicatorCode,
          visualization,
          uiConf,
          vzContext[currentVz],
          dimension,
          filterParams,
        ),
      );

      history.push(
        `/${language}/indicators/${code}/${currentDimension}/${currentVz}/${
          embed ? embed : ""
        }${location.search.trim()}`,
      );
    }
  }, [visualization, dimension]);

  useEffect(() => {
    const currentDimension = dimension ? dimension : uiConf?.defaultViewData?.dimensions;

    const currentVz =
      visualization && uiConf?.viewData?.visualizations[currentDimension].indexOf(+visualization) !== -1
        ? +visualization
        : uiConf?.defaultViewData.visualizations[currentDimension];
    const defaultUIState = getDefaultUIState(indicatorCode, uiConf, currentDimension, currentVz);

    setCurrentUIState(defaultUIState);
    setDefaultFilterParams({
      region: [],
      partner: [],
      product: [],
      year: [],
    });
    setDefaultFilterParams(
      getDefaultFilterParams(indicatorCode, visualization, uiConf, vzContext[currentVz], dimension, {
        region: [],
        partner: [],
        product: [],
        year: [],
      }),
    );
    // dispatch()
    dispatch(setFilterParamsYear([]));
    dispatch(fetchFilterDataYear());

    history.push(
      `/${language}/indicators/${code}/${currentDimension}/${currentVz}/${
        embed ? embed : ""
      }${location.search.trim()}`,
    );
  }, []);

  useEffect(() => {
    if (region.length > 0 && partner.length > 0 && product.length > 0) {
      dispatch(fetchFilterDataYear());
    }
    if (region.length === 0 || partner.length === 0 || product.length === 0) {
      dispatch(setSeriesData({ status: STATUS_OK, data: [] }));
      dispatch(setSecondarySeriesData({ status: STATUS_OK, data: [] }));
    }
  }, [region, partner, product, visualization, dimension, measure]);

  useEffect(() => {
    const currentVz =
      visualization && uiConf?.viewData?.visualizations[currentDimension].indexOf(+visualization) !== -1
        ? +visualization
        : uiConf?.defaultViewData.visualizations[currentDimension];
    const defaultUIState = getDefaultUIState(indicatorCode, uiConf, currentDimension, currentVz);

    setCurrentUIState(defaultUIState);
  }, [visualization]);

  useEffect(() => {
      dispatch(fetchDataAvailability(measure));
  }, [measure]);

  return (
    <ErrorBoundary>
      <></>
    </ErrorBoundary>
  );
};

SetData.propTypes = {
  currentUIState: PropTypes.object.isRequired,
  filterParams: PropTypes.object.isRequired,
  setCurrentUIState: PropTypes.func.isRequired,
  deleteSeriesData: PropTypes.func.isRequired,
  setDefaultFilterParams: PropTypes.func.isRequired,
  fetchSeriesData: PropTypes.func.isRequired,
  fetchSecondarySeriesData: PropTypes.func.isRequired,
  deleteSecondarySeriesData: PropTypes.func.isRequired,
  fetchCrossCuttingSeriesData: PropTypes.func.isRequired,
  deleteCrossCuttingSeriesData: PropTypes.func.isRequired,
};

export default SetData;
