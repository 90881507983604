import { connect } from "react-redux";
import { selectSeriesDataTreeRadialChart } from "modules/indicators/selectors/charts/tree-radial-chart";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import Chart from "./TreeRadialComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataTreeRadialChart(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(Chart);
