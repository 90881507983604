export default [
  {
    id: 3128,
    indicatorTypeId: 4,
    geoAreaId: 26,
    partnerId: 1,
    year: 2016,
    value: 369.763671875,
  },
  {
    id: 3140,
    indicatorTypeId: 4,
    geoAreaId: 27,
    partnerId: 1,
    year: 2016,
    value: 73.1443862915039,
  },
  {
    id: 3152,
    indicatorTypeId: 4,
    geoAreaId: 33,
    partnerId: 1,
    year: 2016,
    value: 159.04556274414063,
  },
  {
    id: 3164,
    indicatorTypeId: 4,
    geoAreaId: 48,
    partnerId: 1,
    year: 2016,
    value: 532.07720947265625,
  },
  {
    id: 3172,
    indicatorTypeId: 4,
    geoAreaId: 64,
    partnerId: 1,
    year: 2016,
    value: 6.4953198432922363,
  },
  {
    id: 3184,
    indicatorTypeId: 4,
    geoAreaId: 66,
    partnerId: 1,
    year: 2016,
    value: 247.03642272949219,
  },
  {
    id: 3196,
    indicatorTypeId: 4,
    geoAreaId: 75,
    partnerId: 1,
    year: 2016,
    value: 68.358489990234375,
  },
  {
    id: 3208,
    indicatorTypeId: 4,
    geoAreaId: 76,
    partnerId: 1,
    year: 2016,
    value: 2446.389404296875,
  },
  {
    id: 3220,
    indicatorTypeId: 4,
    geoAreaId: 83,
    partnerId: 1,
    year: 2016,
    value: 5887.07763671875,
  },
  {
    id: 3232,
    indicatorTypeId: 4,
    geoAreaId: 85,
    partnerId: 1,
    year: 2016,
    value: 0.0009399999980814755,
  },
  {
    id: 3234,
    indicatorTypeId: 4,
    geoAreaId: 93,
    partnerId: 1,
    year: 2016,
    value: 3.704780101776123,
  },
  {
    id: 3240,
    indicatorTypeId: 4,
    geoAreaId: 94,
    partnerId: 1,
    year: 2016,
    value: 7.681149959564209,
  },
  {
    id: 3252,
    indicatorTypeId: 4,
    geoAreaId: 99,
    partnerId: 1,
    year: 2016,
    value: 39.280769348144531,
  },
  {
    id: 3264,
    indicatorTypeId: 4,
    geoAreaId: 101,
    partnerId: 1,
    year: 2016,
    value: 75.560379028320312,
  },
  {
    id: 3276,
    indicatorTypeId: 4,
    geoAreaId: 104,
    partnerId: 1,
    year: 2016,
    value: 11645.578125,
  },
  {
    id: 3287,
    indicatorTypeId: 4,
    geoAreaId: 109,
    partnerId: 1,
    year: 2016,
    value: 1010.7265625,
  },
  {
    id: 3299,
    indicatorTypeId: 4,
    geoAreaId: 120,
    partnerId: 1,
    year: 2016,
    value: 37.108329772949219,
  },
  {
    id: 3311,
    indicatorTypeId: 4,
    geoAreaId: 139,
    partnerId: 1,
    year: 2016,
    value: 496.1700439453125,
  },
  {
    id: 3323,
    indicatorTypeId: 4,
    geoAreaId: 143,
    partnerId: 1,
    year: 2016,
    value: 121.88442993164063,
  },
  {
    id: 3335,
    indicatorTypeId: 4,
    geoAreaId: 147,
    partnerId: 1,
    year: 2016,
    value: 330.6932373046875,
  },
  {
    id: 3339,
    indicatorTypeId: 4,
    geoAreaId: 154,
    partnerId: 1,
    year: 2016,
    value: 75.655403137207031,
  },
  {
    id: 3351,
    indicatorTypeId: 4,
    geoAreaId: 155,
    partnerId: 1,
    year: 2016,
    value: 4.1008100509643555,
  },
  {
    id: 3355,
    indicatorTypeId: 4,
    geoAreaId: 171,
    partnerId: 1,
    year: 2016,
    value: 0.75839000940322876,
  },
  {
    id: 3362,
    indicatorTypeId: 4,
    geoAreaId: 173,
    partnerId: 1,
    year: 2016,
    value: 1.1643699407577515,
  },
  {
    id: 3374,
    indicatorTypeId: 4,
    geoAreaId: 177,
    partnerId: 1,
    year: 2016,
    value: 41.573341369628906,
  },
  {
    id: 3386,
    indicatorTypeId: 4,
    geoAreaId: 183,
    partnerId: 1,
    year: 2016,
    value: 337.7890625,
  },
  {
    id: 3398,
    indicatorTypeId: 4,
    geoAreaId: 184,
    partnerId: 1,
    year: 2016,
    value: 355.2017822265625,
  },
  {
    id: 3410,
    indicatorTypeId: 4,
    geoAreaId: 199,
    partnerId: 1,
    year: 2016,
    value: 833.633056640625,
  },
  {
    id: 3422,
    indicatorTypeId: 4,
    geoAreaId: 202,
    partnerId: 1,
    year: 2016,
    value: 2901.0263671875,
  },
  {
    id: 3463,
    indicatorTypeId: 4,
    geoAreaId: 243,
    partnerId: 1,
    year: 2016,
    value: 122.39984038966941,
  },
  {
    id: 3464,
    indicatorTypeId: 4,
    geoAreaId: 248,
    partnerId: 1,
    year: 2016,
    value: 86.613893091678619,
  },
  {
    id: 3465,
    indicatorTypeId: 4,
    geoAreaId: 249,
    partnerId: 1,
    year: 2016,
    value: 1864.4721884727478,
  },
  {
    id: 3466,
    indicatorTypeId: 4,
    geoAreaId: 250,
    partnerId: 1,
    year: 2016,
    value: 9454.1371459960938,
  },
  {
    id: 3511,
    indicatorTypeId: 4,
    geoAreaId: 5,
    partnerId: 1,
    year: 2016,
    value: 3433.1035766601562,
  },
  {
    id: 3512,
    indicatorTypeId: 4,
    geoAreaId: 6,
    partnerId: 1,
    year: 2016,
    value: 12656.3046875,
  },
  {
    id: 3513,
    indicatorTypeId: 4,
    geoAreaId: 9,
    partnerId: 1,
    year: 2016,
    value: 491.64810180664062,
  },
  {
    id: 3514,
    indicatorTypeId: 4,
    geoAreaId: 12,
    partnerId: 1,
    year: 2016,
    value: 11527.62306795019,
  },
  {
    id: 3548,
    indicatorTypeId: 4,
    geoAreaId: 2,
    partnerId: 1,
    year: 2016,
    value: 491.64810180664062,
  },
  {
    id: 3549,
    indicatorTypeId: 4,
    geoAreaId: 15,
    partnerId: 1,
    year: 2016,
    value: 14960.726644610346,
  },
  {
    id: 3550,
    indicatorTypeId: 4,
    geoAreaId: 18,
    partnerId: 1,
    year: 2016,
    value: 12656.3046875,
  },
  {
    id: 3562,
    indicatorTypeId: 4,
    geoAreaId: 1,
    partnerId: 1,
    year: 2016,
    value: 28108.679433916986,
  },
  {
    id: 3574,
    indicatorTypeId: 4,
    geoAreaId: 230,
    partnerId: 1,
    year: 2016,
    value: 27097.952871416986,
  },
  {
    id: 3585,
    indicatorTypeId: 4,
    geoAreaId: 19,
    partnerId: 1,
    year: 2016,
    value: 1010.7265625,
  },
];
