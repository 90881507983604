import { createSelector } from "reselect";
import { selectSingleCountries } from "modules/indicators/selectors/indicators";

const getSeriesData = state => state.indicators.seriesData.data;

// =========== Tree radial chart ===========
export const selectSeriesDataTreeRadialChart = createSelector(
  getSeriesData,
  selectSingleCountries,
  ({ data, status }, regions) => {
    if (!data || !regions || status) return null;
    if (data.length === 0 || Object.keys(regions).length === 0) return [];

    return data.reduce(
      (acc, currentVal) => [
        ...acc,
        {
          ...currentVal,
          id: currentVal?.id,
          // source_id: currentVal.geoAreaId,
          // target_id: currentVal.partnerId,
          // source_name: regions[currentVal.geoAreaId].name,
          // target_name: regions[currentVal.partnerId].name,
          region: regions[currentVal.geoAreaId]?.region[0],
          name: regions[currentVal.geoAreaId]?.name,
          geoAreaId: currentVal?.geoAreaId,
          year: currentVal?.year,
          value: currentVal?.value,
        },
      ],
      []
    );
  }
);
