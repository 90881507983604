import React from "react";
import styled from "styled-components";
import { Alert } from "@material-ui/lab";
import { FormattedMessage } from "react-intl";

const AlertStyled = styled.div`
  flex: 1 0 auto;
`;

export default () => (
  <AlertStyled>
    <Alert severity="error">
      <FormattedMessage id="indicators.exception.no-data" defaultMessage="There is no data to visualize!" />
    </Alert>
  </AlertStyled>
);
