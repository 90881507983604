export default {
  REGION: "REGION",
  PARTNER: "PARTNER",
  PRODUCT: "PRODUCT",
  YEAR_1_POINT: "YEAR_1_POINT",
  YEAR_2_POINTS: "YEAR_2_POINTS",
  YEAR_RANGE: "YEAR_RANGE",
  GROWTH: "GROWTH",
  TARIFF: "TARIFF",
};
