import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
import es from "react-intl/locale-data/es";
import siteFrMessages from "i18n/sdg-fr.json";
import siteEsMessages from "i18n/sdg-es.json";

export const availableLanguages = [
    {
        key: "en",
        locale: "en-us",
        label: "English",
    },
    {
        key: "fr",
        locale: "fr-fr",
        label: "Français",
    },
    {
        key: "es",
        locale: "es-es",
        label: "Español",
    },
];
export const languagesData = [...en, ...fr, ...es];
export const messages = {
    fr: {...siteFrMessages},
    es: {...siteEsMessages},
};
