import { createSelector } from "reselect";
import { selectProducts, selectSingleCountries } from "modules/indicators/selectors/indicators";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import * as constants from "modules/constants";

const getSeriesData = state => state.indicators.seriesData;

// =========== Radar Chart ===========
export const selectSeriesDataRadarChart = createSelector(
  getSeriesData,
  selectProducts,
  selectSingleCountries,
  selectFilterParams,
  ({ data, status }, products, regions, { product }) => {
    if (data === null || status === constants.STATUS_LOADING) return null;

    // group by 'geoAreaId'
    const dataGrouped = data
      .map(d => ({ ...d, value: parseFloat(d.value?.toFixed(3)) }))
      .reduce((rv, x) => {
        (rv[x.geoAreaId] = rv[x.geoAreaId] || []).push(x);
        return rv;
      }, {});
    const dataGroupedClean = Object.keys(dataGrouped).map(key => dataGrouped[key]);

    // replace id by country name
    return dataGroupedClean.map(item =>
      product.map(productTypeId => {
        const data = item.find(dataPoint => dataPoint.productTypeId === productTypeId);
        if (data !== undefined) {
          const region = regions[data.geoAreaId];
          const product = products[productTypeId];

          return {
            ...data,
            regionName: region.name,
            m49Code: region.m49Code,
            name: product.type,
            isNull: false,
          };
        } else {
          const region = regions[item[0].geoAreaId];
          const product = products[productTypeId];

          return {
            regionName: region.name,
            m49Code: region.m49Code,
            name: product.type,
            isNull: false,
            value: 0,
          };
        }
      })
    );
  }
);
