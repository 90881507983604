import { INDICATORS } from "./meta-data/constants";

export const rangeIndex = [0, 0.2, 0.4, 0.6, 0.8, 1];

export const rangePercentage = {
  [INDICATORS.i10_a_1]: [0, 25, 50, 75, 85, 95],
  [INDICATORS.i10_a_i]: [0, 25, 50, 75, 85, 95],
  [INDICATORS.i17_10_1]: [0, 5, 10, 15, 25, 50],
  [INDICATORS.i17_11_1]: [0, 0.5, 1, 3, 5, 10],
  [INDICATORS.i17_11_i]: [0, 5, 10, 20, 30, 50],
  [INDICATORS.i17_11_ii]: [0, 10, 20, 40, 60, 80],
  [INDICATORS.i17_12_1]: [0, 5, 10, 15, 25, 50],
};
