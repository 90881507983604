import React from "react";
import styled from "styled-components";
import OrganizationLinks from "./OrganizationLinks";
import Navigation from "./Navigation";
import { useParams } from "react-router-dom";

const HeaderStyled = styled.header`
  display: flex;
  max-width: 1170px;
  padding: 0 0 0 15px;
  margin: auto;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 999;
  height: 79px;
`;

const Header = () => {
  const { embed } = useParams();

  if (embed === "embed") return <></>;

  return (
    <HeaderStyled>
      <OrganizationLinks />
      <Navigation />
      {/* <UserQuickAcess /> */}
    </HeaderStyled>
  );
};

export default Header;
