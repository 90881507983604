import { createSelector } from "reselect";
import { selectRegionWorld } from "./regions";

const getCurrentIndicatorCode = state => state.indicators.currentUIState.indicatorCode;
const getVisualizationCode = state => state.indicators.currentUIState.visualization;

const getEconomicRegions = state => state.metaData.economicRegions;

export const selectEconomicRegions = createSelector(
  getEconomicRegions,
  selectRegionWorld,
  getCurrentIndicatorCode,
  getVisualizationCode,
  (data, world) => {
    if (data === undefined || world === undefined) return [{ items: [] }];

    return [
      {
        items: [world, ...data],
      },
    ];
  }
);
