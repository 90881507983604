import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import { BarchartHorizontal } from "datadesign-charts-sdgtrade";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import ErrorBoundary from "modules/ui/components/ErrorBoundary";
import Skeleton from "@material-ui/lab/Skeleton";

const Chart = ({ data, chartMetaData, title }) => {
  const { embed } = useParams();
  const dataTitle = Object.keys(chartMetaData)
    .filter(d => chartMetaData[d] !== undefined && d !== "crossCutting")
    .map(d => `Growth of ${chartMetaData[d]?.seriesDescription}, base 2015`);
  if (data === null) {
    return (
      <div className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  return (
    <div className="chart">
      <ErrorBoundary>
        <BarchartHorizontal
          data={data}
          dataTitle={dataTitle} //Title for Tooltip and Legend
          xValueKey={["growth", "growthSecond"]}
          yValueKey="name" // must be a unique name otherwise it will be rendered over each other
          uniqueIDKey="id"
          title={title}
          aspectRatio={0.5} // optional
        />
      </ErrorBoundary>
      {(!embed || embed !== "embed") && <Toolbar vzData={data} />}
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  chartMetaData: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
