import { connect } from "react-redux";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { setCurrentUIState } from "modules/indicators/reducer";
import { selectMeasuresMetaData } from "modules/meta-data/selectors/measures";
import Select from "./Select";

const mapStateToProps = state => ({
  currentUIState: selectCurrentUIState(state),
  measureMetaData: selectMeasuresMetaData(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrentUIState: data => dispatch(setCurrentUIState(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Select);
