import React from "react";
import Download from "./Download";
import ShareEmbed from "./ShareEmbed";
import AddToReport from "./AddToReport";
import ExportData from "./ExportData";
import styled from "styled-components";

const ToolbarStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  background: #fff;
  z-index: 20;
  ul {
    list-style-type: none;
    display: flex;
  }
  img {
    vertical-align: sub;
    margin-right: 5px;
  }
  button {
    border: 0;
    background: transparent;
    padding: 5px 15px;
    color: #38568c;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
  }
`;

const index = props => (
  <ToolbarStyled>
    <ul>
      <li>
        <ShareEmbed />
      </li>
      <li>
        <Download />
      </li>
      <li>
        <ExportData {...props} />
      </li>
      <li>
        <AddToReport />
      </li>
    </ul>
  </ToolbarStyled>
);

export default index;
