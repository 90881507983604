import styled from "styled-components";

export const SelectionStyled = styled.div`
  .tab-content {
    display: none;
    height: 300px;
    overflow-y: scroll;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    &.active {
      display: block;
    }
  }
  .selected-items-lbl {
    position: relative;
    padding-right: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: rgb(27, 49, 89);
    overflow: hidden;
    font-size: 15px;
    letter-spacing: -0.2px;
    font-weight: 500;
    max-height: 50px;
  }

  .tab {
    font-size: 15px;
    letter-spacing: -0.2px;
  }
`;
