import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { removeChart, updateChartDescription, updateChartLayout } from "modules/reports/reducer";

const LAYOUT = {
  TEXT_DOWN: 1,
  TEXT_RIGHT: 2,
  TEXT_LEFT: 3,
};

const CloseBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  color: #ff0077;
  border: 0;
  outline: 0;
  background: transparent;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s ease-out;
  padding: 5px;
`;

const Button = styled.button`
  height: 36px;
  padding: 0 20px;
  margin-left: 5px;
  border: 1px solid #1b3159;
  border-radius: 22px;
  vertical-align: middle;
  color: #1b3159;
  background-color: #f4f7fc;
  cursor: pointer;
  opacity: 0;
`;

const ItemStyled = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed gainsboro;
  margin: 15px auto;
  cursor: move;
  width: 782px;
  background: #f4f7fc;
  &:hover {
    border: 1px dashed gray;
    ${CloseBtn},
    ${Button} {
      opacity: 1;
    }
  }
  .layout {
    position: relative;
    display: flex;
    flex-direction: column;
    textarea {
      border: 1px dashed gray;
      padding: 10px;
      margin: 15px;
      max-width: calc(100% - 30px);
    }
  }
  .layout-${LAYOUT.TEXT_RIGHT}, .layout-${LAYOUT.TEXT_LEFT} {
    flex-direction: row;
    img {
      flex: 1 0 70%;
      max-width: 70%;
      height: auto;
    }
    textarea {
      flex: 1 0 calc(30% - 30px);
      max-width: calc(30% - 30px);
      margin: 30px 15px;
    }
  }
  .layout-${LAYOUT.TEXT_LEFT} {
    textarea {
      order: -1;
    }
  }
  .text-alignment {
    position: absolute;
    top: 10px;
  }
  img {
    display: block;
    margin: auto;
  }
  textarea {
    width: 100%;
    background: #f4f7fc;
    padding: 0 5px 15px;
  }
`;

const Item = ({ id, layout, desc, img, index, moveCard }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const setLayout = layout =>
    dispatch(
      updateChartLayout({
        id: id,
        layout: layout,
      }),
    );

  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [, drag] = useDrag({
    type: "card",
    item: () => ({ id, index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <ItemStyled ref={ref} data-handler-id={handlerId}>
      <div className={`layout layout-${layout}`}>
        <div className="text-alignment">
          <Button onClick={() => setLayout(LAYOUT.TEXT_DOWN)}>Text - bottom</Button>
          <Button onClick={() => setLayout(LAYOUT.TEXT_RIGHT)}>Text - right</Button>
        </div>
        <CloseBtn>
          <FontAwesomeIcon icon={faTimesCircle} size="lg" onClick={() => dispatch(removeChart(id))}/>
        </CloseBtn>
        <img src={`data:image/png;base64,${img}`} alt="" width={780} height={516}/>
        <textarea
          value={desc}
          placeholder="[Description]"
          onChange={e => dispatch(updateChartDescription({ id: id, desc: e.target.value }))}
          cols="30"
          rows="5"
        >
          {desc}
        </textarea>
      </div>
    </ItemStyled>
  );
};

Item.propTypes = {
  id: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  layout: PropTypes.number.isRequired,
  desc: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  moveCard: PropTypes.func.isRequired,
};

export default Item;
