import React from "react";
// import PropTypes from "prop-types";
// import { IntlProvider } from "react-intl";
import styled from "styled-components";
import ITCLogo from "./images/ITC_logo_EN_Black.png";
import UNCTADLogo from "./images/unctad.png";
import WTOLogo from "./images/wto-dark.png";
import SDGLogo from "./images/sdg-logo.svg";
import { useParams } from "react-router";

const OrganizationLinksStyled = styled.div`
  flex-grow: 1;
  overflow: hidden;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      display: inline-block;
      margin-right: 15px;
      vertical-align: middle;
    }
    .sdg-logo {
      position: relative;
      padding-left: 15px;
      &:before {
        content: "";
        position: absolute;
        top: 7px;
        bottom: 7px;
        left: 0;
        background: #979797;
        opacity: 0.33;
        width: 1px;
      }
      img {
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-family: Roboto;
        font-size: 21px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.95;
        letter-spacing: normal;
        color: #000000;
      }
    }
  }
`;

const OrganizationLinks = () => {
  const { language } = useParams();
  const homeUrl =  location.search.trim() ?  `/${language}${location.search.trim()}`: `/${language}`;

  return (
    <OrganizationLinksStyled>
      <ul>
        <li>
          <a
            href={"http://www.intracen.org"}
            rel="noopener noreferrer"
            target="_blank"
            title="International Trade Centre"
          >
            <img src={ITCLogo} alt="International Trade Centre" height={45} width={108}/>
          </a>
        </li>
        <li style={{ transform: "translateY(7px)" }}>
          <a href={"https://unctad.org"} rel="noopener noreferrer" target="_blank" title="UNCTAD">
            <img src={UNCTADLogo} alt="UNCTADLogo" height={56} />
          </a>
        </li>
        <li>
          <a
            href={"https://wto.org"}
            rel="noopener noreferrer"
            target="_blank"
            title="World Trade Organization"
          >
            <img src={WTOLogo} alt="World Trade Organization" height={39} width={43} />
          </a>
        </li>
        <li className="sdg-logo">
          <a href={homeUrl}>
            <img src={SDGLogo} alt="SDG Trade Monitor" height={65} width={229} />
            <sup style={{ color: "#000" }}>&nbsp;Beta</sup>
          </a>
        </li>
      </ul>
    </OrganizationLinksStyled>
  );
}

export default OrganizationLinks;
