import { of, concat } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { addError } from "modules/ui/reducer";
import { fetchBearerToken, setBearerToken, setCmsApiRoot } from "../reducer";

export default (action$, state$, { apiConfig, ajax, timestamp }) => {
  const request = () => ({
    responseType: "json",
    method: "POST",
    url: `${apiConfig.cmsRoot}/identity-server/connect/token`,
    contentType: "application/x-www-form-urlencoded",
    body: {
      grant_type: "client_credentials",
      client_id: apiConfig.cmsClientId,
      client_secret: apiConfig.cmsClientSecret,
      scope: "squidex-api",
    },
  });

  return action$.pipe(
    ofType(fetchBearerToken),
    mergeMap(() =>
      ajax(request()).pipe(
        mergeMap(data =>
          concat(
            of(setBearerToken(data.response.access_token)),
            of(
              setCmsApiRoot({
                cmsRoot: apiConfig.cmsRoot,
              })
            )
          )
        ),
        catchError(e => [
          addError({
            timestamp: timestamp(),
            message: "cms.api-error",
            details: e,
          }),
        ])
      )
    )
  );
};
