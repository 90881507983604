import React, { useState } from "react";
import styled from "styled-components";
import { NavLink, useParams } from "react-router-dom";
import officialIcon from "./images/official-icon.svg";
import LanguageSwitcher from "../LanguageSwitcher";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { selectAllIndicatorsByCode } from "../../../meta-data/selectors/meta-data";

const NavigationStyled = styled.nav`
  ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      a {
        display: inline;
        opacity: 0.9;
        color: #000000;
        cursor: pointer;
        min-width: 130px;
      }
      > .item {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px 30px;
        &:before {
          position: absolute;
          content: "";
          background: #000000;
          height: 3px;
          left: 0;
          width: 100%;
          bottom: 0;
          opacity: 0;
          transition: 0.05s ease-out opacity;
        }
        &.hovered {
          &:before {
            opacity: 0.58;
          }
        }
      }
      &.hovered {
        > .item + .sub-menu {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .sub-menu {
    position: absolute;
    left: 0;
    width: 100%;
    background: #3d537a;
    padding: 10px 37px 50px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.22);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.05s ease-out, visibility 0.05s ease-out;
    .highlight-txt {
      color: #ffffff;
      opacity: 0.74;
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.87px;
      text-transform: uppercase;
      img {
        vertical-align: middle;
      }
    }
    table {
      border-collapse: collapse;
      color: #fff;
      width: 100%;
      a {
        display: block;
        color: #fff;
        padding: 10px;
      }
      tr {
        background: #6e8296;
        &.official-complementary {
          background: rgba(0, 0, 0, 0);
        }
        td {
          border-bottom: 2px solid rgba(255, 255, 255, 0.15);

          font-weight: 500;
          &:first-child {
            padding-left: 5px;
          }
          &:first-child {
            vertical-align: middle;
          }
        }
      }
    }
  }
`;

const Navigation = () => {
  const { language } = useParams();
  const indicators = useSelector(selectAllIndicatorsByCode, shallowEqual);
  const [shouldShow, setShouldShow] = useState(false);

  return (
    <NavigationStyled>
      <ul>
        <li>
          <div className="item">
            <NavLink to={`/${language}`}>
              <FormattedMessage id="nav.menu-item.home" defaultMessage="Home" />
            </NavLink>
          </div>
        </li>
        <li
          className={`${shouldShow ? "hovered" : ""}`}
          onMouseEnter={() => setShouldShow(true)}
          onMouseLeave={() => setShouldShow(false)}
        >
          <div className="item">
            <FormattedMessage id="nav.menu-item.indicators" defaultMessage="Indicators" />
          </div>
          <div className="sub-menu">
            <p className="highlight-txt">
              <FormattedMessage
                id="nav.menu-item.indicators-note"
                defaultMessage="Select an {img} official or complementary SDG indicator"
                values={{
                  img: <img src={officialIcon} alt="" width="16" height="16" />,
                }}
              />
            </p>
            <table>
              <tbody>
                <tr className="official-complementary">
                  <td>
                    <img src={officialIcon} alt="" width="16" height="16" />
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/2-b-1/BC/1/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>2.b.1</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/2-b-1/BC/1/${location.search.trim()}`}
                    >
                      {indicators["2.b.1"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/8-2-i/BC/6/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>8.2.(i)</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/8-2-i/BC/6/${location.search.trim()}`}
                    >
                      {indicators["8.2.(i)"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
                <tr className="official-complementary">
                  <td>
                    <img src={officialIcon} alt="" width="16" height="16" />
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/8-a-1/BC/1/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>8.a.1</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/8-a-1/BC/1/${location.search.trim()}`}
                    >
                      {indicators["8.a.1"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
                <tr className="official-complementary">
                  <td>
                    <img src={officialIcon} alt="" width="16" height="16" />
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/10-a-1/BP/7/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>10.a.1</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/10-a-1/BP/7/${location.search.trim()}`}
                    >
                      {indicators["10.a.1"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/10-a-i/BC/3/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>10.a.i</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/10-a-i/BC/3/${location.search.trim()}`}
                    >
                      {indicators["10.a.(i)"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
                <tr className="official-complementary">
                  <td>
                    <img src={officialIcon} alt="" width="16" height="16" />
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-10-1/BC/8/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>17.10.1</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-10-1/BC/8/${location.search.trim()}`}
                    >
                      {indicators["17.10.1"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
                <tr className="official-complementary">
                  <td>
                    <img src={officialIcon} alt="" width="16" height="16" />
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-11-1/BC/1/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>17.11.1</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-11-1/BC/1/${location.search.trim()}`}
                    >
                      {indicators["17.11.1"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-11-i/BC/6/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>17.11.(i)</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-11-i/BC/6/${location.search.trim()}`}
                    >
                      {indicators["17.11.(i)"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-11-ii/BC/5/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>17.11.(ii)</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-11-ii/BC/3/${location.search.trim()}`}
                    >
                      {indicators["17.11.(ii)"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
                <tr className="official-complementary">
                  <td>
                    <img src={officialIcon} alt="" width="16" height="16" />
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-12-1/BC/3/${location.search.trim()}`}
                    >
                      <FormattedMessage
                        id="misc.txt.sdg"
                        defaultMessage="SDG {value}"
                        values={{ value: <>17.12.1</> }}
                      />
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      onClick={() => setShouldShow(false)}
                      to={`/${language}/indicators/17-12-1/BC/3/${location.search.trim()}`}
                    >
                      {indicators["17.12.1"]?.longDescription}
                    </NavLink>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <div className="item">
            <NavLink to={`/${language}/reports`}>
              <FormattedMessage id="nav.menu-item.reports" defaultMessage="Reports" />
            </NavLink>
          </div>
        </li>
        <li>
          <div className="item">
            <NavLink to={`/${language}/about`}>
              <FormattedMessage id="nav.menu-item.about" defaultMessage="About" />
            </NavLink>
          </div>
        </li>
        <li>
          <div className="item">
            <LanguageSwitcher />
          </div>
        </li>
      </ul>
    </NavigationStyled>
  );
};

export default Navigation;
