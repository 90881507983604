export default (data, regionsData, productsData, unit, filterParams) =>
  data.flat().map(d => ({
    name: d.name,
    reporterCode: d.m49Code || "",
    partner: regionsData[d.partnerId]?.name || regionsData[(filterParams?.partner[0])]?.name || "",
    partnerCode: d.partnerId || filterParams?.partner[0] || "",
    product:
      +d.partnerId !== 42
        ? productsData[d.productTypeId]?.type || productsData[(filterParams?.product[0])]?.type || ""
        : "",
    productSector:
      productsData[d.productTypeId]?.category || productsData[(filterParams?.product[0])]?.category || "",
    year: d.year || filterParams?.year[0] || "",
    value: d?.growthSecond || d?.valueSecond || "",
    unit: unit || "",
  }));
