import { createSelector } from "reselect";
import { selectSingleCountries, selectProducts } from "modules/indicators/selectors/indicators";
import * as constants from "modules/constants";

const getSeriesData = state => state.indicators.seriesData;
const getDimension = state => state.indicators.currentUIState.dimension;

const getSeriesDataLineChartByProductDimension = (data, productsByKey) => {
  if (data === null) return data;
  const dataByKey = data.reduce((accumulator, currentValue) => {
    if (!accumulator[currentValue.productTypeId]) {
      accumulator[currentValue.productTypeId] = [];
    }

    if (productsByKey[currentValue.productTypeId] !== undefined) {
      const name = productsByKey[currentValue.productTypeId].type;
      accumulator[currentValue.productTypeId].push({
        ...currentValue,
        name: name,
      });
    }

    accumulator[currentValue.productTypeId] = accumulator[currentValue.productTypeId].sort(
      (a, b) => a.year < b.year
    );
    return accumulator;
  }, {});

  return Object.values(dataByKey).filter(d => d.length > 0);
};

const getSeriesDataLineChartByCountryDimension = (data, singleCountries) => {
  if (data === null) return data;

  const dataByKey = data.reduce((accumulator, currentValue) => {
    if (!accumulator[currentValue.geoAreaId]) {
      accumulator[currentValue.geoAreaId] = [];
    }

    if (singleCountries[currentValue.geoAreaId] !== undefined) {
      const name = singleCountries[currentValue.geoAreaId].name;
      const m49Code = singleCountries[currentValue.geoAreaId].m49Code;
      accumulator[currentValue.geoAreaId].push({
        ...currentValue,
        name: name,
        m49Code: m49Code,
      });
    }

    accumulator[currentValue.geoAreaId] = accumulator[currentValue.geoAreaId].sort((a, b) => a.year < b.year);
    return accumulator;
  }, {});

  return Object.values(dataByKey).filter(d => d.length > 0);
};

export const selectSeriesDataLineChart = createSelector(
  getSeriesData,
  selectProducts,
  selectSingleCountries,
  getDimension,
  ({ data, status }, products, singleCountries, currentDimension) => {
    if (status === constants.STATUS_LOADING) {
      return null;
    }

    return currentDimension === "BC"
      ? getSeriesDataLineChartByCountryDimension(data, singleCountries)
      : getSeriesDataLineChartByProductDimension(data, products);
  }
);
