import { connect } from "react-redux";
import { withRouter } from "react-router";
import { selectIndicatorMetaData } from "modules/meta-data/selectors/meta-data";
import Banner from "./Banner";

const mapStateToProps = (state, { match }) => ({
  metaData: selectIndicatorMetaData(
    state,
    match.params.code.replace(/-/g, "_").replace(/ *\([^)]*\) */g, "")
  ),
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Banner)
);
