import React, { useState } from "react";
import PropTypes from "prop-types";
import TreeItem from "@material-ui/lab/TreeItem";
import Checkbox from "@material-ui/core/Checkbox";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const TreeStyled = styled.div`
  position: relative;
  .toolbar {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9999;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
  .group-items {
    display: flex;
    padding-left: 15px;
    label {
      flex: 1 0 auto;
    }
  }
  .item {
    display: flex;
  }
  .keyword-input {
    border-bottom: 1px solid #eee;
    margin-top: 5px;
    padding: 5px 15px;
    width: 100%;
    label {
      padding-left: 15px;
    }
  }
  .deselect {
    display: inline-block;
    padding: 5px 15px;
    margin: 5px 5px;
    background: #eee;
    cursor: pointer;
  }
`;

let dataSelectionState = {};
const NestedTreBuilder = React.memo(({ data, onChangeCallback, preselectedValues /*, isSearchable*/ }) => {
  const handleValueChange = e => {
    const value = parseInt(e.target.value);
    onChangeCallback({ [value]: e.target.checked });
  };

  const handleMassUpdateState = flag => {
    const updatedDataSelectionState = Object.keys(dataSelectionState).reduce(
      (acc, currentVal) => ({ ...acc, [currentVal]: flag }),
      {}
    );
    onChangeCallback(updatedDataSelectionState);
  };

  const [keyword, setKeyword] = useState("");

  return (
    <TreeStyled>
      <div className="toolbar">
        <FormattedMessage id="indicators.search-form.enter-keyword" defaultMessage="Enter keyword">
          {text => (
            <TextField
              className="keyword-input"
              label={<FormattedMessage id="indicators.search-form.keyword" defaultMessage="Keyword" />}
              placeholder={text}
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
            />
          )}
        </FormattedMessage>
        {keyword.trim().length === 0 && (
          <div className="deselect" onClick={() => handleMassUpdateState(false)}>
            Deselect All
          </div>
        )}
      </div>
      <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
        {data.map((d, i) => (
          <div key={`ts-${i}`}>{_buildTree(d, preselectedValues, handleValueChange, keyword)}</div>
        ))}
      </TreeView>
    </TreeStyled>
  );
});

const _buildTree = (data, selectedValues, handleValueChange, keyword) => {
  const isChecked = id => selectedValues.indexOf(id) !== -1;

  if (data.id !== undefined) {
    dataSelectionState[data.id] = selectedValues.indexOf(data.id) !== -1;
  }

  if (data.items) {
    return (
      <TreeItem
        key={`it-${data.id}`}
        nodeId={`${data.id}`}
        label={
          <FormControlLabel
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <Checkbox
                checked={isChecked(data.id)}
                onChange={handleValueChange}
                disableRipple
                value={data.id}
              />
            }
            label={data.type}
          />
        }
      >
        {data.items.map(item => _buildTree(item, selectedValues, handleValueChange, keyword))}
      </TreeItem>
    );
  }
  if (
    data.type &&
    data.type
      .toLowerCase()
      .trim()
      .indexOf(keyword.toLowerCase().trim()) === -1
  ) {
    return <div key={`it-${data.id}`} />;
  }

  return (
    <TreeItem
      key={data.id}
      nodeId={`${data.id}`}
      label={
        <div className="group-items">
          <FormControlLabel
            checked={isChecked(data.id)}
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={<Checkbox onChange={handleValueChange} disableRipple value={data.id} />}
            label={data.type}
          />
        </div>
      }
    />
  );
};

NestedTreBuilder.propTypes = {
  data: PropTypes.array.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  preselectedValues: PropTypes.array.isRequired,
  isSearchable: PropTypes.bool.isRequired,
};

export default React.memo(NestedTreBuilder);
