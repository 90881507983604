export default (keys, dataSource) =>
  Object.keys(keys).reduce((acc, key) => {
    if (key === "growth") {
      return {
        ...acc,
        [key]: "BasicGrowth2015",
      };
    }
    return {
      ...acc,
      [key]: dataSource[key],
    };
  }, {});
