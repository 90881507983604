export default [
  {
    id: 5314,
    indicatorTypeId: 1,
    geoAreaId: 1,
    year: 2016,
    value: 117.18079877504097,
    growthCommit: 298.6821096253527,
    growthDisb: -64.3205120752708,
  },
  {
    id: 5508,
    indicatorTypeId: 1,
    geoAreaId: 11,
    year: 2016,
    value: 0.5807399999999999,
    growthCommit: 1.7138899999999997,
    growthDisb: -0.55241,
  },
  {
    id: 5531,
    indicatorTypeId: 1,
    geoAreaId: 12,
    year: 2016,
    value: 115.89666560639542,
    growthCommit: 231.6961398746197,
    growthDisb: 0.09719133817115733,
  },
  {
    id: 5554,
    indicatorTypeId: 1,
    geoAreaId: 13,
    year: 2016,
    value: 0.7033931686455461,
    growthCommit: 1.8444018773945157,
    growthDisb: -0.4376155401034234,
  },
  {
    id: 5600,
    indicatorTypeId: 1,
    geoAreaId: 15,
    year: 2016,
    value: 115.89666560639542,
    growthCommit: 295.12381774795824,
    growthDisb: -63.330486535167395,
  },
  {
    id: 5618,
    indicatorTypeId: 1,
    geoAreaId: 16,
    year: 2016,
    value: 0.7033931686455461,
    growthCommit: 1.8444018773945157,
    growthDisb: -0.4376155401034234,
  },
  {
    id: 5641,
    indicatorTypeId: 1,
    geoAreaId: 17,
    year: 2016,
    value: 0.5807399999999999,
    growthCommit: 1.7138899999999997,
    growthDisb: -0.55241,
  },
  {
    id: 5687,
    indicatorTypeId: 1,
    geoAreaId: 19,
    year: 2016,
    value: 0.7033931686455461,
    growthCommit: 1.8444018773945157,
    growthDisb: -0.4376155401034234,
  },
  {
    id: 5937,
    indicatorTypeId: 1,
    geoAreaId: 100,
    year: 2016,
    value: 0.5807399999999999,
    name: "Israel",
    value2015: 1.1331499999999999,
    value2016: 0.5807399999999999,
    growthCommit: 1.7138899999999997,
    growthDisb: -0.55241,
  },
  {
    id: 5962,
    indicatorTypeId: 1,
    geoAreaId: 129,
    year: 2016,
    value: 0.7033931686455461,
    name: "Mauritius",
    value2015: 1.1410087087489695,
    value2016: 0.7033931686455461,
    growthCommit: 1.8444018773945157,
    growthDisb: -0.4376155401034234,
  },
  {
    id: 6032,
    indicatorTypeId: 1,
    geoAreaId: 147,
    year: 2016,
    value: 19.821428571428573,
    name: "Norway",
    value2015: 16.356646825396826,
    value2016: 19.821428571428573,
    growthCommit: 36.1780753968254,
    growthDisb: 3.464781746031747,
  },
  {
    id: 6137,
    indicatorTypeId: 1,
    geoAreaId: 184,
    year: 2016,
    value: 96.07523703496685,
    name: "Switzerland",
    value2015: 99.44282744282744,
    value2016: 96.07523703496685,
    growthCommit: 195.51806447779427,
    growthDisb: -3.3675904078605896,
  },
  {
    id: 6257,
    indicatorTypeId: 1,
    geoAreaId: 230,
    year: 2016,
    value: 116.47740560639542,
    growthCommit: 296.83770774795823,
    growthDisb: -63.88289653516739,
  },
  {
    id: 6299,
    indicatorTypeId: 1,
    geoAreaId: 232,
    year: 2016,
    value: 0.7033931686455461,
    growthCommit: 1.8444018773945157,
    growthDisb: -0.4376155401034234,
  },
  {
    id: 6342,
    indicatorTypeId: 1,
    geoAreaId: 234,
    year: 2016,
    value: 0.7033931686455461,
    growthCommit: 1.8444018773945157,
    growthDisb: -0.4376155401034234,
  },
  {
    id: 6394,
    indicatorTypeId: 1,
    geoAreaId: 238,
    year: 2016,
    value: 0.7033931686455461,
    growthCommit: 1.8444018773945157,
    growthDisb: -0.4376155401034234,
  },
  {
    id: 6476,
    indicatorTypeId: 1,
    geoAreaId: 247,
    year: 2016,
    value: 0.5807399999999999,
    growthCommit: 1.7138899999999997,
    growthDisb: -0.55241,
  },
  {
    id: 6511,
    indicatorTypeId: 1,
    geoAreaId: 249,
    year: 2016,
    value: 19.821428571428573,
    growthCommit: 36.1780753968254,
    growthDisb: 3.464781746031747,
  },
  {
    id: 6534,
    indicatorTypeId: 1,
    geoAreaId: 250,
    year: 2016,
    value: 96.07523703496685,
    growthCommit: 195.51806447779427,
    growthDisb: -3.3675904078605896,
  },
];
