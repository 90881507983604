import { connect } from "react-redux";
import Filter from "./Filter";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { selectSingleCountriesRegions } from "modules/meta-data/selectors/single-countries";
import { selectEconomicRegions } from "modules/meta-data/selectors/economic-regions";
import { selectGeographicRegions } from "modules/meta-data/selectors/geographic-regions";
import { selectProducts } from "modules/meta-data/selectors/products";
import { selectRegions } from "modules/meta-data/selectors/regions";
import { setFilterParams, fetchFilterParamsProduct } from "modules/indicators/reducer";

const mapStateToProps = state => ({
  params: selectFilterParams(state),
  currentUIState: selectCurrentUIState(state),
  singleCountries: selectSingleCountriesRegions(state),
  economicRegions: selectEconomicRegions(state),
  geographicRegions: selectGeographicRegions(state),
  products: selectProducts(state),
  regions: selectRegions(state),
});

const mapDispatchToProps = dispatch => ({
  setFilterParams: data => 
    dispatch(setFilterParams(data)),
  fetchFilterParamsProduct: data => dispatch(fetchFilterParamsProduct(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter);
