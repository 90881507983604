
import { addError } from "modules/ui/reducer";
import { ofType } from "redux-observable";
import { of, concat } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { fetchDataAvailability, setDataAvailability } from "../reducer";
import * as constants from "modules/constants";

const request = (apiConfig, measure) => ({
  responseType: "json",
  method: "GET",
  mode: "cors",
  crossDomain: true,
  url: `${apiConfig.apiRoot}/Metadata/available-data-pairs?indicator=${measure}`,
  headers: {
    "Ocp-Apim-Subscription-Key": apiConfig.OcpApimSubscriptionKey,
  },
});

const handleResponses = data => of(setDataAvailability(data));

const handleErrors = (error, action, timestamp) => [
  addError({
    timestamp: timestamp(),
    message: "meta-data.available-data-pairs.api-error",
    details: error,
    retryAction: action,
  }),
];

export default (action$, state$, { ajax, apiConfig, timestamp }) =>
  action$.pipe(
    ofType(fetchDataAvailability),
    filter(action => action.payload !== ""),
    mergeMap(({ payload }) =>
      concat(
        of(setDataAvailability({ [payload]: { status: constants.STATUS_LOADING } })),
        ajax(request(apiConfig, payload)).pipe(
          mergeMap(({ response }) => handleResponses({ [payload]: { data: response } })),
          catchError(error => handleErrors(error, action$.value, timestamp))
        )
      )
    )
  );
