export default (data, regionsData, productsData, unit, filterParams) => {
  let processedData = data;
  const fixedYear = filterParams?.year.length === 2 ? filterParams?.year[0] : null;

  if (fixedYear !== null) {
    processedData = processedData.reduce(
      (result, d) => [
        ...result,
        {
          ...d,
        },
        {
          ...d,
          year: fixedYear,
          value: d.valueFixedYear,
        },
      ],
      []
    );
  }

  return processedData.flat().map(d => ({
    name: d.name,
    reporterCode: d.m49Code || "",
    partner: regionsData[d.partnerId]?.name || regionsData[(filterParams?.partner[0])]?.name || "",
    partnerCode: d?.partnerId || filterParams?.partner[0] || "",
    product:
      +d.partnerId !== 42
        ? productsData[d.productTypeId]?.type || productsData[(filterParams?.product[0])]?.type || ""
        : "",
    productSector:
      productsData[d.productTypeId]?.category || productsData[(filterParams?.product[0])]?.category || "",
    year: d.year || filterParams?.year[0] || "",
    value: d.value || d?.growth ? d.value || d?.growth : "",
    unit: unit || "",
  }));
};
