import React, { Fragment } from "react";
import { LineChart } from "datadesign-charts-sdgtrade";
import lineChartAll from "./data/191119_wto_agr_prd_xsubdy/line-chart-all";
import regions from "./data/region_by_id";
import { FormattedMessage } from "react-intl";

const LineChartModule = () => {
  // const [{ scaleType }, dispatch] = useStateValue();
  const handleScaleChange = /*option*/ () => {
    // dispatch({ type: 'SET_SCALE_TYPE', payload: option.id });
  };

  // replace id by country name
  const dataModified = [];
  lineChartAll.forEach(item => {
    const filteredItem = item.filter(e => +e.geoAreaId !== 296);
    const filteredItemReturn = filteredItem.map(d => {
      const region = regions.find(k => +k.id === +d.geoAreaId);
      d.name = region ? region.name : "";
      d.region = region ? region.subregionID : "";
      return d;
    });
    if (filteredItemReturn.length > 0) dataModified.push(filteredItemReturn);
  });

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.line-chart"
        defaultMessage="Agriculture Export subsidies (millions of current United States dollars)"
      >
        {text => (
          <LineChart
            data={dataModified}
            xValueKey="year"
            yValueKey="value"
            uniqueIDKey="id"
            nameKey="name"
            scaleType="LINEAR"
            handleScaleChange={handleScaleChange}
            yUnit="USD"
            title={text}
            aspectRatio={0.7}
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};

export default LineChartModule;
