import React, { Fragment } from "react";
import { TreeRadial } from "datadesign-charts-sdgtrade";
import AG_PRD_XSUBDY_2015 from "./data/AG_PRD_XSUBDY_2015";
import regions from "./data/region_by_id";
import { FormattedMessage } from "react-intl";

const TreeRadialModule = () => {
  // replace id by country name
  const dataFiltered = AG_PRD_XSUBDY_2015.map(item => {
    const country = regions.find(k => +k.id === +item.geoAreaId);
    item.name = country ? country.name : "";
    item.region = country ? country.region : "";
    return item;
  });

  // // show random data for all countries for testing
  // const dataModified = regions.map(d => {
  //   let item = {}
  //   item.name = d.name;
  //   item.id = d.id;
  //   item.value = Math.floor((Math.random() * 100) + 1);
  //   item.region = d.region;
  //   return item;
  // });
  // const dataModifiedFiltered = dataModified.filter(d=>d.value > 95)

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.map-force-chart"
        defaultMessage="Agriculture Export subsidies (millions of current United States dollars)"
      >
        {text => (
          <TreeRadial
            data={dataFiltered}
            radiusValueKey="value"
            colorValueKey="region" // should be the subregion name for countries,
            uniqueIDKey="name"
            groupKey="region" // they key to group the hierarchy
            nameKey="name" //must be the name of the country to match with geo map
            title={text}
            aspectRatio={0.5} //optional
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};

export default TreeRadialModule;
