import { connect } from "react-redux";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { selectFilterDataYear } from "modules/indicators/selectors/filter-data";
import Charts from "./Charts";

const mapStateToProps = state => ({
  currentUIState: selectCurrentUIState(state),
  filterDataYear: selectFilterDataYear(state),
});

export default connect(
  mapStateToProps,
  null
)(Charts);
