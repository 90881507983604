export default {
  AG_PRD_XSUBDY: "AG_PRD_XSUBDY",
  TM_TRF_ZERO: "TM_TRF_ZERO_EXP",
  DC_TOF_TRDCMDL: "DC_TOF_TRDCMDL",
  DC_TOF_TRDCML: "DC_TOF_TRDCML",
  DC_TOF_TRDDBMDL: "DC_TOF_TRDDBMDL",
  DC_TOF_TRDDBML: "DC_TOF_TRDDBML",
  TM_TAX_WWTAV: "TM_TAX_WWTAV",
  TX_EXP_GBMRCH: "TX_EXP_GBMRCH",
  TX_EXP_GBSVR: "TX_EXP_GBSVR",
  TX_IMP_GBMRCH: "TX_IMP_GBMRCH",
  TX_IMP_GBSVR: "TX_IMP_GBSVR",
  TM_TAX_ATRFD: "TM_TAX_ATRFD",
  SDG_GDP: "SDG_GDP",
  SDG_TiS_Exp: "SDG_TiS_Exp",
  SDG_TMT_Exp: "SDG_TMT_Exp",
  SDG_TMT_EXP_Ratio: "SDG_TMT_EXP_Ratio",
  SDG_TIS_EXP_Ratio: "SDG_TIS_EXP_Ratio",

  // update 16/1/2020
  PR_EXP_DIVM: "PR_EXP_DIVM",
  PR_EXP_DIVP: "PR_EXP_DIVP",
  TM_EXP_DTFR: "TM_EXP_DTFR",
  SDG_TMT_Exp_Pr: "SDG_TMT_Exp_Pr",

  // update 27/1/2020
  TM_TAX_WWTAV_Pref: "TM_TAX_WWTAV_Pref",
  TM_TAX_WWTAV_MFN: "TM_TAX_WWTAV_MFN",
  TM_TAX_ATRFD_MFN: "TM_TAX_ATRFD_MFN",
  TM_TAX_ATRFD_Pref: "TM_TAX_ATRFD_Pref",
  M17: "TM_TRF_ZERO_ALL",
};
