import React, { Fragment } from "react";
import { AreaChart } from "datadesign-charts-sdgtrade";
import TM_TRF_ZERO_Asia from "./data/TM_TRF_ZERO_centralasia";
import products from "./data/products_by_id";
import { FormattedMessage } from "react-intl";

const AreaChartModule = () => {
  const handleScaleChange = /*option*/ () => {
  };

  const dataFiltered = TM_TRF_ZERO_Asia.filter(
    d => d.productTypeId !== 42 && (d.year === 2005 || d.year === 2010 || d.year === 2015)
  ); //exclude all
  const productsFlatData = products.reduce((acc, d) => acc.concat(d.items), []);

  dataFiltered.map(d => {
    const product = productsFlatData.find(k => +k.id === +d.productTypeId);
    d.productName = product.type;
    return d;
  });

  return (
    <Fragment>
      <FormattedMessage
        id="demo.chart.title.area-chart"
        defaultMessage="Proportion of tariff lines applied to imports with zero-tariff (%) for Central Asia"
      >
        {text => (
          <AreaChart
            data={dataFiltered}
            xValueKey="year"
            yValueKey="value"
            uniqueIDKey="id"
            nameKey="productName"
            visualizationType="STACKED"
            handleScaleChange={handleScaleChange}
            yUnit="USD"
            title={text}
            aspectRatio={0.5}
          />
        )}
      </FormattedMessage>
    </Fragment>
  );
};
export default AreaChartModule;
