import styled from "styled-components";
import { Box } from "@rebass/grid";

const Container = styled(Box)`
  width: 100%;
  max-width: ${props => props.theme.containerWidth}px;
`;
Container.defaultProps = {
  mx: "auto",
};

export default Container;
