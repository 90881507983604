export default [
  {
    id: 105,
    m49Code: "398",
    name: "Kazakhstan",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Central Asia",
  },
  {
    id: 111,
    m49Code: "417",
    name: "Kyrgyzstan",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Central Asia",
  },
  {
    id: 186,
    m49Code: "762",
    name: "Tajikistan",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Central Asia",
  },
  {
    id: 194,
    m49Code: "795",
    name: "Turkmenistan",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Central Asia",
  },
  {
    id: 206,
    m49Code: "860",
    name: "Uzbekistan",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Central Asia",
  },
  {
    id: 20,
    m49Code: "4",
    name: "Afghanistan",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Southern Asia",
  },
  {
    id: 30,
    m49Code: "50",
    name: "Bangladesh",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Southern Asia",
  },
  {
    id: 34,
    m49Code: "64",
    name: "Bhutan",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Southern Asia",
  },
  {
    id: 95,
    m49Code: "356",
    name: "India",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Southern Asia",
  },
  {
    id: 97,
    m49Code: "364",
    name: "Iran (Islamic Republic of)",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Southern Asia",
  },
  {
    id: 125,
    m49Code: "462",
    name: "Maldives",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Southern Asia",
  },
  {
    id: 138,
    m49Code: "524",
    name: "Nepal",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Southern Asia",
  },
  {
    id: 148,
    m49Code: "586",
    name: "Pakistan",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Southern Asia",
  },
  {
    id: 52,
    m49Code: "144",
    name: "Sri Lanka",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Southern Asia",
  },
  {
    id: 55,
    m49Code: "156",
    name: "China",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Asia",
  },
  {
    id: 92,
    m49Code: "344",
    name: "China, Hong Kong Special Administrative Region",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Asia",
  },
  {
    id: 121,
    m49Code: "446",
    name: "China, Macao Special Administrative Region",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Asia",
  },
  {
    id: 108,
    m49Code: "408",
    name: "Democratic People's Republic of Korea",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Asia",
  },
  {
    id: 104,
    m49Code: "392",
    name: "Japan",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Asia",
  },
  {
    id: 131,
    m49Code: "496",
    name: "Mongolia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Eastern Asia",
  },
  {
    id: 109,
    m49Code: "410",
    name: "Republic of Korea",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Asia",
  },
  {
    id: 295,
    m49Code: "158",
    name: "Taiwan Province of China",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Asia",
  },
  {
    id: 41,
    m49Code: "96",
    name: "Brunei Darussalam",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South-Eastern Asia",
  },
  {
    id: 46,
    m49Code: "116",
    name: "Cambodia",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "South-Eastern Asia",
  },
  {
    id: 96,
    m49Code: "360",
    name: "Indonesia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South-Eastern Asia",
  },
  {
    id: 112,
    m49Code: "418",
    name: "Lao People's Democratic Republic",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "South-Eastern Asia",
  },
  {
    id: 124,
    m49Code: "458",
    name: "Malaysia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South-Eastern Asia",
  },
  {
    id: 43,
    m49Code: "104",
    name: "Myanmar",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "South-Eastern Asia",
  },
  {
    id: 153,
    m49Code: "608",
    name: "Philippines",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South-Eastern Asia",
  },
  {
    id: 170,
    m49Code: "702",
    name: "Singapore",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "South-Eastern Asia",
  },
  {
    id: 187,
    m49Code: "764",
    name: "Thailand",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South-Eastern Asia",
  },
  {
    id: 157,
    m49Code: "626",
    name: "Timor-Leste",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: true,
    region: "South-Eastern Asia",
  },
  {
    id: 172,
    m49Code: "704",
    name: "Viet Nam",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South-Eastern Asia",
  },
  {
    id: 45,
    m49Code: "112",
    name: "Belarus",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 42,
    m49Code: "100",
    name: "Bulgaria",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 64,
    m49Code: "203",
    name: "Czechia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 93,
    m49Code: "348",
    name: "Hungary",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 154,
    m49Code: "616",
    name: "Poland",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 132,
    m49Code: "498",
    name: "Republic of Moldova",
    isDeveloped: true,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 160,
    m49Code: "642",
    name: "Romania",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 161,
    m49Code: "643",
    name: "Russian Federation",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 171,
    m49Code: "703",
    name: "Slovakia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 196,
    m49Code: "804",
    name: "Ukraine",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 275,
    m49Code: "248",
    name: "Åland Islands",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 200,
    m49Code: "830",
    name: "Channel Islands",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 66,
    m49Code: "208",
    name: "Denmark",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 73,
    m49Code: "233",
    name: "Estonia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 268,
    m49Code: "234",
    name: "Faroe Islands",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 75,
    m49Code: "246",
    name: "Finland",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 262,
    m49Code: "831",
    name: "Guernsey",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 94,
    m49Code: "352",
    name: "Iceland",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 99,
    m49Code: "372",
    name: "Ireland",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 261,
    m49Code: "833",
    name: "Isle of Man",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 276,
    m49Code: "832",
    name: "Jersey",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 115,
    m49Code: "428",
    name: "Latvia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 119,
    m49Code: "440",
    name: "Lithuania",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 147,
    m49Code: "578",
    name: "Norway",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 299,
    m49Code: "579",
    name: "Norway, Svalbard and Jan Mayen Islands",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 305,
    m49Code: "680",
    name: "Sark",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 286,
    m49Code: "744",
    name: "Svalbard and Jan Mayen Islands",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 183,
    m49Code: "752",
    name: "Sweden",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 199,
    m49Code: "826",
    name: "United Kingdom of Great Britain and Northern Ireland",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 21,
    m49Code: "8",
    name: "Albania",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 291,
    m49Code: "20",
    name: "Andorra",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 36,
    m49Code: "70",
    name: "Bosnia and Herzegovina",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 61,
    m49Code: "191",
    name: "Croatia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 259,
    m49Code: "292",
    name: "Gibraltar",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 85,
    m49Code: "300",
    name: "Greece",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 263,
    m49Code: "336",
    name: "Holy See",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 101,
    m49Code: "380",
    name: "Italy",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 127,
    m49Code: "470",
    name: "Malta",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 133,
    m49Code: "499",
    name: "Montenegro",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 197,
    m49Code: "807",
    name: "North Macedonia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 155,
    m49Code: "620",
    name: "Portugal",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 265,
    m49Code: "674",
    name: "San Marino",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 168,
    m49Code: "688",
    name: "Serbia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 173,
    m49Code: "705",
    name: "Slovenia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 177,
    m49Code: "724",
    name: "Spain",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 302,
    m49Code: "891",
    name: "Yugoslavia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 27,
    m49Code: "40",
    name: "Austria",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 33,
    m49Code: "56",
    name: "Belgium",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 76,
    m49Code: "250",
    name: "France",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 297,
    m49Code: "251",
    name: "France-Monaco",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 83,
    m49Code: "276",
    name: "Germany",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 254,
    m49Code: "438",
    name: "Liechtenstein",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 120,
    m49Code: "442",
    name: "Luxembourg",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 293,
    m49Code: "492",
    name: "Monaco",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 139,
    m49Code: "528",
    name: "Netherlands",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 184,
    m49Code: "756",
    name: "Switzerland",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 300,
    m49Code: "757",
    name: "Switzerland, Liechtenstein",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Europe",
  },
  {
    id: 283,
    m49Code: "60",
    name: "Bermuda",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern America",
  },
  {
    id: 48,
    m49Code: "124",
    name: "Canada",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern America",
  },
  {
    id: 260,
    m49Code: "304",
    name: "Greenland",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern America",
  },
  {
    id: 271,
    m49Code: "666",
    name: "Saint Pierre and Miquelon",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern America",
  },
  {
    id: 202,
    m49Code: "840",
    name: "United States of America",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern America",
  },
  {
    id: 301,
    m49Code: "842",
    name: "United States, Puerto Rico, United States Virgin Islands",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern America",
  },
  {
    id: 270,
    m49Code: "660",
    name: "Anguilla",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 211,
    m49Code: "28",
    name: "Antigua and Barbuda",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 256,
    m49Code: "533",
    name: "Aruba",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 28,
    m49Code: "44",
    name: "Bahamas",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 32,
    m49Code: "52",
    name: "Barbados",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 292,
    m49Code: "535",
    name: "Bonaire, Sint Eustatius and Saba",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 252,
    m49Code: "92",
    name: "British Virgin Islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 273,
    m49Code: "136",
    name: "Cayman Islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Caribbean",
  },
  {
    id: 62,
    m49Code: "192",
    name: "Cuba",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 279,
    m49Code: "531",
    name: "Curaçao",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 213,
    m49Code: "212",
    name: "Dominica",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 67,
    m49Code: "214",
    name: "Dominican Republic",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 215,
    m49Code: "308",
    name: "Grenada",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 289,
    m49Code: "312",
    name: "Guadeloupe",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Caribbean",
  },
  {
    id: 90,
    m49Code: "332",
    name: "Haiti",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 103,
    m49Code: "388",
    name: "Jamaica",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 266,
    m49Code: "474",
    name: "Martinique",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Caribbean",
  },
  {
    id: 217,
    m49Code: "500",
    name: "Montserrat",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 298,
    m49Code: "530",
    name: "Netherlands Antilles",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 158,
    m49Code: "630",
    name: "Puerto Rico",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 285,
    m49Code: "652",
    name: "Saint Barthélemy",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Caribbean",
  },
  {
    id: 255,
    m49Code: "659",
    name: "Saint Kitts and Nevis",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 163,
    m49Code: "662",
    name: "Saint Lucia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 278,
    m49Code: "663",
    name: "Saint Martin (French Part)",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Caribbean",
  },
  {
    id: 164,
    m49Code: "670",
    name: "Saint Vincent and the Grenadines",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 272,
    m49Code: "534",
    name: "Sint Maarten (Dutch part)",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 190,
    m49Code: "780",
    name: "Trinidad and Tobago",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 257,
    m49Code: "796",
    name: "Turks and Caicos Islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Caribbean",
  },
  {
    id: 203,
    m49Code: "850",
    name: "United States Virgin Islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Caribbean",
  },
  {
    id: 39,
    m49Code: "84",
    name: "Belize",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Central America",
  },
  {
    id: 60,
    m49Code: "188",
    name: "Costa Rica",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Central America",
  },
  {
    id: 69,
    m49Code: "222",
    name: "El Salvador",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Central America",
  },
  {
    id: 87,
    m49Code: "320",
    name: "Guatemala",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Central America",
  },
  {
    id: 91,
    m49Code: "340",
    name: "Honduras",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Central America",
  },
  {
    id: 130,
    m49Code: "484",
    name: "Mexico",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Central America",
  },
  {
    id: 144,
    m49Code: "558",
    name: "Nicaragua",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Central America",
  },
  {
    id: 149,
    m49Code: "591",
    name: "Panama",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Central America",
  },
  {
    id: 25,
    m49Code: "32",
    name: "Argentina",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 35,
    m49Code: "68",
    name: "Bolivia (Plurinational State of)",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 304,
    m49Code: "74",
    name: "Bouvet Island",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 38,
    m49Code: "76",
    name: "Brazil",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 54,
    m49Code: "152",
    name: "Chile",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 56,
    m49Code: "170",
    name: "Colombia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 68,
    m49Code: "218",
    name: "Ecuador",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 251,
    m49Code: "238",
    name: "Falkland Islands (Malvinas)",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 274,
    m49Code: "254",
    name: "French Guiana",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 89,
    m49Code: "328",
    name: "Guyana",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "South America",
  },
  {
    id: 151,
    m49Code: "600",
    name: "Paraguay",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 152,
    m49Code: "604",
    name: "Peru",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 264,
    m49Code: "239",
    name: "South Georgia and the South Sandwich Islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 181,
    m49Code: "740",
    name: "Suriname",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "South America",
  },
  {
    id: 205,
    m49Code: "858",
    name: "Uruguay",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 207,
    m49Code: "862",
    name: "Venezuela (Bolivarian Republic of)",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "South America",
  },
  {
    id: 22,
    m49Code: "12",
    name: "Algeria",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern Africa",
  },
  {
    id: 198,
    m49Code: "818",
    name: "Egypt",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern Africa",
  },
  {
    id: 118,
    m49Code: "434",
    name: "Libya",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern Africa",
  },
  {
    id: 134,
    m49Code: "504",
    name: "Morocco",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern Africa",
  },
  {
    id: 179,
    m49Code: "729",
    name: "Sudan",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Northern Africa",
  },
  {
    id: 303,
    m49Code: "736",
    name: "Sudan, former",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Northern Africa",
  },
  {
    id: 192,
    m49Code: "788",
    name: "Tunisia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern Africa",
  },
  {
    id: 180,
    m49Code: "732",
    name: "Western Sahara",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Northern Africa",
  },
  {
    id: 31,
    m49Code: "51",
    name: "Armenia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 24,
    m49Code: "31",
    name: "Azerbaijan",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 29,
    m49Code: "48",
    name: "Bahrain",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 63,
    m49Code: "196",
    name: "Cyprus",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 80,
    m49Code: "268",
    name: "Georgia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 98,
    m49Code: "368",
    name: "Iraq",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 100,
    m49Code: "376",
    name: "Israel",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 106,
    m49Code: "400",
    name: "Jordan",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 110,
    m49Code: "414",
    name: "Kuwait",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 113,
    m49Code: "422",
    name: "Lebanon",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 136,
    m49Code: "512",
    name: "Oman",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 159,
    m49Code: "634",
    name: "Qatar",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 166,
    m49Code: "682",
    name: "Saudi Arabia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 82,
    m49Code: "275",
    name: "State of Palestine",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 185,
    m49Code: "760",
    name: "Syrian Arab Republic",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 193,
    m49Code: "792",
    name: "Turkey",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 191,
    m49Code: "784",
    name: "United Arab Emirates",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 209,
    m49Code: "887",
    name: "Yemen",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Western Asia",
  },
  {
    id: 26,
    m49Code: "36",
    name: "Australia",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Australia and New Zealand",
  },
  {
    id: 277,
    m49Code: "162",
    name: "Christmas Island",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Australia and New Zealand",
  },
  {
    id: 288,
    m49Code: "166",
    name: "Cocos (Keeling) Islands",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Australia and New Zealand",
  },
  {
    id: 267,
    m49Code: "334",
    name: "Heard Island and McDonald Islands",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Australia and New Zealand",
  },
  {
    id: 143,
    m49Code: "554",
    name: "New Zealand",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Australia and New Zealand",
  },
  {
    id: 269,
    m49Code: "574",
    name: "Norfolk Island",
    isDeveloped: true,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Australia and New Zealand",
  },
  {
    id: 280,
    m49Code: "16",
    name: "American Samoa",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 212,
    m49Code: "184",
    name: "Cook Islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 74,
    m49Code: "242",
    name: "Fiji",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 77,
    m49Code: "258",
    name: "French Polynesia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 86,
    m49Code: "316",
    name: "Guam",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 214,
    m49Code: "296",
    name: "Kiribati",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 221,
    m49Code: "584",
    name: "Marshall Islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 220,
    m49Code: "583",
    name: "Micronesia (Federated States of)",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 218,
    m49Code: "520",
    name: "Nauru",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 140,
    m49Code: "540",
    name: "New Caledonia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 219,
    m49Code: "570",
    name: "Niue",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 281,
    m49Code: "580",
    name: "Northern Mariana Islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 222,
    m49Code: "585",
    name: "Palau",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 150,
    m49Code: "598",
    name: "Papua New Guinea",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 282,
    m49Code: "612",
    name: "Pitcairn",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 208,
    m49Code: "882",
    name: "Samoa",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 40,
    m49Code: "90",
    name: "Solomon Islands",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 225,
    m49Code: "772",
    name: "Tokelau",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 189,
    m49Code: "776",
    name: "Tonga",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 226,
    m49Code: "798",
    name: "Tuvalu",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 294,
    m49Code: "581",
    name: "United States minor outlying islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 142,
    m49Code: "548",
    name: "Vanuatu",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: true,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 227,
    m49Code: "876",
    name: "Wallis and Futuna Islands",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Oceania (exc. Australia and New Zealand)",
  },
  {
    id: 258,
    m49Code: "86",
    name: "British Indian Ocean Territory",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 44,
    m49Code: "108",
    name: "Burundi",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 57,
    m49Code: "174",
    name: "Comoros",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: true,
    region: "Eastern Africa",
  },
  {
    id: 78,
    m49Code: "262",
    name: "Djibouti",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 72,
    m49Code: "232",
    name: "Eritrea",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 71,
    m49Code: "231",
    name: "Ethiopia",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 290,
    m49Code: "260",
    name: "French Southern and Antarctic Territories",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 107,
    m49Code: "404",
    name: "Kenya",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 122,
    m49Code: "450",
    name: "Madagascar",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 123,
    m49Code: "454",
    name: "Malawi",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 129,
    m49Code: "480",
    name: "Mauritius",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Eastern Africa",
  },
  {
    id: 253,
    m49Code: "175",
    name: "Mayotte",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 135,
    m49Code: "508",
    name: "Mozambique",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 287,
    m49Code: "638",
    name: "Réunion",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 162,
    m49Code: "646",
    name: "Rwanda",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 224,
    m49Code: "690",
    name: "Seychelles",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Eastern Africa",
  },
  {
    id: 174,
    m49Code: "706",
    name: "Somalia",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 178,
    m49Code: "728",
    name: "South Sudan",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 195,
    m49Code: "800",
    name: "Uganda",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 201,
    m49Code: "834",
    name: "United Republic of Tanzania",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 210,
    m49Code: "894",
    name: "Zambia",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 176,
    m49Code: "716",
    name: "Zimbabwe",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Eastern Africa",
  },
  {
    id: 23,
    m49Code: "24",
    name: "Angola",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Middle Africa",
  },
  {
    id: 47,
    m49Code: "120",
    name: "Cameroon",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Middle Africa",
  },
  {
    id: 50,
    m49Code: "140",
    name: "Central African Republic",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Middle Africa",
  },
  {
    id: 53,
    m49Code: "148",
    name: "Chad",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Middle Africa",
  },
  {
    id: 58,
    m49Code: "178",
    name: "Congo",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Middle Africa",
  },
  {
    id: 59,
    m49Code: "180",
    name: "Democratic Republic of the Congo",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Middle Africa",
  },
  {
    id: 70,
    m49Code: "226",
    name: "Equatorial Guinea",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Middle Africa",
  },
  {
    id: 79,
    m49Code: "266",
    name: "Gabon",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Middle Africa",
  },
  {
    id: 165,
    m49Code: "678",
    name: "Sao Tome and Principe",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: true,
    region: "Middle Africa",
  },
  {
    id: 37,
    m49Code: "72",
    name: "Botswana",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Southern Africa",
  },
  {
    id: 182,
    m49Code: "748",
    name: "Eswatini",
    isDeveloped: false,
    isLDC: false,
    isLLDC: true,
    isSIDS: false,
    region: "Southern Africa",
  },
  {
    id: 114,
    m49Code: "426",
    name: "Lesotho",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Southern Africa",
  },
  {
    id: 137,
    m49Code: "516",
    name: "Namibia",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Southern Africa",
  },
  {
    id: 175,
    m49Code: "710",
    name: "South Africa",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Southern Africa",
  },
  {
    id: 65,
    m49Code: "204",
    name: "Benin",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 204,
    m49Code: "854",
    name: "Burkina Faso",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 49,
    m49Code: "132",
    name: "Cabo Verde",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: true,
    region: "Western Africa",
  },
  {
    id: 102,
    m49Code: "384",
    name: "Côte d'Ivoire",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 81,
    m49Code: "270",
    name: "Gambia",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 84,
    m49Code: "288",
    name: "Ghana",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 88,
    m49Code: "324",
    name: "Guinea",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 156,
    m49Code: "624",
    name: "Guinea-Bissau",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: true,
    region: "Western Africa",
  },
  {
    id: 116,
    m49Code: "430",
    name: "Liberia",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 126,
    m49Code: "466",
    name: "Mali",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 128,
    m49Code: "478",
    name: "Mauritania",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 145,
    m49Code: "562",
    name: "Niger",
    isDeveloped: false,
    isLDC: true,
    isLLDC: true,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 146,
    m49Code: "566",
    name: "Nigeria",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 223,
    m49Code: "654",
    name: "Saint Helena",
    isDeveloped: false,
    isLDC: false,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 167,
    m49Code: "686",
    name: "Senegal",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 169,
    m49Code: "694",
    name: "Sierra Leone",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
  {
    id: 188,
    m49Code: "768",
    name: "Togo",
    isDeveloped: false,
    isLDC: true,
    isLLDC: false,
    isSIDS: false,
    region: "Western Africa",
  },
];
