import { createAction, handleActions } from "redux-actions";

export const addContent = createAction("[Content] Add content 🏳️");

export const fetchHelpItems = createAction("[Content] Fetch Help items ⚡");
export const fetchPageContentByKey = createAction("[Content] Fetch page content by key ⚡");

export default handleActions(
  {
    [addContent]: (state, { payload: { key, value } }) => ({
      ...state,
      [key]: value,
    }),
  },
  {}
);
