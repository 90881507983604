import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import { svgAsDataUri } from "save-svg-as-png";
import downloadIcon from "../images/download.svg";
import { FormattedMessage } from "react-intl";

const DownloadStyled = styled.div`
  &.pending {
    pointer-events: none;
  }
`;

const Download = ({ currentUIState: { visualization } }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentVz, setCurentVz] = useState(null);

  if (visualization !== currentVz) setCurentVz(visualization);

  const handleClick = () => {
    const chartWrapper = document.querySelector(".chart");

    if (chartWrapper === null || isDownloading === true) return;

    document.getElementById("rdct").innerHTML = "";

    setIsDownloading(true);

    if (chartWrapper.querySelector("svg")) {
      svgAsDataUri(chartWrapper.querySelector("svg"), {
        encoderOptions: 1,
      }).then(uri => {
        const content = document.createElement("div");

        const chartImg = document.createElement("img");
        chartImg.src = uri;
        chartImg.style.border = "1px solid #ccc";
        chartImg.style.display = "block";
        chartImg.style.margin = "auto";

        const title = document.createElement("h1");
        title.innerText = document.querySelector("#svg-chart h4").innerText;
        title.style.textAlign = "center";
        title.style.marginTop = "50px";
        title.style.marginBottom = "30px";

        const legend =
          Array.from(document.querySelector("#svg-chart .chart").children[0].children).length === 3
            ? Array.from(
                document.querySelector("#svg-chart .chart").children[0].cloneNode(true).children
              ).pop()
            : null;

        const copyright = document.createElement("h4");
        copyright.innerText = "Generated by SDG Trade Monitor";
        copyright.style.marginTop = "30px";
        copyright.style.padding = "0 30px";
        copyright.style.textAlign = "right";

        const website = document.createElement("p");
        website.innerText = location.protocol + "//" + location.host;
        website.style.padding = "0 30px";
        website.style.textAlign = "right";

        content.appendChild(title);
        content.appendChild(chartImg);
        if (legend !== null) {
          content.appendChild(legend);
        }
        content.appendChild(copyright);
        content.appendChild(website);

        document.getElementById("rdct").appendChild(content);

        htmlToImage
          .toBlob(content, {
            backgroundColor: "#F4F7FC",
            width: 880,
            height: 880,
          })
          .then(function(blob) {
            saveAs(blob, `${title.innerText}.png`);

            setTimeout(() => {
              setIsDownloading(false);
            }, 500);
          });
      });
    } else {
      const content = document.createElement("div");

      const chartImg = chartWrapper.querySelector("h4 + div").cloneNode(true);
      chartImg.style.display = "flex";
      chartImg.style.justifyContent = "center";
      chartImg.style.alignItems = "center";
      chartImg.style.border = "1px solid #ccc";
      chartImg.style.display = "block";
      chartImg.style.margin = "auto";

      const chartInner = chartImg.children[0];
      chartInner.style.margin = "auto";

      const title = document.createElement("h1");
      title.innerText = document.querySelector("#svg-chart h4").innerText;
      title.style.textAlign = "center";
      title.style.marginTop = "50px";
      title.style.marginBottom = "30px";

      const legend =
        Array.from(document.querySelector("#svg-chart .chart").children[0].children).length === 3
          ? Array.from(document.querySelector("#svg-chart .chart").children[0].cloneNode(true).children).pop()
          : null;

      const copyright = document.createElement("h4");
      copyright.innerText = "Generated by SDG Trade Monitor";
      copyright.style.marginTop = "30px";
      copyright.style.padding = "0 30px";
      copyright.style.textAlign = "right";

      const website = document.createElement("p");
      website.innerText = location.protocol + "//" + location.host;
      website.style.padding = "0 30px";
      website.style.textAlign = "right";

      content.appendChild(title);
      content.appendChild(chartImg);
      if (legend !== null) {
        content.appendChild(legend);
      }
      content.appendChild(copyright);
      content.appendChild(website);

      document.getElementById("rdct").appendChild(content);

      htmlToImage
        .toBlob(content, {
          backgroundColor: "#F4F7FC",
          width: 880,
          height: 880,
        })
        .then(function(blob) {
          saveAs(blob, `${title.innerText}.png`);

          setTimeout(() => {
            setIsDownloading(false);
          }, 500);
        });
    }
  };

  return (
    <DownloadStyled className={`vz${visualization} ${isDownloading === true ? "pending" : ""}`}>
      <div id="rdct" style={{ display: "none" }}></div>
      <button onClick={() => handleClick()}>
        <img src={downloadIcon} width="20" height="20" />
        <FormattedMessage id="indicators.download-image" defaultMessage="Download Image" />
      </button>
    </DownloadStyled>
  );
};

Download.propTypes = {
  currentUIState: PropTypes.object.isRequired,
};

export default Download;
