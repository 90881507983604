import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { useRouteMatch } from "react-router";
import PropTypes from "prop-types";
import { IndicatorContext } from "components/IndicatorContextProviderComponent";
import { INDICATORS, VISUALIZATIONS, DIMENSIONS } from "modules/meta-data/constants";
import iconTime from "./images/icon-time.svg";
import iconMapChart from "./images/icon-geo.svg";
import iconVertBarChart from "./images/icon-comparison_years.svg";
import iconHorzBarChart from "./images/icon-growth.svg";
import iconRadarChart from "./images/icon_productmap.svg";
import iconGapChart from "./images/icon-comparison_measurements.svg";
import iconTreeMapChart from "./images/icon-tree-map.svg";
import iconAreAChart from "./images/icon-area.svg";
import iconFlowChart from "./images/icon-flow.svg";
import iconRadialTree from "./images/icon-radialtree.svg";
import iconScatterPlot from "./images/icon-scatterplot.svg";
import { useParams, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const ButtonGroupStyled = styled.div`
  z-index: 1;
  text-align: right;
  .btn {
    margin-bottom: 5px;
    display: inline-flex;
    box-sizing: border-box;
    height: 36px;
    padding: 0 10px;
    margin-left: 5px;
    border: 1px solid #f4f7fc;
    border-radius: 22px;
    vertical-align: middle;
    color: #1b3159;
    font-family: Roboto, "sans-serif";
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    transition: background-color 0.1s ease-out, border-color 0.1s ease-out;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: #f4f7fc;
      border-color: #1b3159;
    }
  }
  i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
  }
  img {
    margin-right: 10px;
  }
  .pending {
    color: orange;
  }
`;

const ViewSwitcherStyled = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
`;

const getButtonLbl = vzCode => {
  switch (vzCode) {
    case VISUALIZATIONS.LINE_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconTime} alt="" />
          <FormattedMessage id="misc.charts.line-chart" defaultMessage="Line chart" />
        </i>
      );
    case VISUALIZATIONS.BUBBLE_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconMapChart} alt="" />
          <FormattedMessage id="misc.charts.bubble-chart" defaultMessage="Bubble chart" />
        </i>
      );
    case VISUALIZATIONS.VERTICAL_BAR_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconVertBarChart} alt="" />
          <FormattedMessage id="misc.charts.vertical-bar-char" defaultMessage="Vertical bar chart" />
        </i>
      );
    case VISUALIZATIONS.HORIZONTAL_BAR_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconHorzBarChart} alt="" />
          <FormattedMessage id="misc.charts.horizontal-bar-chart" defaultMessage="Horizontal bar chart" />
        </i>
      );
    case VISUALIZATIONS.FLOW_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconFlowChart} alt="" />
          <FormattedMessage id="misc.charts.flow-chart" defaultMessage="Flow chart" />
        </i>
      );
    case VISUALIZATIONS.MAP:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconMapChart} alt="" />
          <FormattedMessage id="misc.charts.map" defaultMessage="Map" />
        </i>
      );
    case VISUALIZATIONS.RADAR_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconRadarChart} alt="" />
          <FormattedMessage id="misc.charts.radar-chart" defaultMessage="Radar chart" />
        </i>
      );
    case VISUALIZATIONS.GAP_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconGapChart} alt="" />
          <FormattedMessage id="misc.charts.gap-chart" defaultMessage="Gap chart" />
        </i>
      );
    case VISUALIZATIONS.TREEMAP_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconTreeMapChart} alt="" />
          <FormattedMessage id="misc.charts.map-chart" defaultMessage="Tree map chart" />
        </i>
      );
    case VISUALIZATIONS.SCATTER_PLOT_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconScatterPlot} alt="" />
          <FormattedMessage id="misc.charts.scatter-radial-chart" defaultMessage="Scatter plot chart" />
        </i>
      );
    case VISUALIZATIONS.AREA_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconAreAChart} alt="" />
          <FormattedMessage id="misc.charts.area-chart" defaultMessage="Area chart" />
        </i>
      );
    case VISUALIZATIONS.TREE_RADIAL_CHART:
      return (
        <i key={`btn-${vzCode}`}>
          <img src={iconRadialTree} alt="" />
          <FormattedMessage id="misc.charts.tree-radial-chart" defaultMessage="Tree radial chart" />
        </i>
      );
    default:
      return null;
  }
};

const ViewSwitcher = ({ metaData, setVisualizationCharts, currentUIState }) => {
  const { language, code, visualization, dimension, embed } = useParams();
  const match = useRouteMatch();
  const history = useHistory();

  const indicatorContext = useContext(IndicatorContext);
  const indicatorCode = match.params.code.replace(/-/g, "_");
  const uiConf = indicatorContext[INDICATORS[`i${indicatorCode}`]];
  const vzList =
    dimension === DIMENSIONS.BY_COUNTRIES
      ? uiConf?.viewData?.visualizations[DIMENSIONS.BY_COUNTRIES]
      : uiConf?.viewData?.visualizations[DIMENSIONS.BY_PRODUCTS];
  const currentDimension = dimension ? dimension : uiConf?.defaultViewData?.dimensions;
  const handleVzChange = vzCode => {
    if (
      uiConf.viewData.underDevelopment[currentUIState.dimension][vzCode] === true &&
      location.search.trim().localeCompare("?showAllVz=true") !== 0
    ) {
      alert("The data for this visualization is not ready!");
    } else if (currentUIState.visualization !== vzCode) {
      setVisualizationCharts(vzCode);
      history.push(
        `/${language}/indicators/${code}/${currentDimension}/${vzCode}/${
          embed && embed === "embed" ? embed : ""
        }${location.search.trim()}`
      );
    }
  };

  const isPending = d => (uiConf?.viewData?.underDevelopment[currentDimension][d] === true ? "pending" : "");

  if (!metaData.indicator || visualization === "" || embed === "embed") return null;

  return (
    <ViewSwitcherStyled className={`${embed === true ? "embed" : ""}`}>
      <Grid>
        <ButtonGroupStyled>
          {vzList?.map(d => (
            <span
              data-value={d}
              onClick={() => handleVzChange(d)}
              className={`btn ${isPending(d)} ${+visualization === d ? "active" : ""}`}
              key={`btn-${d}`}
            >
              {getButtonLbl(d, visualization)}
            </span>
          ))}
        </ButtonGroupStyled>
      </Grid>
    </ViewSwitcherStyled>
  );
};

ViewSwitcher.propTypes = {
  metaData: PropTypes.any.isRequired,
  currentUIState: PropTypes.any.isRequired,
  setVisualizationCharts: PropTypes.func.isRequired,
};

export default ViewSwitcher;
