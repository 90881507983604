import React from "react";
import Header from "modules/ui/components/Header";
import Footer from "modules/ui/components/Footer";
import ScrollToTopOnMount from "modules/shared-components/ScrollToTopOnMount";
import useContent from "modules/cms/hooks/useContent";
import CMSPage from "../modules/cms/components/CMSPage";

export default () => {
  const content = useContent({ contentType: "data-visualizations-library", contentKey: "page-data-vz-lib" });

  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      <CMSPage content={content} />
      <Footer />
    </>
  );
};
