import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import SDG_icon_2_official from "modules/indicators/images/icons/sdg_icon_2_official.png";
import SDG_icon_8_official from "modules/indicators/images/icons/sdg_icon_8_official.png";
import SDG_icon_8 from "modules/indicators/images/icons/sdg_icon_8.png";
import SDG_icon_10 from "modules/indicators/images/icons/sdg_icon_10.png";
import SDG_icon_10_official from "modules/indicators/images/icons/sdg_icon_10_official.png";
import SDG_icon_17 from "modules/indicators/images/icons/sdg_icon_17.png";
import SDG_icon_17_official from "modules/indicators/images/icons/sdg_icon_17_official.png";
import Measures from "./Measures";
import { FormattedMessage } from "react-intl";

const HeaderStyled = styled.div`
  background: #425a85;
  padding: 35px 0 0;
  min-height: 315px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  .container {
    display: flex;
    width: 100%;
    max-width: 1190px;
    margin: 15px auto 0;
    padding: 0 25px;
    &-top {
      flex: 1 0 auto;
    }
  }
  .goal-badge {
    position: relative;
    width: 100px;
    max-width: 100px;
    height: 100px;
    flex: 1 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .indicator {
    margin-left: 25px;
    // max-width: 760px;
    &-name {
      opacity: 0.79;
      font-family: Roboto;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.56px;
      color: #ffffff;
    }
    &-description {
      margin: 10px 0;
      font-family: Roboto;
      font-size: 26px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: 0.33px;
      color: #ffffff;
    }
    &-primary {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: 0.18px;
      color: #ffffff;
      p {
        margin-top: 5px;
      }
    }
    &-primary-name {
      opacity: 0.7;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.16px;
      color: #ffffff;
    }
  }

  .measurement {
    display: flex;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #ffffff;
    > span {
      white-space: nowrap;
    }
  }
`;

const getIndicatorIcon = indicatorId => {
  switch (indicatorId) {
    case 1:
      return SDG_icon_2_official;
    case 2:
      return SDG_icon_8_official;
    case 3:
      return SDG_icon_10_official;
    case 11:
      return SDG_icon_10;
    case 4:
    case 5:
    case 6:
      return SDG_icon_17_official;
    case 7:
    case 9:
      return SDG_icon_17;
    case 10:
      return SDG_icon_8;
    default:
      return "";
  }
};

const IndicatorBanner = ({ metaData, children }) => {
  const { embed } = useParams();

  if (!metaData || embed === "embed") return <></>;

  return (
    <HeaderStyled>
      <div className="container container-top">
        {metaData.goal && (
          <div className="goal-badge">
            {metaData.indicator && (
              <img
                className="goal-icon"
                src={getIndicatorIcon(metaData.indicator.id)}
                alt={metaData.goal.longDescription}
                title={metaData.goal.longDescription}
                width="50"
                height="50"
              />
            )}
          </div>
        )}

        <div className="indicator">
          {metaData.indicator && (
            <>
              <h1 className="indicator-name">SDG {metaData.indicator.shortDescription.toLowerCase()}</h1>
              <p className="indicator-description">{metaData.indicator.longDescription}</p>
            </>
          )}
          {metaData.target && (
            <div className="indicator-primary">
              <span className="indicator-primary-name">
                <FormattedMessage
                  id="indicators.measure.for-target"
                  defaultMessage="FOR SDG {shortDescription}"
                  values={{ shortDescription: metaData.target.shortDescription }}
                />
              </span>
              <p>{metaData.target.longDescription}</p>
            </div>
          )}
        </div>
      </div>

      {metaData.target && (
        <div className="container measurement">
          <span>
            <FormattedMessage
              id="indicators.measure.explore"
              defaultMessage="Explore SDG {target} with measurement"
              values={{ target: metaData.target.targetId }}
            />
          </span>
          {metaData.indicator !== undefined && <Measures />}
        </div>
      )}

      {children && <div className="container">{children}</div>}
    </HeaderStyled>
  );
};

IndicatorBanner.propTypes = {
  metaData: PropTypes.any,
  children: PropTypes.node.isRequired,
};

IndicatorBanner.defaultProps = {
  metaData: {
    indicator: null,
    target: null,
    goal: null,
    measures: null,
  },
  children: null,
};

export default IndicatorBanner;
