import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { setFilterParamsYear } from "modules/indicators/reducer";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import { selectFilterDataYear } from "modules/indicators/selectors/filter-data";
import { FormattedMessage } from "react-intl";

const Styled = styled.div`
  display: flex;
  .selection {
    flex: 1 1 40%;
  }
  .lbl {
    white-space: nowrap;
  }
  .filter-criteria {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`;

const FilterYearsDuoPoints = () => {
  const defaultBaseYear = 2015;
  const dispatch = useDispatch();
  const { year } = useSelector(selectFilterParams) || [];
  const { data } = useSelector(selectFilterDataYear) || [];

  const setYears = value => dispatch(setFilterParamsYear(value));

  useEffect(() => {
    if (data !== null && data?.length > 0) {
      const latestYear = Math.max(...data);
      dispatch(
        setFilterParamsYear([
          defaultBaseYear,
          latestYear,
        ])
      );
    }
  }, [data]);

  if (!year || !data || year?.length < 2 || data?.length === 0) return null;

  return (
    <Styled>
      <FormControl className="filter-criteria selection">
        <InputLabel className="lbl">
          <FormattedMessage id="indicators.search-form.base-year" defaultMessage="Base Year" />
        </InputLabel>
        <Select value={year[0]}>
          {data.map(d => (
            <MenuItem key={`available-year-${d}`} value={d} onClick={() => setYears([d, year[1]])}>
              {d}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="filter-criteria selection">
        <InputLabel className="lbl">
          <FormattedMessage id="indicators.search-form.comparison-year" defaultMessage="Comparison Year" />
        </InputLabel>
        <Select value={year[1]}>
          {data.map(d => (
            <MenuItem key={`available-year-${d}`} value={d} onClick={() => setYears([year[0], d])}>
              {d}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Styled>
  );
};

export default FilterYearsDuoPoints;
