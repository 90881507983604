import { createAction, handleActions } from "redux-actions";

const moduleName = "[Reports]";

export const loadReports = createAction(`${moduleName} Load reports`);
export const addReports = createAction(`${moduleName} Add reports`);

export const addChart = createAction(`${moduleName} Update reports`);
export const removeChart = createAction(`${moduleName} Remove chart from reports`);
export const deleteReports = createAction(`${moduleName}  Delete reports`);
export const reorderReports = createAction(`${moduleName}  Reorder reports`);
export const deleteAllReports = createAction(`${moduleName}  Delete all reports`);
export const updateTitle = createAction(`${moduleName} Update title`);
export const updateChartDescription = createAction(`${moduleName} Update chart description`);
export const updateChartLayout = createAction(`${moduleName} Update chart layout`);

export const addReportsSuccessfully = createAction(`${moduleName} Add reports successfully`);
export const addVzIntoAReportSuccessfully = createAction(`${moduleName} Add vz into report successfully`);
export const updateReportsSuccessfully = createAction(`${moduleName} Update report successfully`);

// Pseudo action
export const fetchReports = createAction(`${moduleName} Fetch report`);

export const LAYOUT = {
  TEXT_DOWN: 1,
  TEXT_RIGHT: 2,
  TEXT_LEFT: 3,
};

const initState = localStorage.getItem("SDGReports")
  ? JSON.parse(localStorage.getItem("SDGReports"))
  : {
      title: "",
      content: [],
    };

export default handleActions(
  {
    [loadReports]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [removeChart]: (state, { payload }) => ({
      ...state,
      content: state.content.filter(d => d.id !== payload),
    }),
    [addChart]: (state, { payload: { img } }) => ({
      ...state,
      content: [
        ...state.content,
        {
          layout: LAYOUT.TEXT_DOWN,
          id: state.content.length,
          img: img,
          desc: "",
        },
      ],
    }),
    [updateTitle]: (state, { payload }) => ({
      ...state,
      title: payload,
    }),
    [updateChartDescription]: (state, { payload: { id, desc } }) => ({
      ...state,
      content: [
        ...state.content.map(d => {
          if (d.id === id) {
            return {
              ...d,
              desc,
            };
          }
          return d;
        }),
      ],
    }),
    [updateChartLayout]: (state, { payload: { id, layout } }) => ({
      ...state,
      content: [
        ...state.content.map(d => {
          if (d.id === id) {
            return {
              ...d,
              layout,
            };
          }
          return d;
        }),
      ],
    }),
    [deleteReports]: () => ({
      title: "",
      content: [],
    }),
    [reorderReports]: (state, { payload }) => ({
      ...state,
      content: [...payload],
    }),
  },
  initState
);
