import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { GapChart } from "datadesign-charts-sdgtrade";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import { IndicatorContext } from "components/IndicatorContextProviderComponent";
import { INDICATORS, VISUALIZATIONS, MEASURES_UNIT } from "modules/meta-data/constants";
import Skeleton from "@material-ui/lab/Skeleton";

const Chart = ({ data, chartMetaData, isSecondarySeriesDataEmpty, title }) => {
  const { embed } = useParams();
  const match = useRouteMatch();
  const indicatorContext = useContext(IndicatorContext);
  const indicatorCode = match.params.code.replace(/-/g, "_");
  const uiConf = indicatorContext[INDICATORS[`i${indicatorCode}`]];
  let dataTitle = Object.keys(chartMetaData)
    .filter(d => chartMetaData[d] !== undefined)
    .map(d => chartMetaData[d].seriesDescription);

  const shouldSwitchMeasures =
    uiConf.viewData.switchMeasureVsSecondaryMeasure.indexOf(VISUALIZATIONS.GAP_CHART) !== -1;
  const xValueKey = ["value", "valueSecond"];

  if (data === null) {
    return (
      <div className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  if (isSecondarySeriesDataEmpty) {
    return (
      <div className="chart">
        <GapChart
          data={data}
          dataTitle={dataTitle}
          xValueKey={xValueKey}
          yValueKey="name"
          yUnit={MEASURES_UNIT[chartMetaData?.primaryMeasure?.unit?.trim()]}
          uniqueIDKey="id"
          title={title}
          aspectRatio={0.5} // optional
        />
        {(!embed || embed !== "embed") && <Toolbar vzData={{}} />}
      </div>
    );
  }

  return (
    <div className="chart">
      <GapChart
        data={data}
        dataTitle={shouldSwitchMeasures ? dataTitle.reverse() : dataTitle}
        xValueKey={shouldSwitchMeasures ? xValueKey.reverse() : xValueKey}
        yValueKey="name"
        yUnit={MEASURES_UNIT[(chartMetaData?.primaryMeasure?.unit)]}
        uniqueIDKey="id"
        title={title}
        aspectRatio={data.length > 100 ? (data.length * 0.5) / 20 : 0.5} // optional
      />
      {(!embed || embed !== "embed") && <Toolbar vzData={data} />}
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  chartMetaData: PropTypes.object.isRequired,
  isSecondarySeriesDataEmpty: PropTypes.bool.isRequired,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
