import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ContainerStyled = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containerWidth}px;
  padding: ${props => props.theme.containerPadding}px;
  margin: auto;
`;

const Container = ({ children, ...otherProps }) => (
    <ContainerStyled {...otherProps}>{children}</ContainerStyled>
);

Container.propTypes = {
    children: PropTypes.any,
};

Container.defaultProps = {
    children: null,
};

export default Container;
