import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Hero from "../../ui/components/Hero";
import H1 from "../../shared-components/H1";
import Container from "../../shared-components/PaddedContainer";
import MetaData from "./MetaData";
// import MeasureDataAvailabilityTable from "./Table";
import heroBg from "../images/hero-bg.jpg";
// import MapContent from "./MapContent";

const useStyles = makeStyles(() => ({
  root: {
    "& .measure-info": {
      padding: 0,
      listStyleType: "none",
    },
  },
  hero: {
    display: "flex",
    alignItems: "center",
  },
  measurementSelection: {
    color: "inherit",
    flexDirection: "row",
    alignItems: "center",
    "& > span": {
      marginRight: "15px",
      fontFamily: "Roboto",
      fontSize: "16px",
      letterSpacing: "0.2px",
    },
  },
  content: {
    padding: "100px 0",
    height: "870px",
  },
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const Content = props => {
  const {
    measurements,
    measureMetaData,
    // dataTableContent,
    // countriesDataByM49Code,
    onChangeMeasure,
  } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(
    measurements && measurements[0] ? measurements[0].seriesCode : "AG_PRD_XSUBDY"
  );

  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

  // const handleOnChangeTab = (event, newValue) => setCurrentTabIndex(newValue);

  const getSelectMeasurementIndex = (data, selectedValue) => {
    const initialValue = data.findIndex(item => item.seriesCode === selectedValue);

    if (initialValue < 0) return 0;
    return initialValue;
  };

  const handleOnClick = () => {
    setValue(event.target.dataset.value);
    onChangeMeasure(event.target.dataset.value);
  };

  setCurrentTabIndex(0);

  const selectedMeasurementIndex = getSelectMeasurementIndex(measureMetaData, value);

  return (
    <div className={classes.root}>
      <Hero height={240} background={heroBg} className={`${classes.hero} as-${currentTabIndex}`}>
        <Container>
          <H1>Data Availability</H1>
          {measurements.length > 0 && (
            <FormControl className={classes.measurementSelection}>
              <span>Measurements</span>
              <Select
                value={value}
                inputProps={{
                  name: "measurement",
                  id: "measurement",
                }}
                onChange={handleOnClick}
              >
                {measurements.map((item, index) => (
                  <MenuItem key={index} value={item.seriesCode}>
                    {item.seriesDescription}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {measureMetaData && (
            <MetaData selectedItemIndex={selectedMeasurementIndex} measureMetaData={measureMetaData} />
          )}
        </Container>
      </Hero>

      <Container className={classes.content}>
        This module is under development at this moment.
        {/* <AppBar position="static">
          <Tabs value={currentTabIndex} onChange={handleOnChangeTab}>
            <Tab label="Table" {...a11yProps(0)} />
            <Tab label="MapContent" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={currentTabIndex} index={0}>
          {dataTableContent.heading.length > 0 &&
            dataTableContent.body.length && (
              <MeasureDataAvailabilityTable
                columns={dataTableContent.heading}
                data={dataTableContent.body}
              />
            )}
        </TabPanel>
        <TabPanel value={currentTabIndex} index={1}>
          <MapContent
            selectedMeasure={measureMetaData[selectedMeasurementIndex]}
            dataAvailabilityTimeLine={dataTableContent.heading}
            dataAvailability={dataTableContent.body}
          />
        </TabPanel> */}
      </Container>
    </div>
  );
};

Content.propTypes = {
  measurements: PropTypes.array,
  measureMetaData: PropTypes.array,
  dataTableContent: PropTypes.object,
  // countriesDataByM49Code: PropTypes.any,
  onChangeMeasure: PropTypes.func.isRequired,
};

Content.defaultProps = {
  measurements: [],
  measureMetaData: [],
  dataTableContent: {
    heading: [],
    body: [],
  },
  // countriesDataByM49Code: null,
};

export default Content;
