import { connect } from "react-redux";
import { selectSeriesDataBubbleChart } from "modules/indicators/selectors/charts/bubble-chart";
import { selectChartMetaData, isSecondarySeriesDataEmpty } from "modules/indicators/selectors/indicators";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import Chart from "./BubbleChartComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataBubbleChart(state),
  chartMetaData: selectChartMetaData(state),
  isSecondarySeriesDataEmpty: isSecondarySeriesDataEmpty(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(Chart);
