import React, { useState } from "react";
import styled from "styled-components";
import shareIcon from "./images/share.svg";
import Modal from "@material-ui/core/Modal";
import { useParams } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage } from "react-intl";

const ShareEmbedStyled = styled.div`
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  max-width: 700px;
  max-height: 560px;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: translate(-50%, -50%);
  padding: 30px;

  a,
  .btn-preview {
    display: inline-block;
    padding: 8px 30px;
    margin-right: 15px;
    background: #425a85;
    color: #fff;
  }
  .btn-preview {
    margin-left: auto;
    max-width: 200px;
    text-align: center;
    cursor: pointer;
  }

  .sharing {
    margin: 30px 0;
  }

  .url {
    em {
      display: block;
      width: 100%;
      padding: 15px;
      background: #f7fafc;
    }
    .note {
      margin-top: 10px;
      color: #99a2aa;
    }
  }

  .preview {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    position: fixed;
    z-index: 1111;
  }

  hr {
    margin: 30px 0;
  }
`;

const EmbeddedStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;

  h2 {
    margin-bottom: 30px;
  }

  .btn-preview {
    display: inline-block;
    padding: 8px 30px;
    margin-right: 15px;
    background: #425a85;
    color: #fff;
  }

  .preview {
    position: relative;
    background: #fff;
  }
`;

const BtnCloseStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: 0;
  border-radius: 50%;
  overflow: hidden;
`;

const ShareEmbed = () => {
  const { language, code, visualization, dimension } = useParams();
  const [open, setOpen] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const sharingUrl = `${location.protocol}//${location.host}/${language}/indicators/${code}/${dimension}/${visualization}`;
  const sharingText = "SDG Trade Monitor";

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleClosePreivew = () => setIsPreview(false);

  const handleOpenPreview = () => setIsPreview(true);

  return (
    <div>
      <button onClick={handleOpen}>
        <img src={shareIcon} width="15" height="20" />
        <FormattedMessage id="misc.share-embed" defaultMessage="Share / Embed" />
      </button>

      <Modal open={open} onClose={handleClose}>
        <ShareEmbedStyled>
          <div className="sharing-content">
            <BtnCloseStyle className="btn-close" onClick={handleClose}>
              <CloseIcon />
            </BtnCloseStyle>
            <h2>
              <FormattedMessage id="misc.share-this-graph" defaultMessage="Share this graph" />
            </h2>
            <div className="sharing">
              <a
                href={`https://twitter.com/intent/tweet?text=${sharingText}&url=${sharingUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${sharingUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a href={`mailto:?subject=${sharingText}&body=${sharingUrl}`}>Email</a>
            </div>
            <div className="url">
              <em>{sharingUrl}</em>
              <p className="note">
                <FormattedMessage
                  id="misc.sharing-note"
                  defaultMessage="Copy URL to open this chart with default selection"
                />
              </p>
            </div>
            <hr />
            <h2>
              <FormattedMessage id="misc.embed-code" defaultMessage="Embed code" />
            </h2>
            <div className="url">
              <em>
                {`<iframe src=${sharingUrl}/embed} width="1200" height="1200" style="border: 0" mozallowfullscreen="true" webkitallowfullscreen="true" allowfullscreen="true"></iframe>`}
              </em>
              <p className="note">
                <FormattedMessage
                  id="misc.embed-this-graph"
                  defaultMessage="Use this code to embed the visualization into your website"
                />
              </p>
              <span className="btn-preview" onClick={handleOpenPreview}>
                <FormattedMessage id="misc.preview" defaultMessage="Preview" />
              </span>
            </div>
          </div>
        </ShareEmbedStyled>
      </Modal>
      <Modal open={isPreview} onClose={handleClosePreivew}>
        <EmbeddedStyled>
          <BtnCloseStyle className="btn-close" onClick={handleClosePreivew}>
            <CloseIcon />
          </BtnCloseStyle>
          <div className="preview">
            <iframe
              src={`${sharingUrl}/embed`}
              width="1200"
              height="800"
              style={{ border: 0 }}
              allowFullScreen={true}
            />
          </div>
        </EmbeddedStyled>
      </Modal>
    </div>
  );
};

export default ShareEmbed;
