export default [
  {
    id: 5723,
    indicatorTypeId: 1,
    geoAreaId: 32,
    year: 2016,
    value: 0.0,
  },
  {
    id: 5745,
    indicatorTypeId: 1,
    geoAreaId: 38,
    year: 2016,
    value: 0.0,
  },
  {
    id: 5800,
    indicatorTypeId: 1,
    geoAreaId: 52,
    year: 2016,
    value: 0.0,
  },
  {
    id: 5823,
    indicatorTypeId: 1,
    geoAreaId: 56,
    year: 2016,
    value: 0.0,
  },
  {
    id: 5876,
    indicatorTypeId: 1,
    geoAreaId: 94,
    year: 2016,
    value: 0.0,
  },
  {
    id: 5914,
    indicatorTypeId: 1,
    geoAreaId: 96,
    year: 2016,
    value: 0.0,
  },
  {
    id: 5937,
    indicatorTypeId: 1,
    geoAreaId: 100,
    year: 2016,
    value: 0.58073999999999992,
  },
  {
    id: 5962,
    indicatorTypeId: 1,
    geoAreaId: 129,
    year: 2016,
    value: 0.70339316864554613,
  },
  {
    id: 5985,
    indicatorTypeId: 1,
    geoAreaId: 130,
    year: 2016,
    value: 0.0,
  },
  {
    id: 6032,
    indicatorTypeId: 1,
    geoAreaId: 147,
    year: 2016,
    value: 19.821428571428573,
  },
  {
    id: 6115,
    indicatorTypeId: 1,
    geoAreaId: 175,
    year: 2016,
    value: 0.0,
  },
  {
    id: 6137,
    indicatorTypeId: 1,
    geoAreaId: 184,
    year: 2016,
    value: 96.075237034966847,
  },
  {
    id: 6225,
    indicatorTypeId: 1,
    geoAreaId: 205,
    year: 2016,
    value: 0.0,
  },
];
