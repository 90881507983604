import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import { MapChoropleth } from "datadesign-charts-sdgtrade";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import { MEASURES_UNIT } from "modules/meta-data/constants";
import Skeleton from "@material-ui/lab/Skeleton";
import { selectCurrentUIState } from "modules/indicators/selectors/current-ui-state";
import { rangeIndex, rangePercentage } from "modules/map-range";
import { INDICATORS } from "modules/meta-data/constants";

const Chart = ({ data, chartMetaData, title }) => {
  const { indicatorCode } = useSelector(selectCurrentUIState);

  const { embed } = useParams();

  if (data === null) {
    return (
      <div className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  let domain;

  switch (chartMetaData?.primaryMeasure?.unit.trim().toUpperCase()) {
    case "INDEX":
      domain = rangeIndex;
      break;
    case "PERCENT":
      domain = rangePercentage[INDICATORS[`i${indicatorCode.replace(/-/g, "_")}`]];
      break;
    default:
      domain = [];
  }

  if (domain === undefined) return null;

  return (
    <div className="chart" style={{ overflow: "hidden" }}>
      <MapChoropleth
        data={data.filter(d => d.value)}
        colorValueKey="value"
        uniqueIDKey="alpha3Code"
        uniqueIDKeyMap="alpha3Code"
        nameKey="name"
        yUnit={MEASURES_UNIT[chartMetaData?.primaryMeasure?.unit.trim()]}
        title={title}
        aspectRatio={0.5}
        colorConfig={{
          domain: domain,
          range: "schemePurples",
        }}
      />
      {(!embed || embed !== "embed") && <Toolbar vzData={data} />}
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  chartMetaData: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
