import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const PublicationListStyled = styled.div`
  width: 100%;
  border-radius: 5px;
  h2 {
    margin: 0;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .content {
    padding-bottom: 15px;
  }

  .title {
    background-color: #425a85;
    border-bottom: 1px solid #fff;
    transition: all 0.2s ease-out;
    color: #fff;
    font-size: 16px;
    & > * {
      color: #fff;
    }
  }

  .actions {
    display: table;
    width: 100%;
    border-collapse: collapse;
    .item {
      display: table-row;

      > * {
        display: table-cell;
        padding: 10px 0;
        border-bottom: 1px solid #04519c;
      }
      > span {
        width: 200px;
        font-weight: bold;
      }
    }
  }
  .action {
    color: #38568c;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .tt {
    flex: 1 0 auto;
  }

  .MuiExpansionPanel-root.Mui-expanded {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
`;

const CMSContentFAQ = ({ data }) => (
  <PublicationListStyled>
    {data.map((article, key) => (
      <Accordion key={key}>
        <AccordionSummary className="title" expandIcon={<ExpandMoreIcon />}>
          <span
            dangerouslySetInnerHTML={{
              __html: article?.["question"],
            }}
          />
        </AccordionSummary>

        <AccordionDetails>
          <div className="content">
            <span dangerouslySetInnerHTML={{ __html: article?.["answer"] }} />
          </div>
        </AccordionDetails>
      </Accordion>
    ))}
  </PublicationListStyled>
);

CMSContentFAQ.propTypes = {
  data: PropTypes.array,
};

CMSContentFAQ.defaultProps = {
  data: [],
};

export default CMSContentFAQ;
