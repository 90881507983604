import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

const defaultTheme = {
  containerWidth: 1090,
  containerPadding: 25,
  colorBackground: "#3E75B0",
  colorLightBackground: "#f5f6f7",
  colorAccent: "#4CAF4F",
  colorHighlight: "#781a36",
  colorDisabled: "#666666",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightBold: 500,
  fontWeightBlack: 700,
};

const CustomThemeProvider = ({ children, theme, rtl }) => (
  <ThemeProvider
    theme={{
      ...defaultTheme,
      ...theme,
      rtl,
    }}
  >
    {children}
  </ThemeProvider>
);

CustomThemeProvider.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.shape({}),
  rtl: PropTypes.bool,
};

CustomThemeProvider.defaultProps = {
  children: null,
  theme: {},
  rtl: false,
};

export default CustomThemeProvider;
