import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const HeroStyled = styled.div`
  position: relative;
  background: ${props =>
    props.background && props.background !== "" ? `url(${props.background}) 100% 100% no-repeat` : "initial"};
  background-color: #557390;
  height: ${props => (props.height ? `${props.height}px` : "530px")};
  background-size: cover;
  ul {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  article {
    margin: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 1170px;
    width: 100%;
    padding: 0 25px;
  }
  .heading {
    opacity: 0.7;
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: 1.33px;
    color: #000000;
    text-transform: uppercase;
    &:before {
      content: "";
      display: inline-block;
      width: 35px;
      height: 2px;
      opacity: 0.4;
      background: #000;
      vertical-align: middle;
      margin-right: 15px;
    }
  }
  .sub-heading {
    max-width: 50%;
    font-family: Roboto, serif;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #000000;
  }
  .content {
    margin-bottom: 0;
    max-width: 50%;
    font-family: Roboto, serif;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #000000;
  }
`;
const Hero = ({ className, background, height, children, ...otherProps }) => (
  <HeroStyled className={className} background={background || ""} height={height} {...otherProps}>
    {children}
  </HeroStyled>
);

Hero.propTypes = {
  height: PropTypes.number,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Hero.defaultProps = {
  height: null,
  background: "",
  className: "",
};

export default Hero;
