import React, { useContext } from "react";
import styled from "styled-components";
import LineChart from "./LineChart";
// import Map from "./Mapv2";
import Map from "./Map";
import VerticalBarChart from "./VerticalBarChart";
import RadarChart from "./RadarChart";
import GapChart from "./GapChart";
import TreeMapChart from "./TreeMapChart";
import HorizontalBarChart from "./HorizontalBarChart";
import BubbleChart from "./BubbleChart";
import FlowChart from "./FlowChart";
import ScatterPlotChart from "./ScatterPlotChart";
import TreeRadialChart from "./TreeRadialChart";
import AreaChart from "./AreaChart";
import PropTypes from "prop-types";
import { INDICATORS, VISUALIZATIONS } from "modules/meta-data/constants";
import { UnderDevelopmentMessage } from "modules/indicators/components/Visualization/Messages";
import { IndicatorContext } from "components/IndicatorContextProviderComponent";
import Skeleton from "@material-ui/lab/Skeleton";
import * as constants from "modules/constants";
import ErrorBoundary from "../../../../ui/components/ErrorBoundary";

const ChartStyled = styled.div`
  min-height: 600px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  transform: translateZ(0);
  flex-direction: column;
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    pointer-events: none;
  }
  .chart {
    width: 100%;
    transform: translateZ(0);
    will-change: height;
    > div:first-child {
      aspect-ratio: 880 / 560;
      background: #f4f7fc;
    }
    h4 {
      text-align: center;
    }
  }
`;

const Charts = ({ currentUIState, filterDataYear }) => {
  if (currentUIState.indicatorCode === "" || currentUIState.dimension === "") return null;
  const { status } = filterDataYear;

  const indicatorContext = useContext(IndicatorContext);
  const indicatorConf = indicatorContext[INDICATORS[`i${currentUIState.indicatorCode}`]].viewData;
  const availableVzSum = indicatorConf.underDevelopment[currentUIState.dimension];
  const availableVzSumQty = Object.keys(availableVzSum).reduce(
    (sum, key) => (availableVzSum[key] === false ? sum + 1 : sum),
    0,
  );

  if (availableVzSumQty === 0 && location.search.trim().localeCompare("?showAllVz=true") !== 0) return null;

  if (status === constants.STATUS_LOADING || status === constants.STATUS_NO_DATA) {
    return (
      <ChartStyled className={`i-${currentUIState.indicatorCode}`} id="svg-chart">
        <div className="loading">
          <Skeleton variant="rect" width={880} height={560}/>
        </div>
      </ChartStyled>
    );
  }
  return (
    <ErrorBoundary>
      <ChartStyled className={`i-${currentUIState.indicatorCode}`} id="svg-chart">
        {_getChart(currentUIState.visualization, indicatorConf)}
      </ChartStyled>
    </ErrorBoundary>
  );
};

const _getChart = (visualizationCode, indicatorConf) => {
  switch (visualizationCode) {
    case VISUALIZATIONS.LINE_CHART:
      return <LineChart/>;
    case VISUALIZATIONS.MAP:
      return <Map/>;
    case VISUALIZATIONS.VERTICAL_BAR_CHART:
      return <VerticalBarChart indicatorConf={indicatorConf}/>;
    case VISUALIZATIONS.RADAR_CHART:
      return <RadarChart/>;
    case VISUALIZATIONS.GAP_CHART:
      return <GapChart/>;
    case VISUALIZATIONS.TREEMAP_CHART:
      return <TreeMapChart/>;
    case VISUALIZATIONS.BUBBLE_CHART:
      return <BubbleChart/>;
    case VISUALIZATIONS.HORIZONTAL_BAR_CHART:
      return <HorizontalBarChart/>;
    case VISUALIZATIONS.FLOW_CHART:
      return <FlowChart/>;
    case VISUALIZATIONS.SCATTER_PLOT_CHART:
      return <ScatterPlotChart/>;
    case VISUALIZATIONS.TREE_RADIAL_CHART:
      return <TreeRadialChart/>;
    case VISUALIZATIONS.AREA_CHART:
      return <AreaChart/>;
    default:
      return <UnderDevelopmentMessage/>;
  }
};

Charts.propTypes = {
  currentUIState: PropTypes.object.isRequired,
  seriesDataLineChart: PropTypes.array,
  seriesDataMap: PropTypes.array,
  selectSeriesDataVerticalChart: PropTypes.array,
  filterDataYear: PropTypes.object.isRequired,
};

Charts.defaultProps = {
  seriesDataLineChart: null,
  selectSeriesDataVerticalChart: null,
  seriesDataMap: null,
};

export default Charts;
