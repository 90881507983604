import React from "react";
import ReportDetail from "modules/reports/components/Detail";
import styled from "styled-components";

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Reports = () => (
  <Styled>
    <ReportDetail />
  </Styled>
);

export default Reports;
