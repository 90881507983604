import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Container from "modules/shared-components/Container";
import emailjs from "emailjs-com";
import { FormattedMessage } from "react-intl";
import SendIcon from "@material-ui/icons/Send";
import Snackbar from "@material-ui/core/Snackbar";

const SnackBarStyled = styled(Snackbar)`
  .MuiSnackbarContent {
    &-root {
      background: #4caf50;
    }
  }
`;

const ContactFormStyled = styled.div`
  max-width: 800px;
  margin: 100px auto;
  padding: 50px 30px;
  background: #3d537a;

  form {
    display: flex;
    flex-wrap: wrap;
  }

  .field {
    width: 100%;
    flex: 1 0 100%;
    padding: 0 15px;
    margin: 15px auto;
    &-name,
    &-email {
      flex: 1 0 50%;
      width: 50%;
    }
  }

  .MuiFilledInput-root {
    background: #fff;
    border-radius: 3px;
    &.Mui-focused,
    &:hover {
      background: #fff;
    }
  }

  .actions {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      display: flex;
      align-items: center;
      margin: 0 15px;
      font-size: 18px;
      background: transparent;
      color: #fff;
      outline: 0;
      cursor: pointer;
      border-radius: 20px;
      border: 1px solid #fff;
      padding: 10px 25px 10px 20px;
      transition: all 0.1s ease-out;
      &:hover {
        color: #3d537a;
        background: #fff;
      }
      > * {
        margin: 0 5px;
      }
    }
  }
`;

const ContactForm = () => {
  const btnSubmitRef = useRef();

  const [isMessageShowed, setIsMessageShowed] = useState(false);

  const [message, setMessage] = useState("");

  const handleCloseMessage = () => {
    setIsMessageShowed(false);
    setMessage("");
  };

  useEffect(() => {
    emailjs.init("user_QPnIQA2IkQdE1VywNJovI");
  }, []);

  const handleSubmitForm = e => {
    e.preventDefault();

    emailjs.sendForm("smtp_server", "template_m10ZfK8i", e.target, "user_QPnIQA2IkQdE1VywNJovI").then(
      () => {
        setMessage(
          <FormattedMessage id="contact.message.sent" defaultMessage="Your message has been sent!" />
        );

        setIsMessageShowed(true);

        if (btnSubmitRef.current !== null) {
          btnSubmitRef.current.disabled = true;
        }
      },
      () => {
        setMessage(
          <FormattedMessage
            id="contact.message.error"
            defaultMessage="There was an error! Please try again later!"
          />
        );
        setIsMessageShowed(true);
      }
    );
  };

  return (
    <ContactFormStyled>
      <Container>
        <div className="inner-wrapper">
          <form className={""} noValidate autoComplete="off" onSubmit={e => handleSubmitForm(e)}>
            <div className="field field-name">
              <FormattedMessage className="field" id="contact.form.name" defaultMessage="Name">
                {text => (
                  <TextField name="name" label={text} type="text" variant="filled" fullWidth required />
                )}
              </FormattedMessage>
            </div>

            <div className="field field-email">
              <FormattedMessage className="field" id="contact.form.email" defaultMessage="Email">
                {text => (
                  <TextField name="email" label={text} type="text" variant="filled" fullWidth required />
                )}
              </FormattedMessage>
            </div>

            <div className="field field-subject">
              <FormattedMessage
                className="field"
                id="contact.form.subject"
                variant="filled"
                defaultMessage="Subject"
              >
                {text => (
                  <TextField name="subject" label={text} type="text" variant="filled" fullWidth required />
                )}
              </FormattedMessage>
            </div>

            <div className="field field-message">
              <FormattedMessage className="field" id="contact.form.message" defaultMessage="Message">
                {text => (
                  <TextField
                    name="message"
                    rows={5}
                    multiline
                    label={text}
                    fullWidth
                    required
                    variant="filled"
                  />
                )}
              </FormattedMessage>
            </div>

            <input type="hidden" name="origin" value={location.origin} />

            <div className="actions">
              <button type="submit" ref={btnSubmitRef}>
                <SendIcon />
                <FormattedMessage id="contact.form.button.send" defaultMessage="Send" />
              </button>
            </div>
          </form>
        </div>
      </Container>
      <SnackBarStyled
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isMessageShowed}
        onClose={handleCloseMessage}
        autoHideDuration={6000}
        message={message}
      />
    </ContactFormStyled>
  );
};

export default ContactForm;
