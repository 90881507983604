import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Toolbar from "modules/indicators/components/Visualization/Toolbar";
import { Scatterplot } from "datadesign-charts-sdgtrade";
import { EmptyMessage } from "modules/indicators/components/Visualization/Messages";
import { MEASURES_UNIT } from "modules/meta-data/constants";
import ErrorBoundary from "modules/ui/components/ErrorBoundary";
import Skeleton from "@material-ui/lab/Skeleton";

const Chart = ({ data, chartMetaData, title }) => {
  const { embed } = useParams();
  let dataTitle = Object.keys(chartMetaData)
    .filter(d => chartMetaData[d] !== undefined)
    .map(d => chartMetaData[d].seriesDescription);

  if (data === null) {
    return (
      <div className="loading">
        <Skeleton variant="rect" width={880} height={560} />
      </div>
    );
  }
  if (data.length === 0) {
    return <EmptyMessage />;
  }

  return (
    <div className="chart">
      <ErrorBoundary>
        <Scatterplot
          data={data}
          uniqueIDKey="id"
          nameKey="name"
          xValueKey="value"
          yValueKey="valueSecond"
          rValueKey="valueGDP"
          xyUnit={MEASURES_UNIT[chartMetaData?.primaryMeasure?.unit?.trim()]}
          rUnit="USD"
          xValueTitle={dataTitle[0]}
          yValueTitle={dataTitle[1]}
          rValueTitle={dataTitle[2] ? dataTitle[2] : "GDP"}
          title={title}
          aspectRatio={0.5}
        />
      </ErrorBoundary>
      {(!embed || embed !== "embed") && <Toolbar vzData={data} />}
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array,
  chartMetaData: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
};

Chart.defaultProps = {
  data: null,
};

export default Chart;
