import { createAction, handleActions } from "redux-actions";

export const fetchBearerToken = createAction("[CMS] Fetch bearer token ⚡");
export const fetchContent = createAction("[CMS] Fetch content ⚡");
export const fetchContentByGraphQL = createAction("[CMS] Fetch content by GraphQL");
export const fetchComponent = createAction("[CMS] Fetch component ⚡");

export const setBearerToken = createAction("[CMS] Set bearer token");
export const setContent = createAction("[CMS] Set content");
export const setComponent = createAction("[CMS] Set component");
export const setCmsApiRoot = createAction("[CMS] Set cms api root");
export const clearCmsData = createAction("[CMS] Clear content");

export default handleActions(
  {
    [setBearerToken]: (state, { payload }) => ({
      ...state,
      bearerToken: payload,
    }),
    [setCmsApiRoot]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [setContent]: (state, { payload: { key, ...data } }) => ({
      ...state,
      contents: {
        ...state.contents,
        [key]: { ...data },
      },
    }),
    [setComponent]: (state, { payload: { id, ...data } }) => ({
      ...state,
      components: {
        ...state.components,
        [id]: { ...data },
      },
    }),
    [clearCmsData]: state => ({
      ...state,
      contents: {},
      components: {},
    }),
  },
  { bearerToken: null }
);
