import { createSelector } from "reselect";
import {
  ctryWithAlpha3,
  selectSingleCountries,
  selectProducts,
} from "modules/indicators/selectors/indicators";
import * as constants from "modules/constants";

const getSeriesData = state => state.indicators.seriesData;
const getDimension = state => state.indicators.currentUIState.dimension;

export const selectSeriesDataTreeMapChart = createSelector(
  getSeriesData,
  selectSingleCountries,
  selectProducts,
  getDimension,
  ({ data, status }, countriesData, products, currentDimension) => {
    if (data === null || Object.keys(countriesData).length === 0 || status === constants.STATUS_LOADING)
      return null;

    return data
      .filter(item => +item.geoAreaId !== 1)
      .map(item => {
        const regionData = countriesData[item.geoAreaId];
        if (!regionData) return null;
        return {
          ...item,
          alpha3: ctryWithAlpha3[regionData.name],
          name: currentDimension === "BC" ? regionData.name : products[item.productTypeId].type,
          m49Code: currentDimension === "BC" ? regionData.m49Code : "",
          region: currentDimension === "BC" ? regionData.name : products[item.productTypeId].type,
        };
      })
      .filter(item => item !== null);
  }
);
