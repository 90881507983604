export default [
  {
    id: 36987,
    indicatorTypeId: 8,
    geoAreaId: 51,
    partnerId: 1,
    year: 2017,
    value: 2.4779890791600376,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37022,
    indicatorTypeId: 8,
    geoAreaId: 41,
    partnerId: 1,
    year: 2017,
    value: 0.27175365775837856,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37055,
    indicatorTypeId: 8,
    geoAreaId: 190,
    partnerId: 1,
    year: 2017,
    value: 6.8267654038100893,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37070,
    indicatorTypeId: 8,
    geoAreaId: 181,
    partnerId: 1,
    year: 2017,
    value: 9.72753937968805,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37177,
    indicatorTypeId: 8,
    geoAreaId: 36,
    partnerId: 1,
    year: 2017,
    value: 6.0267898047796074,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37181,
    indicatorTypeId: 8,
    geoAreaId: 48,
    partnerId: 1,
    year: 2017,
    value: 0.49457680212330968,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37187,
    indicatorTypeId: 8,
    geoAreaId: 4,
    partnerId: 1,
    year: 2017,
    value: 8.44846251115373,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37207,
    indicatorTypeId: 8,
    geoAreaId: 8,
    partnerId: 1,
    year: 2017,
    value: 3.5290921062071567,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37236,
    indicatorTypeId: 8,
    geoAreaId: 240,
    partnerId: 1,
    year: 2017,
    value: 3.4824263563365108,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37258,
    indicatorTypeId: 8,
    geoAreaId: 234,
    partnerId: 1,
    year: 2017,
    value: 5.4787101700196121,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37265,
    indicatorTypeId: 8,
    geoAreaId: 14,
    partnerId: 1,
    year: 2017,
    value: 6.2329351996686766,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37325,
    indicatorTypeId: 8,
    geoAreaId: 96,
    partnerId: 1,
    year: 2017,
    value: 5.2812140021732823,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37335,
    indicatorTypeId: 8,
    geoAreaId: 12,
    partnerId: 1,
    year: 2017,
    value: 0.70574488295113735,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37413,
    indicatorTypeId: 8,
    geoAreaId: 134,
    partnerId: 1,
    year: 2017,
    value: 1.5187072918300903,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37513,
    indicatorTypeId: 8,
    geoAreaId: 49,
    partnerId: 1,
    year: 2017,
    value: 9.5592206038110259,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37533,
    indicatorTypeId: 8,
    geoAreaId: 236,
    partnerId: 1,
    year: 2017,
    value: 8.5442827133026924,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37558,
    indicatorTypeId: 8,
    geoAreaId: 1,
    partnerId: 1,
    year: 2017,
    value: 1.6816023019803315,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37704,
    indicatorTypeId: 8,
    geoAreaId: 170,
    partnerId: 1,
    year: 2017,
    value: 0.0,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37764,
    indicatorTypeId: 8,
    geoAreaId: 167,
    partnerId: 1,
    year: 2017,
    value: 8.9330094226419181,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37853,
    indicatorTypeId: 8,
    geoAreaId: 153,
    partnerId: 1,
    year: 2017,
    value: 5.5599594933734027,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37862,
    indicatorTypeId: 8,
    geoAreaId: 41,
    partnerId: 1,
    year: 2017,
    value: 0.035709157145743943,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 37866,
    indicatorTypeId: 8,
    geoAreaId: 228,
    partnerId: 1,
    year: 2017,
    value: 3.0652335373111614,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37927,
    indicatorTypeId: 8,
    geoAreaId: 250,
    partnerId: 1,
    year: 2017,
    value: 0.65141989384141386,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 37935,
    indicatorTypeId: 8,
    geoAreaId: 32,
    partnerId: 1,
    year: 2017,
    value: 40.33713465239699,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38001,
    indicatorTypeId: 8,
    geoAreaId: 20,
    partnerId: 1,
    year: 2017,
    value: 5.3957449712429186,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38026,
    indicatorTypeId: 8,
    geoAreaId: 246,
    partnerId: 1,
    year: 2017,
    value: 7.5806231870685163,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38066,
    indicatorTypeId: 8,
    geoAreaId: 121,
    partnerId: 1,
    year: 2017,
    value: 0.0,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38155,
    indicatorTypeId: 8,
    geoAreaId: 141,
    partnerId: 1,
    year: 2017,
    value: 9.2674880099480141,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38207,
    indicatorTypeId: 8,
    geoAreaId: 214,
    partnerId: 1,
    year: 2017,
    value: 18.282733969799693,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38247,
    indicatorTypeId: 8,
    geoAreaId: 107,
    partnerId: 1,
    year: 2017,
    value: 5.9790482037875172,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38292,
    indicatorTypeId: 8,
    geoAreaId: 222,
    partnerId: 1,
    year: 2017,
    value: 2.994548293578529,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38298,
    indicatorTypeId: 8,
    geoAreaId: 246,
    partnerId: 1,
    year: 2017,
    value: 5.6854234448424146,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38301,
    indicatorTypeId: 8,
    geoAreaId: 71,
    partnerId: 1,
    year: 2017,
    value: 10.230350297272803,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38347,
    indicatorTypeId: 8,
    geoAreaId: 249,
    partnerId: 1,
    year: 2017,
    value: 0.020856273154283969,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38413,
    indicatorTypeId: 8,
    geoAreaId: 40,
    partnerId: 1,
    year: 2017,
    value: 7.8167053372662778,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38414,
    indicatorTypeId: 8,
    geoAreaId: 176,
    partnerId: 1,
    year: 2017,
    value: 8.2905619486465039,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38435,
    indicatorTypeId: 8,
    geoAreaId: 146,
    partnerId: 1,
    year: 2017,
    value: 8.80169217208722,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38494,
    indicatorTypeId: 8,
    geoAreaId: 110,
    partnerId: 1,
    year: 2017,
    value: 3.9027829719624982,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38520,
    indicatorTypeId: 8,
    geoAreaId: 91,
    partnerId: 1,
    year: 2017,
    value: 5.0891189153491254,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38541,
    indicatorTypeId: 8,
    geoAreaId: 157,
    partnerId: 1,
    year: 2017,
    value: 2.5000000372529079,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38560,
    indicatorTypeId: 8,
    geoAreaId: 168,
    partnerId: 1,
    year: 2017,
    value: 5.5263109789968583,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38561,
    indicatorTypeId: 8,
    geoAreaId: 224,
    partnerId: 1,
    year: 2017,
    value: 0.87889719382711129,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38565,
    indicatorTypeId: 8,
    geoAreaId: 117,
    partnerId: 1,
    year: 2017,
    value: 4.833278612887,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38568,
    indicatorTypeId: 8,
    geoAreaId: 151,
    partnerId: 1,
    year: 2017,
    value: 3.8949612031576484,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38587,
    indicatorTypeId: 8,
    geoAreaId: 16,
    partnerId: 1,
    year: 2017,
    value: 1.1743339548699976,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38590,
    indicatorTypeId: 8,
    geoAreaId: 136,
    partnerId: 1,
    year: 2017,
    value: 4.2923598884226211,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38597,
    indicatorTypeId: 8,
    geoAreaId: 13,
    partnerId: 1,
    year: 2017,
    value: 7.2025762821658112,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38603,
    indicatorTypeId: 8,
    geoAreaId: 236,
    partnerId: 1,
    year: 2017,
    value: 9.20825056434821,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38615,
    indicatorTypeId: 8,
    geoAreaId: 5,
    partnerId: 1,
    year: 2017,
    value: 0.89143615633377016,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38653,
    indicatorTypeId: 8,
    geoAreaId: 208,
    partnerId: 1,
    year: 2017,
    value: 8.3184938232224,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38773,
    indicatorTypeId: 8,
    geoAreaId: 67,
    partnerId: 1,
    year: 2017,
    value: 7.4509848249301918,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38797,
    indicatorTypeId: 8,
    geoAreaId: 30,
    partnerId: 1,
    year: 2017,
    value: 7.754490485516631,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38826,
    indicatorTypeId: 8,
    geoAreaId: 149,
    partnerId: 1,
    year: 2017,
    value: 3.8091592960149625,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38895,
    indicatorTypeId: 8,
    geoAreaId: 207,
    partnerId: 1,
    year: 2017,
    value: 12.701631043233286,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38922,
    indicatorTypeId: 8,
    geoAreaId: 147,
    partnerId: 1,
    year: 2017,
    value: 0.084686799407367036,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38958,
    indicatorTypeId: 8,
    geoAreaId: 205,
    partnerId: 1,
    year: 2017,
    value: 9.9647182006739463,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 38965,
    indicatorTypeId: 8,
    geoAreaId: 80,
    partnerId: 1,
    year: 2017,
    value: 0.11613698119105831,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38966,
    indicatorTypeId: 8,
    geoAreaId: 135,
    partnerId: 1,
    year: 2017,
    value: 3.290299125756821,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38989,
    indicatorTypeId: 8,
    geoAreaId: 77,
    partnerId: 1,
    year: 2017,
    value: 5.0984446701180373,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 38999,
    indicatorTypeId: 8,
    geoAreaId: 182,
    partnerId: 1,
    year: 2017,
    value: 0.96183577796518338,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39010,
    indicatorTypeId: 8,
    geoAreaId: 24,
    partnerId: 1,
    year: 2017,
    value: 7.6758642596333582,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39024,
    indicatorTypeId: 8,
    geoAreaId: 29,
    partnerId: 1,
    year: 2017,
    value: 2.850157205235675,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39082,
    indicatorTypeId: 8,
    geoAreaId: 209,
    partnerId: 1,
    year: 2017,
    value: 4.7818488905104806,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39087,
    indicatorTypeId: 8,
    geoAreaId: 187,
    partnerId: 1,
    year: 2017,
    value: 5.4104572578807044,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39120,
    indicatorTypeId: 8,
    geoAreaId: 69,
    partnerId: 1,
    year: 2017,
    value: 1.7997483914003405,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39159,
    indicatorTypeId: 8,
    geoAreaId: 6,
    partnerId: 1,
    year: 2017,
    value: 3.1105009424355736,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39180,
    indicatorTypeId: 8,
    geoAreaId: 7,
    partnerId: 1,
    year: 2017,
    value: 8.3499545549783623,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39259,
    indicatorTypeId: 8,
    geoAreaId: 36,
    partnerId: 1,
    year: 2017,
    value: 0.95296627951313784,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39280,
    indicatorTypeId: 8,
    geoAreaId: 48,
    partnerId: 1,
    year: 2017,
    value: 2.1980557295535523,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39327,
    indicatorTypeId: 8,
    geoAreaId: 87,
    partnerId: 1,
    year: 2017,
    value: 3.6825487778158958,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39334,
    indicatorTypeId: 8,
    geoAreaId: 207,
    partnerId: 1,
    year: 2017,
    value: 9.9079596437340509,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39362,
    indicatorTypeId: 8,
    geoAreaId: 24,
    partnerId: 1,
    year: 2017,
    value: 6.038184687221511,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39387,
    indicatorTypeId: 8,
    geoAreaId: 35,
    partnerId: 1,
    year: 2017,
    value: 8.01996805183648,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39403,
    indicatorTypeId: 8,
    geoAreaId: 47,
    partnerId: 1,
    year: 2017,
    value: 14.085384544447651,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39405,
    indicatorTypeId: 8,
    geoAreaId: 104,
    partnerId: 1,
    year: 2017,
    value: 0.7813328867835877,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39415,
    indicatorTypeId: 8,
    geoAreaId: 184,
    partnerId: 1,
    year: 2017,
    value: 0.022552231620642092,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39448,
    indicatorTypeId: 8,
    geoAreaId: 130,
    partnerId: 1,
    year: 2017,
    value: 0.88691631878147092,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39452,
    indicatorTypeId: 8,
    geoAreaId: 240,
    partnerId: 1,
    year: 2017,
    value: 4.649090297846282,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39469,
    indicatorTypeId: 8,
    geoAreaId: 231,
    partnerId: 1,
    year: 2017,
    value: 2.6531320936218337,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39512,
    indicatorTypeId: 8,
    geoAreaId: 4,
    partnerId: 1,
    year: 2017,
    value: 6.7923323842522993,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39578,
    indicatorTypeId: 8,
    geoAreaId: 69,
    partnerId: 1,
    year: 2017,
    value: 4.5631452406215249,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39662,
    indicatorTypeId: 8,
    geoAreaId: 74,
    partnerId: 1,
    year: 2017,
    value: 10.919057362004313,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39789,
    indicatorTypeId: 8,
    geoAreaId: 40,
    partnerId: 1,
    year: 2017,
    value: 8.1360349930299467,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39819,
    indicatorTypeId: 8,
    geoAreaId: 182,
    partnerId: 1,
    year: 2017,
    value: 1.0484543409247304,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39820,
    indicatorTypeId: 8,
    geoAreaId: 105,
    partnerId: 1,
    year: 2017,
    value: 2.6775554634823187,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39832,
    indicatorTypeId: 8,
    geoAreaId: 255,
    partnerId: 1,
    year: 2017,
    value: 16.178513911024854,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39838,
    indicatorTypeId: 8,
    geoAreaId: 131,
    partnerId: 1,
    year: 2017,
    value: 4.3763911411718519,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 39893,
    indicatorTypeId: 8,
    geoAreaId: 117,
    partnerId: 1,
    year: 2017,
    value: 6.44841335500969,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 39989,
    indicatorTypeId: 8,
    geoAreaId: 248,
    partnerId: 1,
    year: 2017,
    value: 4.7048220034395456,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40029,
    indicatorTypeId: 8,
    geoAreaId: 130,
    partnerId: 1,
    year: 2017,
    value: 3.0081023471388018,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40049,
    indicatorTypeId: 8,
    geoAreaId: 2,
    partnerId: 1,
    year: 2017,
    value: 2.7164349502266742,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40060,
    indicatorTypeId: 8,
    geoAreaId: 205,
    partnerId: 1,
    year: 2017,
    value: 5.7158644558819951,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 40209,
    indicatorTypeId: 8,
    geoAreaId: 50,
    partnerId: 1,
    year: 2017,
    value: 14.792188389226821,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 40270,
    indicatorTypeId: 8,
    geoAreaId: 245,
    partnerId: 1,
    year: 2017,
    value: 8.4284511984415538,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 40376,
    indicatorTypeId: 8,
    geoAreaId: 54,
    partnerId: 1,
    year: 2017,
    value: 5.9609777062132236,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40379,
    indicatorTypeId: 8,
    geoAreaId: 6,
    partnerId: 1,
    year: 2017,
    value: 2.238045270516273,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 40449,
    indicatorTypeId: 8,
    geoAreaId: 67,
    partnerId: 1,
    year: 2017,
    value: 3.6764739596739289,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 40548,
    indicatorTypeId: 8,
    geoAreaId: 233,
    partnerId: 1,
    year: 2017,
    value: 4.0782728830709258,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 40564,
    indicatorTypeId: 8,
    geoAreaId: 44,
    partnerId: 1,
    year: 2017,
    value: 9.470053602845363,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40669,
    indicatorTypeId: 8,
    geoAreaId: 144,
    partnerId: 1,
    year: 2017,
    value: 1.943782670604578,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 40681,
    indicatorTypeId: 8,
    geoAreaId: 161,
    partnerId: 1,
    year: 2017,
    value: 5.3425490492777445,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 40695,
    indicatorTypeId: 8,
    geoAreaId: 151,
    partnerId: 1,
    year: 2017,
    value: 6.3227928506069535,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40699,
    indicatorTypeId: 8,
    geoAreaId: 102,
    partnerId: 1,
    year: 2017,
    value: 8.2039455988629939,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40794,
    indicatorTypeId: 8,
    geoAreaId: 237,
    partnerId: 1,
    year: 2017,
    value: 3.16656306476627,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40806,
    indicatorTypeId: 8,
    geoAreaId: 234,
    partnerId: 1,
    year: 2017,
    value: 8.2968220111403479,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40823,
    indicatorTypeId: 8,
    geoAreaId: 3,
    partnerId: 1,
    year: 2017,
    value: 9.9987736249797958,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 40891,
    indicatorTypeId: 8,
    geoAreaId: 245,
    partnerId: 1,
    year: 2017,
    value: 8.4284511984415538,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41028,
    indicatorTypeId: 8,
    geoAreaId: 123,
    partnerId: 1,
    year: 2017,
    value: 5.2293973439902777,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41080,
    indicatorTypeId: 8,
    geoAreaId: 31,
    partnerId: 1,
    year: 2017,
    value: 2.06304784316951,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41119,
    indicatorTypeId: 8,
    geoAreaId: 148,
    partnerId: 1,
    year: 2017,
    value: 10.943331718159573,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41139,
    indicatorTypeId: 8,
    geoAreaId: 255,
    partnerId: 1,
    year: 2017,
    value: 14.853760106256855,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41150,
    indicatorTypeId: 8,
    geoAreaId: 96,
    partnerId: 1,
    year: 2017,
    value: 1.5584671570294066,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41168,
    indicatorTypeId: 8,
    geoAreaId: 147,
    partnerId: 1,
    year: 2017,
    value: 0.022259993527656695,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41179,
    indicatorTypeId: 8,
    geoAreaId: 124,
    partnerId: 1,
    year: 2017,
    value: 3.3581190227101465,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41251,
    indicatorTypeId: 8,
    geoAreaId: 230,
    partnerId: 1,
    year: 2017,
    value: 1.3031886646885187,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41292,
    indicatorTypeId: 8,
    geoAreaId: 228,
    partnerId: 1,
    year: 2017,
    value: 2.5944739920995756,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41325,
    indicatorTypeId: 8,
    geoAreaId: 17,
    partnerId: 1,
    year: 2017,
    value: 4.6865576442182855,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41333,
    indicatorTypeId: 8,
    geoAreaId: 204,
    partnerId: 1,
    year: 2017,
    value: 8.6117923126893139,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41374,
    indicatorTypeId: 8,
    geoAreaId: 88,
    partnerId: 1,
    year: 2017,
    value: 7.0684034420390747,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41411,
    indicatorTypeId: 8,
    geoAreaId: 13,
    partnerId: 1,
    year: 2017,
    value: 6.1131710305915341,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41529,
    indicatorTypeId: 8,
    geoAreaId: 56,
    partnerId: 1,
    year: 2017,
    value: 3.8152566096045204,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41539,
    indicatorTypeId: 8,
    geoAreaId: 9,
    partnerId: 1,
    year: 2017,
    value: 2.5881075634740331,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41545,
    indicatorTypeId: 8,
    geoAreaId: 62,
    partnerId: 1,
    year: 2017,
    value: 6.450639019544516,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41561,
    indicatorTypeId: 8,
    geoAreaId: 29,
    partnerId: 1,
    year: 2017,
    value: 4.1191485290551615,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41662,
    indicatorTypeId: 8,
    geoAreaId: 111,
    partnerId: 1,
    year: 2017,
    value: 2.6083224294064444,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41678,
    indicatorTypeId: 8,
    geoAreaId: 179,
    partnerId: 1,
    year: 2017,
    value: 15.506699122542649,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41683,
    indicatorTypeId: 8,
    geoAreaId: 106,
    partnerId: 1,
    year: 2017,
    value: 3.584805316578505,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41706,
    indicatorTypeId: 8,
    geoAreaId: 26,
    partnerId: 1,
    year: 2017,
    value: 2.7084778487875214,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41712,
    indicatorTypeId: 8,
    geoAreaId: 242,
    partnerId: 1,
    year: 2017,
    value: 10.230547360697081,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41731,
    indicatorTypeId: 8,
    geoAreaId: 104,
    partnerId: 1,
    year: 2017,
    value: 0.46488605567208363,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41735,
    indicatorTypeId: 8,
    geoAreaId: 58,
    partnerId: 1,
    year: 2017,
    value: 7.5970952318913643,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41864,
    indicatorTypeId: 8,
    geoAreaId: 128,
    partnerId: 1,
    year: 2017,
    value: 8.89476398470471,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41884,
    indicatorTypeId: 8,
    geoAreaId: 191,
    partnerId: 1,
    year: 2017,
    value: 2.6212903189787822,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41894,
    indicatorTypeId: 8,
    geoAreaId: 163,
    partnerId: 1,
    year: 2017,
    value: 11.786285525446033,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 41918,
    indicatorTypeId: 8,
    geoAreaId: 39,
    partnerId: 1,
    year: 2017,
    value: 10.86111515703519,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41921,
    indicatorTypeId: 8,
    geoAreaId: 165,
    partnerId: 1,
    year: 2017,
    value: 10.061168371396448,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 41985,
    indicatorTypeId: 8,
    geoAreaId: 172,
    partnerId: 1,
    year: 2017,
    value: 2.1861057549351051,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42020,
    indicatorTypeId: 8,
    geoAreaId: 112,
    partnerId: 1,
    year: 2017,
    value: 7.0185600413501952,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42022,
    indicatorTypeId: 8,
    geoAreaId: 136,
    partnerId: 1,
    year: 2017,
    value: 1.7802951979563066,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42029,
    indicatorTypeId: 8,
    geoAreaId: 146,
    partnerId: 1,
    year: 2017,
    value: 8.7455773189573751,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42042,
    indicatorTypeId: 8,
    geoAreaId: 193,
    partnerId: 1,
    year: 2017,
    value: 1.0321384033435346,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42047,
    indicatorTypeId: 8,
    geoAreaId: 32,
    partnerId: 1,
    year: 2017,
    value: 50.451939424090433,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42129,
    indicatorTypeId: 8,
    geoAreaId: 208,
    partnerId: 1,
    year: 2017,
    value: 8.3184938232224,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42217,
    indicatorTypeId: 8,
    geoAreaId: 26,
    partnerId: 1,
    year: 2017,
    value: 0.857130889780339,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42219,
    indicatorTypeId: 8,
    geoAreaId: 100,
    partnerId: 1,
    year: 2017,
    value: 1.0246686235277696,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42228,
    indicatorTypeId: 8,
    geoAreaId: 74,
    partnerId: 1,
    year: 2017,
    value: 10.832953120733098,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42276,
    indicatorTypeId: 8,
    geoAreaId: 189,
    partnerId: 1,
    year: 2017,
    value: 5.51216639448708,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42284,
    indicatorTypeId: 8,
    geoAreaId: 87,
    partnerId: 1,
    year: 2017,
    value: 1.1893154842337861,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42337,
    indicatorTypeId: 8,
    geoAreaId: 172,
    partnerId: 1,
    year: 2017,
    value: 4.7388597828278982,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42355,
    indicatorTypeId: 8,
    geoAreaId: 210,
    partnerId: 1,
    year: 2017,
    value: 7.7108762461524343,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42357,
    indicatorTypeId: 8,
    geoAreaId: 5,
    partnerId: 1,
    year: 2017,
    value: 1.7703798164017917,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42361,
    indicatorTypeId: 8,
    geoAreaId: 89,
    partnerId: 1,
    year: 2017,
    value: 6.4475320596563312,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42411,
    indicatorTypeId: 8,
    geoAreaId: 60,
    partnerId: 1,
    year: 2017,
    value: 3.5955425883870182,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42491,
    indicatorTypeId: 8,
    geoAreaId: 1,
    partnerId: 1,
    year: 2017,
    value: 2.6720218746054307,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42548,
    indicatorTypeId: 8,
    geoAreaId: 35,
    partnerId: 1,
    year: 2017,
    value: 5.4014964166569728,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42565,
    indicatorTypeId: 8,
    geoAreaId: 31,
    partnerId: 1,
    year: 2017,
    value: 1.893298355195389,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42674,
    indicatorTypeId: 8,
    geoAreaId: 159,
    partnerId: 1,
    year: 2017,
    value: 4.1943518026806137,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42688,
    indicatorTypeId: 8,
    geoAreaId: 132,
    partnerId: 1,
    year: 2017,
    value: 3.4764873106256164,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42760,
    indicatorTypeId: 8,
    geoAreaId: 133,
    partnerId: 1,
    year: 2017,
    value: 0.44523223828890252,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42817,
    indicatorTypeId: 8,
    geoAreaId: 143,
    partnerId: 1,
    year: 2017,
    value: 0.82988541255379855,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42818,
    indicatorTypeId: 8,
    geoAreaId: 161,
    partnerId: 1,
    year: 2017,
    value: 5.5463945485616044,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 42846,
    indicatorTypeId: 8,
    geoAreaId: 196,
    partnerId: 1,
    year: 2017,
    value: 1.5267144893944828,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42852,
    indicatorTypeId: 8,
    geoAreaId: 47,
    partnerId: 1,
    year: 2017,
    value: 13.993272485092575,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42917,
    indicatorTypeId: 8,
    geoAreaId: 256,
    partnerId: 1,
    year: 2017,
    value: 10.534565184190036,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 42922,
    indicatorTypeId: 8,
    geoAreaId: 241,
    partnerId: 1,
    year: 2017,
    value: 1.5917686426021653,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43063,
    indicatorTypeId: 8,
    geoAreaId: 283,
    partnerId: 1,
    year: 2017,
    value: 22.253898589443263,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43125,
    indicatorTypeId: 8,
    geoAreaId: 164,
    partnerId: 1,
    year: 2017,
    value: 12.02237603659138,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43173,
    indicatorTypeId: 8,
    geoAreaId: 16,
    partnerId: 1,
    year: 2017,
    value: 1.4981193868037126,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43183,
    indicatorTypeId: 8,
    geoAreaId: 202,
    partnerId: 1,
    year: 2017,
    value: 1.685304195384147,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43196,
    indicatorTypeId: 8,
    geoAreaId: 54,
    partnerId: 1,
    year: 2017,
    value: 0.32102239042448089,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43203,
    indicatorTypeId: 8,
    geoAreaId: 100,
    partnerId: 1,
    year: 2017,
    value: 2.8494703282239739,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43218,
    indicatorTypeId: 8,
    geoAreaId: 296,
    partnerId: 1,
    year: 2017,
    value: 0.55241359427922687,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43220,
    indicatorTypeId: 8,
    geoAreaId: 77,
    partnerId: 1,
    year: 2017,
    value: 7.694528370609091,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43258,
    indicatorTypeId: 8,
    geoAreaId: 28,
    partnerId: 1,
    year: 2017,
    value: 19.503738701880089,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43295,
    indicatorTypeId: 8,
    geoAreaId: 121,
    partnerId: 1,
    year: 2017,
    value: 0.0,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43309,
    indicatorTypeId: 8,
    geoAreaId: 191,
    partnerId: 1,
    year: 2017,
    value: 2.9482769976234797,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43344,
    indicatorTypeId: 8,
    geoAreaId: 110,
    partnerId: 1,
    year: 2017,
    value: 3.1082039486262194,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43363,
    indicatorTypeId: 8,
    geoAreaId: 97,
    partnerId: 1,
    year: 2017,
    value: 18.204758799830422,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43383,
    indicatorTypeId: 8,
    geoAreaId: 21,
    partnerId: 1,
    year: 2017,
    value: 0.54774488057253212,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43419,
    indicatorTypeId: 8,
    geoAreaId: 71,
    partnerId: 1,
    year: 2017,
    value: 10.212134287241669,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43444,
    indicatorTypeId: 8,
    geoAreaId: 204,
    partnerId: 1,
    year: 2017,
    value: 6.5805660307680478,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43445,
    indicatorTypeId: 8,
    geoAreaId: 53,
    partnerId: 1,
    year: 2017,
    value: 12.469264888273385,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43446,
    indicatorTypeId: 8,
    geoAreaId: 92,
    partnerId: 1,
    year: 2017,
    value: 0.0,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43451,
    indicatorTypeId: 8,
    geoAreaId: 112,
    partnerId: 1,
    year: 2017,
    value: 1.2154534554798833,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43466,
    indicatorTypeId: 8,
    geoAreaId: 56,
    partnerId: 1,
    year: 2017,
    value: 8.203702636842646,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43473,
    indicatorTypeId: 8,
    geoAreaId: 81,
    partnerId: 1,
    year: 2017,
    value: 14.120607236032393,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43488,
    indicatorTypeId: 8,
    geoAreaId: 169,
    partnerId: 1,
    year: 2017,
    value: 13.18041531225621,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43537,
    indicatorTypeId: 8,
    geoAreaId: 214,
    partnerId: 1,
    year: 2017,
    value: 18.282733969799693,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43550,
    indicatorTypeId: 8,
    geoAreaId: 60,
    partnerId: 1,
    year: 2017,
    value: 1.0288300960841297,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43562,
    indicatorTypeId: 8,
    geoAreaId: 19,
    partnerId: 1,
    year: 2017,
    value: 4.7244892921445762,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43649,
    indicatorTypeId: 8,
    geoAreaId: 179,
    partnerId: 1,
    year: 2017,
    value: 12.514386867277169,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43657,
    indicatorTypeId: 8,
    geoAreaId: 157,
    partnerId: 1,
    year: 2017,
    value: 2.5000000372529079,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43658,
    indicatorTypeId: 8,
    geoAreaId: 232,
    partnerId: 1,
    year: 2017,
    value: 6.3087892554046565,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43659,
    indicatorTypeId: 8,
    geoAreaId: 18,
    partnerId: 1,
    year: 2017,
    value: 1.985714017387652,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43801,
    indicatorTypeId: 8,
    geoAreaId: 138,
    partnerId: 1,
    year: 2017,
    value: 13.423155465057695,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43818,
    indicatorTypeId: 8,
    geoAreaId: 249,
    partnerId: 1,
    year: 2017,
    value: 0.078723015981174732,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43833,
    indicatorTypeId: 8,
    geoAreaId: 187,
    partnerId: 1,
    year: 2017,
    value: 2.4840372525752574,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43896,
    indicatorTypeId: 8,
    geoAreaId: 10,
    partnerId: 1,
    year: 2017,
    value: 7.1064388804002441,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 43918,
    indicatorTypeId: 8,
    geoAreaId: 28,
    partnerId: 1,
    year: 2017,
    value: 19.979472177643594,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 43989,
    indicatorTypeId: 8,
    geoAreaId: 197,
    partnerId: 1,
    year: 2017,
    value: 3.849539707386286,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 44013,
    indicatorTypeId: 8,
    geoAreaId: 169,
    partnerId: 1,
    year: 2017,
    value: 13.18041531225621,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44014,
    indicatorTypeId: 8,
    geoAreaId: 45,
    partnerId: 1,
    year: 2017,
    value: 2.2596897066777362,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 44022,
    indicatorTypeId: 8,
    geoAreaId: 22,
    partnerId: 1,
    year: 2017,
    value: 7.7764237467673771,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44054,
    indicatorTypeId: 8,
    geoAreaId: 94,
    partnerId: 1,
    year: 2017,
    value: 0.0041072346123216547,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44066,
    indicatorTypeId: 8,
    geoAreaId: 113,
    partnerId: 1,
    year: 2017,
    value: 2.1880829664289689,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44106,
    indicatorTypeId: 8,
    geoAreaId: 296,
    partnerId: 1,
    year: 2017,
    value: 0.89563833900410539,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 44157,
    indicatorTypeId: 8,
    geoAreaId: 80,
    partnerId: 1,
    year: 2017,
    value: 0.8277306088669304,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 44245,
    indicatorTypeId: 8,
    geoAreaId: 166,
    partnerId: 1,
    year: 2017,
    value: 3.8408463124524337,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 44265,
    indicatorTypeId: 8,
    geoAreaId: 163,
    partnerId: 1,
    year: 2017,
    value: 8.50447557855702,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44340,
    indicatorTypeId: 8,
    geoAreaId: 176,
    partnerId: 1,
    year: 2017,
    value: 3.8504903906928107,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44365,
    indicatorTypeId: 8,
    geoAreaId: 124,
    partnerId: 1,
    year: 2017,
    value: 1.0403052390013505,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44369,
    indicatorTypeId: 8,
    geoAreaId: 209,
    partnerId: 1,
    year: 2017,
    value: 7.0688892223779884,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 44376,
    indicatorTypeId: 8,
    geoAreaId: 168,
    partnerId: 1,
    year: 2017,
    value: 0.95482090608192527,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44410,
    indicatorTypeId: 8,
    geoAreaId: 210,
    partnerId: 1,
    year: 2017,
    value: 3.3055090871355839,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44484,
    indicatorTypeId: 8,
    geoAreaId: 126,
    partnerId: 1,
    year: 2017,
    value: 5.4099517818545717,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44571,
    indicatorTypeId: 8,
    geoAreaId: 43,
    partnerId: 1,
    year: 2017,
    value: 1.6152245335658066,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44585,
    indicatorTypeId: 8,
    geoAreaId: 193,
    partnerId: 1,
    year: 2017,
    value: 3.0944354783366346,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 44589,
    indicatorTypeId: 8,
    geoAreaId: 145,
    partnerId: 1,
    year: 2017,
    value: 7.2365127047851212,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44652,
    indicatorTypeId: 8,
    geoAreaId: 170,
    partnerId: 1,
    year: 2017,
    value: 0.0,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44693,
    indicatorTypeId: 8,
    geoAreaId: 122,
    partnerId: 1,
    year: 2017,
    value: 5.0209237427513447,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44711,
    indicatorTypeId: 8,
    geoAreaId: 114,
    partnerId: 1,
    year: 2017,
    value: 2.7644006258091456,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 44721,
    indicatorTypeId: 8,
    geoAreaId: 164,
    partnerId: 1,
    year: 2017,
    value: 9.0874448876880916,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44879,
    indicatorTypeId: 8,
    geoAreaId: 250,
    partnerId: 1,
    year: 2017,
    value: 0.022552231620642092,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44918,
    indicatorTypeId: 8,
    geoAreaId: 238,
    partnerId: 1,
    year: 2017,
    value: 7.4208710189398825,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 44940,
    indicatorTypeId: 8,
    geoAreaId: 44,
    partnerId: 1,
    year: 2017,
    value: 6.0944420882108954,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 44992,
    indicatorTypeId: 8,
    geoAreaId: 58,
    partnerId: 1,
    year: 2017,
    value: 6.868994638226865,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45048,
    indicatorTypeId: 8,
    geoAreaId: 51,
    partnerId: 1,
    year: 2017,
    value: 2.6698893497665783,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45069,
    indicatorTypeId: 8,
    geoAreaId: 235,
    partnerId: 1,
    year: 2017,
    value: 5.8007099639453781,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45094,
    indicatorTypeId: 8,
    geoAreaId: 195,
    partnerId: 1,
    year: 2017,
    value: 7.5699896616645139,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45138,
    indicatorTypeId: 8,
    geoAreaId: 84,
    partnerId: 1,
    year: 2017,
    value: 10.035832513643131,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45152,
    indicatorTypeId: 8,
    geoAreaId: 109,
    partnerId: 1,
    year: 2017,
    value: 4.9274323384490826,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45370,
    indicatorTypeId: 8,
    geoAreaId: 141,
    partnerId: 1,
    year: 2017,
    value: 8.4861699742472432,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45386,
    indicatorTypeId: 8,
    geoAreaId: 2,
    partnerId: 1,
    year: 2017,
    value: 0.99972064185475606,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45391,
    indicatorTypeId: 8,
    geoAreaId: 39,
    partnerId: 1,
    year: 2017,
    value: 11.385624810211613,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45416,
    indicatorTypeId: 8,
    geoAreaId: 12,
    partnerId: 1,
    year: 2017,
    value: 1.0835564173733694,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45443,
    indicatorTypeId: 8,
    geoAreaId: 192,
    partnerId: 1,
    year: 2017,
    value: 11.470563932534,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45444,
    indicatorTypeId: 8,
    geoAreaId: 235,
    partnerId: 1,
    year: 2017,
    value: 9.3291614801723,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45460,
    indicatorTypeId: 8,
    geoAreaId: 20,
    partnerId: 1,
    year: 2017,
    value: 5.4223187301252436,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45496,
    indicatorTypeId: 8,
    geoAreaId: 197,
    partnerId: 1,
    year: 2017,
    value: 0.58907892786954252,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45579,
    indicatorTypeId: 8,
    geoAreaId: 62,
    partnerId: 1,
    year: 2017,
    value: 7.8550296316236734,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45583,
    indicatorTypeId: 8,
    geoAreaId: 239,
    partnerId: 1,
    year: 2017,
    value: 8.8900496702336067,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45602,
    indicatorTypeId: 8,
    geoAreaId: 95,
    partnerId: 1,
    year: 2017,
    value: 6.883789411535564,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45638,
    indicatorTypeId: 8,
    geoAreaId: 11,
    partnerId: 1,
    year: 2017,
    value: 3.4939545971697981,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45682,
    indicatorTypeId: 8,
    geoAreaId: 65,
    partnerId: 1,
    year: 2017,
    value: 9.7398648547832121,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45710,
    indicatorTypeId: 8,
    geoAreaId: 89,
    partnerId: 1,
    year: 2017,
    value: 12.347179277402342,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45729,
    indicatorTypeId: 8,
    geoAreaId: 53,
    partnerId: 1,
    year: 2017,
    value: 13.453769194945792,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45739,
    indicatorTypeId: 8,
    geoAreaId: 111,
    partnerId: 1,
    year: 2017,
    value: 2.3304762236139638,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45740,
    indicatorTypeId: 8,
    geoAreaId: 148,
    partnerId: 1,
    year: 2017,
    value: 9.7281091075633412,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45753,
    indicatorTypeId: 8,
    geoAreaId: 134,
    partnerId: 1,
    year: 2017,
    value: 8.5441612473384616,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45776,
    indicatorTypeId: 8,
    geoAreaId: 145,
    partnerId: 1,
    year: 2017,
    value: 9.92313513206217,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45787,
    indicatorTypeId: 8,
    geoAreaId: 103,
    partnerId: 1,
    year: 2017,
    value: 10.184361132521746,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45802,
    indicatorTypeId: 8,
    geoAreaId: 52,
    partnerId: 1,
    year: 2017,
    value: 6.73364790037146,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45838,
    indicatorTypeId: 8,
    geoAreaId: 224,
    partnerId: 1,
    year: 2017,
    value: 4.27169596926474,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45839,
    indicatorTypeId: 8,
    geoAreaId: 82,
    partnerId: 1,
    year: 2017,
    value: 0.80212255388337639,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45905,
    indicatorTypeId: 8,
    geoAreaId: 65,
    partnerId: 1,
    year: 2017,
    value: 7.9166793727815969,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45923,
    indicatorTypeId: 8,
    geoAreaId: 38,
    partnerId: 1,
    year: 2017,
    value: 7.8795219738120288,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 45937,
    indicatorTypeId: 8,
    geoAreaId: 84,
    partnerId: 1,
    year: 2017,
    value: 10.341346706450572,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 45968,
    indicatorTypeId: 8,
    geoAreaId: 132,
    partnerId: 1,
    year: 2017,
    value: 2.6961520214035235,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46007,
    indicatorTypeId: 8,
    geoAreaId: 129,
    partnerId: 1,
    year: 2017,
    value: 0.78312533541256379,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46033,
    indicatorTypeId: 8,
    geoAreaId: 195,
    partnerId: 1,
    year: 2017,
    value: 5.7544356234278258,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46044,
    indicatorTypeId: 8,
    geoAreaId: 244,
    partnerId: 1,
    year: 2017,
    value: 10.414583130544679,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46093,
    indicatorTypeId: 8,
    geoAreaId: 90,
    partnerId: 1,
    year: 2017,
    value: 5.7679062351280619,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46113,
    indicatorTypeId: 8,
    geoAreaId: 19,
    partnerId: 1,
    year: 2017,
    value: 3.0819991200610413,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46132,
    indicatorTypeId: 8,
    geoAreaId: 190,
    partnerId: 1,
    year: 2017,
    value: 6.637625914536879,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46137,
    indicatorTypeId: 8,
    geoAreaId: 91,
    partnerId: 1,
    year: 2017,
    value: 2.6217051553435304,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46139,
    indicatorTypeId: 8,
    geoAreaId: 25,
    partnerId: 1,
    year: 2017,
    value: 8.3318240517723652,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46203,
    indicatorTypeId: 8,
    geoAreaId: 229,
    partnerId: 1,
    year: 2017,
    value: 2.2632195261202379,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46259,
    indicatorTypeId: 8,
    geoAreaId: 23,
    partnerId: 1,
    year: 2017,
    value: 7.1114378364371591,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46260,
    indicatorTypeId: 8,
    geoAreaId: 46,
    partnerId: 1,
    year: 2017,
    value: 10.891581967039654,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46264,
    indicatorTypeId: 8,
    geoAreaId: 237,
    partnerId: 1,
    year: 2017,
    value: 1.0234638534005141,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46314,
    indicatorTypeId: 8,
    geoAreaId: 107,
    partnerId: 1,
    year: 2017,
    value: 6.5049151186533161,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46387,
    indicatorTypeId: 8,
    geoAreaId: 106,
    partnerId: 1,
    year: 2017,
    value: 9.67506185363616,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46389,
    indicatorTypeId: 8,
    geoAreaId: 231,
    partnerId: 1,
    year: 2017,
    value: 3.6557463781561226,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46390,
    indicatorTypeId: 8,
    geoAreaId: 232,
    partnerId: 1,
    year: 2017,
    value: 7.4563464736589591,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46405,
    indicatorTypeId: 8,
    geoAreaId: 114,
    partnerId: 1,
    year: 2017,
    value: 2.7171159405721608,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46409,
    indicatorTypeId: 8,
    geoAreaId: 25,
    partnerId: 1,
    year: 2017,
    value: 15.364736887674932,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46431,
    indicatorTypeId: 8,
    geoAreaId: 202,
    partnerId: 1,
    year: 2017,
    value: 0.95688275241431942,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46439,
    indicatorTypeId: 8,
    geoAreaId: 248,
    partnerId: 1,
    year: 2017,
    value: 4.3502992143970225,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46500,
    indicatorTypeId: 8,
    geoAreaId: 123,
    partnerId: 1,
    year: 2017,
    value: 6.854394274209155,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46516,
    indicatorTypeId: 8,
    geoAreaId: 9,
    partnerId: 1,
    year: 2017,
    value: 0.85306994766584754,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46612,
    indicatorTypeId: 8,
    geoAreaId: 37,
    partnerId: 1,
    year: 2017,
    value: 0.57511508846635873,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46663,
    indicatorTypeId: 8,
    geoAreaId: 233,
    partnerId: 1,
    year: 2017,
    value: 9.7147992884620038,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46668,
    indicatorTypeId: 8,
    geoAreaId: 3,
    partnerId: 1,
    year: 2017,
    value: 4.4918918923911324,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46677,
    indicatorTypeId: 8,
    geoAreaId: 50,
    partnerId: 1,
    year: 2017,
    value: 16.578968623788111,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46686,
    indicatorTypeId: 8,
    geoAreaId: 88,
    partnerId: 1,
    year: 2017,
    value: 8.6978559802359765,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46743,
    indicatorTypeId: 8,
    geoAreaId: 49,
    partnerId: 1,
    year: 2017,
    value: 9.5592206038110259,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46744,
    indicatorTypeId: 8,
    geoAreaId: 133,
    partnerId: 1,
    year: 2017,
    value: 2.3693688882890305,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46853,
    indicatorTypeId: 8,
    geoAreaId: 138,
    partnerId: 1,
    year: 2017,
    value: 14.031331012746426,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46860,
    indicatorTypeId: 8,
    geoAreaId: 55,
    partnerId: 1,
    year: 2017,
    value: 3.621250874462234,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46873,
    indicatorTypeId: 8,
    geoAreaId: 125,
    partnerId: 1,
    year: 2017,
    value: 12.464877899905309,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46875,
    indicatorTypeId: 8,
    geoAreaId: 162,
    partnerId: 1,
    year: 2017,
    value: 9.60413326381173,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46933,
    indicatorTypeId: 8,
    geoAreaId: 82,
    partnerId: 1,
    year: 2017,
    value: 2.1665751080839515,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 46943,
    indicatorTypeId: 8,
    geoAreaId: 192,
    partnerId: 1,
    year: 2017,
    value: 2.6633894421414572,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46948,
    indicatorTypeId: 8,
    geoAreaId: 23,
    partnerId: 1,
    year: 2017,
    value: 7.1114378364371591,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 46949,
    indicatorTypeId: 8,
    geoAreaId: 37,
    partnerId: 1,
    year: 2017,
    value: 0.44781001175448365,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47021,
    indicatorTypeId: 8,
    geoAreaId: 8,
    partnerId: 1,
    year: 2017,
    value: 1.3177421799599527,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47030,
    indicatorTypeId: 8,
    geoAreaId: 198,
    partnerId: 1,
    year: 2017,
    value: 3.7250334284413973,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47078,
    indicatorTypeId: 8,
    geoAreaId: 90,
    partnerId: 1,
    year: 2017,
    value: 5.7679062351280619,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47174,
    indicatorTypeId: 8,
    geoAreaId: 95,
    partnerId: 1,
    year: 2017,
    value: 5.7601715851677673,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47199,
    indicatorTypeId: 8,
    geoAreaId: 18,
    partnerId: 1,
    year: 2017,
    value: 3.2252714283610011,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47201,
    indicatorTypeId: 8,
    geoAreaId: 129,
    partnerId: 1,
    year: 2017,
    value: 0.91817429158984309,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47218,
    indicatorTypeId: 8,
    geoAreaId: 94,
    partnerId: 1,
    year: 2017,
    value: 0.0075638019696186894,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47243,
    indicatorTypeId: 8,
    geoAreaId: 242,
    partnerId: 1,
    year: 2017,
    value: 7.8699205089421618,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47257,
    indicatorTypeId: 8,
    geoAreaId: 175,
    partnerId: 1,
    year: 2017,
    value: 4.0245323324771309,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47274,
    indicatorTypeId: 8,
    geoAreaId: 162,
    partnerId: 1,
    year: 2017,
    value: 14.061470410986535,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47293,
    indicatorTypeId: 8,
    geoAreaId: 135,
    partnerId: 1,
    year: 2017,
    value: 6.0433638174847637,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47384,
    indicatorTypeId: 8,
    geoAreaId: 68,
    partnerId: 1,
    year: 2017,
    value: 9.2628838356087755,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47401,
    indicatorTypeId: 8,
    geoAreaId: 211,
    partnerId: 1,
    year: 2017,
    value: 13.438720000528543,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47417,
    indicatorTypeId: 8,
    geoAreaId: 243,
    partnerId: 1,
    year: 2017,
    value: 0.83029499261455986,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47478,
    indicatorTypeId: 8,
    geoAreaId: 283,
    partnerId: 1,
    year: 2017,
    value: 22.253898589443263,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47495,
    indicatorTypeId: 8,
    geoAreaId: 103,
    partnerId: 1,
    year: 2017,
    value: 11.179516804402436,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47550,
    indicatorTypeId: 8,
    geoAreaId: 184,
    partnerId: 1,
    year: 2017,
    value: 0.65141989384141386,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47598,
    indicatorTypeId: 8,
    geoAreaId: 149,
    partnerId: 1,
    year: 2017,
    value: 3.78653417771945,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47661,
    indicatorTypeId: 8,
    geoAreaId: 30,
    partnerId: 1,
    year: 2017,
    value: 8.0118613320202829,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47735,
    indicatorTypeId: 8,
    geoAreaId: 247,
    partnerId: 1,
    year: 2017,
    value: 3.74601964250021,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47795,
    indicatorTypeId: 8,
    geoAreaId: 15,
    partnerId: 1,
    year: 2017,
    value: 1.2915699488075034,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47896,
    indicatorTypeId: 8,
    geoAreaId: 81,
    partnerId: 1,
    year: 2017,
    value: 14.120607236032393,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47897,
    indicatorTypeId: 8,
    geoAreaId: 137,
    partnerId: 1,
    year: 2017,
    value: 0.9820464223876052,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 47966,
    indicatorTypeId: 8,
    geoAreaId: 68,
    partnerId: 1,
    year: 2017,
    value: 6.9664703620084838,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47974,
    indicatorTypeId: 8,
    geoAreaId: 239,
    partnerId: 1,
    year: 2017,
    value: 8.6720714603793,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 47983,
    indicatorTypeId: 8,
    geoAreaId: 201,
    partnerId: 1,
    year: 2017,
    value: 6.9284996087730075,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48064,
    indicatorTypeId: 8,
    geoAreaId: 55,
    partnerId: 1,
    year: 2017,
    value: 4.4172422339579684,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48083,
    indicatorTypeId: 8,
    geoAreaId: 46,
    partnerId: 1,
    year: 2017,
    value: 4.7777037876965469,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48115,
    indicatorTypeId: 8,
    geoAreaId: 15,
    partnerId: 1,
    year: 2017,
    value: 0.761983935941236,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48186,
    indicatorTypeId: 8,
    geoAreaId: 125,
    partnerId: 1,
    year: 2017,
    value: 11.759631870526906,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48277,
    indicatorTypeId: 8,
    geoAreaId: 188,
    partnerId: 1,
    year: 2017,
    value: 9.6910311958514246,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48374,
    indicatorTypeId: 8,
    geoAreaId: 105,
    partnerId: 1,
    year: 2017,
    value: 2.4963569026361156,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48406,
    indicatorTypeId: 8,
    geoAreaId: 10,
    partnerId: 1,
    year: 2017,
    value: 8.017638214013262,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48482,
    indicatorTypeId: 8,
    geoAreaId: 14,
    partnerId: 1,
    year: 2017,
    value: 3.459667464593144,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48527,
    indicatorTypeId: 8,
    geoAreaId: 113,
    partnerId: 1,
    year: 2017,
    value: 4.4033003402067719,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48583,
    indicatorTypeId: 8,
    geoAreaId: 97,
    partnerId: 1,
    year: 2017,
    value: 18.204758799830422,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48585,
    indicatorTypeId: 8,
    geoAreaId: 181,
    partnerId: 1,
    year: 2017,
    value: 9.34106932167419,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48598,
    indicatorTypeId: 8,
    geoAreaId: 230,
    partnerId: 1,
    year: 2017,
    value: 0.74764853722240321,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48599,
    indicatorTypeId: 8,
    geoAreaId: 45,
    partnerId: 1,
    year: 2017,
    value: 2.0404093258267593,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48641,
    indicatorTypeId: 8,
    geoAreaId: 131,
    partnerId: 1,
    year: 2017,
    value: 4.68828568622393,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48671,
    indicatorTypeId: 8,
    geoAreaId: 229,
    partnerId: 1,
    year: 2017,
    value: 3.5464805068783183,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48679,
    indicatorTypeId: 8,
    geoAreaId: 256,
    partnerId: 1,
    year: 2017,
    value: 10.534565184190036,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48745,
    indicatorTypeId: 8,
    geoAreaId: 167,
    partnerId: 1,
    year: 2017,
    value: 9.2581908911247339,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48883,
    indicatorTypeId: 8,
    geoAreaId: 201,
    partnerId: 1,
    year: 2017,
    value: 5.8028270953425238,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 48890,
    indicatorTypeId: 8,
    geoAreaId: 143,
    partnerId: 1,
    year: 2017,
    value: 1.9008953285521768,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48987,
    indicatorTypeId: 8,
    geoAreaId: 43,
    partnerId: 1,
    year: 2017,
    value: 3.0474724533554594,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 48997,
    indicatorTypeId: 8,
    geoAreaId: 152,
    partnerId: 1,
    year: 2017,
    value: 0.52277388218774468,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49007,
    indicatorTypeId: 8,
    geoAreaId: 109,
    partnerId: 1,
    year: 2017,
    value: 2.0835150656671115,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49014,
    indicatorTypeId: 8,
    geoAreaId: 92,
    partnerId: 1,
    year: 2017,
    value: 0.0,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49019,
    indicatorTypeId: 8,
    geoAreaId: 175,
    partnerId: 1,
    year: 2017,
    value: 5.4107051833920732,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49060,
    indicatorTypeId: 8,
    geoAreaId: 11,
    partnerId: 1,
    year: 2017,
    value: 2.1731555009572281,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49067,
    indicatorTypeId: 8,
    geoAreaId: 166,
    partnerId: 1,
    year: 2017,
    value: 3.3177660903829729,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49084,
    indicatorTypeId: 8,
    geoAreaId: 128,
    partnerId: 1,
    year: 2017,
    value: 8.89476398470471,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49125,
    indicatorTypeId: 8,
    geoAreaId: 52,
    partnerId: 1,
    year: 2017,
    value: 6.23286056112399,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49158,
    indicatorTypeId: 8,
    geoAreaId: 7,
    partnerId: 1,
    year: 2017,
    value: 7.3940570055840285,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49165,
    indicatorTypeId: 8,
    geoAreaId: 222,
    partnerId: 1,
    year: 2017,
    value: 2.994548293578529,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49242,
    indicatorTypeId: 8,
    geoAreaId: 38,
    partnerId: 1,
    year: 2017,
    value: 9.680672442921038,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49319,
    indicatorTypeId: 8,
    geoAreaId: 153,
    partnerId: 1,
    year: 2017,
    value: 1.3156282493163165,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49320,
    indicatorTypeId: 8,
    geoAreaId: 238,
    partnerId: 1,
    year: 2017,
    value: 5.7051927320859512,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49324,
    indicatorTypeId: 8,
    geoAreaId: 247,
    partnerId: 1,
    year: 2017,
    value: 2.5288287274057653,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49326,
    indicatorTypeId: 8,
    geoAreaId: 21,
    partnerId: 1,
    year: 2017,
    value: 3.0083546766971114,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49398,
    indicatorTypeId: 8,
    geoAreaId: 188,
    partnerId: 1,
    year: 2017,
    value: 8.0248658527510983,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49482,
    indicatorTypeId: 8,
    geoAreaId: 152,
    partnerId: 1,
    year: 2017,
    value: 1.6179362072268617,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49524,
    indicatorTypeId: 8,
    geoAreaId: 159,
    partnerId: 1,
    year: 2017,
    value: 3.2703468292559239,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49619,
    indicatorTypeId: 8,
    geoAreaId: 22,
    partnerId: 1,
    year: 2017,
    value: 11.81228952010451,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49644,
    indicatorTypeId: 8,
    geoAreaId: 165,
    partnerId: 1,
    year: 2017,
    value: 10.061168371396448,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49718,
    indicatorTypeId: 8,
    geoAreaId: 137,
    partnerId: 1,
    year: 2017,
    value: 0.82231474645704572,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49858,
    indicatorTypeId: 8,
    geoAreaId: 241,
    partnerId: 1,
    year: 2017,
    value: 2.9872766343088322,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49859,
    indicatorTypeId: 8,
    geoAreaId: 211,
    partnerId: 1,
    year: 2017,
    value: 14.543934774081111,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49880,
    indicatorTypeId: 8,
    geoAreaId: 198,
    partnerId: 1,
    year: 2017,
    value: 8.4778295499827134,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49911,
    indicatorTypeId: 8,
    geoAreaId: 189,
    partnerId: 1,
    year: 2017,
    value: 5.51216639448708,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 49978,
    indicatorTypeId: 8,
    geoAreaId: 17,
    partnerId: 1,
    year: 2017,
    value: 2.5982760268173934,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 49998,
    indicatorTypeId: 8,
    geoAreaId: 102,
    partnerId: 1,
    year: 2017,
    value: 7.8113131744260338,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 50031,
    indicatorTypeId: 8,
    geoAreaId: 244,
    partnerId: 1,
    year: 2017,
    value: 10.286202473156731,
    productTypeId: 1,
    tariffRegimeStatusId: 1,
  },
  {
    id: 50097,
    indicatorTypeId: 8,
    geoAreaId: 196,
    partnerId: 1,
    year: 2017,
    value: 2.5540325166819389,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 50137,
    indicatorTypeId: 8,
    geoAreaId: 144,
    partnerId: 1,
    year: 2017,
    value: 5.3626318769766748,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 50146,
    indicatorTypeId: 8,
    geoAreaId: 122,
    partnerId: 1,
    year: 2017,
    value: 7.7778998163056405,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 50155,
    indicatorTypeId: 8,
    geoAreaId: 243,
    partnerId: 1,
    year: 2017,
    value: 4.9843038274348839,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
  {
    id: 50160,
    indicatorTypeId: 8,
    geoAreaId: 126,
    partnerId: 1,
    year: 2017,
    value: 10.02496199541282,
    productTypeId: 1,
    tariffRegimeStatusId: 2,
  },
];
