import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import { GoalsStyled } from "./Styled";

const HomeFeaturedGoalsLoading = ({ goals }) => (
    <GoalsStyled>
        <ul>
            {goals.map(goal => (
                <li key={`goal-${goal}`} className="item">
                    <div className="inner-wrapper">
                        <div className="goal-targets">
                            <div className="goal-heading">
                                <div className="img">
                                    <Skeleton variant="rect" width={100} height={100}/>
                                </div>

                                <div className="txt">
                                    <span className="goal-title">
                                        <Skeleton variant="rect" width={375} height={36}/>
                                    </span>

                                    <span className="goal-code">
                                        <Skeleton variant="rect" width={375} height={42}/>
                                    </span>
                                </div>
                            </div>

                            <ul className="targets">
                                <li className="target">
                                    <p>
                                        <Skeleton variant="rect" width={490} height={50}/>
                                    </p>

                                    <p>
                                        <Skeleton variant="rect" width={490} height={80}/>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="target-indicators">
                            <Skeleton variant="rect" width={590} height={420}/>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    </GoalsStyled>
);

HomeFeaturedGoalsLoading.propTypes = {
    goals: PropTypes.array.isRequired,
};

export default HomeFeaturedGoalsLoading;
