import { concat, of } from "rxjs";
import { catchError, filter, map, mergeMap, take } from "rxjs/operators";
import { ofType } from "redux-observable";
import { addError } from "modules/ui/reducer";
import { selectCurrentLanguage } from "modules/ui/selectors";
import { selectBearerToken } from "../selectors";
import { fetchContentByGraphQL, setContent } from "../reducer";
import * as constants from "modules/constants";

export default (action$, state$, { apiConfig, ajax, timestamp }) => {
  const request = params => ({
    responseType: "json",
    method: "POST",
    url: `${apiConfig.cmsContentRoot}/graphql`,
    headers: {
      Authorization: `Bearer ${params.bearerToken}`,
      "Content-Type": "application/json",
      "X-Languages": params.language,
      "X-Flatten": true,
    },
    body: {
      query: params.query || {},
    },
  });

  return action$.pipe(
    ofType(fetchContentByGraphQL),
    mergeMap(action =>
      concat(
        of(
          setContent({
            key: `${action.payload.contentType}/${action.payload.contentKey}`,
            status: constants.STATUS_LOADING,
          })
        ),
        state$.pipe(
          map(selectBearerToken),
          filter(Boolean),
          take(1),
          map(() => ({
            ...action.payload,
            storeKey: `${action.payload.contentType}/${action.payload.contentKey}`,
            language: selectCurrentLanguage(state$.value),
            bearerToken: selectBearerToken(state$.value),
          })),
          mergeMap(params =>
            ajax(request(params)).pipe(
              mergeMap(data =>
                of(
                  setContent({
                    key: params.storeKey,
                    status: constants.STATUS_OK,
                    response: { items: [data.response] },
                  })
                )
              ),
              catchError(e =>
                concat(
                  of(setContent({ key: params.storeKey, status: constants.STATUS_ERROR })),
                  of(
                    addError({
                      timestamp: timestamp(),
                      message: "cms.api-error",
                      details: e,
                    })
                  )
                )
              )
            )
          )
        )
      )
    )
  );
};
