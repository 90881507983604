import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { VisualizationContext } from "components/VisualizationContextProviderComponent";
import { IndicatorContext } from "components/IndicatorContextProviderComponent";
import { INDICATORS, VALUES } from "modules/meta-data/constants";
import iconSelectionArrow from "../images/arrow.svg";

const MeasuresStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  .selection-wrapper {
    display: flex;
    position: relative;
    width: 100%;
  }
  .txt-and {
    position: absolute;
    left: 0;
    transform: translateX(-100%);
  }
  select {
    width: 100%;
    margin: 0 15px 15px;
    padding: 0 30px 5px 0;
    position: relative;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(${iconSelectionArrow}) 99% / 0% no-repeat;
    background-size: 12px 17px;
    color: #fff;
    line-height: 1;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    option {
      padding: 15px;
      color: #000;
    }
  }
`;

// if merge -> show in one indicator

const Select = ({ currentUIState, measureMetaData, setCurrentUIState }) => {
  const vzContext = useContext(VisualizationContext);
  const indicatorContext = useContext(IndicatorContext);

  const selectionType = vzContext[currentUIState.visualization]?.measureSelectionType;
  const indicatorConf = indicatorContext[INDICATORS[`i${currentUIState.indicatorCode}`]].viewData;

  const availablePrimaryMeasures = indicatorConf.measures;
  const availableSecondaryMeasures = indicatorConf.measure_secondary;
  const measureMetaDataByKeyCode = measureMetaData.reduce(
    (acc, curVal) => ({
      ...acc,
      [curVal.seriesCode]: curVal,
    }),
    {}
  );

  const handleMeasureChange = e => {
    setCurrentUIState({
      measure: e.target.value,
      measure_secondary:
        selectionType === VALUES.MEASURE_SELECTION_MERGED || !indicatorConf.measures_dependency
          ? ""
          : indicatorConf.measures_dependency[e.target.value],
    });
  };

  if (
    selectionType === VALUES.MEASURE_SELECTION_SEPARATED &&
    Object.keys(indicatorConf.measures_dependency).length > 0
  ) {
    return (
      <MeasuresStyled>
        <select value={currentUIState.measure} onChange={handleMeasureChange}>
          {availablePrimaryMeasures.map(d => {
            const measure = measureMetaDataByKeyCode[d];

            if (measure) {
              return (
                <option key={measure.seriesCode} value={measure.seriesCode}>
                  {measure.seriesDescription}
                </option>
              );
            }
            return null;
          })}
        </select>
        <div className="selection-wrapper">
          <span className="txt-and">and</span>
          <select
            value={currentUIState.measure_secondary}
            style={{ pointerEvents: "none", background: "transparent" }}
            readOnly
          >
            {availableSecondaryMeasures.map(d => {
              const measure = measureMetaDataByKeyCode[d];
              if (measure) {
                return (
                  <option key={measure.seriesCode} value={measure.seriesCode}>
                    {measure.seriesDescription}
                  </option>
                );
              }
              return null;
            })}
          </select>
        </div>
      </MeasuresStyled>
    );
  }
  return (
    <MeasuresStyled>
      <select value={currentUIState.measure} onChange={handleMeasureChange}>
        {[...availablePrimaryMeasures, ...availableSecondaryMeasures].map(d => {
          const measure = measureMetaDataByKeyCode[d];
          if (measure) {
            return (
              <option key={measure.seriesCode} value={measure.seriesCode}>
                {measure.seriesDescription}
              </option>
            );
          }
          return null;
        })}
      </select>
    </MeasuresStyled>
  );
};

Select.propTypes = {
  currentUIState: PropTypes.object.isRequired,
  measureMetaData: PropTypes.array.isRequired,
  setCurrentUIState: PropTypes.func.isRequired,
};
export default React.memo(Select);
