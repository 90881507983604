import { connect } from "react-redux";
import { selectChartMetaData, isSecondarySeriesDataEmpty } from "modules/indicators/selectors/indicators";
import { selectSeriesDataGapChart } from "modules/indicators/selectors/charts/gap-chart";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import GapChart from "./GapChartComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataGapChart(state),
  chartMetaData: selectChartMetaData(state),
  isSecondarySeriesDataEmpty: isSecondarySeriesDataEmpty(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(GapChart);
