import { of, concat } from "rxjs";
import { map, mergeMap, catchError, filter, take } from "rxjs/operators";
import { ofType } from "redux-observable";
import { addError } from "modules/ui/reducer";
import { selectCurrentLanguage } from "modules/ui/selectors";
import { selectBearerToken } from "../selectors";
import { fetchContent, setContent } from "../reducer";
import * as constants from "modules/constants";

export default (action$, state$, { apiConfig, ajax, timestamp }) => {
  const request = params => {
    const defaultQuery = {
      filter: {
        path: "data.key.iv",
        op: "eq",
        value: params.contentKey,
      },
    };

    return {
      responseType: "json",
      method: "GET",
      url: `${apiConfig.cmsContentRoot}/${params.contentType}?q=${JSON.stringify(
        params.query ? params.query : defaultQuery
      )}`,
      headers: {
        Authorization: `Bearer ${params.bearerToken}`,
        "X-Languages": params.language,
        "X-Flatten": true,
      },
    };
  };

  return action$.pipe(
    ofType(fetchContent),
    mergeMap(action =>
      concat(
        of(
          setContent({
            key: `${action.payload.contentType}/${action.payload.contentKey}`,
            status: constants.STATUS_LOADING,
          })
        ),
        state$.pipe(
          map(selectBearerToken),
          filter(Boolean),
          take(1),
          map(() => ({
            ...action.payload,
            storeKey: `${action.payload.contentType}/${action.payload.contentKey}`,
            language: selectCurrentLanguage(state$.value),
            bearerToken: selectBearerToken(state$.value),
          })),
          mergeMap(params =>
            ajax(request(params)).pipe(
              mergeMap(data =>
                of(setContent({ key: params.storeKey, status: constants.STATUS_OK, response: data.response }))
              ),
              catchError(e =>
                concat(
                  of(setContent({ key: params.storeKey, status: constants.STATUS_ERROR })),
                  of(
                    addError({
                      timestamp: timestamp(),
                      message: "cms.api-error",
                      details: e,
                    })
                  )
                )
              )
            )
          )
        )
      )
    )
  );
};
