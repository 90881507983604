import React from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";

const LangProvider = ({ children, language, messages }) => (
  <IntlProvider key={language} locale={language} messages={messages}>
    <>{children}</>
  </IntlProvider>
);

LangProvider.propTypes = {
  children: PropTypes.node,
  language: PropTypes.string,
  messages: PropTypes.shape({}),
};

LangProvider.defaultProps = {
  children: undefined,
  language: "en",
  messages: undefined,
};
export default LangProvider;
