import { createSelector } from "reselect";
import { INDICATORS } from "modules/meta-data/constants";

const getCurrentIndicatorMetaData = state => {
  const indicatorId = INDICATORS[`i${state.indicators.currentUIState.indicatorCode}`];

  return state.metaData.indicators.find(d => +d.id === +indicatorId);
};
const getAllIndicatorsMetaData = state => state.metaData.indicators;
const getTargetsMetaData = state => state.metaData.targets;
const getGoalsMetaData = state => state.metaData.goals;
const getMeasuresMetaData = state => state.metaData.measures;

export const selectIndicatorMetaData = createSelector(
  getCurrentIndicatorMetaData,
  getTargetsMetaData,
  getGoalsMetaData,
  getMeasuresMetaData,
  (currentIndicator, targets, goals, measures) => {
    if (currentIndicator !== undefined) {
      const target = targets.find(d => d.id === +currentIndicator.targetId);
      const goal = target !== undefined ? goals.find(d => +d.id === +target.goalId) : null;
      const measure = measures.filter(d => (!currentIndicator ? [] : d.indicatorId === currentIndicator.id));

      return {
        indicator: currentIndicator,
        target: target,
        goal: goal,
        measures: measure,
      };
    }
    return {
      indicator: null,
      target: null,
      goal: null,
      measures: null,
    };
  }
);

export const selectAllIndicators = createSelector(
  getAllIndicatorsMetaData,
  data => data
);

export const selectAllIndicatorsByCode = createSelector(
  getAllIndicatorsMetaData,
  data => data.reduce((result, cur) => {
    const code = cur.shortDescription.split(" ").pop();
    return {
      ...result,
      [code.toLowerCase()]: cur,
    }
  }, {})
);

export const selectAllTargets = createSelector(
  getTargetsMetaData,
  data => data
);

export const selectAllGoals = createSelector(
  getGoalsMetaData,
  data => data
);
