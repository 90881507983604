import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { setFilterParamsYear } from "modules/indicators/reducer";
import { selectFilterParams } from "modules/indicators/selectors/filter-params";
import { selectFilterDataYear } from "modules/indicators/selectors/filter-data";
import { FormattedMessage } from "react-intl";

const Styled = styled.div`
  display: flex;
  .selection {
    flex: 1 1 40%;
  }
`;

const FilterYearsSinglePoints = () => {
  const dispatch = useDispatch();
  const { year } = useSelector(selectFilterParams) || [];
  const { data } = useSelector(selectFilterDataYear) || [];

  const setYear = value => dispatch(setFilterParamsYear([value]));

  useEffect(() => {
    if (data && data.length > 0) {
      const latestYear = Math.max(...data);
      dispatch(setYear(latestYear));
    }
  }, [data]);

  if (!year || !data || year?.length < 1 || data?.length === 0) return null;

  return (
    <Styled>
      <FormControl className="filter-criteria selection">
        <InputLabel>
          <FormattedMessage id="indicators.search-form.year" defaultMessage="Year" />
        </InputLabel>
        <Select value={year}>
          {data?.map(d => (
            <MenuItem key={`available-year-${d}`} value={d} onClick={() => setYear(d)}>
              {d}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Styled>
  );
};

export default FilterYearsSinglePoints;
