import React from "react";
import Header from "../modules/ui/components/Header";
import Footer from "../modules/ui/components/Footer";
import CMSPage from "modules/cms/components/CMSPage";
import ScrollToTopOnMount from "modules/shared-components/ScrollToTopOnMount";
import useContent from "modules/cms/hooks/useContent";

const About = () => {
  const content = useContent({ contentType: "about", contentKey: "page-about" });

  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      <CMSPage content={content} />
      <Footer />
    </>
  );
};

export default About;
