import { connect } from "react-redux";
import { selectSeriesDataTreeMapChart } from "modules/indicators/selectors/charts/tree-map-chart";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import TreeMapChart from "./TreeMapChartComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataTreeMapChart(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(TreeMapChart);
