import { createSelector } from "reselect";
import { selectSingleCountries } from "modules/indicators/selectors/indicators";
import * as constants from "modules/constants";

const getSeriesData = state => state.indicators.seriesData;

export const selectSeriesDataFlowChart = createSelector(
  getSeriesData,
  selectSingleCountries,
  ({ data, status }, regions) => {
    if (!data || !regions || status === constants.STATUS_LOADING) return null;
    if (data.length === 0) return [];

    return data.reduce(
      (acc, currentVal) => [
        ...acc,
        {
          ...currentVal,
          id: currentVal.id,
          source_id: currentVal.geoAreaId,
          target_id: currentVal.partnerId,
          source_name: regions[currentVal.geoAreaId]?.name,
          target_name: regions[currentVal.partnerId]?.name,
          value: currentVal.value,
        },
      ],
      []
    );
  }
);
