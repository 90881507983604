import { concat, forkJoin, of } from "rxjs";
import { catchError, mergeMap, take } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  fetchMetaData,
  resetMetaData,
  setEconomicRegionsData,
  setGeographicRegionsData,
  setGoalsMetaData,
  setIndicatorsMetaData,
  setMeasuresMetaData,
  setProductsData,
  setRegionsData,
  setTargetsMetaData,
} from "../reducer";
import { addError } from "modules/ui/reducer";

export default (action$, state$, { ajax, apiConfig, timestamp }) => {
  const requests = language =>
    [
      "Metadata/goals",
      "Metadata/indicators",
      "Metadata/measures",
      "Metadata/targets",
      "dimension/regions/economic",
      "dimension/regions/geographic",
      "dimension/regions",
      "dimension/products",
    ].map(endpoint => ({
      responseType: "json",
      method: "GET",
      mode: "cors",
      crossDomain: true,
      url: `${apiConfig.apiRoot}/${endpoint}?lng=${language}`,
      headers: {
        "Ocp-Apim-Subscription-Key": apiConfig.OcpApimSubscriptionKey,
      },
    }));

  const handleResponses = responses => {
    const [
      { response: goalsResponse },
      { response: indicatorsResponse },
      { response: measuresResponse },
      { response: targetsResponse },
      { response: economicRegionsResponse },
      { response: geographicRegionsResponse },
      { response: regionsResponse },
      { response: productsResponse },
    ] = responses;

    return concat(
      of(setGoalsMetaData(goalsResponse)),
      of(setIndicatorsMetaData(indicatorsResponse)),
      of(setMeasuresMetaData(measuresResponse)),
      of(setTargetsMetaData(targetsResponse)),
      of(setEconomicRegionsData(economicRegionsResponse)),
      of(setGeographicRegionsData(geographicRegionsResponse)),
      of(setRegionsData(regionsResponse)),
      of(setProductsData(productsResponse))
    );
  };

  const handleErrors = (error, action) => [
    addError({
      timestamp: timestamp(),
      message: "meta-data.api-error",
      details: error,
      retryAction: action,
    }),
  ];

  return action$.pipe(
    ofType(fetchMetaData),
    take(1),
    mergeMap(action =>
      concat(
        of(resetMetaData()),
        forkJoin(requests(action.payload).map(request => ajax(request))).pipe(
          mergeMap(responses => handleResponses(responses)),
          catchError(error => handleErrors(error, action))
        )
      )
    )
  );
};
