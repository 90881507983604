export default [
  {
    id: 496,
    name: "Mongolia",
    PREF: 4.290413150005044,
    MFN: 4.290413150005044,
    year: 2005,
    iso: "MNG",
  },
  {
    id: 496,
    name: "Mongolia",
    PREF: 4.9552130791857545,
    MFN: 5.231473768447077,
    year: 2017,
    iso: "MNG",
  },
  {
    id: 496,
    name: "Mongolia",
    PREF: 5.056897836076054,
    MFN: 5.056897836076054,
    year: 2010,
    iso: "MNG",
  },
  {
    id: 624,
    name: "Guinea-Bissau",
    PREF: 12.010865363352478,
    MFN: 14.089946498759067,
    year: 2010,
    iso: "GNB",
  },
  {
    id: 624,
    name: "Guinea-Bissau",
    PREF: 12.51660271891844,
    MFN: 14.996456003553032,
    year: 2005,
    iso: "GNB",
  },
  {
    id: 688,
    name: "Serbia",
    PREF: 2.964361182741662,
    MFN: 5.919566508542252,
    year: 2010,
    iso: "SRB",
  },
  {
    id: 688,
    name: "Serbia",
    PREF: 5.618662300711152,
    MFN: 6.290967251206135,
    year: 2005,
    iso: "SRB",
  },
  {
    id: 688,
    name: "Serbia",
    PREF: 1.404568635395658,
    MFN: 6.688702667088781,
    year: 2017,
    iso: "SRB",
  },
  {
    id: 762,
    name: "Tajikistan",
    PREF: 3.612138744094251,
    MFN: 6.875466051450259,
    year: 2005,
    iso: "TJK",
  },
  {
    id: 762,
    name: "Tajikistan",
    PREF: 5.933758355415759,
    MFN: 8.066341889300404,
    year: 2010,
    iso: "TJK",
  },
  {
    id: 840,
    name: "United States of America",
    PREF: 1.5746396182058932,
    MFN: 2.9210596223962016,
    year: 2010,
    iso: "USA",
  },
  {
    id: 840,
    name: "United States of America",
    PREF: 1.6217974351885003,
    MFN: 2.436754194502021,
    year: 2005,
    iso: "USA",
  },
  {
    id: 541,
    name: "Germany",
    PREF: 0.151296973209829,
    MFN: 1.281636258966,
    year: 2017,
    iso: "DEU",
  },
];
