import { connect } from "react-redux";
import { selectChartMetaData, isSecondarySeriesDataEmpty } from "modules/indicators/selectors/indicators";
import { selectSeriesDataScatterPlotChart } from "modules/indicators/selectors/charts/scatter-plot-chart";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import ScatterPlotChart from "./ScatterPlotChartComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataScatterPlotChart(state),
  chartMetaData: selectChartMetaData(state),
  isSecondarySeriesDataEmpty: isSecondarySeriesDataEmpty(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(ScatterPlotChart);
