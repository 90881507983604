import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  btnToggle: {
    textTransform: "none",
    textDecoration: "underline",
    padding: 0,
    margin: "5px 0",
    "&:hover": {
      background: "none",
    },
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxWidth: 600,
    maxHeight: 600,
    transform: "translate(-50%, -50%)",
    background: "#fff",

    padding: 0,
  },
  contentPanel: {
    width: "100%",
    height: "300px",
    overflowY: "scroll",
    background: "#fff",
    color: "#000",
    padding: "15px 30px",
  },
}));

const a11yProps = index => ({
  id: `scrollable-force-tab-${index}`,
  "aria-controls": `scrollable-force-tabpanel-${index}`,
});

const TabPanel = props => {
  const { className, children, value, index, ...other } = props;

  return (
    <div
      className={className}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

TabPanel.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const MetaData = props => {
  const classes = useStyles();
  const { measureMetaData } = props;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button className={classes.btnToggle} onClick={handleOpen}>
        Take a look on Measure Information
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.content}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="on">
              {measureMetaData.map((item, index) => (
                <Tab key={index} label={item.seriesCode} {...a11yProps({ index })} />
              ))}
            </Tabs>
            {measureMetaData.map((item, index) => (
              <TabPanel className={classes.contentPanel} value={value} key={index} index={index}>
                {Object.keys(item).map((k, i) => (
                  <div key={i}>
                    <b>{k}</b>: {item[k]}
                  </div>
                ))}
              </TabPanel>
            ))}
          </AppBar>
        </div>
      </Modal>
    </div>
  );
};

MetaData.propTypes = {
  measureMetaData: PropTypes.array,
};

MetaData.defaultProps = {
  measureMetaData: [],
};

export default MetaData;
