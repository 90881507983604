import { BehaviorSubject } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { contentReducer, contentEpic } from "modules/content";
import { uiReducer, uiEpic } from "./ui";
import { metaDataReducer, metaDataEpic } from "./meta-data";
import { dataAvailabilityReducer } from "./data-availability";
import { indicatorsReducer, indicatorEpic } from "./indicators";
import { reportsReducer, reportsEpic } from "./reports";
import { indicatorsV2Epic, indicatorV2Reducer } from "modules/v2/indicators";

// import { metaDataV2Epic, metaDataV2Reducer } from "modules/v2/meta-data";
import apiConfig, {apisDev} from "./api.config";
import indicatorsConfig from "./indicators.config";
import visualizationsConfig from "./visualizations.config";
import { cmsReducer, cmsEpic } from "modules/cms";

export const history = createBrowserHistory();

const routerReducer = connectRouter(history);
const epic$ = new BehaviorSubject(
  combineEpics(
    uiEpic,
    contentEpic,
    metaDataEpic,
    indicatorEpic,
    reportsEpic,
    indicatorsV2Epic,
    cmsEpic
    /*metaDataV2Epic,*/
  )
);
const rootEpic = (action$, state$, dependencies) =>
  epic$.pipe(
    mergeMap(epic => epic(action$, state$, dependencies)),
    catchError((error, source) => source)
  );

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    ajax,
    apiConfig: location.pathname.includes("&env=dev") ? apisDev:  apiConfig,
    timestamp: () => Date.now(),
    indicatorsConfig,
    visualizationsConfig,
  },
});
const middleWares = [routerMiddleware(history), epicMiddleware];

const rootReducer = combineReducers({
  router: routerReducer,
  ui: uiReducer,
  metaData: metaDataReducer,
  dataAvailability: dataAvailabilityReducer,
  content: contentReducer,
  indicators: indicatorsReducer,
  indicatorsV2: indicatorV2Reducer,
  // metaDataV2: metaDataV2Reducer,
  reports: reportsReducer,
  cms: cmsReducer,
});
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleWares)));

epicMiddleware.run(rootEpic);

export { epic$ };
export default store;
