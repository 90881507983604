import { connect } from "react-redux";
import { selectChartMetaData } from "modules/indicators/selectors/indicators";
import { selectSeriesDataLineChart } from "modules/indicators/selectors/charts/line-chart";
import { selectCurrentVzTitle } from "modules/indicators/selectors/charts/title";
import LineChart from "./LineChartComponent";

const mapStateToProps = state => ({
  data: selectSeriesDataLineChart(state),
  chartMetaData: selectChartMetaData(state),
  title: selectCurrentVzTitle(state),
});

export default connect(
  mapStateToProps,
  null
)(LineChart);
